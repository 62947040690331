<template>
  <v-row align="center" justify="center">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
  </v-row>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable vue/no-unused-components */
import firebase from 'firebase/compat/app';
import _ from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "@/router";

export default {
  name: "login",
  components: {
    Loading
  },
  data: () => ({
    drawer: null,
    isLoading: true
  }),
  mounted() {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          localStorage.removeItem("user");
          router.push("/auth/login");
        },
        err => {
          alert("Oops. " + err.message);
        }
      );
  }
};
</script>