<template>
  <v-app id="inspire">
    <v-content>
      <v-container id="demo">
        <ul>
          <li>
            <a href="/demo/baseline">Baseline</a>
          </li>
          <li>
            <a href="/demo/centered">Centered</a>
          </li>
          <li>
            <a href="/demo/dark">Dark</a>
          </li>
          <li>
            <a href="/demo/googlecontact">Google Contact</a>
          </li>
          <li>
            <a href="/demo/googlekeep">Google Keep</a>
          </li>
          <li>
            <a href="/demo/youtube">Youtube</a>
          </li>
        </ul>
      </v-container>
    </v-content>

    <router-view></router-view>
  </v-app>
</template>
<script>
export default {};
</script>
<style css-scoped>
#demo ul {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 100000;
}
</style>