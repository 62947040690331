
<template>
  <v-container class="fluid pa-0" id="user">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <v-card>
      <v-card-title>
        Profile
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="user.firstname" dense label="Firstname" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="user.lastname" dense label="Lastname" required></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field v-model="user.photoURL" dense label="Photo" required></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="user.phoneNumber" dense label="Telephone" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :disabled="true"
                  v-model="user.role"
                  dense
                  :items="[{text:'Admin',value:'admin'},{text:'Manager',value:'manager'},{text:'Staff',value:'staff'}]"
                  label="Role"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field :disabled="true" v-model="user.email" dense label="Email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="user.password"
                  dense
                  label="Password"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn color="blue darken-1" dark small @click="user_edit(user._id)">Update Profile</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
import firebase from 'firebase/compat/app';

export default {
  name: "profile",
  components: {
    Loading
  },
  computed: {
    ...mapGetters({ currentUser: "user" }),
    user: function() {
      if (this.currentUser !== {}) {
        this.isLoading = false;
        return this.currentUser;
      }
      return {};
    }
  },
  async mounted() {},
  data() {
    return {
      isLoading: true
    };
  },
  methods:{
      // USER_EDIT
    async user_edit() {
      this.isLoading = true;
      const firebaseAuthupdateUser = firebase
        .functions()
        .httpsCallable("firebaseAuthupdateUser");
      const updateUser = {
        email: this.user.email,
        password: this.user.password,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        displayName: this.user.firstname + " " + this.user.lastname,
        phoneNumber: this.user.phoneNumber,
        photoURL: this.user.photoURL,
        role: this.user.role
      };
      try {
        const result = await firebaseAuthupdateUser({
          uid: this.user.uid,
          user: updateUser
        });
        if (result.data.status == 1) {
          // this.close_user_form_dialog();
        } else {
          Vue.notify({
            group: "auth",
            type: "error",
            title: "Important message",
            text: JSON.stringify(result.data.error)
          });
        }
        console.log(result);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  }
};
</script>