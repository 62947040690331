<template>
  <v-container
    class="fluid pa-0 wealthplus-container export-pdf"
    id="education"
  >
    <v-row class="editable">
      <v-col cols="12">
        <ul>
          <li>
            <v-btn small color="primary" @click="saveChanges()">
              <v-icon small>mdi-content-save</v-icon>Save Changes
            </v-btn>
          </li>
          <li>
            <v-btn small :color="btnEditColor" @click="toggleShowEdit()">
              <v-icon small>mdi-lead-pencil</v-icon>Edit
            </v-btn>
          </li>
          <li v-if="enableFormula">
            <v-btn small :color="btnFormulaColor" @click="toggleShowFormula()">
              <v-icon small>mdi-calculator</v-icon>Formula
            </v-btn>
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <!-- <v-img src="../../assets/img/004-EDU.jpg" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>-->

      <div
        class="wrapper-content"
        id="educationPage"
        v-bind:class="{ 'print-able': printAble, '': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          <div class="primary-content -education">
            <div id="educationTitle" class="title-page">
              <div class="detail">
                <h2>Education Plan</h2>
                <h3>วางแผนการศึกษาสำหรับ น้อง{{ education.cell.M2.value }}</h3>
                <p>
                  ทุกลมหายใจของคนเป็นพ่อเป็นแม่ คือ ลูก แน่นอนว่าคุณพ่อ
                  คุณแม่ย่อมอยากให้ลูกได้รับสิ่งที่ดีที่สุด
                  แต่การเลี้ยงลูกยุคนี้ ไม่ใช่เรื่องง่ายและใช้เงินไม่น้อย
                  ฉะนั้นต้องวางแผนการเงินให้ รัดกุม
                  เพื่อให้ลูกได้รับสิ่งที่ดีอย่างที่ตั้งใจไว้
                </p>
              </div>
                            
              <img id="educationTitleImage" src="../../assets/img/education/title-page-img.png" alt />
            </div>

            <div class="box-content _pd-0">
              <div class="timeline">
                <div class="title-timeline">
                  <p>จำนวนปีสะสมเงิน {{ education.cell.G8.value }} ปี</p>
                  <p>ปริญญาตรี 4 ปี</p>
                  <p>ปริญญาโท 2 ปี</p>
                </div>

                <div class="detail">
                  <div class="column">
                    <p>
                      {{ education.cell.C7.value }} ปี |
                      {{ education.cell.C6.value }}
                    </p>
                    <p>
                      <strong>เริ่มสะสมเงิน</strong>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      {{ education.cell.M7.value }} ปี |
                      {{ education.cell.M6.value }}
                    </p>
                    <p>
                      <strong>{{
                        education.cell.AB12.value | numeralFormat
                      }}</strong>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      {{ education.cell.O7.value }} ปี |
                      {{ education.cell.O6.value }}
                    </p>
                    <p>
                      <strong>{{
                        education.cell.AB13.value | numeralFormat
                      }}</strong>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      {{ education.cell.S7.value }} ปี |
                      {{ education.cell.S6.value }}
                    </p>
                    <p>
                      <strong>{{
                        education.cell.AB14.value | numeralFormat
                      }}</strong>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      {{ education.cell.V7.value }} ปี |
                      {{ education.cell.V6.value }}
                    </p>
                    <p>
                      <strong>{{
                        education.cell.AB15.value | numeralFormat
                      }}</strong>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      {{ education.cell.Z7.value }} ปี |
                      {{ education.cell.Z6.value }}
                    </p>
                    <p>
                      <strong>{{
                        education.cell.AB22.value | numeralFormat
                      }}</strong>
                    </p>
                  </div>
                  <div class="column">
                    <p>
                      {{ education.cell.AB7.value }} ปี |
                      {{ education.cell.AB6.value }}
                    </p>
                    <p>
                      <strong>{{
                        education.cell.AB23.value | numeralFormat
                      }}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div style="display: none" class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>ระดับปริญญาตรี</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <small>ค่าใช้จ่ายที่คุณต้องเตรียม</small>
                      <strong>{{
                        education.cell.AB17.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <div class="row">
                        <p>ค่าใช้จ่ายในการเรียนปัจจุบัน</p>
                      </div>
                      <div class="row">
                        <p>
                          <strong>{{
                            education.cell.G13.value | numeralFormat
                          }}</strong
                          >ต่อเดือน
                        </p>
                        <p>
                          <strong>{{
                            education.cell.L13.value | numeralFormat
                          }}</strong
                          >ต่อปี
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p>ผลคำนวณค่าใช้จ่ายในอนาคต*</p>
                      </div>
                      <div class="row">
                        <p>
                          <strong>{{
                            education.cell.AB12.value | numeralFormat
                          }}</strong>
                          {{ education.cell.M7.value }} ปี |
                          {{ education.cell.M6.value }}
                        </p>
                        <p>
                          <strong>{{
                            education.cell.AB13.value | numeralFormat
                          }}</strong>
                          {{ education.cell.O7.value }} ปี |
                          {{ education.cell.O6.value }}
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p>
                          * อัตราเงินเฟ้อการศึกษา
                          {{
                            education.cell.M19.value | numeralFormat("0,0.00")
                          }}% ต่อปี
                        </p>
                      </div>
                      <div class="row">
                        <p>
                          <strong>{{
                            education.cell.AB14.value | numeralFormat
                          }}</strong>
                          {{ education.cell.S7.value }} ปี |
                          {{ education.cell.S6.value }}
                        </p>
                        <p>
                          <strong>{{
                            education.cell.AB15.value | numeralFormat
                          }}</strong>
                          {{ education.cell.V7.value }} ปี |
                          {{ education.cell.V6.value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>ระดับปริญญาตรี</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <small>ค่าใช้จ่ายที่คุณต้องเตรียม</small>
                      <strong>{{
                        education.cell.AB17.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <div class="row">
                        <p>ค่าใช้จ่ายในการเรียนปัจจุบัน</p>
                        <span class="backup" v-if="showFormula"
                          >&nbsp;G13 = {{ education.cell["G13"].formula }}</span
                        >
                      </div>
                      <div class="row">
                        <p v-show="!showEdit">
                          <strong>{{
                            education.cell.G13.value | numeralFormat
                          }}</strong
                          >ต่อเดือน
                        </p>
                        <p
                          style="
                            max-width: 100px;
                            padding: 0px !important;
                            margin: 0px !important;
                          "
                          v-show="showEdit"
                          v-for="(item, index) in {
                            M50: dataInputSheet.form.education.cell.M50,
                          }"
                          v-bind:key="index"
                        >
                          <strong>
                            <v-text-field
                              :ref="`dataInput.cell.${index}`"
                              :type="item.type"
                              :value="
                                dataInputSheet.cell[index]
                                  ? dataInputSheet.cell[index].value
                                  : ''
                              "
                              @change="
                                onDataInputChange(
                                  `dataInput.cell.${index}`,
                                  $event,
                                  item.type
                                )
                              "
                            ></v-text-field> </strong
                          >ต่อเดือน
                        </p>
                        <p>
                          <strong>{{
                            education.cell.L13.value | numeralFormat
                          }}</strong
                          >ต่อปี
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p>ผลคำนวณค่าใช้จ่ายในอนาคต*</p>
                      </div>
                      <div class="row">
                        <p>
                          <strong>{{
                            education.cell.AB12.value | numeralFormat
                          }}</strong>
                          {{ education.cell.M7.value }} ปี |
                          {{ education.cell.M6.value }}
                        </p>
                        <p>
                          <strong>{{
                            education.cell.AB13.value | numeralFormat
                          }}</strong>
                          {{ education.cell.O7.value }} ปี |
                          {{ education.cell.O6.value }}
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p v-show="!showEdit">
                          * อัตราเงินเฟ้อการศึกษา<span
                            class="backup"
                            v-if="showFormula"
                            >&nbsp;M19 =
                            {{ education.cell["M19"].formula }}</span
                          >
                          {{
                            education.cell.M19.value | numeralFormat("0,0.00")
                          }}% ต่อปี
                        </p>
                        <p
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A80: dataInputSheet.form.education.cell.A80,
                          }"
                          v-bind:key="index"
                        >
                          * อัตราเงินเฟ้อการศึกษา
                          <span class="backup" v-if="showFormula"
                            >&nbsp;M19 =
                            {{ education.cell["M19"].formula }}</span
                          >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="% ต่อปี"
                          ></v-text-field>
                        </p>
                      </div>
                      <div class="row">
                        <p>
                          <strong>{{
                            education.cell.AB14.value | numeralFormat
                          }}</strong>
                          {{ education.cell.S7.value }} ปี |
                          {{ education.cell.S6.value }}
                        </p>
                        <p>
                          <strong>{{
                            education.cell.AB15.value | numeralFormat
                          }}</strong>
                          {{ education.cell.V7.value }} ปี |
                          {{ education.cell.V6.value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>ระดับปริญญาโท</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <small>ค่าใช้จ่ายที่คุณต้องเตรียม</small>
                      <strong>{{
                        education.cell.AB25.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <div class="row">
                        <p>ค่าใช้จ่ายในการเรียนปัจจุบัน</p>
                        <span class="backup" v-if="showFormula"
                          >&nbsp;G15 = {{ education.cell["G15"].formula }}</span
                        >
                      </div>
                      <div class="row">
                        <p v-show="!showEdit">
                          <strong>{{
                            education.cell.G15.value | numeralFormat
                          }}</strong
                          >ต่อเดือน
                        </p>
                        <p
                          style="
                            max-width: 100px;
                            padding: 0px !important;
                            margin: 0px !important;
                          "
                          v-show="showEdit"
                          v-for="(item, index) in {
                            M51: dataInputSheet.form.education.cell.M51,
                          }"
                          v-bind:key="index"
                        >
                          <strong>
                            <v-text-field
                              :ref="`dataInput.cell.${index}`"
                              :type="item.type"
                              :value="
                                dataInputSheet.cell[index]
                                  ? dataInputSheet.cell[index].value
                                  : ''
                              "
                              @change="
                                onDataInputChange(
                                  `dataInput.cell.${index}`,
                                  $event,
                                  item.type
                                )
                              "
                            ></v-text-field> </strong
                          >ต่อเดือน
                        </p>
                        <p>
                          <strong>{{
                            education.cell.L15.value | numeralFormat
                          }}</strong
                          >ต่อปี
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p>ผลคำนวณค่าใช้จ่ายในอนาคต*</p>
                      </div>
                      <div class="row">
                        <p>
                          <strong>{{
                            education.cell.AB22.value | numeralFormat
                          }}</strong>
                          {{ education.cell.Z7.value }} ปี |
                          {{ education.cell.Z6.value }}
                        </p>
                        <p>
                          <strong>{{
                            education.cell.AB23.value | numeralFormat
                          }}</strong>
                          {{ education.cell.AB7.value }} ปี |
                          {{ education.cell.AB6.value }}
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p v-show="!showEdit">
                          * อัตราเงินเฟ้อการศึกษา<span
                            class="backup"
                            v-if="showFormula"
                            >&nbsp;M19 =
                            {{ education.cell["M19"].formula }}</span
                          >
                          {{
                            education.cell.M19.value | numeralFormat("0,0.00")
                          }}% ต่อปี
                        </p>
                        <p
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A80: dataInputSheet.form.education.cell.A80,
                          }"
                          v-bind:key="index"
                        >
                          * อัตราเงินเฟ้อการศึกษา<span
                            class="backup"
                            v-if="showFormula"
                            >&nbsp;M19 =
                            {{ education.cell["M19"].formula }}</span
                          >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="% ต่อปี"
                          ></v-text-field>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green _bor-b-0">
                <header class="heading">
                  <div class="column">
                    <h3>
                      จำนวนเงินสำหรับแผนการศึกษาของ น้อง{{
                        education.cell.M2.value
                      }}
                      ทั้งหมด
                    </h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong>{{
                        education.cell.AB35.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </header>

                <div class="group-chart">
                  <div class="column-25">
                    <div
                      class="box-chart"
                      style="position: relative; width: 136px; height: 136px"
                    >
                      <img
                        v-show="!enableChartJs"
                        src="../../assets/img/education/img-chart-01.png"
                        alt
                      />
                      <div
                        v-show="enableChartJs"
                        class="absolute-center-parent mimi"
                      >
                        <div
                          v-bind:class="
                            education.cell.AB17.value > 0 ? '_c-green' : ''
                          "
                        >
                          {{
                            (100 *
                              (education.cell.AB17.value /
                                education.cell.AB35.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </div>
                      </div>
                      <div
                        style="
                          width: 136px;
                          height: 136px;
                          position: absolute;
                          z-index: 1;
                          top: 0;
                        "
                        v-show="enableChartJs"
                      >
                        <DoughnutChart
                          :width="136"
                          :height="136"
                          :chartDataSet="getChartDataSet('AB17')"
                          :chartOptions="getChartOptions('AB17')"
                        ></DoughnutChart>
                      </div>
                      <!-- {{ 100*(education.cell.AB17.value/education.cell.AB35.value) | numeralFormat("0,0.0")}} -->
                    </div>
                    <div class="detail">
                      <p>
                        ค่าใช้จ่ายสำหรับปริญญาตรี
                        <strong
                          v-bind:class="
                            education.cell.AB17.value > 0 ? '_c-green' : ''
                          "
                          >{{
                            education.cell.AB17.value | numeralFormat
                          }}</strong
                        >
                      </p>
                    </div>
                  </div>
                  <div class="column-25">
                    <div
                      class="box-chart"
                      style="position: relative; width: 136px; height: 136px"
                    >
                      <img
                        v-show="!enableChartJs"
                        src="../../assets/img/education/img-chart-02.png"
                        alt
                      />
                      <div
                        v-show="enableChartJs"
                        class="absolute-center-parent mimi"
                      >
                        <div
                          v-bind:class="
                            education.cell.AB25.value > 0 ? '_c-green' : ''
                          "
                        >
                          {{
                            (100 *
                              (education.cell.AB25.value /
                                education.cell.AB35.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </div>
                      </div>
                      <div
                        style="
                          width: 136px;
                          height: 136px;
                          position: absolute;
                          z-index: 1;
                          top: 0;
                        "
                        v-show="enableChartJs"
                      >
                        <DoughnutChart
                          :width="136"
                          :height="136"
                          :chartDataSet="getChartDataSet('AB25')"
                          :chartOptions="getChartOptions('AB25')"
                        ></DoughnutChart>
                      </div>
                      <!-- {{ 100*(education.cell.AB25.value/education.cell.AB35.value) | numeralFormat("0,0.0")}} -->
                    </div>
                    <div class="detail">
                      <p>
                        ค่าใช้จ่ายสำหรับปริญญาโท
                        <strong
                          v-bind:class="
                            education.cell.AB25.value > 0 ? '_c-green' : ''
                          "
                          >{{
                            education.cell.AB25.value | numeralFormat
                          }}</strong
                        >
                      </p>
                    </div>
                  </div>
                  <div class="column-100">
                    <div style="position: absolute; width: 450px">
                      <div
                        class="box-chart"
                        style="
                          position: relative;
                          width: 136px;
                          height: 136px;
                          margin-left: 156px;
                        "
                      >
                        <img
                          v-show="!enableChartJs"
                          src="../../assets/img/education/img-chart-03.png"
                          alt
                        />
                        <div
                          style="
                            width: 136px;
                            height: 136px;
                            position: absolute;
                            z-index: 1;
                            top: 0;
                          "
                          v-show="enableChartJs"
                        >
                          <DoughnutChart
                            :width="136"
                            :height="136"
                            :chartDataSet="getChartDataSet('AB37A40')"
                            :chartOptions="getChartOptions('AB37A40')"
                          ></DoughnutChart>
                        </div>
                        <!-- {{
                          (100 *
                            (education.cell.AB37.value /
                              education.cell.AB35.value))
                            | numeralFormat("0,0.0")
                        }} -->
                      </div>
                    </div>
                    <div class="column-25">
                      <div
                        class="box-chart"
                        style="
                          position: relative;
                          width: 136px;
                          height: 136px;
                          padding-top: 56px;
                        "
                      >
                        <p class="_c-green -fs-26">
                          {{
                            (100 *
                              (education.cell.AB37.value /
                                education.cell.AB35.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </p>
                      </div>
                      <div class="detail">
                        <p>
                          เงินส่วนที่เตรียมไว้แล้ว
                          <strong
                            v-bind:class="
                              education.cell.AB37.value > 0 ? '_c-green' : ''
                            "
                            >{{
                              education.cell.AB37.value | numeralFormat
                            }}</strong
                          >
                        </p>
                      </div>
                    </div>
                    <div class="column-25">
                      <div
                        class="box-chart"
                        style="
                          position: relative;
                          width: 136px;
                          height: 136px;
                          padding-top: 56px;
                        "
                      >
                        <p class="_c-orange -fs-26">
                          {{
                            (100 *
                              (education.cell.AB40.value /
                                education.cell.AB35.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </p>
                      </div>
                      <div class="detail">
                        <p>
                          เงินส่วนที่เตรียมเพิ่ม
                          <strong
                            v-bind:class="
                              education.cell.AB40.value > 0 ? '_c-orange' : ''
                            "
                            >{{
                              education.cell.AB40.value | numeralFormat
                            }}</strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="false" class="heading">
                  <div class="column">
                    <h3>ส่วนที่เตรียมไว้แล้ว</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong>{{
                        education.cell.AB37.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </div>

                <table v-if="false" class="already-prepared">
                  <tbody>
                    <tr>
                      <td>-</td>
                      <td>
                        เตรียมเงินก้อน แบบชำระเบี้ยครั้งเดียว ( investment Link
                        Single Premium)
                        <p class="backup" v-if="showFormula">
                          &nbsp;M25 = {{ education.cell["M25"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M25.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A81: dataInputSheet.form.education.cell.A81,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O25.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                        ฝากรายงวดธนาคาร (A/C) ฝากประจำอัตราดอกเบี้ย 0-2%
                        หลังหักภาษีเหลือ
                        <p class="backup" v-if="showFormula">
                          &nbsp;M27 = {{ education.cell["M27"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M27.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A82: dataInputSheet.form.education.cell.A82,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O27.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                        ซื้อพันธบัตร/ตราสารหนี้/ประกันชีวิตสะสมทรัพย์
                        (Bond/MMF/Endownment)
                        <p class="backup" v-if="showFormula">
                          &nbsp;M30 = {{ education.cell["M30"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M30.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A83: dataInputSheet.form.education.cell.A83,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O30.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                        ลงทุนในหุ้น กองทุนรวมชั้นดี อัตราผลตอบแทน (MF 6-8% )
                        <p class="backup" v-if="showFormula">
                          &nbsp;M32 = {{ education.cell["M32"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit"
                          >{{
                            education.cell.M32.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A84: dataInputSheet.form.education.cell.A84,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O32.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                        Investment Link ไม่หักภาษี/ลดภาษีได้
                        ซื้อครั้งเดียวได้กองทุนจำนวนมาก
                        (การันตีคุ้มครองด้วยทุนประกันชีวิตควบหน่วยลงทุนในกองทุนรวม
                        มีเครื่องมือบริหารด้วยระบบ DCA & Auto Rebalancing)
                        <p class="backup" v-if="showFormula">
                          &nbsp;M35 = {{ education.cell["M35"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M35.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A85: dataInputSheet.form.education.cell.A85,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O35.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <small>
                          * การคำนวณผลตอบแทน เป็นผลตอบแทนสุทธิ
                          ที่พิจารณาจากผลตอบแทนย้อนหลัง ยังไม่รวมค่าธรรมเนียม
                          ดังนั้นจึงไม่การันตีอนาคต
                          ผู้ลงทุนต้องศึกษาข้อมูลให้ดีก่อนตัดสินใจลงทุน
                          ละต้องดูค่าธรรมเนียม ของแต่ละบริษัท
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <footer class="footer -orange">
                  <div class="column">
                    <h3>
                      จำนวนเงินสำหรับแผนการศึกษาของ น้อง{{
                        education.cell.M2.value
                      }}
                      ทั้งหมด
                    </h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong>{{
                        education.cell.AB40.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </footer>

                 <table class="already-prepared">
                  <tbody>
                    <tr>
                      <td>-</td>
                      <td>
                        <span v-show="!showEdit" v-if="education.cell.A810.value==''">เตรียมเงินก้อน แบบชำระเบี้ยครั้งเดียว ( investment Link Single Premium)</span>
                        <span v-show="!showEdit" v-if="education.cell.A810.value!=''">{{education.cell.A810.value}}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A810: dataInputSheet.form.education.cell.A810,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                        <p class="backup" v-if="showFormula">
                          &nbsp;M25 = {{ education.cell["M25"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M25.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A81: dataInputSheet.form.education.cell.A81,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O25.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>                        
                        <span v-show="!showEdit" v-if="education.cell.A820.value==''">ฝากรายงวดธนาคาร (A/C) ฝากประจำอัตราดอกเบี้ย 0-2% หลังหักภาษีเหลือ</span>
                        <span v-show="!showEdit" v-if="education.cell.A820.value!=''">{{education.cell.A820.value}}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A820: dataInputSheet.form.education.cell.A820,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                        <p class="backup" v-if="showFormula">
                          &nbsp;M27 = {{ education.cell["M27"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M27.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A82: dataInputSheet.form.education.cell.A82,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O27.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>                        
                        <span v-show="!showEdit" v-if="education.cell.A830.value==''">ซื้อพันธบัตร/ตราสารหนี้/ประกันชีวิตสะสมทรัพย์ (Bond/MMF/Endownment)</span>
                        <span v-show="!showEdit" v-if="education.cell.A830.value!=''">{{education.cell.A830.value}}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A830: dataInputSheet.form.education.cell.A830,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                        <p class="backup" v-if="showFormula">
                          &nbsp;M30 = {{ education.cell["M30"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M30.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A83: dataInputSheet.form.education.cell.A83,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O30.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>                        
                        <span v-show="!showEdit" v-if="education.cell.A840.value==''">ลงทุนในหุ้น กองทุนรวมชั้นดี อัตราผลตอบแทน (MF 6-8% )</span>
                        <span v-show="!showEdit" v-if="education.cell.A840.value!=''">{{education.cell.A840.value}}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A840: dataInputSheet.form.education.cell.A840,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                        <p class="backup" v-if="showFormula">
                          &nbsp;M32 = {{ education.cell["M32"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit"
                          >{{
                            education.cell.M32.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A84: dataInputSheet.form.education.cell.A84,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O32.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>                        
                        <span v-show="!showEdit" v-if="education.cell.A850.value==''">Investment Link ไม่หักภาษี/ลดภาษีได้
                        ซื้อครั้งเดียวได้กองทุนจำนวนมาก
                        (การันตีคุ้มครองด้วยทุนประกันชีวิตควบหน่วยลงทุนในกองทุนรวม
                        มีเครื่องมือบริหารด้วยระบบ DCA & Auto Rebalancing)</span>
                        <span v-show="!showEdit" v-if="education.cell.A850.value!=''">{{education.cell.A850.value}}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A850: dataInputSheet.form.education.cell.A850,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                        <p class="backup" v-if="showFormula">
                          &nbsp;M35 = {{ education.cell["M35"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            education.cell.M35.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            A85: dataInputSheet.form.education.cell.A85,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ education.cell.O35.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <small>
                          * การคำนวณผลตอบแทน เป็นผลตอบแทนสุทธิ
                          ที่พิจารณาจากผลตอบแทนย้อนหลัง ยังไม่รวมค่าธรรมเนียม
                          ดังนั้นจึงไม่การันตีอนาคต
                          ผู้ลงทุนต้องศึกษาข้อมูลให้ดีก่อนตัดสินใจลงทุน
                          ละต้องดูค่าธรรมเนียม ของแต่ละบริษัท
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- DATA -->
    <v-row v-if="showBackup">
      <v-col cols="12">
        <h2>Education</h2>
      </v-col>
    </v-row>
    <v-row v-if="showBackup">
      <v-col cols="12">
        <h3>ลูกคนที่ 1</h3>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(item, index) in education.cell"
        v-bind:key="index"
      >
        <v-text-field
          :dense="true"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
          :placeholder="item.placeholder ? item.placeholder : ''"
          :suffix="item.suffix ? item.suffix : ''"
          :disabled="true"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { mapGetters, mapActions } from "vuex";
import DoughnutChart from "../ChartJs/DoughnutChart.js";
export default {
  name: "education-component",
  components: {
    DoughnutChart,
  },
  props: {
    printAble: Boolean,
    enableChartJs: Boolean,
    enableFormula: Boolean,
  },
  computed: {
    ...mapGetters(["dataInputSheet", "education", "education1", "user"]),
  },
  created() {
    console.log("education - created");
  },
  mounted() {
    console.log("education - mounted");
  },
  methods: {
    ...mapActions(["updateDataInput"]),
    getChartOptions(section) {
      return {
        tooltips: {
          enabled: false,
        },
      };
    },
    getChartDataSet(section) {
      let dataset = {};

      if (section == "AB17") {
        dataset.labels = ["ค่าใช้จ่ายสำหรับปริญญาตรี", ""];
        if (this.education.cell.AB17.value != undefined) {
          dataset.data = [
            this.education.cell.AB17.value,
            this.education.cell.AB35.value - this.education.cell.AB17.value,
          ];
        }
        dataset.backgroundColor = ["#2b999f", "#e1e1e1"];
        return dataset;
      } else if (section == "AB25") {
        dataset.labels = ["ค่าใช้จ่ายสำหรับปริญญาโท", ""];
        if (this.education.cell.AB25.value != undefined) {
          dataset.data = [
            this.education.cell.AB25.value,
            this.education.cell.AB35.value - this.education.cell.AB25.value,
          ];
        }
        dataset.backgroundColor = ["#2b999f", "#e1e1e1"];
        return dataset;
      } else if (section == "AB37") {
        dataset.labels = ["เงินส่วนที่เตรียมไว้แล้ว", ""];
        if (this.education.cell.AB37.value != undefined) {
          dataset.data = [
            this.education.cell.AB37.value,
            this.education.cell.AB35.value - this.education.cell.AB37.value,
          ];
        }
        dataset.backgroundColor = ["#2b999f", "#e1e1e1"];
        return dataset;
      } else if (section == "AB40") {
        dataset.labels = ["เงินส่วนที่เตรียมเพิ่ม", ""];
        if (this.education.cell.AB40.value != undefined) {
          dataset.data = [
            this.education.cell.AB40.value,
            this.education.cell.AB35.value - this.education.cell.AB40.value,
          ];
        }
        dataset.backgroundColor = ["#fd8d50", "#e1e1e1"];
        return dataset;
      } else if (section == "AB37A40") {
        dataset.labels = ["เงินส่วนที่เตรียมเพิ่ม", "เงินส่วนที่เตรียมไว้แล้ว"];
        if (this.education.cell.AB37.value != undefined) {
          dataset.data = [
            this.education.cell.AB40.value,
            this.education.cell.AB37.value,
          ];
        }
        dataset.backgroundColor = ["#fd8d50", "#2b999f"];
        return dataset;
      } else {
        return {};
      }
    },
    onDataInputChange: function (name, value, type) {
      var keys = name.split(".");
      var sheet = keys[0];
      var cell = keys[2];

      this.updateDataInput({
        sheet: sheet,
        cell: cell,
        value: value,
        type: type,
      });
    },
    toggleShowEdit() {
      this.showEdit = !this.showEdit;
      if (this.showEdit) {
        this.btnEditColor = "warning";
      } else {
        this.btnEditColor = "default";
      }
    },
    toggleShowFormula() {
      this.showFormula = !this.showFormula;
      if (this.showFormula) {
        this.btnFormulaColor = "warning";
      } else {
        this.btnFormulaColor = "default";
      }
    },
    saveChanges() {
      this.showEdit = false;
      this.btnEditColor = "default";
      this.$parent.$refs.dataInputComponent.saveChanges();
    },
  },
  data: function () {
    return {
      btnEditColor: "default",
      btnFormulaColor: "default",
      showBackup: false,
      showFormula: false,
      showEdit: false,
    };
  },
};
</script>