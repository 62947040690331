<template>
  <div>
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <Navigation :print-able="printAble"></Navigation>
    <!-- <v-row style="position: fixed; left: 550px; top: 189px; z-index: 9999">
      <v-btn @click="print()">print home</v-btn>
      <img :src="output" />
    </v-row> -->
    <!-- <div id="pdf" style="margin-top:50px; min-height:200px;border:2px solid red;"></div> -->
    <img id="whitebar" src="../../assets/img/whitebar.png" />
    <DataInput    
      ref="dataInputComponent"
      v-show="
        !navigationItems.dataInput.disabled && navigationItems.dataInput.active
      "
    ></DataInput>
    <BalanceSheet
      :print-able="printAble"
      :enable-chart-js="enableChartJs"
      :enable-formula="enableFormula"
      v-show="
        !navigationItems.balanceSheet.disabled &&
        navigationItems.balanceSheet.active
      "
    ></BalanceSheet>
    <CashFlow
      :print-able="printAble"
      :enable-chart-js="enableChartJs"
      :enable-formula="enableFormula"
      v-show="
        !navigationItems.cashFlow.disabled && navigationItems.cashFlow.active
      "
    ></CashFlow>
    <FinancialRatio
      :print-able="printAble"
      :enable-chart-js="enableChartJs"
      v-show="
        !navigationItems.financialRatio.disabled &&
        navigationItems.financialRatio.active
      "
    ></FinancialRatio>
    <Education
      :print-able="printAble"
      :enable-chart-js="enableChartJs"
      :enable-formula="enableFormula"
      v-show="
        !navigationItems.education.disabled && navigationItems.education.active
      "
    ></Education>
    <Education1
      :print-able="printAble"
      :enable-chart-js="enableChartJs"
      :enable-formula="enableFormula"
      v-if="
        !navigationItems.education1.disabled &&
        navigationItems.education1.active
      "
    ></Education1>
    <Retirement
      :print-able="printAble"
      :enable-chart-js="enableChartJs"
      :enable-formula="enableFormula"
      v-show="
        !navigationItems.retirement.disabled &&
        navigationItems.retirement.active
      "
    ></Retirement>
    <Retirement1
      :print-able="printAble"
      :enable-chart-js="enableChartJs"
      :enable-formula="enableFormula"
      v-show="
        !navigationItems.retirement1.disabled &&
        navigationItems.retirement1.active
      "
    ></Retirement1>
    <Tax
      :print-able="printAble"
      :enable-formula="enableFormula"
      v-show="!navigationItems.tax.disabled && navigationItems.tax.active"
    ></Tax>
    <HealthAndFamilyIncomeProtection
      :print-able="printAble"
      :enable-formula="enableFormula"
      v-show="!navigationItems.health.disabled && navigationItems.health.active"
    ></HealthAndFamilyIncomeProtection>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import Vue from "vue";
import NProgress from "nprogress";

import domtoimage from "dom-to-image";
import jsPDF from "jspdf";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Navigation from "@/components/Plan/Navigation.vue";
import DataInput from "@/components/Plan/DataInput.vue";

import BalanceSheet from "@/components/Plan/BalanceSheet.vue";
import CashFlow from "@/components/Plan/CashFlow.vue";
import FinancialRatio from "@/components/Plan/FinancialRatio.vue";
import Education from "@/components/Plan/Education.vue";
import Education1 from "@/components/Plan/Education1.vue";
import Retirement from "@/components/Plan/Retirement.vue";
import Retirement1 from "@/components/Plan/Retirement1.vue";
import Tax from "@/components/Plan/Tax.vue";
import HealthAndFamilyIncomeProtection from "@/components/Plan/HealthAndFamilyIncomeProtection.vue";

import $ from "jquery";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan-add-view",
  props: {
    source: String,
  },
  components: {
    Loading,
    Navigation,
    DataInput,
    BalanceSheet,
    CashFlow,
    FinancialRatio,
    Education,
    Education1,
    Retirement,
    Retirement1,
    Tax,
    HealthAndFamilyIncomeProtection,
  },
  data() {
    return {
      dialog: false,
      isLoading: true,
      customer_name: "",
      printAble: false,
      enableChartJs: false,
      enableFormula: true,
      output: null,
    };
  },
  /**
   * https://alligator.io/vuejs/component-lifecycle/
   * In the created hook, you will be able to access reactive data and events are active.
   * Templates and Virtual DOM have not yet been mounted or rendered.
   */
  created() {
    if (this.$route.name == "plan-edit" || this.$route.name == "plan-detail") {
      this.loadDataInput({ id: this.$route.params.id });
    } else {
      this.loadDataInput({});
    }
    //this.loadDataInput({});
    //this.loadDataInput({ id: 0 });

    // console.log("hook created");
    // if(this.$route.name == "plan-edit" || this.$route.name == "plan-detail"){
    //   this.planID = this.$route.params.id;
    //   this.navigationItems.dataInput.disabled = true;
    //   this.navigationItems.dataInput.active = false;
    // }
    // console.log(this.$route);
    // console.log(this.$route.params);
    // console.log(this.planID);
  },
  /**
   * https://alligator.io/vuejs/component-lifecycle/
   * In the mounted hook, you will have full access to the reactive component, templates, and rendered DOM (via. this.$el).
   * Mounted is the most-often used lifecycle hook.
   * The most frequently used patterns are fetching data for your component (use created for this instead,) and modifying the DOM, often to integrate non-Vue libraries
   */
  mounted() {    
    console.log("hook mounted");
    console.log("isSafari",this.$browserDetect.isSafari);

    // use arrow function because `this` variable scope
    setTimeout(() => {
      this.isLoading = false;
      NProgress.done();
      this.showDrawer();
    }, 1000);

    setTimeout(() => {
      console.log("mounted plan added");
      // document.getElementsByName("dataInput.cell.C4")[0].value = "Kergrit";
      // this.updateDataInput({
      //   sheet: "dataInput",
      //   cell: "C4",
      //   value: "Kergrit",
      //   type: "text",
      // });
      // this.updateDataInput({
      //   sheet: "dataInput",
      //   cell: "K3",
      //   value: 1000,
      //   type: "number",
      // });

      // dataInput.cell.K3
      // this.$refs.dataInputComponent.saveChanges();
      // console.log("saved");
    }, 5000);

    setInterval(function () {
      console.log("interval auto save 10m");
      this.$refs.dataInputComponent.saveChanges();
      // 1000*60*10
      }, 1000*60*10);
  },
  /**
   * https://alligator.io/vuejs/component-lifecycle/
   * The updated hook runs after data changes on your component and the DOM re-renders.
   * If you need to access the DOM after a property change, here is probably the safest place to do it.
   */
  updated() {
    // console.log("hook updated");
  },
  methods: {
    ...mapActions(["loadDataInput", "toggleDrawer", "updateDataInput","showDrawer"]),
    async print() {
      /**
       * A4 Paper dimesion
       * https://www.graphic-design-employment.com/a4-paper-dimensions.html
       * Standard ISO A4 paper dimensions are:
       * 210mm x 297mm
       * 8.27" x 11.69"
       *
       * Equivalent A4 paper dimensions in pixels at 300 DPI and 72 DPI respectively are:
       * 2480 pixels x 3508 pixels (print resolution)
       * 595 pixels x 842 pixels (screen resolution)
       */

      this.isLoading = true;

      var doc = new jsPDF();
      var allPages = [
        "CoverPage",
        "SummaryPage",
        "balanceSheetPage",
        "cashFlowPage",
        "financialRatioPage",
      ];

      if (this.navigationItems.education.progress == 100) {
        allPages.push("educationPage");
      }
      if (this.navigationItems.education1.progress == 100) {
        allPages.push("education1Page");
      }
      if (this.navigationItems.retirement.progress == 100) {
        allPages.push("retirementPage");
      }
      if (this.navigationItems.retirement1.progress == 100) {
        allPages.push("retirement1Page");
      }
      if (this.navigationItems.tax.progress == 100) {
        allPages.push("taxPage");
      }
      if (this.navigationItems.health.progress == 100) {
        allPages.push("healthAndFamilyIncomeProtectionPage");
      }
      // !navigationItems.education1.disabled && navigationItems.education1.active

      // var allPages = ["healthAndFamilyIncomeProtectionPage"];
      var page_id,
        el,
        png,
        png_resize,
        png_dimesion,
        x,
        y,
        w1,
        h1,
        w2,
        h2,
        pfd_page;

      var whitebar_img = document.getElementById("whitebar");

      // var html2canvasEle = ["CoverPage","SummaryPage","balanceSheetPage","cashFlowPage"];  
      var html2canvasEle = ["CoverPage","SummaryPage"];  

      // el = document.getElementById("cashFlowChart1");
      // await this.$html2canvas(el, {type: 'dataURL'}).then(function(dataUrl){
      //   png = dataUrl;
      //   var img = new Image();
      //   img.src = dataUrl;
      //   img.style = "width:200px; border:2px solid blue;";
      //   document.getElementById("pdf").appendChild(img);
      // }).catch(function (error) {
      //   console.error("oops:html2canvas, something went wrong!", error);
      // });
      // el = document.getElementById("balanceSheetChart2");
      // await this.$html2canvas(el, {type: 'dataURL'}).then(function(dataUrl){
      //   png = dataUrl;
      //   var img = new Image();
      //   img.src = dataUrl;
      //   img.style = "width:200px; border:2px solid blue;";
      //   document.getElementById("pdf").appendChild(img);
      // }).catch(function (error) {
      //   console.error("oops:html2canvas, something went wrong!", error);
      // });
               
      for (var i = 0; i < allPages.length; i++) {
        console.log("generate pdf : " + allPages[i]);
        page_id = allPages[i];

        el = document.getElementById(allPages[i]);
        if (el === null) continue;

        if(html2canvasEle.includes(allPages[i])){
          var dataUrl = await this.$html2canvas(el, {type: 'dataURL'}).then(dataUrl).catch(function (error) {
            console.error("oops:html2canvas, something went wrong!", error);
          });
          png = dataUrl;

          // var img = new Image();
          // img.src = dataUrl;
          // img.style = "width:200px; border:2px solid blue;";
          // document.getElementById("pdf").appendChild(img);

        }else{
        // console.log(el.childNodes[0]);
        png = await domtoimage
          .toPng(el, { bgcolor: '#FF0000',cacheBust:true, quality: 1 })
          .then((dataUrl) => {
            // this.output = dataUrl;
            // var img = new Image();
            // img.src = dataUrl;
            // img.style = "width:200px; padding:2px;";
            // document.getElementById("pdf").appendChild(img);

            return dataUrl;
          })
          .catch(function (error) {
            console.error("oops:domtoimage, something went wrong!", error);
          });
        }
        
        // SOLUTION #2
        // png_resize = await this.resizeImage(png)
        //   .then((obj) => {
        //     return obj;
        //   })
        //   .catch(function (error) {
        //     console.error("oops, something went wrong! - resizeImage", error);
        //   });
        // console.log(png_resize);

        // SOLUTION #1
        png_dimesion = await this.addImageProcess(png)
          .then((obj) => {
            return obj;
          })
          .catch(function (error) {
            console.error(
              "oops, something went wrong! - addImageProcess",
              error
            );
          });

        w1 = png_dimesion.width;
        h1 = png_dimesion.height;
        w2 = 210.0015555555555;
        h2 = (h1 / w1) * w2;
        pfd_page = Math.ceil(h2 / doc.internal.pageSize.height);

        console.log("jpeg : ", w1, h1);
        console.log(
          "pdf : ",
          doc.internal.pageSize.width,
          doc.internal.pageSize.height
        );
        console.log("jpeg aspect : ", w2, h2);

        
        // custom print for CoverPage
        if (page_id == "CoverPage") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
        }
        // custom print for SummaryPage
        else if (page_id == "SummaryPage") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
        }
        // custom print for balanceSheetPage
        else if (page_id == "balanceSheetPage") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 286, 211, 14, undefined, "FAST");
          
          el = document.getElementById("balanceSheetChart1");
          await this.$html2canvas(el, {type: 'dataURL'}).then(function(dataUrl){    
            // ratio = 1.452784503631961            
            doc.addImage(dataUrl, "PNG", 14.4, 56.4, 74.8*1.013698630136986, 74.8, undefined, "FAST");
          }).catch(function (error) {
            console.error("oops:html2canvas, something went wrong!", error);
          });

          // page #2
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 1 + 14,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 270, 211, 30, undefined, "FAST");
          
          el = document.getElementById("balanceSheetChart2");
          await this.$html2canvas(el, {type: 'dataURL'}).then(function(dataUrl){    
            // ratio = 1.452784503631961            
            doc.addImage(dataUrl, "PNG", 14.4, 116, 74.8*1.013698630136986, 74.8, undefined, "FAST");
          }).catch(function (error) {
            console.error("oops:html2canvas, something went wrong!", error);
          });

          // page #3
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 2 + 44,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
          // custom print for balanceSheetPage
        } else if (page_id == "cashFlowPage") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 267, 211, 30, undefined, "FAST");

          el = document.getElementById("cashFlowChart1");
          await this.$html2canvas(el, {type: 'dataURL'}).then(function(dataUrl){    
            // ratio = 1.452784503631961            
            doc.addImage(dataUrl, "PNG", 14.4, 77.5, 74.8*1.013698630136986, 74.8, undefined, "FAST");
          }).catch(function (error) {
            console.error("oops:html2canvas, something went wrong!", error);
          });

          // page #2
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 1 + 36,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 264, 211, 35, undefined, "FAST");

          // page #3
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 2 + 36 + 38,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
        }
        // custom print for financialRatioPage
        else if (page_id == "financialRatioPage") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 293, 211, 30, undefined, "FAST");
          // page #2
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 1 + 8,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
        }
        // custom print for educationPage
        else if (page_id == "educationPage" || page_id == "education1Page") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 232, 211, 70, undefined, "FAST");

          el = document.getElementById("educationTitleImage");
          await this.$html2canvas(el, {backgroundColor:null,type: 'dataURL'}).then(function(dataUrl){    
            // ratio = 1.452784503631961            
            doc.addImage(dataUrl, "PNG", 86.50, 7.10, 84.8*1.452784503631961, 84.8, undefined, "FAST");
          }).catch(function (error) {
            console.error("oops:html2canvas, something went wrong!", error);
          });

          // page #2
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 1 + 72,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
        }
        // custom print for retirementPage
        else if (page_id == "retirementPage" || page_id == "retirement1Page") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 275, 211, 30, undefined, "FAST");

          el = document.getElementById("retirementTitleImage");
          await this.$html2canvas(el, {backgroundColor:null,type: 'dataURL'}).then(function(dataUrl){    
            // ratio = 1.359338061465721            
            doc.addImage(dataUrl, "PNG", 92, 7.10, 86.5*1.359338061465721, 86.5, undefined, "FAST");
          }).catch(function (error) {
            console.error("oops:html2canvas, something went wrong!", error);
          });

          // page #2
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 1 + 27,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
        }
        // custom print for taxPage
        else if (page_id == "taxPage") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 290, 211, 30, undefined, "FAST");
          // page #2
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 1 + 12,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 290, 211, 35, undefined, "FAST");
          // page #3
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 2 + 12 + 12,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
        } else if (page_id == "healthAndFamilyIncomeProtectionPage") {
          if (i > 0) doc.addPage();
          doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
          doc.addImage(whitebar_img, "PNG", 0, 282, 211, 20, undefined, "FAST");
        
          el = document.getElementById("healthTitleImage");
          await this.$html2canvas(el, {backgroundColor:null,type: 'dataURL'}).then(function(dataUrl){    
            // ratio = 1.554123711340206            
            doc.addImage(dataUrl, "PNG", 86.3, 7.05, 79.35*1.554123711340206, 79.35, undefined, "FAST");
          }).catch(function (error) {
            console.error("oops:html2canvas, something went wrong!", error);
          });
          // page #2
          doc.addPage();
          doc.addImage(
            png,
            "PNG",
            0,
            -297.0000833333333 * 1 + 20,
            w2,
            h2,
            undefined,
            "FAST"
          );
          doc.addImage(whitebar_img, "PNG", 0, -5, 211, 10, undefined, "FAST");
        } else {
          for (var curr_page = 0; curr_page < pfd_page; curr_page++) {
            if (curr_page == 0) {
              if (i > 0) doc.addPage();
              doc.addImage(png, "JPEG", 0, 0, w2, h2, undefined, "FAST");
            } else {
              doc.addPage();
              doc.addImage(
                png,
                "PNG",
                0,
                -297.0000833333333 * curr_page,
                w2,
                h2,
                undefined,
                "FAST"
              );
            }
          }
        }

        // SOLUTION #2
        // if (i > 0) doc.addPage();
        // w1 = png_resize.width;
        // h1 = png_resize.height;
        // w2 = 210.0015555555555;
        // h2 = (h1 / w1) * w2;
        // doc.addImage(png_resize.src, "PNG", 0, 0, w2, h2, undefined, "FAST");

        Vue.notify({
          group: "plan",
          type: "success",
          title: "Important message",
          text: "Capture page : " + allPages[i] + " Successful!",
        });
      }

      doc.save("estest-report.pdf");

      this.isLoading = false;
    },
    async addImageProcess(src) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = reject;
        img.src = src;
      });
    },
    async resizeImage(src) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = function () {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");

          var src_width = this.width;
          var src_height = this.height;
          var src_ratio = src_height / src_width;

          var dest_width = 595; // for A4
          var dest_height = src_ratio * dest_width;

          // We set the dimensions at the wanted size.
          canvas.width = dest_width;
          canvas.height = dest_height;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, dest_width, dest_height);

          var dataURI = canvas.toDataURL();
          this.src = dataURI;

          resolve({ src: this.src, width: dest_width, height: dest_height });
        };
        img.onerror = reject;
        img.src = src;
      });
    },
  },
  computed: {
    ...mapGetters(["navigationItems", "drawer"]),
    isLoadingComputed: function () {
      // console.log("isLoadingComputed", this.isLoading);
      return this.isLoading;
    },
  },
  watch: {
    isLoading: function (oldVal, newVal) {
      oldVal, newVal;
      // console.log("isLoading", oldVal, newVal);
    },
  },
};
</script>
