<template>
  <v-app id="inspire"> 
    <notifications group="auth" />
    <notifications style="z-index:99999" group="plan" />    
    <router-view></router-view>
  </v-app>
</template>
<script>
// import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  components: {
    // HelloWorld
  },

  data: () => ({
    //
  })
};
</script>
