export const navigation = {
  dataInput: {
    label: "Data Input",
    icon: "mdi-table-edit",
    color: "blue-grey",
    disabled: false,
    progress: 100,
    active: true,
  },
  balanceSheet: {
    label: "Balance Sheet",
    icon: "mdi-home-city",
    color: "primary",
    disabled: true,
    progress: 0,
    active: false,
  },
  cashFlow: {
    label: "Cash Flow",
    icon: "mdi-cash-multiple",
    color: "green",
    disabled: true,
    progress: 0,
    active: false,
  },
  financialRatio: {
    label: "Financial Ratio",
    icon: "mdi-chart-pie",
    color: "red",
    disabled: true,
    progress: 0,
    active: false,
  },
  education: {
    label: "Education",
    icon: "mdi-school",
    color: "amber",
    disabled: true,
    progress: 0,
    active: false,
  },
  education1: {
    label: "Education1",
    icon: "mdi-school",
    color: "amber",
    disabled: true,
    progress: 0,
    active: false,
  },
  retirement: {
    label: "Retirement",
    icon: "mdi-incognito",
    color: "teal",
    disabled: true,
    progress: 0,
    active: false,
  },
  retirement1: {
    label: "Retirement1",
    icon: "mdi-incognito",
    color: "teal",
    disabled: true,
    progress: 0,
    active: false,
  }, 
  health: {
    icon: "mdi-shield-check",
    label: "Health",
    color: "purple",
    disabled: true,
    progress: 0,
    active: false,
  },
  tax: {
    label: "Tax",
    icon: "mdi-calculator",
    color: "pink",
    disabled: true,
    progress: 0,
    active: false,
  }
};
