<template>
  <v-container
    class="fluid pa-0 wealthplus-container export-pdf"
    id="healthAndFamilyIncomeProtection"
  >
    <v-row class="editable">
      <v-col cols="12">
        <ul>
          <li>
            <v-btn small color="primary" @click="saveChanges()">
              <v-icon small>mdi-content-save</v-icon>Save Changes
            </v-btn>
          </li>
          <li>
            <v-btn small :color="btnEditColor" @click="toggleShowEdit()">
              <v-icon small>mdi-lead-pencil</v-icon>Edit
            </v-btn>
          </li>
          <li v-if="enableFormula">
            <v-btn small :color="btnFormulaColor" @click="toggleShowFormula()">
              <v-icon small>mdi-calculator</v-icon>Formula
            </v-btn>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <!-- <v-img src="../../assets/img/007-FHP.jpg" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>-->

      <div
        class="wrapper-content"
        id="healthAndFamilyIncomeProtectionPage"
        v-bind:class="{ 'print-able': printAble, '': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          <div class="primary-content -health-family">
            <div class="title-page">
              <div class="detail">
                <h2 style="font-size: 40px">
                  Health Insurance<br />
                  & Family Income<br />
                  Protection
                </h2>
                <h3>การคุ้มครองสุขภาพและการป้องกันรายได้ของครอบครัว</h3>
                <p>
                  สำหรับคนที่มีครอบครัว และทำประกันสุขภาพเพื่อคุ้มครองตัวเอง
                  ก็ควรพิจารณาทำ "ประกันสุขภาพเพื่อคุ้มครองคนในครอบครัว"
                  ด้วยเพื่อความสบายใจของทุกคนในครอบครัว
                  การโอนย้ายความเสี่ยงในด้านของสุขภาพ, ด้านค่าความสามารถ
                </p>
              </div>

              <img id="healthTitleImage" 
                src="../../assets/img/health-family/title-page-img.png"
                alt
              />
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>ความคุ้มครองสุขภาพอุบัติเหตุ</h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <p class="backup" v-if="showFormula">
                        HC3 = {{ health.cell["HC3"].formula }}
                      </p>
                      <p v-show="!showEdit">
                        โรงพยาบาลที่ใช้บริการ
                        <strong>{{ health.cell.HC3.value }}</strong>
                      </p>

                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC3: dataInputSheet.form.health.cell.HC3,
                        }"
                        v-bind:key="index"
                      >
                        โรงพยาบาลที่ใช้บริการ
                        <v-text-field
                          style="width: 100px; display: inline-block"
                          :ref="`dataInput.cell.${index}`"
                          :type="item.type"
                          :value="
                            dataInputSheet.cell[index]
                              ? dataInputSheet.cell[index].value
                              : ''
                          "
                          @change="
                            onDataInputChange(
                              `dataInput.cell.${index}`,
                              $event,
                              item.type
                            )
                          "
                        ></v-text-field>
                      </p>
                    </div>
                    <div class="column">
                      <p class="backup" v-if="showFormula">
                        HC4 = {{ health.cell["HC4"].formula }}
                      </p>
                      <p v-show="!showEdit">
                        ค่าห้อง ค่าบริการ /วัน
                        <strong>{{
                          health.cell.HC4.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC4: dataInputSheet.form.health.cell.HC4,
                        }"
                        v-bind:key="index"
                      >
                        ค่าห้อง ค่าบริการ /วัน
                        <v-text-field
                          style="width: 100px; display: inline-block"
                          :ref="`dataInput.cell.${index}`"
                          :type="item.type"
                          :value="
                            dataInputSheet.cell[index]
                              ? dataInputSheet.cell[index].value
                              : ''
                          "
                          @change="
                            onDataInputChange(
                              `dataInput.cell.${index}`,
                              $event,
                              item.type
                            )
                          "
                        ></v-text-field>
                      </p>
                    </div>
                    <div class="column">
                      <p class="backup" v-if="showFormula">
                        HC5 = {{ health.cell["HC5"].formula }}
                      </p>
                      <p v-show="!showEdit">
                        งบประมาณในการดูแลสุขภาพ / ปี
                        <strong>{{
                          health.cell.HC5.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC5: dataInputSheet.form.health.cell.HC5,
                        }"
                        v-bind:key="index"
                      >
                        งบประมาณในการดูแลสุขภาพ / ปี
                        <v-text-field
                          style="width: 100px; display: inline-block"
                          :ref="`dataInput.cell.${index}`"
                          :type="item.type"
                          :value="
                            dataInputSheet.cell[index]
                              ? dataInputSheet.cell[index].value
                              : ''
                          "
                          @change="
                            onDataInputChange(
                              `dataInput.cell.${index}`,
                              $event,
                              item.type
                            )
                          "
                        ></v-text-field>
                      </p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="column">
                      <div class="row">
                        <h4 class="_c-green">สวัสดิการที่เตรียมไว้แล้ว</h4>
                      </div>
                      <div class="row">
                        <p v-show="!showEdit">
                          <span class="backup" v-if="showFormula"
                            >HC7 = {{ health.cell["HC7"].formula }}<br
                          /></span>
                          ค่าห้อง ค่าบริการ /วัน
                          <strong class="_block _c-green">{{
                            health.cell.HC7.value | numeralFormat
                          }}</strong>
                        </p>
                        <p
                          v-show="showEdit"
                          v-for="(item, index) in {
                            HC7: dataInputSheet.form.health.cell.HC7,
                          }"
                          v-bind:key="index"
                        >
                          <span class="backup" v-if="showFormula"
                            >HC7 = {{ health.cell["HC7"].formula }}<br
                          /></span>
                          ค่าห้อง ค่าบริการ /วัน
                          <strong class="_block _c-green">
                            <v-text-field
                              style="width: 150px; display: inline-block"
                              :ref="`dataInput.cell.${index}`"
                              :type="item.type"
                              :value="
                                dataInputSheet.cell[index]
                                  ? dataInputSheet.cell[index].value
                                  : ''
                              "
                              @change="
                                onDataInputChange(
                                  `dataInput.cell.${index}`,
                                  $event,
                                  item.type
                                )
                              "
                            ></v-text-field>
                          </strong>
                        </p>
                        <p v-show="!showEdit">
                          <span class="backup" v-if="showFormula"
                            >HC8 = {{ health.cell["HC8"].formula }}<br
                          /></span>
                          วงเงินค่ารักษาพยาบาลเหมาจ่าย
                          <strong class="_block _c-green">{{
                            health.cell.HC8.value | numeralFormat
                          }}</strong>
                        </p>
                        <p
                          v-show="showEdit"
                          v-for="(item, index) in {
                            HC8: dataInputSheet.form.health.cell.HC8,
                          }"
                          v-bind:key="index"
                        >
                          <span class="backup" v-if="showFormula"
                            >HC8 = {{ health.cell["HC8"].formula }}<br
                          /></span>
                          วงเงินค่ารักษาพยาบาลเหมาจ่าย
                          <strong class="_block _c-green">
                            <v-text-field
                              style="width: 150px; display: inline-block"
                              :ref="`dataInput.cell.${index}`"
                              :type="item.type"
                              :value="
                                dataInputSheet.cell[index]
                                  ? dataInputSheet.cell[index].value
                                  : ''
                              "
                              @change="
                                onDataInputChange(
                                  `dataInput.cell.${index}`,
                                  $event,
                                  item.type
                                )
                              "
                            ></v-text-field>
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <h4 class="_c-orange">สวัสดิการที่ควรเตรียมเพิ่ม</h4>
                      </div>
                      <div class="row">
                        <p>
                          ค่าห้อง ค่าบริการ /วัน
                          <strong class="_block _c-orange">{{
                            health.cell.HC10.value | numeralFormat
                          }}</strong>
                        </p>
                        <p>
                          วงเงินค่ารักษาพยาบาลเหมาจ่าย
                          <strong class="_block _c-orange">{{
                            health.cell.HC11.value | abs | numeralFormat
                          }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>ความคุ้มครองโรคร้ายแรง</h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula"
                          >HC13 = {{ health.cell["HC13"].formula }}<br
                        /></span>
                        รายได้ /ปี ที่อาจสูเสียไป
                        <strong class="_block">{{
                          health.cell.HC13.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC13: dataInputSheet.form.health.cell.HC13,
                        }"
                        v-bind:key="index"
                      >
                        <span class="backup" v-if="showFormula"
                          >HC13 = {{ health.cell["HC13"].formula }}<br
                        /></span>
                        รายได้ /ปี ที่อาจสูเสียไป
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula"
                          >HC14 = {{ health.cell["HC14"].formula }}<br
                        /></span>
                        จำนวนปีที่ต้องรักษา
                        <strong class="_block">{{
                          health.cell.HC14.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC14: dataInputSheet.form.health.cell.HC14,
                        }"
                        v-bind:key="index"
                      >
                        <span class="backup" v-if="showFormula"
                          >HC14 = {{ health.cell["HC14"].formula }}<br
                        /></span>
                        จำนวนปีที่ต้องรักษา
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        จำนวนเงินที่ต้องเตรียม
                        <strong class="_block">{{
                          health.cell.HC15.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula">HC16 = {{ health.cell["HC16"].formula }}<br></span>
                        เงินก้อนที่เตรียมไว้แล้ว
                        <strong class="_block _c-green">{{
                          health.cell.HC16.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC16: dataInputSheet.form.health.cell.HC16,
                        }"
                        v-bind:key="index"
                      >
                        <span class="backup" v-if="showFormula">HC16 = {{ health.cell["HC16"].formula }}<br></span>
                        เงินก้อนที่เตรียมไว้แล้ว
                        <strong class="_block _c-green">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        เงินสำรองที่ขาดอยู่
                        <strong class="_block _c-orange">{{
                          health.cell.HC17.value | abs | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>การปกป้องรายได้ของหัวหน้าครอบครัว</h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula">HC19 = {{ health.cell["HC19"].formula }}<br></span>
                        ค่าใช้จ่ายที่รับผิดชอบครอบครัว/ปี
                        <strong>{{
                          health.cell.HC19.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC19: dataInputSheet.form.health.cell.HC19,
                        }"
                        v-bind:key="index"
                      >
                      <span class="backup" v-if="showFormula">HC19 = {{ health.cell["HC19"].formula }}<br></span>
                        ค่าใช้จ่ายที่รับผิดชอบครอบครัว/ปี
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula">HC20 = {{ health.cell["HC20"].formula }}<br></span>
                        จำนวนปีที่ต้องการคุ้มครอง
                        <strong>{{
                          health.cell.HC20.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC20: dataInputSheet.form.health.cell.HC20,
                        }"
                        v-bind:key="index"
                      >
                      <span class="backup" v-if="showFormula">HC20 = {{ health.cell["HC20"].formula }}<br></span>
                        จำนวนปีที่ต้องการคุ้มครอง
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula">HC21 = {{ health.cell["HC21"].formula }}<br></span>
                        เงินเฟ้อ
                        <strong
                          >{{
                            health.cell.HC21.value | numeralFormat("0,0[.]00")
                          }}%</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC21: dataInputSheet.form.health.cell.HC21,
                        }"
                        v-bind:key="index"
                      >
                      <span class="backup" v-if="showFormula">HC21 = {{ health.cell["HC21"].formula }}<br></span>
                        เงินเฟ้อ
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula">HC22 = {{ health.cell["HC22"].formula }}<br></span>
                        อัตราผลตอบแทนกองทุน
                        <strong
                          >{{
                            health.cell.HC22.value | numeralFormat("0,0[.]00")
                          }}%</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC22: dataInputSheet.form.health.cell.HC22,
                        }"
                        v-bind:key="index"
                      >
                      <span class="backup" v-if="showFormula">HC22 = {{ health.cell["HC22"].formula }}<br></span>
                        อัตราผลตอบแทนกองทุน
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="column">
                      <p>
                        จำนวนเงินที่ต้องเตรียม
                        <strong class="_c-orange _f32">{{
                          health.cell.HC23.value | abs | numeralFormat("0,0[.]00")
                        }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -orange">
                <header class="heading">
                  <div class="column">
                    <h3>หนี้สินคงค้าง</h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula">HC26 = {{ health.cell["HC26"].formula }}<br></span>
                        หนี้สินคงค้างระยะสั้น
                        <strong class="_block _c-orange">{{
                          health.cell.HC26.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC26: dataInputSheet.form.health.cell.HC26,
                        }"
                        v-bind:key="index"
                      >
                      <span class="backup" v-if="showFormula">HC26 = {{ health.cell["HC26"].formula }}<br></span>
                        หนี้สินคงค้างระยะสั้น
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p v-show="!showEdit">
                        <span class="backup" v-if="showFormula">HC27 = {{ health.cell["HC27"].formula }}<br></span>
                        หนี้สินคงค้างระยะยาว
                        <strong class="_block _c-orange">{{
                          health.cell.HC27.value | numeralFormat
                        }}</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          HC27: dataInputSheet.form.health.cell.HC27,
                        }"
                        v-bind:key="index"
                      >
                      <span class="backup" v-if="showFormula">HC27 = {{ health.cell["HC27"].formula }}<br></span>
                        หนี้สินคงค้างระยะยาว
                        <strong class="_block">
                          <v-text-field
                            style="width: 150px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        รวมหนี้สินคงค้าง
                        <strong class="_block _c-orange">{{
                          health.cell.HC28.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        ทุนคุ้มครองรายได้ครอบครัวที่ต้องเตรียมเพิ่ม
                        <strong class="_block _c-orange">{{
                          health.cell.HC30.value | numeralFormat("0,0[.]00")
                        }}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="column">
                      <div class="row">
                        <h4 class="_c-orange">สินทรัพย์เงินสดที่เตรียมไว้</h4>
                      </div>
                      <div class="row">
                        <p v-show="!showEdit">
                          <span class="backup" v-if="showFormula">HC33 = {{ health.cell["HC33"].formula }}<br></span>
                          ทุนประกัน
                          <strong class="_block _c-green">{{
                            health.cell.HC33.value | numeralFormat
                          }}</strong>
                        </p>
                        <p
                          v-show="showEdit"
                          v-for="(item, index) in {
                            HC33: dataInputSheet.form.health.cell.HC33,
                          }"
                          v-bind:key="index"
                        >
                        <span class="backup" v-if="showFormula">HC33 = {{ health.cell["HC33"].formula }}<br></span>
                          ทุนประกัน
                          <strong class="_block">
                            <v-text-field
                              style="width: 150px; display: inline-block"
                              :ref="`dataInput.cell.${index}`"
                              :type="item.type"
                              :value="
                                dataInputSheet.cell[index]
                                  ? dataInputSheet.cell[index].value
                                  : ''
                              "
                              @change="
                                onDataInputChange(
                                  `dataInput.cell.${index}`,
                                  $event,
                                  item.type
                                )
                              "
                            ></v-text-field>
                          </strong>
                        </p>
                        <p>
                          รวมสินทรัพย์ที่สามารถแปลงเป็นเงินสดเตรียมไว้
                          <strong class="_block _c-green">{{
                            health.cell.HC34.value | numeralFormat
                          }}</strong>
                        </p>
                        <p>
                          ความคุ้มครองรายได้ที่ขาดอยู่
                          <strong class="_block _c-orange">{{
                            health.cell.HC35.value | abs | numeralFormat("0,0[.]00")
                          }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- DATA -->
    <v-row class="content-hide">
      <v-col cols="12">
        <h2>Tax</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        v-for="(item, index) in health.cell"
        v-bind:key="index"
      >
        <v-text-field
          :disabled="true"
          :dense="true"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
          :placeholder="item.placeholder ? item.placeholder : 'ระบุจำนวน'"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "health-and-family-income-protect-component",
  props: {
    printAble: Boolean,
    enableFormula: Boolean,
  },
  computed: {
    ...mapGetters(["dataInputSheet", "health", "user"]),
  },
  methods: {
    ...mapActions(["updateDataInput"]),
    onDataInputChange: function (name, value, type) {
      var keys = name.split(".");
      var sheet = keys[0];
      var cell = keys[2];

      this.updateDataInput({
        sheet: sheet,
        cell: cell,
        value: value,
        type: type,
      });
    },
    toggleShowEdit() {
      this.showEdit = !this.showEdit;
      if (this.showEdit) {
        this.btnEditColor = "warning";
      } else {
        this.btnEditColor = "default";
      }
    },
    toggleShowFormula() {
      this.showFormula = !this.showFormula;
      if (this.showFormula) {
        this.btnFormulaColor = "warning";
      } else {
        this.btnFormulaColor = "default";
      }
    },
    saveChanges() {
      this.showEdit = false;
      this.btnEditColor = "default";
      this.$parent.$refs.dataInputComponent.saveChanges();
    },
  },
  data: function () {
    return {
      btnEditColor: "default",
      btnFormulaColor: "default",
      showBackup: false,
      showFormula: false,
      showEdit: false,
    };
  },
  filters: {
  abs: function (value) {    
    if (!value) return 0;    
    return Math.abs(value);
  }
}
};
</script>