<template>
  <v-container class="fluid pa-0 wealthplus-container export-pdf" id="tax">
    <v-row class="editable">
      <v-col cols="12">
        <ul>
          <li>
            <v-btn small color="primary" @click="saveChanges()">
              <v-icon small>mdi-content-save</v-icon>Save Changes
            </v-btn>
          </li>
          <li>
            <v-btn small :color="btnEditColor" @click="toggleShowEdit()">
              <v-icon small>mdi-lead-pencil</v-icon>Edit
            </v-btn>
          </li>
          <li v-if="enableFormula">
            <v-btn small :color="btnFormulaColor" @click="toggleShowFormula()">
              <v-icon small>mdi-calculator</v-icon>Formula
            </v-btn>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <!-- <v-img src="../../assets/img/006-TAX.jpg" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>-->

      <div
        class="wrapper-content"
        id="taxPage"
        v-bind:class="{ 'print-able': printAble, '': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          <div class="primary-content -tax">
            <div class="title-page">
              <div class="detail">
                <h2>Tax Planning</h2>
                <h3>
                  รายการภาษีปี {{ planItem.createAt | momentTaxTH }} สำหรับ คุณ
                  {{ tax.cell.A1.value }}
                </h3>
              </div>

              <img src="../../assets/img/tax/title-page-img.png" alt />
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <div class="heading">
                  <div class="column">
                    <h3>เงินได้พึงประเมิน หลังหักค่าใช้จ่าย</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong>{{ tax.cell.TJ13.value | numeralFormat }}</strong>
                    </h3>
                  </div>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>เงินได้ มาตรา 40(1)-40(8)</th>
                      <th>เงินได้ทั้งปี</th>
                      <th class="_c-orange">หักค่าใช้จ่าย</th>
                      <th>เงินได้</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        (1) เงินเดือน โบนัส ค่าจ้าง บำนาญ
                        <p class="backup" v-if="showFormula">
                          TH5 = {{ tax.cell["TH5"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TH5.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH5: dataInputSheet.form.tax.cell.TH5,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td rowspan="2" class="_c-orange">
                        {{ tax.cell.TI5.value | numeralFormat }}
                      </td>
                      <td rowspan="2">
                        {{ tax.cell.TJ5.value | numeralFormat }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        (2) ค่านายหน้า ค่ารับจ้างทำของ
                        <p class="backup" v-if="showFormula">
                          TH6 = {{ tax.cell["TH6"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TH6.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH6: dataInputSheet.form.tax.cell.TH6,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td class="_hide"></td>
                    </tr>
                    <tr>
                      <td>
                        (3) ลิขสิทธิ์ (หักค่าใช้จ่ายตามจริง หรือเหมา 50% ไม่เกิน
                        100,000)
                        <p class="backup" v-if="showFormula">
                          TH7 = {{ tax.cell["TH7"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TH7.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH7: dataInputSheet.form.tax.cell.TH7,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td class="_c-orange">
                        {{ tax.cell.TI7.value | numeralFormat }}
                      </td>
                      <td>{{ tax.cell.TJ7.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>
                        (4) ดอกเบี้ย เงินปันผล (หักค่าใช้จ่ายไม่ได้)
                        <p class="backup" v-if="showFormula">
                          TH8 = {{ tax.cell["TH8"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TH8.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH8: dataInputSheet.form.tax.cell.TH8,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td class="_c-orange">0</td>
                      <td>{{ tax.cell.TJ8.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>
                        (5) ค่าเช่า (บ้าน,รถ 30% ที่ดินเกษตร 20% ที่ดินเปล่า 15%
                        อื่นๆ 10%)
                        <p class="backup" v-if="showFormula">
                          TH9 = {{ tax.cell["TH9"].formula }}, TI9 =
                          {{ tax.cell["TI9"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TH9.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH9: dataInputSheet.form.tax.cell.TH9,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td class="_c-orange">
                        <span v-show="!showEdit">
                          {{ tax.cell.TI9.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI9: dataInputSheet.form.tax.cell.TI9,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ tax.cell.TJ9.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>
                        (6) วิชาชีพอิสระ (แพทย์ 60% อื่นๆ 30%)
                        <p class="backup" v-if="showFormula">
                          TH10 = {{ tax.cell["TH10"].formula }}, TI10 =
                          {{ tax.cell["TI10"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">{{
                          tax.cell.TH10.value | numeralFormat
                        }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH10: dataInputSheet.form.tax.cell.TH10,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td class="_c-orange">
                        <span v-show="!showEdit">
                          {{ tax.cell.TI10.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI10: dataInputSheet.form.tax.cell.TI10,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ tax.cell.TJ10.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>
                        (7) รับเหมาโดยการจัดหาของให้ (ตามจริง หรือเหมา 60%)
                        <p class="backup" v-if="showFormula">
                          TH11 = {{ tax.cell["TH11"].formula }}, TI11 =
                          {{ tax.cell["TI11"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">{{
                          tax.cell.TH11.value | numeralFormat
                        }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH11: dataInputSheet.form.tax.cell.TH11,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td class="_c-orange">
                        <span v-show="!showEdit">
                          {{ tax.cell.TI11.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI11: dataInputSheet.form.tax.cell.TI11,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ tax.cell.TJ11.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>
                        (8) อื่นๆ (ตามจริง หรือเหมา 60%)
                        <p class="backup" v-if="showFormula">
                          TH12 = {{ tax.cell["TH12"].formula }}, TI12 =
                          {{ tax.cell["TI12"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">{{
                          tax.cell.TH12.value | numeralFormat
                        }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH12: dataInputSheet.form.tax.cell.TH12,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td class="_c-orange">
                        <span v-show="!showEdit">
                          {{ tax.cell.TI12.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI12: dataInputSheet.form.tax.cell.TI12,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ tax.cell.TJ12.value | numeralFormat }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <table>
                  <thead>
                    <tr class="-highlight">
                      <th>ค่าลดหย่อนต่างๆ</th>
                      <th>ก่อนวางแผน</th>
                      <th>หลังวางแผน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="-clear-border">
                      <td>หักค่าใช้จ่าย</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        ส่วนตัว (60,000)
                        <p class="backup" v-if="showFormula">
                          TI16 = {{ tax.cell["TI16"].formula }}, TJ16 =
                          {{ tax.cell["TJ16"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI16.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI16: dataInputSheet.form.tax.cell.TI16,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ16.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ16: dataInputSheet.form.tax.cell.TJ16,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        คู่สมรส (กรณีคู่สมรสไม่มีเงินได้ หรือยื่นรวม 60,000)
                        <p class="backup" v-if="showFormula">
                          TI17 = {{ tax.cell["TI17"].formula }}, TJ17 =
                          {{ tax.cell["TJ17"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI17.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI17: dataInputSheet.form.tax.cell.TI17,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ17.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ17: dataInputSheet.form.tax.cell.TJ17,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        บุตร (คนละ 30,000)
                        <p class="backup" v-if="showFormula">
                          TI18 = {{ tax.cell["TI18"].formula }}, TJ18 =
                          {{ tax.cell["TJ18"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI18.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI18: dataInputSheet.form.tax.cell.TI18,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ18.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ18: dataInputSheet.form.tax.cell.TJ18,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        บุตรคนที่ 2 เป็นต้นไป ที่คลอดในปี 2561 และหลังจากนั้น
                        (ไม่เกิน 60,000/ครรภ์)
                        <p class="backup" v-if="showFormula">
                          TI19 = {{ tax.cell["TI19"].formula }}, TJ19 =
                          {{ tax.cell["TJ19"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI19.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI19: dataInputSheet.form.tax.cell.TI19,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ19.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ19: dataInputSheet.form.tax.cell.TJ19,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ค่าฝากครรภ์-คลอดบุตร (ไม่เกิน 60,000/ครรภ์)
                        <p class="backup" v-if="showFormula">
                          TI20 = {{ tax.cell["TI20"].formula }}, TJ20 =
                          {{ tax.cell["TJ20"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI20.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI20: dataInputSheet.form.tax.cell.TI20,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ20.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ20: dataInputSheet.form.tax.cell.TJ20,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        บิดา/มารดา( ไม่เกิน 4 คน อายุเกิน 60 ปีและรายได้น้อยกว่า
                        30,000 คนละ 30,000)
                        <p class="backup" v-if="showFormula">
                          TI21 = {{ tax.cell["TI21"].formula }}, TJ21 =
                          {{ tax.cell["TJ21"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI21.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI21: dataInputSheet.form.tax.cell.TI21,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ21.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ21: dataInputSheet.form.tax.cell.TJ21,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ค่าเลี้ยงดูบุคคลทุพพลภาพหรือพิการ (เหมา 60,000)
                        <p class="backup" v-if="showFormula">
                          TI22 = {{ tax.cell["TI22"].formula }}, TJ22 =
                          {{ tax.cell["TJ22"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI22.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI22: dataInputSheet.form.tax.cell.TI22,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ22.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ22: dataInputSheet.form.tax.cell.TJ22,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr class="-clear-border">
                      <td>หักค่าลดหย่อน</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        เบี้ยประกันสุขภาพ บิดา/มารดา (สูงสุด 15,000)
                        <p class="backup" v-if="showFormula">
                          TI23 = {{ tax.cell["TI23"].formula }}, TJ23 =
                          {{ tax.cell["TJ23"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI23.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI23: dataInputSheet.form.tax.cell.TI23,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ23.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ23: dataInputSheet.form.tax.cell.TJ23,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        เบี้ยประกันชีวิต - ผู้มีเงินได้ (สูงสุด 100,000)
                        <p class="backup" v-if="showFormula">
                          TI24 = {{ tax.cell["TI24"].formula }}, TJ24 =
                          {{ tax.cell["TJ24"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI24.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI24: dataInputSheet.form.tax.cell.TI24,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ24.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ24: dataInputSheet.form.tax.cell.TJ24,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        เบี้ยประกันชีวิต - คู่สมรส
                        <p class="backup" v-if="showFormula">
                          TI25 = {{ tax.cell["TI25"].formula }}, TJ25 =
                          {{ tax.cell["TJ25"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI25.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI25: dataInputSheet.form.tax.cell.TI25,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ25.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ25: dataInputSheet.form.tax.cell.TJ25,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        เบี้ยประกันบำนาญ - ผู้มีเงินได้ (15%
                        ของเงินได้พึงประเมิน ไม่เกิน 200,000 )
                        <p class="backup" v-if="showFormula">
                          TI26 = {{ tax.cell["TI26"].formula }}, TJ26 =
                          {{ tax.cell["TJ26"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI26.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI26: dataInputSheet.form.tax.cell.TI26,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ26.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ26: dataInputSheet.form.tax.cell.TJ26,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        เงินสมทบกองทุนสำรองเลี้ยงชีพ/กบข/ครูเอกชน
                        <p class="backup" v-if="showFormula">
                          TI27 = {{ tax.cell["TI27"].formula }}, TJ27 =
                          {{ tax.cell["TJ27"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI27.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI27: dataInputSheet.form.tax.cell.TI27,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ27.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ27: dataInputSheet.form.tax.cell.TJ27,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ค่าซื้อหน่วยลงทุน SSF
                        <p class="backup" v-if="showFormula">
                          TI28 = {{ tax.cell["TI28"].formula }}, TJ28 =
                          {{ tax.cell["TJ28"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI28.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI28: dataInputSheet.form.tax.cell.TI28,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ28.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ28: dataInputSheet.form.tax.cell.TJ28,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ค่าซื้อหน่วยลงทุน LTF
                        <p class="backup" v-if="showFormula">
                          TI29 = {{ tax.cell["TI29"].formula }}, TJ29 =
                          {{ tax.cell["TJ29"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI29.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI29: dataInputSheet.form.tax.cell.TI29,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ29.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ29: dataInputSheet.form.tax.cell.TJ29,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย (สูงสุด 100,000)
                        <p class="backup" v-if="showFormula">
                          TI30 = {{ tax.cell["TI30"].formula }}, TJ30 =
                          {{ tax.cell["TJ30"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI30.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI30: dataInputSheet.form.tax.cell.TI30,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ30.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ30: dataInputSheet.form.tax.cell.TJ30,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        เงินสมทบกองทุนประกันสังคม (ตามที่จ่ายจริง)
                        <p class="backup" v-if="showFormula">
                          TI31 = {{ tax.cell["TI31"].formula }}, TJ31 =
                          {{ tax.cell["TJ31"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI31.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI31: dataInputSheet.form.tax.cell.TI31,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ31.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ31: dataInputSheet.form.tax.cell.TJ31,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr class="-clear-border">
                      <td>หักค่าลดหย่อนอื่นๆ</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        บ้านหลังแรก (20% ของราคาไม่เกิน 3 ล้าน แยก 5 ปี)
                        <p class="backup" v-if="showFormula">
                          TI32 = {{ tax.cell["TI32"].formula }}, TJ32 =
                          {{ tax.cell["TJ32"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI32.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI32: dataInputSheet.form.tax.cell.TI32,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ32.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ32: dataInputSheet.form.tax.cell.TJ32,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ค่าธรรมเนียมจากการรับชำระเงินด้วยบัตรเดบิต สำหรับ
                        40(5)-(8) ที่มีเงินได้ไม่เกิน 30 ล้าน
                        <p class="backup" v-if="showFormula">
                          TI33 = {{ tax.cell["TI33"].formula }}, TJ33 =
                          {{ tax.cell["TJ33"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI33.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI33: dataInputSheet.form.tax.cell.TI33,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ33.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ33: dataInputSheet.form.tax.cell.TJ33,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ลงทุนในธุรกิจ Startup (สูงสุด 100,000)
                        <p class="backup" v-if="showFormula">
                          TI34 = {{ tax.cell["TI34"].formula }}, TJ34 =
                          {{ tax.cell["TJ34"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI34.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI34: dataInputSheet.form.tax.cell.TI34,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ34.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ34: dataInputSheet.form.tax.cell.TJ34,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        ช้อปช่วยชาติ (ยางรถยนต์, หนังสือ-ebook, OTOP ช่วง
                        15/12/61-16/01/62 สูงสุด 15,000)
                        <p class="backup" v-if="showFormula">
                          TI35 = {{ tax.cell["TI35"].formula }}, TJ35=
                          {{ tax.cell["TJ35"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI35.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI35: dataInputSheet.form.tax.cell.TI35,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ35.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ35: dataInputSheet.form.tax.cell.TJ35,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        ท่องเที่ยวในประเทศ (เมืองหลัก 15,000/เมืองรอง 20,000
                        สูงสุด 20,000)
                        <p class="backup" v-if="showFormula">
                          TI36 = {{ tax.cell["TI36"].formula }}, TJ36 =
                          {{ tax.cell["TJ36"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI36.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI36: dataInputSheet.form.tax.cell.TI36,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ36.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ36: dataInputSheet.form.tax.cell.TJ36,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        สินค้าการศึกษาและอุปกรณ์กีฬา (ช่วง 01/05/62 - 30/06/62
                        สูงสุด 15,000)
                        <p class="backup" v-if="showFormula">
                          TI37 = {{ tax.cell["TI37"].formula }}, TJ37 =
                          {{ tax.cell["TJ37"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI37.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI37: dataInputSheet.form.tax.cell.TI37,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ37.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ37: dataInputSheet.form.tax.cell.TJ37,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        สินค้า OTOP (ช่วง 30/04/62 - 30/06/62 สูงสุด 15,000)
                        <p class="backup" v-if="showFormula">
                          TI38 = {{ tax.cell["TI38"].formula }}, TJ38 =
                          {{ tax.cell["TJ38"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI38.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI38: dataInputSheet.form.tax.cell.TI38,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ38.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ38: dataInputSheet.form.tax.cell.TJ38,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        หนังสือและ e-boook (ช่วง 01/01/62 - 31/12/62 สูงสุด
                        15,000)
                        <p class="backup" v-if="showFormula">
                          TI39 = {{ tax.cell["TI39"].formula }}, TJ39 =
                          {{ tax.cell["TJ39"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI39.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI39: dataInputSheet.form.tax.cell.TI39,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ39.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ39: dataInputSheet.form.tax.cell.TJ39,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        บ้านหลังแรก มูลค่าไม่เกิน 5 ล้าน (ช่วง 01/05/62 -
                        31/12/62 สูงสุด 200,000)
                        <p class="backup" v-if="showFormula">
                          TI40 = {{ tax.cell["TI40"].formula }}, TJ40 =
                          {{ tax.cell["TJ40"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI40.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI40: dataInputSheet.form.tax.cell.TI40,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ40.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ40: dataInputSheet.form.tax.cell.TJ40,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        อื่นๆ
                        <p class="backup" v-if="showFormula">
                          TI41 = {{ tax.cell["TI41"].formula }}, TJ41 =
                          {{ tax.cell["TJ41"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI41.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI41: dataInputSheet.form.tax.cell.TI41,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ41.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ41: dataInputSheet.form.tax.cell.TJ41,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-show="!showEdit">
                          อื่นๆ {{ tax.cell["TH501"].value }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH501: dataInputSheet.form.tax.cell.TH501,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>

                        <p class="backup" v-if="showFormula">
                          TI501 = {{ tax.cell["TI501"].formula }}, TJ501 =
                          {{ tax.cell["TJ501"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI501.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI501: dataInputSheet.form.tax.cell.TI501,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ501.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ501: dataInputSheet.form.tax.cell.TJ501,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-show="!showEdit">
                          อื่นๆ {{ tax.cell["TH502"].value }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH502: dataInputSheet.form.tax.cell.TH502,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>

                        <p class="backup" v-if="showFormula">
                          TI502 = {{ tax.cell["TI502"].formula }}, TJ502 =
                          {{ tax.cell["TJ502"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI502.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI502: dataInputSheet.form.tax.cell.TI502,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ502.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ502: dataInputSheet.form.tax.cell.TJ502,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-show="!showEdit">
                          อื่นๆ {{ tax.cell["TH503"].value }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH503: dataInputSheet.form.tax.cell.TH503,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>

                        <p class="backup" v-if="showFormula">
                          TI503 = {{ tax.cell["TI503"].formula }}, TJ503 =
                          {{ tax.cell["TJ503"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI503.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI503: dataInputSheet.form.tax.cell.TI503,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ503.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ503: dataInputSheet.form.tax.cell.TJ503,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span v-show="!showEdit">
                          อื่นๆ {{ tax.cell["TH504"].value }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TH504: dataInputSheet.form.tax.cell.TH504,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>

                        <p class="backup" v-if="showFormula">
                          TI504 = {{ tax.cell["TI504"].formula }}, TJ504 =
                          {{ tax.cell["TJ504"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI504.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI504: dataInputSheet.form.tax.cell.TI504,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ504.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ504: dataInputSheet.form.tax.cell.TJ504,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="-clear-border">
                      <td>รวมค่าลดหย่อน</td>
                      <td>{{ tax.cell.TI42.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ42.value | numeralFormat }}</td>
                    </tr>
                    <tr class="-highlight">
                      <td>รายได้พึงประเมิน หลังหักค่าลดหย่อน</td>
                      <td>{{ tax.cell.TI44.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ44.value | numeralFormat }}</td>
                    </tr>
                    <tr class="-odd">
                      <td>
                        หัก เงินบริจาคเพื่อสนับสนุนการศึกษา, กีฬา, โรงพยาบาล
                        <p class="backup" v-if="showFormula">
                          TI45 = {{ tax.cell["TI45"].formula }}, TJ45 =
                          {{ tax.cell["TJ45"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI45.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI45: dataInputSheet.form.tax.cell.TI45,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ45.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ45: dataInputSheet.form.tax.cell.TJ45,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr class="-even">
                      <td>
                        หัก เงินบริจาคเพื่อการสาธารณกุศลอื่นๆ
                        <p class="backup" v-if="showFormula">
                          TI47 = {{ tax.cell["TI47"].formula }}, TJ47 =
                          {{ tax.cell["TJ47"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TI47.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TI47: dataInputSheet.form.tax.cell.TI47,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ tax.cell.TJ47.value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            TJ47: dataInputSheet.form.tax.cell.TJ47,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100px; display: inline-block"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                    </tr>
                    <tr class="-highlight">
                      <td>เงินได้สุทธิ</td>
                      <td>{{ tax.cell.TI48.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ48.value | numeralFormat }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -orange">
                <table class="tax-calculation">
                  <thead>
                    <tr class="-highlight">
                      <th>เงินได้สุทธิ</th>
                      <th>อัตราภาษี</th>
                      <th>ภาษีสะสม</th>
                      <th>ก่อนวางแผน</th>
                      <th>หลังวางแผน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0 - 150,000</td>
                      <td>ยกเว้น</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>150,001 - 300,000</td>
                      <td>5%</td>
                      <td>{{ tax.cell.TH52.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TI52.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ52.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>300,001 - 500,000</td>
                      <td>10%</td>
                      <td>{{ tax.cell.TH53.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TI53.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ53.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>500,001 - 750,000</td>
                      <td>15%</td>
                      <td>{{ tax.cell.TH54.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TI54.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ54.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>750,001 - 1,000,000</td>
                      <td>20%</td>
                      <td>{{ tax.cell.TH55.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TI55.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ55.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>1,000,001 - 2,000,000</td>
                      <td>25%</td>
                      <td>{{ tax.cell.TH56.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TI56.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ56.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>2,000,001 - 5,000,000</td>
                      <td>30%</td>
                      <td>{{ tax.cell.TH57.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TI57.value | numeralFormat }}</td>
                      <td>{{ tax.cell.TJ57.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>5,000,001 ขึ้นไป</td>
                      <td>35%</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>ภาษีที่ต้องจ่าย</td>
                      <td></td>
                      <td></td>
                      <td>
                        {{ tax.cell.TI59.value | numeralFormat("0,0[.]00") }}
                      </td>
                      <td>
                        {{ tax.cell.TJ59.value | numeralFormat("0,0[.]00") }}
                      </td>
                    </tr>
                    <tr>
                      <td>หัก ณ ที่จ่ายไว้แล้ว</td>
                      <td></td>
                      <td></td>
                      <td>
                        {{ tax.cell.TI60.value | numeralFormat("0,0[.]00") }}
                      </td>
                      <td>
                        {{ tax.cell.TJ60.value | numeralFormat("0,0[.]00") }}
                      </td>
                    </tr>
                    <tr>
                      <td>ภาษีที่ต้องชำระ/ขอคืน</td>
                      <td></td>
                      <td></td>
                      <td>
                        {{ tax.cell.TI61.value | numeralFormat("0,0[.]00") }}
                      </td>
                      <td>
                        {{ tax.cell.TJ61.value | numeralFormat("0,0[.]00") }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>ประหยัดภาษี</td>
                      <td>{{ tax.cell.TJ63.value | numeralFormat }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- DATA -->
    <v-row v-if="showBackup">
      <v-col cols="12">
        <h2>Tax</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        v-for="(item, index) in tax.cell"
        v-bind:key="index"
      >
        <v-text-field
          :disabled="true"
          :dense="true"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
          :placeholder="item.placeholder ? item.placeholder : 'ระบุจำนวน'"
        ></v-text-field>
      </v-col>
    </v-row>
    <hr v-if="showBackup" style="border: 1px solid red" />
    <v-row v-if="showBackup" :set="(sheet = dataInputSheet.form.tax)">
      <v-col cols="12" class="sheet-heading">
        <h3>{{ sheet.heading }}</h3>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :md="3"
        v-for="(item, index) in sheet.cell"
        v-bind:key="index"
      >
        <v-text-field
          :ref="`dataInput.cell.${index}`"
          :type="item.type"
          :label="`${index} ${item.label}`"
          :suffix="item.suffix ? item.suffix : ''"
          :value="
            dataInputSheet.cell[index] ? dataInputSheet.cell[index].value : ''
          "
          @change="
            onDataInputChange(`dataInput.cell.${index}`, $event, item.type)
          "
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "tax-component",
  props: {
    printAble: Boolean,
    enableFormula: Boolean,
  },
  computed: {
    ...mapGetters(["dataInputSheet", "tax", "planItem", "user"]),
  },
  methods: {
    ...mapActions(["updateDataInput"]),
    onDataInputChange: function (name, value, type) {
      var keys = name.split(".");
      var sheet = keys[0];
      var cell = keys[2];

      this.updateDataInput({
        sheet: sheet,
        cell: cell,
        value: value,
        type: type,
      });
    },
    toggleShowEdit() {
      this.showEdit = !this.showEdit;
      if (this.showEdit) {
        this.btnEditColor = "warning";
      } else {
        this.btnEditColor = "default";
      }
    },
    toggleShowFormula() {
      this.showFormula = !this.showFormula;
      if (this.showFormula) {
        this.btnFormulaColor = "warning";
      } else {
        this.btnFormulaColor = "default";
      }
    },
    saveChanges() {
      this.showEdit = false;
      this.btnEditColor = "default";
      this.$parent.$refs.dataInputComponent.saveChanges();
    },
  },
  data: function () {
    return {
      btnEditColor: "default",
      btnFormulaColor: "default",
      showBackup: false,
      showFormula: false,
      showEdit: false,
    };
  },
  filters: {
    momentTaxTH: function (date) {
      moment.locale("th_TH");
      return parseInt(moment(date).format("YYYY")) + 542;
    },
  },
};
</script>