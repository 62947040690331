var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('Navigation',{attrs:{"print-able":_vm.printAble}}),_c('img',{attrs:{"id":"whitebar","src":require("../../assets/img/whitebar.png")}}),_c('DataInput',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.navigationItems.dataInput.disabled && _vm.navigationItems.dataInput.active
    ),expression:"\n      !navigationItems.dataInput.disabled && navigationItems.dataInput.active\n    "}],ref:"dataInputComponent"}),_c('BalanceSheet',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.navigationItems.balanceSheet.disabled &&
      _vm.navigationItems.balanceSheet.active
    ),expression:"\n      !navigationItems.balanceSheet.disabled &&\n      navigationItems.balanceSheet.active\n    "}],attrs:{"print-able":_vm.printAble,"enable-chart-js":_vm.enableChartJs,"enable-formula":_vm.enableFormula}}),_c('CashFlow',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.navigationItems.cashFlow.disabled && _vm.navigationItems.cashFlow.active
    ),expression:"\n      !navigationItems.cashFlow.disabled && navigationItems.cashFlow.active\n    "}],attrs:{"print-able":_vm.printAble,"enable-chart-js":_vm.enableChartJs,"enable-formula":_vm.enableFormula}}),_c('FinancialRatio',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.navigationItems.financialRatio.disabled &&
      _vm.navigationItems.financialRatio.active
    ),expression:"\n      !navigationItems.financialRatio.disabled &&\n      navigationItems.financialRatio.active\n    "}],attrs:{"print-able":_vm.printAble,"enable-chart-js":_vm.enableChartJs}}),_c('Education',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.navigationItems.education.disabled && _vm.navigationItems.education.active
    ),expression:"\n      !navigationItems.education.disabled && navigationItems.education.active\n    "}],attrs:{"print-able":_vm.printAble,"enable-chart-js":_vm.enableChartJs,"enable-formula":_vm.enableFormula}}),(
      !_vm.navigationItems.education1.disabled &&
      _vm.navigationItems.education1.active
    )?_c('Education1',{attrs:{"print-able":_vm.printAble,"enable-chart-js":_vm.enableChartJs,"enable-formula":_vm.enableFormula}}):_vm._e(),_c('Retirement',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.navigationItems.retirement.disabled &&
      _vm.navigationItems.retirement.active
    ),expression:"\n      !navigationItems.retirement.disabled &&\n      navigationItems.retirement.active\n    "}],attrs:{"print-able":_vm.printAble,"enable-chart-js":_vm.enableChartJs,"enable-formula":_vm.enableFormula}}),_c('Retirement1',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.navigationItems.retirement1.disabled &&
      _vm.navigationItems.retirement1.active
    ),expression:"\n      !navigationItems.retirement1.disabled &&\n      navigationItems.retirement1.active\n    "}],attrs:{"print-able":_vm.printAble,"enable-chart-js":_vm.enableChartJs,"enable-formula":_vm.enableFormula}}),_c('Tax',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navigationItems.tax.disabled && _vm.navigationItems.tax.active),expression:"!navigationItems.tax.disabled && navigationItems.tax.active"}],attrs:{"print-able":_vm.printAble,"enable-formula":_vm.enableFormula}}),_c('HealthAndFamilyIncomeProtection',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navigationItems.health.disabled && _vm.navigationItems.health.active),expression:"!navigationItems.health.disabled && navigationItems.health.active"}],attrs:{"print-able":_vm.printAble,"enable-formula":_vm.enableFormula}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }