export const balanceSheet = {
    required: {
      dataInput: {
        cell: [
          // === no required ===
          "YR1",
          "C4",
          "E4",
          // "K3",
          // "K4",
          // "K13",
          // "P18"
          // === no required ===
          // "C4",
          // "K3",
          // "K4",
          // "K5",
          // "K6",
          // "K7",
          // "K8",
          // "K9",
          // "K10",
          // "K11",
          // "K12",
          // "K13",
          // "K14",
          // "K15",
          // "K16",
          // "K17",
          // "K18",
          // "K19",
          // "K20",
          // "P3",
          // "P4",
          // "P5",
          // "P6",
          // "P7",
          // "P8",
          // "P9",
          // "P10",
          // "P11",
          // "P12",
          // "P13",
          // "P14",
          // "P15",
          // "P16",
          // "P17",
          // "P18",
          // "P19",
          // "P20",
          // "P33",
          // "P34",
          // "P35",
          // "P36",
          // "P37",
          // "P38",
          // "P39",
          // "P40"
        ]
      }
    },
    cell: {
      A1:{
        label:"ชื่อ-สกุล",
        value:"",
        type:"text",
        formula: "dataInput.C4"
      },
      //==== [1. สินทรัพย์] ====
      // สินทรัพย์ - สินทรัพย์สภาพคล่อง
      E6: {
        label: "เงินสด",
        value: "",
        type: "number",
        formula: "dataInput.K3"
      },
      E7: {
        label: "บัญชีเงินฝากออมทรัพย์",
        value: "",
        type: "number",
        formula: "dataInput.K4"
      },
      E8: {
        label: "บัญชีเงินฝากประจำ",
        value: "",
        type: "number",
        formula: "dataInput.P4"
      },
      E9: {
        label: "อื่นๆ",
        value: "",
        type: "number"
      },
      E10: {
        label: "กรมธรรม์ประกันชีวิต",
        value: "",
        type: "number",
        formula: "dataInput.K5"
      },
      E11: {
        label: "สหกรณ์",
        value: "",
        type: "number",
        formula: "dataInput.P5"
      },
      E13: {
        label: "รวมสินทรัพย์สภาพคล่อง",
        value: "",
        type: "number",
        formula:
          "balanceSheet.E6+balanceSheet.E7+balanceSheet.E8+balanceSheet.E9+balanceSheet.E10+balanceSheet.E11"
      },
      // สินทรัพย์ - สินทรัพย์เพื่อการลงทุน
      E15: {
        label: "พันธบัตร/หุ้นกู้/ตราสารหนี้",
        value: "",
        type: "number",
        formula: "dataInput.K6+dataInput.P35"
      },
      E16: {
        label: "หุ้นบุริมสิทธิ (perferred stock)",
        value: "",
        type: "number",
        formula: "dataInput.P6+dataInput.P40"
      },
      E17: {
        label: "หุ้นสามัญ (common stock)",
        value: "",
        type: "number",
        formula: "dataInput.K7"
      },
      E18: {
        label: "กองทุนรวมหุ้น",
        value: "",
        type: "number",
        formula: "dataInput.K8+dataInput.P36"
      },
      E19: {
        label: "กองทุนเพื่อลดหย่อนภาษี (LTF,SSF)",
        value: "",
        type: "number",
        formula: "dataInput.P8+dataInput.P37"
      },
      E20: {
        label: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)equity",
        value: "",
        type: "number",
        formula: "dataInput.K9+dataInput.P38"
      },
      E21: {
        label: "warant",
        value: "",
        type: "number",
        // formula: "dataInput.P7"
      },
      E22: {
        label: "Future",
        value: "",
        type: "number",
        // formula: "dataInput.P11"
      },
      E23: {
        label: "forex/option",
        value: "",
        type: "number",
        // formula: "dataInput.K10"
      },
      E24: {
        label: "กองทุนสำรองเลี้ยงชีพ (Provident Fund)",
        value: "",
        type: "number",
        formula: "dataInput.K11"
      },
      E25: {
        label: "กบข./กสจ.",
        value: "",
        type: "number",
        // formula: "dataInput.P12"
      },
      E26: {
        label: "กองทุนรวมตราสารหนี้",
        value: "",
        type: "number",
        formula: "dataInput.P3"
      },
      E27: {
        label: "กองทุนสำรองเลี้ยงชีพ (Provident Fund)ปีนี้",
        value: "",
        type: "number",
        // formula: "dataInput.P33"
      },
      E28: {
        label: "กองทุน PrivateFund",
        value: "",
        type: "number",
        formula: "dataInput.P9"
      },
      E29: {
        label: "เงินออมเพื่อวัยเกษียณ",
        value: "",
        type: "number",
        formula: "dataInput.K12+dataInput.P34"
      },
      E30: {
        label: "เงินลงทุน อื่นๆ",
        value: "",
        type: "number",
        formula: "dataInput.P10+dataInput.P39"
      },
      E31: {
        label: "รวมสินทรัพย์เพื่อการลงทุน",
        value: "",
        type: "number",
        formula:
          "balanceSheet.E15+balanceSheet.E16+balanceSheet.E17+balanceSheet.E18+balanceSheet.E19+balanceSheet.E20+balanceSheet.E21+balanceSheet.E22+balanceSheet.E23+balanceSheet.E24+balanceSheet.E25+balanceSheet.E26+balanceSheet.E27+balanceSheet.E28+balanceSheet.E29+balanceSheet.E30"
      },
      // สินทรัพย์ - บ้าน ที่ดิน รถยนต์ และ สินทรัพย์มีค่าอื่น
      E33: {
        label: "เครื่องประดับ",
        value: "",
        type: "number",
        formula: "dataInput.P14+dataInput.K15"
      },
      E34: {
        label: "รถยนต์",
        value: "",
        type: "number",
        formula: "dataInput.P13"
      },
      E35: {
        label: "ที่อยู่อาศัย",
        value: "",
        type: "number",
        formula: "dataInput.K13"
      },
      E36: {
        label: "คอนโด",
        value: "",
        type: "number",
        // formula: "dataInput.K16"
      },
      E37: {
        label: "หอพัก",
        value: "",
        type: "number",
        // formula: "dataInput.K14"
      },
      E38: {
        label: "ที่ดิน",
        value: "",
        type: "number",
        formula: "dataInput.P15"
      },
      E39: {
        label: "อสังหาริมทรัพย์ / อื่นๆ",
        value: "",
        type: "number",
        formula: "dataInput.P17"
      },
      E40: {
        label: "ของสะสมและทรัพย์สินอื่นๆ",
        value: "",
        type: "number",
        formula: "dataInput.P16+dataInput.K20"
      },
      E41: {
        label: "รวมสินทรัพย์ส่วนตัว/มีค่า",
        value: "",
        type: "number",
        formula:
          "balanceSheet.E33+balanceSheet.E34+balanceSheet.E35+balanceSheet.E36+balanceSheet.E37+balanceSheet.E38+balanceSheet.E39+balanceSheet.E40"
      },
      // สินทรัพย์ - สินทรัพย์ที่ไม่มีตัวตน
      E43: { label: "ลิขสิทธิ์", value: "", type: "number" },
      E44: { label: "สิทธิบัตร", value: "", type: "number" },
      E46: {
        label: "รวมสินทรัพย์ที่ไม่มีตัวตน",
        value: "",
        type: "number",
        formula: "balanceSheet.E43+balanceSheet.E44"
      },
      E47: {
        label: "สินทรัพย์รวม",
        value: "",
        type: "number",
        formula:
          "balanceSheet.E13+balanceSheet.E31+balanceSheet.E41+balanceSheet.E46"
      },
      // สินทรัพย์ - สินทรัพย์สภาพคล่อง %
      F6: {
        label: "เงินสด %",
        value: "",
        type: "number",
        formula: "balanceSheet.E6*100/balanceSheet.E47"
      },
      F7: {
        label: "บัญชีเงินฝากออมทรัพย์ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E7*100/balanceSheet.E47"
      },
      F8: {
        label: "บัญชีเงินฝากประจำ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E8*100/balanceSheet.E47"
      },
      F9: {
        label: "ใบรับฝากเงินที่เปลี่ยนมือได้ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E9*100/balanceSheet.E47"
      },
      F10: {
        label: "มูลค่าเงินสดกรมธรรม์ประกันชีวิต %",
        value: "",
        type: "number",
        formula: "balanceSheet.E10*100/balanceSheet.E47"
      },
      F11: {
        label: "สหกรณ์ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E11*100/balanceSheet.E47"
      },
      F13: {
        label: "รวมสินทรัพย์สภาพคล่อง %",
        value: "",
        type: "number",
        formula:
          "balanceSheet.F6+balanceSheet.F7+balanceSheet.F8+balanceSheet.F9+balanceSheet.F10+balanceSheet.F11"
      },
      // สินทรัพย์ - สินทรัพย์เพื่อการลงทุน %
      F15: {
        label: "พันธบัตร/หุ้นกู้ (Bond) /ตราสารหนี้ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E15*100/balanceSheet.E47"
      },
      F16: {
        label: "หุ้นบุริมสิทธิ (perferred stock) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E16*100/balanceSheet.E47"
      },
      F17: {
        label: "หุ้นสามัญ (common stock) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E17*100/balanceSheet.E47"
      },
      F18: {
        label: "กองทุนรวมหุ้น (Mutual Fund equity) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E18*100/balanceSheet.E47"
      },
      F19: {
        label: "กองทุนรวมหุ้นระยะยาว(LTF) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E19*100/balanceSheet.E47"
      },
      F20: {
        label: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)equity %",
        value: "",
        type: "number",
        formula: "balanceSheet.E20*100/balanceSheet.E47"
      },
      F21: {
        label: "warant %",
        value: "",
        type: "number",
        formula: "balanceSheet.E21*100/balanceSheet.E47"
      },
      F22: {
        label: "Future %",
        value: "",
        type: "number",
        formula: "balanceSheet.E22*100/balanceSheet.E47"
      },
      F23: {
        label: "forex/option %",
        value: "",
        type: "number",
        formula: "balanceSheet.E23*100/balanceSheet.E47"
      },
      F24: {
        label: "กองทุนสำรองเลี้ยงชีพ (Provident Fund) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E24*100/balanceSheet.E47"
      },
      F25: {
        label: "กบข./กสจ. %",
        value: "",
        type: "number",
        formula: "balanceSheet.E25*100/balanceSheet.E47"
      },
      F26: {
        label: "กองทุนรวมตราสารหนี้ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E26*100/balanceSheet.E47"
      },
      F27: {
        label: "กองทุนสำรองเลี้ยงชีพ (Provident Fund)ปีนี้ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E27*100/balanceSheet.E47"
      },
      F28: {
        label: "กองทุน PrivateFund %",
        value: "",
        type: "number",
        formula: "balanceSheet.E28*100/balanceSheet.E47"
      },
      F29: {
        label: "เงินออมเพื่อวัยเกษียณ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E29*100/balanceSheet.E47"
      },
      F30: {
        label: "เงินลงทุน อื่นๆ rmf fix %",
        value: "",
        type: "number",
        formula: "balanceSheet.E30*100/balanceSheet.E47"
      },
      F31: {
        label: "รวมสินทรัพย์เพื่อการลงทุน %",
        value: "",
        type: "number",
        formula:
          "balanceSheet.F15+balanceSheet.F16+balanceSheet.F17+balanceSheet.F18+balanceSheet.F19+balanceSheet.F20+balanceSheet.F21+balanceSheet.F22+balanceSheet.F23+balanceSheet.F24+balanceSheet.F25+balanceSheet.F26+balanceSheet.F27+balanceSheet.F28+balanceSheet.F29+balanceSheet.F30"
      },
      // สินทรัพย์ - บ้าน ที่ดิน รถยนต์ และ สินทรัพย์มีค่าอื่น %
      F33: {
        label: "เครื่องประดับ(Jewelry) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E33*100/balanceSheet.E47"
      },
      F34: {
        label: "รถยนต์(car) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E34*100/balanceSheet.E47"
      },
      F35: {
        label: "บ้าน (House) %",
        value: "",
        type: "number",
        formula: "balanceSheet.E35*100/balanceSheet.E47"
      },
      F36: {
        label: "คอนโด %",
        value: "",
        type: "number",
        formula: "balanceSheet.E36*100/balanceSheet.E47"
      },
      F37: {
        label: "หอพัก %",
        value: "",
        type: "number",
        formula: "balanceSheet.E37*100/balanceSheet.E47"
      },
      F38: {
        label: "ที่ดิน %",
        value: "",
        type: "number",
        formula: "balanceSheet.E38*100/balanceSheet.E47"
      },
      F39: {
        label: "อสังหาริมทรัพย์ / อื่นๆ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E39*100/balanceSheet.E47"
      },
      F40: {
        label: "ของสะสมและทรัพย์สินอื่นๆ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E40*100/balanceSheet.E47"
      },
      F41: {
        label: "รวมสินทรัพย์ส่วนตัว/มีค่า %",
        value: "",
        type: "number",
        formula:
          "balanceSheet.F33+balanceSheet.F34+balanceSheet.F35+balanceSheet.F36+balanceSheet.F37+balanceSheet.F38+balanceSheet.F39+balanceSheet.F40"
      },
      // สินทรัพย์ - สินทรัพย์ที่ไม่มีตัวตน %
      F43: {
        label: "ลิขสิทธิ์ %",
        value: "",
        type: "number",
        formula: "balanceSheet.E43*100/balanceSheet.E47"
      },
      F44: {
        label: "สิทธิบัตร %",
        value: "",
        type: "number",
        formula: "balanceSheet.E44*100/balanceSheet.E47"
      },
      F46: {
        label: "รวมสินทรัพย์ที่ไม่มีตัวตน %",
        value: "",
        type: "number",
        formula: "balanceSheet.F43+balanceSheet.F44"
      },
      F47: {
        label: "สินทรัพย์รวม %",
        value: "",
        type: "number",
        formula:
          "balanceSheet.F13+balanceSheet.F31+balanceSheet.F41+balanceSheet.F46"
      },
      //==== [2. หนี้สินและความมั่งคั่ง] ====
      // หนี้สินและความมั่งคั่ง - หนี้สินระยะสั้น
      I6: {
        label: "ค่าสาธารณูปโภคค้างจ่าย",
        value: "",
        type: "number",
        formula: "dataInput.K18"
      },
      I7: {
        label: "ยอดคงค้างหนี้สินบัตรเครดิต",
        value: "",
        type: "number",
        formula: "dataInput.K17"
      },
      I8: {
        label: "เงินกู้ยืมระยะสั้น/สินเชื่อบุคคล",
        value: "",
        type: "number"
      },
      I9: { label: "อื่นๆ", value: "", type: "number" },
      I10: { label: "อื่นๆ", value: "", type: "number" },
      I13: {
        label: "รวมหนี้สินระยะสั้น",
        value: "",
        type: "number",
        formula:
          "balanceSheet.I6+balanceSheet.I7+balanceSheet.I8+balanceSheet.I9+balanceSheet.I10"
      },
      // หนี้สินและความมั่งคั่ง - หนี้สินระยะยาว
      I15: {
        label: "รถยนต์",
        value: "",
        type: "number",
        formula: "dataInput.K19"
      },
      I16: {
        label: "บ้าน",
        value: "",
        type: "number",
        formula: "dataInput.P18"
      },
      I17: {
        label: "หนี้สินระยะยาวไม่มีคุ้มครอง",
        value: "",
        type: "number",
        // formula: "dataInput.P19"
      },
      I18: {
        label: "หนี้ระยะยาวอื่นๆ",
        value: "",
        type: "number",
        formula: "dataInput.P20"
      },
      I28: {
        label: "รวมหนี้สินระยะยาว",
        value: "",
        type: "number",
        formula:
          "balanceSheet.I15+balanceSheet.I16+balanceSheet.I17+balanceSheet.I18"
      },
      // หนี้สินและความมั่งคั่ง
      I30: {
        label: "รวมหนี้สิน",
        value: "",
        type: "number",
        formula: "balanceSheet.I13+balanceSheet.I28"
      },
      // ความมั่งคั่งสุทธิ
      I32: {
        label: "ความมั่งคั่งสุทธิ",
        value: "",
        type: "number",
        formula: "balanceSheet.E47-balanceSheet.I30"
      },
      // หนี้สินรวมและความมั่งคั่งสุทธิ
      I47: {
        label: "หนี้สินรวมและความมั่งคั่งสุทธิ",
        value: "",
        type: "number",
        formula: "balanceSheet.I30+balanceSheet.I32"
      },
      // หนี้สินและความมั่งคั่ง - หนี้สินระยะสั้น %
      J6: {
        label: "ค่าสาธารณูปโภคค้างจ่าย %",
        value: "",
        type: "number",
        formula: "balanceSheet.I6*100/balanceSheet.I47"
      },
      J7: {
        label: "ยอดคงค้างหนี้สินบัตรเครดิต %",
        value: "",
        type: "number",
        formula: "balanceSheet.I7*100/balanceSheet.I47"
      },
      J8: {
        label: "หนี้สินเงินกู้ระยะสั้นอื่นๆสินเชื่อบุคคล %",
        value: "",
        type: "number",
        formula: "balanceSheet.I8*100/balanceSheet.I47"
      },
      J9: {
        label: "หนี้.. %",
        value: "",
        type: "number",
        formula: "balanceSheet.I9*100/balanceSheet.I47"
      },
      J10: {
        label: "หนี้อื่นๆ %",
        value: "",
        type: "number",
        formula: "balanceSheet.I10*100/balanceSheet.I47"
      },
      J13: {
        label: "รวมหนี้สินระยะสั้น %",
        value: "",
        type: "number",
        formla:
          "balanceSheet.J6+balanceSheet.J7+balanceSheet.J8+balanceSheet.J9+balanceSheet.J10"
      },
      // หนี้สินและความมั่งคั่ง - หนี้สินระยะยาว %
      J15: {
        label: "ยอดคงค้างจากการกู้ยืมซื้อรถยนต์ %",
        value: "",
        type: "number",
        formula: "balanceSheet.I15*100/balanceSheet.I47"
      },
      J16: {
        label: "ยอดคงค้างจากการกู้ยืมซื้อบ้าน %",
        value: "",
        type: "number",
        formula: "balanceSheet.I16*100/balanceSheet.I47"
      },
      J17: {
        label: "หนี้สินระยะยาวไม่มีคุ้มครอง %",
        value: "",
        type: "number",
        formula: "balanceSheet.I17*100/balanceSheet.I47"
      },
      J18: {
        label: "หนี้อื่นระยะยาวอื่นๆ %",
        value: "",
        type: "number",
        formula: "balanceSheet.I18*100/balanceSheet.I47"
      },
      J28: {
        label: "รวมหนี้สินระยะยาว %",
        value: "",
        type: "number",
        formula:
          "balanceSheet.J15+balanceSheet.J16+balanceSheet.J17+balanceSheet.J18"
      },
      // รวมหนี้สิน %
      J30: {
        label: "รวมหนี้สิน %",
        value: "",
        type: "number",
        formula: "balanceSheet.J13+balanceSheet.J28"
      },
      // ความมั่งคั่งสุทธิ %
      J32: {
        label: "ความมั่งคั่งสุทธิ %",
        value: "",
        type: "number",
        formula: "balanceSheet.I32*100/balanceSheet.I47"
      },
      J47: {
        label: "หนี้สินรวมและความมั่งคั่งสุทธิ %",
        value: "",
        type: "number",
        formula: "balanceSheet.J30+balanceSheet.J32"
      }
    }
  };