/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import vuetify from "./plugins/vuetify";
import VueTheMask from "vue-the-mask";
import VuetifyMoney from "vuetify-money";
import VueNumerals from "vue-numerals";
import Notifications from "vue-notification";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/database';

// https://www.npmjs.com/package/material-design-icons-iconfont
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import NProgress from "nprogress";

import "nprogress/nprogress.css";
import "@/assets/css/style.css";
import "@/assets/css/main.css";

import VueHtml2Canvas from 'vue-html2canvas';
import browserDetect from "vue-browser-detect-plugin";


Vue.config.productionTip = false;

const firebaseConfig = { 
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};
firebase.initializeApp(firebaseConfig);


// firebase.functions().useFunctionsEmulator("http://localhost:5001");

/** 
 * ===========================================================================================
 * NAVIGATION GUARDS
 * 
*/
// navigation guards before each
router.beforeEach((to, from, next) => {
  NProgress.start();
  firebase.auth().onAuthStateChanged(function (user) {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = firebase.auth().currentUser;
    const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
    const requiresManager = to.matched.some(
      record => record.meta.requiresManager
    );
    if (requiresAuth && !isAuthenticated) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath }
      });
    } else if (requiresAuth && isAuthenticated) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(idTokenResult => {
          const isAdmin = idTokenResult.claims.admin;
          const isManager = idTokenResult.claims.manager;
          if (requiresManager && !(isManager || isAdmin)) {
            next({
              path: "/403"
            });
          } else if (requiresAdmin && !isAdmin) {
            next({
              path: "/403"
            });
          }
          next();
        })
        .catch(error => {
          console.log(error);
          next({
            path: "/403"
          });
        });
    } else {
      next();
    }
  });
});

// navigation guard after each
router.afterEach(() => {
  setTimeout(() => {
    NProgress.done();
  }, 200);
});

Vue.use(VueTheMask);
Vue.use(VuetifyMoney);
Vue.use(VueNumerals);
Vue.use(Notifications);

Vue.use(VueHtml2Canvas);
Vue.use(browserDetect);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate() { },
  created() {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // autoLogIn
        store.dispatch("login", user);
      } else {
        // autoLogout
        store.dispatch("logout");
      }
    });
  }
}).$mount("#app");
