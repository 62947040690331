<template>
  <v-navigation-drawer
    :value="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-list>
      <!-- <v-list-item>
        <v-list-item-avatar>
          <v-img :src="(user.photoURL)?user.photoURL:'../../assets/img/avatar.png'"></v-img>
        </v-list-item-avatar>
      </v-list-item> -->

      <v-list-item to="/profile">
        <v-list-item-content>
          <v-list-item-title class="title">{{
            user.displayName
          }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-menu-down</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>

    <v-list nav dense v-if="false">
      <v-list-item-group v-model="item" color="primary">
        <v-list-item
          v-for="(item, i) in userMenuItems"
          :key="i"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!-- <v-list dense>
      <v-list-group
        v-for="item in expansion_items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item link v-for="subItem in item.items" :key="subItem.title" :to="subItem.link">
          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list> -->
    <v-list id="navLeft" v-if="showNavigation()">
      <v-list-item>
        <Navigation :print-able="printAble" nav-position="left"></Navigation>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/valid-v-on */
import _ from "lodash";
import { mapGetters } from "vuex";
import Navigation from "@/components/Plan/Navigation.vue";

export default {
  components: {
    Navigation,
  },
  // getters from store
  computed: {
    ...mapGetters(["drawer", "isAuthenticated", "user"]),
    userMenuItems() {
      if (this.user.role !== undefined) {
        if (this.user.role != "admin") {
          _.remove(this.items, function (item) {
            return item.link == "/manage/user";
          });
        }
      }
      return this.items;
    },
  },
  methods:{
    showNavigation: function(){
      if(this.$route.name=="plan-add" || this.$route.name =="plan-edit"){
        return true;
      }
      return false;
    }

  },
  data: () => ({
    printAble: false,
    dialog: false,
    item: 0,
    items: [
      { text: "Dashboard", icon: "mdi-buffer", link: "/dashboard" },
      {
        text: "User Management",
        icon: "mdi-account-multiple",
        link: "/manage/user",
      },
      { text: "Account", icon: "mdi-account", link: "/profile" },
      { text: "Plans", icon: "mdi-clipboard-text", link: "/plan" },
      // { text: "Plan Add", icon: "mdi-history", link: "/plan-add" },
      // { text: "Plan Management", icon: "mdi-star", link: "/manage/plan" },
      { text: "Logout", icon: "mdi-logout-variant", link: "/auth/logout" },
    ],
    expansion_items: [
      {
        action: "local_activity",
        title: "Attractions",
        items: [{ title: "List Item", link: "/dashboard" }],
      },
      {
        action: "restaurant",
        title: "Dining",
        items: [
          { title: "Breakfast & brunch" },
          { title: "New American" },
          { title: "Sushi" },
        ],
      },
      {
        action: "school",
        title: "Education",
        items: [{ title: "List Item" }],
      },
      {
        action: "directions_run",
        title: "Family",
        items: [{ title: "List Item" }],
      },
      {
        action: "healing",
        title: "Health",
        items: [{ title: "List Item" }],
      },
      {
        action: "content_cut",
        title: "Office",
        items: [{ title: "List Item" }],
      },
      {
        action: "local_offer",
        title: "Promotions",
        items: [{ title: "List Item" }],
      },
    ],
  }),
};
</script>