// Doughnut Chart
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */

import { Bar } from "vue-chartjs";
import { ChartConfig } from "@/constants/chart-config.js";
import { template } from "lodash";
import numeral from "numeral";

export default {
  name: "bar-chart",
  extends: Bar,
  props: {
    chartOptions: Object,
    chartDataSet: Object,
    width: {
      default: 365,
      type: Number,
    },
    height: {
      default: 365,
      type: Number,
    },
  },
  data() {
    return {
      ChartConfig,
      options: {
        legend: {
          display: false,
        },        
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
      },
    };
  },
  methods: {
    getDataset() {
      // merge options
      this.options = { ...this.options, ...this.chartOptions };
      const dataset = this.chartDataSet;
      this.renderChart(
        {
          labels: dataset.labels,
          datasets: [
            {
              data: dataset.data,
              backgroundColor: dataset.backgroundColor,
            },
          ],
        },
        this.options
      );
    },
  },
  mounted() {
    this.getDataset();
  },
  watch: {
    chartDataSet: function() {
      this.getDataset();
    },
  },
};
