<template>
  <div></div>
</template>

<script>
import firebase from 'firebase/compat/app';
// import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {},
  components: {},
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  async mounted() {
    await firebase
      .database()
      .ref("users")
      .once("value", (snapshot) => {
        snapshot.forEach(function (childSnapshot) {
          let uid = childSnapshot.key;
          // users
          firebase
            .database()
            .ref("/stats/" + uid)
            .once("value", (snapshot) => {
              let user_stats = snapshot.val();
              let time = moment().format();
              let stats = {
                login: [],
                total_plan: 0,
                total_plan_30days: 0,
                total_plan_detail: {
                  balance_sheet: 0,
                  cash_flow: 0,
                  financial_ratio: 0,
                  education: 0,
                  retirement: 0,
                  tax: 0,
                  health: 0,
                },
              };
              console.log(">> ",user_stats);
              if (user_stats !== null) {
                stats.login = user_stats.login;
              }
              stats.login.push(time);

              // plans
              firebase
                .database()
                .ref("/plans")
                .orderByChild("uid")
                .equalTo(uid)
                .once("value", (snapshot) => {
                  snapshot.forEach(function (childSnapshot) {
                    let plan = childSnapshot.val();
                    let createAt = plan.createAt;
                    let diff = moment().diff(moment(createAt), "days");
                    stats.total_plan += 1;
                    if (diff <= 30) {
                      stats.total_plan_30days += 1;
                    }

                    stats.total_plan_detail.balance_sheet += 1;
                    stats.total_plan_detail.cash_flow += 1;
                    stats.total_plan_detail.financial_ratio += 1;

                    if (plan.progress === undefined) {
                      stats.total_plan_detail.education += 1;
                      stats.total_plan_detail.retirement += 1;
                      stats.total_plan_detail.tax += 1;
                      stats.total_plan_detail.health += 1;
                    } else {
                      if (plan.progress.education == 100) {
                        stats.total_plan_detail.education += 1;
                      }
                      if (plan.progress.retirement == 100) {
                        stats.total_plan_detail.retirement += 1;
                      }
                      if (plan.progress.tax == 100) {
                        stats.total_plan_detail.tax += 1;
                      }
                      if (plan.progress.health == 100) {
                        stats.total_plan_detail.health += 1;
                      }
                    }
                  });
                  firebase
                    .database()
                    .ref("/stats/" + uid)
                    .set(stats);
                });
            });
        });
      });
  },

  methods: {},
  data: () => ({
    users: [],
    login: [],
  }),
};
</script>