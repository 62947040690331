import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth/index.js";
import plan from "./modules/plan/index.js";

import user from "./modules/user/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: localStorage.getItem("drawer")
      ? localStorage.getItem("drawer").toString() == "true"
      : true,
  },
  getters: {
    drawer: (state) => {
      return state.drawer;
    },
  },
  mutations: {
    TOGGLE_DRAWER(state) {
      this.state.drawer = !state.drawer;
      localStorage.setItem("drawer", this.state.drawer);
    },
    HIDE_DRAWER() {
      this.state.drawer = false;
      localStorage.setItem("drawer", this.state.drawer);
    },
    SHOW_DRAWER() {
      this.state.drawer = true;
      localStorage.setItem("drawer", this.state.drawer);
    },
  },
  actions: {
    toggleDrawer(context, payload) {
      context.commit("TOGGLE_DRAWER", payload);
    },
    hideDrawer(context, payload) {
      context.commit("HIDE_DRAWER", payload);
    },
    showDrawer(context, payload) {
      context.commit("SHOW_DRAWER", payload);
    },
  },
  modules: {
    auth,
    plan,
    user,
  },
});

/*
firebase rules
{
  "rules": {
    ".read": "auth != null && (auth.token.admin === true || auth.token.manager === true)",
    ".write": "auth != null && (auth.token.admin === true || auth.token.manager === true)",
      "users":{        
          "$uid":{
            ".read":"auth != null && $uid === auth.uid",
            ".write":"auth != null && $uid === auth.uid"
          }
      },
      "plans":{
        ".indexOn": ["uid"],
				".read": "auth != null",
        ".write": "auth != null && newData.child('uid').val() === auth.uid",
        "$pid":{
          ".read":"auth != null",
          ".write":"auth != null && data.child('uid').val() === auth.uid"
        }
      }
  }
}
*/
