export const tax = {
  required: {
    dataInput: { cell: [
      // === no required ===
      "YR1",
      "C4",
      "E4",
      // "TH5"
      // === no required ===
    ] }
  },
  cell: {
    A1:{
      label:"ชื่อ-สกุล",
      value:"",
      type:"text",
      formula: "dataInput.C4"
    },
    // เงินได้
    TH5: {
      label: "(1) เงินเดือน โบนัส ค่าจ้าง บำนาญ",
      value: "",
      type: "number",
      formula: "dataInput.TH5"
    },
    TH6: {
      label: "(2) ค่านายหน้า ค่ารับจ้างทำ",
      value: "",
      type: "number",
      formula: "dataInput.TH6"
    },
    TI5: {
      label: "(1)+(2) เงินเดือน+ค่านายหน้า - หักค่าใช้จ่าย",
      value: 100000,
      type: "number",
      formula: "Math.min(100000,dataInput.TH5+dataInput.TH6)"
    },
    TJ5: {
      label: "(1)+(2) เงินเดือน+ค่านายหน้า - เงินได้สุทธิ",
      value: 0,
      type: "number",
      formula: "dataInput.TH5+dataInput.TH6-tax.TI5"
    },
    TH7: {
      label: "(3) ลิขสิทธิ์",
      value: "",
      type: "number",
      formula: "dataInput.TH7"
    },
    TI7: {
      label: "ลิขสิทธิ์ - หักค่าใช้จ่าย",
      value: "",
      type: "number",
      formula: "Math.min(dataInput.TH7,dataInput.TH7*0.5)"
    },
    TJ7: {
      label: "ลิขสิทธิ์ - เงินได้สุทธิ",
      value: "",
      type: "number",
      formula: "tax.TH7-tax.TI7"
    },
    TH8: {
      label: "(4) ดอกเบี้ย เงินปันผล",
      value: "",
      type: "number",
      formula: "dataInput.TH8"
    },
    TJ8: {
      label: "(4) ดอกเบี้ย เงินปันผล - เงินได้สุทธิ",
      value: "",
      type: "number",
      formula: "dataInput.TH8"
    },
    TH9: {
      label: "(5) ค่าเช่า",
      value: "",
      type: "number",
      formula: "dataInput.TH9"
    },
    TI9: {
      label: "(5) ค่าเช่า - หักค่าใช้จ่าย",
      value: "",
      type: "number",
      formula: "dataInput.TI9"
    },
    TJ9: {
      label: "(5) ค่าเช่า - เงินได้สุทธิ",
      value: "",
      type: "number",
      formula: "dataInput.TH9-dataInput.TI9"
    },
    TH10: {
      label: "(6) วิชาชีพอิสระ",
      value: "",
      type: "number",
      formula: "dataInput.TH10"
    },
    TI10: {
      label: "(6) วิชาชีพอิสระ - หักค่าใช้จ่าย",
      value: "",
      type: "number",
      formula: "dataInput.TI10"
    },
    TJ10: {
      label: "(6) วิชาชีพอิสระ - เงินได้สุทธิ",
      value: "",
      type: "number",
      formula: "dataInput.TH10-dataInput.TI10"
    },
    TH11: {
      label: "(7) รับเหมาโดยการจัดหาของให้",
      value: "",
      type: "number",
      formula: "dataInput.TH11"
    },
    TI11: {
      label: "(7) รับเหมาโดยการจัดหาของให้ - หักค่าใช้จ่าย",
      value: "",
      type: "number",
      formula: "dataInput.TI11"
    },
    TJ11: {
      label: "(7) รับเหมาโดยการจัดหาของให้ - เงินได้สุทธิ",
      value: "",
      type: "number",
      formula: "dataInput.TH11-dataInput.TI11"
    },
    TH12: {
      label: "(8) อื่นๆ",
      value: "",
      type: "number",
      formula: "dataInput.TH12"
    },
    TI12: {
      label: "(8) อื่นๆ - หักค่าใช้จ่าย",
      value: "",
      type: "number",
      formula: "dataInput.TI12"
    },
    TJ12: {
      label: "(8) อื่นๆ- เงินได้สุทธิ",
      value: "",
      type: "number",
      formula: "dataInput.TH12-dataInput.TI12"
    },
    TJ13: {
      label: "รวมเงินได้พึงประเมิน หลังหักค่าใช้จ่าย",
      value: "",
      type: "number",
      formula: "tax.TJ5+tax.TJ7+tax.TJ8+tax.TJ9+tax.TJ10+tax.TJ11+tax.TJ12"
    },
    // ค่าลดหย่อน
    TI16: {
      label: "ส่วนตัว (ก่อนวางแผน)",
      value: 60000,
      type: "number"
    },
    TJ16: {
      label: "ส่วนตัว (หลังวางแผน)",
      value: 60000,
      type: "number"
    },
    TI17: {
      label: "คู่สมรส (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI17"
    },
    TJ17: {
      label: "คู่สมรส (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ17"
    },
    TI18: {
      label: "บุตร (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI18"
    },
    TJ18: {
      label: "บุตร (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "(dataInput.TJ18)"
    },
    TI19: {
      label:
        "บุตรคนที่ 2 เป็นต้นไป ที่คลอดในปี 2561 และหลังจากนั้น (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI19"
    },
    TJ19: {
      label:
        "บุตรคนที่ 2 เป็นต้นไป ที่คลอดในปี 2561 และหลังจากนั้น (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ19"
    },
    TI20: {
      label: "ค่าฝากครรภ์-คลอดบุตร (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI20"
    },
    TJ20: {
      label: "ค่าฝากครรภ์-คลอดบุตร (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ20"
    },
    TI21: {
      label:
        "บิดา/มารดา ไม่เกิน 4 คน (อายุเกิน 60 ปีและรายได้น้อยกว่า 30,000) (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI21"
    },
    TJ21: {
      label:
        "บิดา/มารดา ไม่เกิน 4 คน (อายุเกิน 60 ปีและรายได้น้อยกว่า 30,000) (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ21"
    },
    TI22: {
      label: "ค่าเลี้ยงดูบุคคลทุพพลภาพหรือพิการ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI22"
    },
    TJ22: {
      label: "ค่าเลี้ยงดูบุคคลทุพพลภาพหรือพิการ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ22"
    },
    TI23: {
      label:
        "เบี้ยประกันสุขภาพ บิดา/มารดา (รายได้น้อยกว่า 30,000 บาท) (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI23"
    },
    TJ23: {
      label:
        "เบี้ยประกันสุขภาพ บิดา/มารดา (รายได้น้อยกว่า 30,000 บาท) (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ23"
    },
    TI24: {
      label: "เบี้ยประกันชีวิต - ผู้มีเงินได้ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI24"
    },
    TJ24: {
      label: "เบี้ยประกันชีวิต - ผู้มีเงินได้ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ24"
    },
    TI25: {
      label: "เบี้ยประกันชีวิต - คู่สมรส (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI25"
    },
    TJ25: {
      label: "เบี้ยประกันชีวิต - คู่สมรส (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ25"
    },
    TI26: {
      label: "เบี้ยประกันบำนาญ - ผู้มีเงินได้ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI26"
    },
    TJ26: {
      label: "เบี้ยประกันบำนาญ - ผู้มีเงินได้ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ26"
    },
    TI27: {
      label: "เงินสมทบกองทุนสำรองเลี้ยงชีพ/กบข/ครูเอกชน (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI27"
    },
    TJ27: {
      label: "เงินสมทบกองทุนสำรองเลี้ยงชีพ/กบข/ครูเอกชน (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ27"
    },
    TI28: {
      label: "ค่าซื้อหน่วยลงทุน RMF (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI28"
    },
    TJ28: {
      label: "ค่าซื้อหน่วยลงทุน RMF (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ28"
    },
    TI29: {
      label: "ค่าซื้อหน่วยลงทุน LTF (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI29"
    },
    TJ29: {
      label: "ค่าซื้อหน่วยลงทุน LTF (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ29"
    },
    TI30: {
      label: "ดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI30"
    },
    TJ30: {
      label: "ดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ30"
    },
    TI31: {
      label: "เงินสมทบกองทุนประกันสังคม (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI31"
    },
    TJ31: {
      label: "เงินสมทบกองทุนประกันสังคม (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ31"
    },
    TI32: {
      label: "บ้านหลังแรก (ก่อนวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TI32"
    },
    TJ32: {
      label: "บ้านหลังแรก (หลังวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TJ32"
    },
    TI33: {
      label:
        "ค่าธรรมเนียมจากการรับชำระเงินด้วยบัตรเดบิต สำหรับ 40(5)-(8) ที่มีเงินได้ไม่เกิน 30 ล้าน (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI33"
    },
    TJ33: {
      label:
        "ค่าธรรมเนียมจากการรับชำระเงินด้วยบัตรเดบิต สำหรับ 40(5)-(8) ที่มีเงินได้ไม่เกิน 30 ล้าน (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ33"
    },
    TI34: {
      label: "ลงทุนในธุรกิจ Startup (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI34"
    },
    TJ34: {
      label: "ลงทุนในธุรกิจ Startup (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ34"
    },
    TI35: {
      label:
        "ช้อปช่วยชาติ (ยางรถยนต์, หนังสือ-ebook, OTOP) ช่วง 15/12/61-16/01/62 (ก่อนวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TI35"
    },
    TJ35: {
      label:
        "ช้อปช่วยชาติ (ยางรถยนต์, หนังสือ-ebook, OTOP) ช่วง 15/12/61-16/01/62 (หลังวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TJ35"
    },
    TI36: {
      label:
        "ท่องเที่ยวในประเทศ (เมืองหลัก 15,000/เมืองรอง 20,000) ช่วง 30/04/62-30/06/62 (ก่อนวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TI36"
    },
    TJ36: {
      label:
        "ท่องเที่ยวในประเทศ (เมืองหลัก 15,000/เมืองรอง 20,000) ช่วง 30/04/62-30/06/62 (หลังวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TJ36"
    },
    TI37: {
      label:
        "สินค้าการศึกษาและอุปกรณ์กีฬา ช่วง 01/05/62 - 30/06/62 (ก่อนวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TI37"
    },
    TJ37: {
      label:
        "สินค้าการศึกษาและอุปกรณ์กีฬา ช่วง 01/05/62 - 30/06/62 (หลังวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TJ37"
    },
    TI38: {
      label: "สินค้า OTOP ช่วง 30/04/62 - 30/06/62 (ก่อนวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TI38"
    },
    TJ38: {
      label: "สินค้า OTOP ช่วง 30/04/62 - 30/06/62 (หลังวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TJ38"
    },
    TI39: {
      label: "หนังสือและ e-boook ช่วง 01/01/62 - 31/12/62 (ก่อนวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TI39"
    },
    TJ39: {
      label: "หนังสือและ e-boook ช่วง 01/01/62 - 31/12/62 (หลังวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TJ39"
    },
    TI40: {
      label:
        "บ้านหลังแรก มูลค่าไม่เกิน 5 ล้าน ช่วง 01/05/62 - 31/12/62 (ก่อนวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TI40"
    },
    TJ40: {
      label:
        "บ้านหลังแรก มูลค่าไม่เกิน 5 ล้าน ช่วง 01/05/62 - 31/12/62 (หลังวางแผน)",
      value: "",
      type: "number",
      // formula: "dataInput.TJ40"
    },
    TI41: {
      label: "อื่นๆ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI41"
    },
    TJ41: {
      label: "อื่นๆ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ41"
    },
    TH501: {
      label: "อื่นๆ ข้อ(1)",
      value: "",
      type: "text",
      formula: "dataInput.TH501"
    },
    TI501: {
      label: "อื่นๆ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI501"
    },
    TJ501: {
      label: "อื่นๆ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ501"
    },

    TH502: {
      label: "อื่นๆ ข้อ(2)",
      value: "",
      type: "text",
      formula: "dataInput.TH502"
    },
    TI502: {
      label: "อื่นๆ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI502"
    },
    TJ502: {
      label: "อื่นๆ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ502"
    },

    TH503: {
      label: "อื่นๆ ข้อ(3)",
      value: "",
      type: "text",
      formula: "dataInput.TH503"
    },
    TI503: {
      label: "อื่นๆ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI503"
    },
    TJ503: {
      label: "อื่นๆ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ503"
    },

    TH504: {
      label: "อื่นๆ ข้อ(4)",
      value: "",
      type: "text",
      formula: "dataInput.TH504"
    },
    TI504: {
      label: "อื่นๆ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI504"
    },
    TJ504: {
      label: "อื่นๆ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ504"
    },

    TI42: {
      label: "รวมค่าลดหย่อน (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula:
        "tax.TI16+tax.TI17+tax.TI18+tax.TI19+tax.TI20+tax.TI21+tax.TI22+tax.TI23+tax.TI24+tax.TI25+tax.TI26+tax.TI27+tax.TI28+tax.TI29+tax.TI30+tax.TI31+tax.TI32+tax.TI33+tax.TI34+tax.TI35+tax.TI36+tax.TI37+tax.TI38+tax.TI39+tax.TI40+tax.TI41+tax.TI501+tax.TI502+tax.TI503+tax.TI504"
    },
    TJ42: {
      label: "รวมค่าลดหย่อน (หลังวางแผน)",
      value: "",
      type: "number",
      formula:
        "tax.TJ16+tax.TJ17+tax.TJ18+tax.TJ19+tax.TJ20+tax.TJ21+tax.TJ22+tax.TJ23+tax.TJ24+tax.TJ25+tax.TJ26+tax.TJ27+tax.TJ28+tax.TJ29+tax.TJ30+tax.TJ31+tax.TJ32+tax.TJ33+tax.TJ34+tax.TJ35+tax.TJ36+tax.TJ37+tax.TJ38+tax.TJ39+tax.TJ40+tax.TJ41+tax.TJ501+tax.TJ502+tax.TJ503+tax.TJ504"
    },
    TI44: {
      label: "รายได้พึงประเมิน หลังหักค่าลดหย่อน (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "(tax.TJ13-tax.TI42>0)?tax.TJ13-tax.TI42:0"
    },
    TJ44: {
      label: "รายได้พึงประเมิน หลังหักค่าลดหย่อน (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "tax.TJ13-tax.TJ42"
    },
    TI45: {
      label:
        "หัก เงินบริจาคเพื่อสนับสนุนการศึกษา, กีฬา, โรงพยาบาล (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI45"
    },
    TJ45: {
      label:
        "หัก เงินบริจาคเพื่อสนับสนุนการศึกษา, กีฬา, โรงพยาบาล (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ45"
    },
    TI47: {
      label: "หัก เงินบริจาคเพื่อการสาธารณกุศลอื่นๆ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TI47"
    },
    TJ47: {
      label: "หัก เงินบริจาคเพื่อการสาธารณกุศลอื่นๆ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "dataInput.TJ47"
    },
    TI48: {
      label: "เงินได้สุทธิ (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula: "(tax.TI44-tax.TI45-tax.TI47>0)?tax.TI44-tax.TI45-tax.TI47:0"
    },
    TJ48: {
      label: "เงินได้สุทธิ (หลังวางแผน)",
      value: "",
      type: "number",
      formula: "(tax.TJ44-tax.TJ45-tax.TJ47>0)?tax.TJ44-tax.TJ45-tax.TJ47:0"
    },
    // บันไดภาษี
    TH52: {
      label: "เงินได้สุทธิ 150,001-300,000",
      value: "",
      type: "number",
      formula: "(1+300000-150001)*0.05"
    },
    TI52: {
      label: "เงินได้สุทธิ 150,001-300,000 (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TI48<150001)?0:(tax.TI48>300000)?tax.TH52:(tax.TI48-150000)*0.05"
    },
    TJ52: {
      label: "เงินได้สุทธิ 150,001-300,000 (หลังวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TJ48<150001)?0:(tax.TJ48>300000)?tax.TH52:(tax.TJ48-150000)*0.05"
    },
    TH53: {
      label: "เงินได้สุทธิ 300,001-500,000",
      value: "",
      type: "number",
      formula: "(1+500000-300001)*0.1"
    },
    TI53: {
      label: "เงินได้สุทธิ 300,001-500,000 (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TI48<300001)?0:(tax.TI48>500000)?tax.TH53:(tax.TI48-300000)*0.1"
    },
    TJ53: {
      label: "เงินได้สุทธิ 300,001-500,000 (หลังวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TJ48<300001)?0:(tax.TJ48>500000)?tax.TH53:(tax.TJ48-300000)*0.1"
    },
    TH54: {
      label: "เงินได้สุทธิ 500,001-750,000",
      value: "",
      type: "number",
      formula: "(1+750000-500001)*0.15"
    },
    TI54: {
      label: "เงินได้สุทธิ 500,001-750,000 (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TI48<500001)?0:(tax.TI48>750000)?tax.TH54:(tax.TI48-500000)*0.15"
    },
    TJ54: {
      label: "เงินได้สุทธิ 500,001-750,000 (หลังวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TJ48<500001)?0:(tax.TJ48>750000)?tax.TH54:(tax.TJ48-500000)*0.15"
    },
    TH55: {
      label: "เงินได้สุทธิ 750,001-1,000,000",
      value: "",
      type: "number",
      formula: "(1+1000000-750001)*0.2"
    },
    TI55: {
      label: "เงินได้สุทธิ 750,001-1,000,000 (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TI48<750001)?0:(tax.TI48>1000000)?tax.TH55:(tax.TI48-750000)*0.2"
    },
    TJ55: {
      label: "เงินได้สุทธิ 750,001-1,000,000 (หลังวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TJ48<750001)?0:(tax.TJ48>1000000)?tax.TH55:(tax.TJ48-750000)*0.2"
    },

    TH56: {
      label: "เงินได้สุทธิ 1,000,001-2,000,000",
      value: "",
      type: "number",
      formula: "(1+2000000-1000001)*0.25"
    },
    TI56: {
      label: "เงินได้สุทธิ 1,000,001-2,000,000 (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TI48<1000001)?0:(tax.TI48>2000000)?tax.TH56:(tax.TI48-1000000)*0.25"
    },
    TJ56: {
      label: "เงินได้สุทธิ 1,000,001-2,000,000 (หลังวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TJ48<1000001)?0:(tax.TJ48>2000000)?tax.TH56:(tax.TJ48-1000000)*0.25"
    },
    TH57: {
      label: "เงินได้สุทธิ 2,000,001-5,000,000",
      value: "",
      type: "number",
      formula: "(1+5000000-2000001)*0.3"
    },
    TI57: {
      label: "เงินได้สุทธิ 2,000,001-5,000,000 (ก่อนวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TI48<2000001)?0:(tax.TI48>5000000)?tax.TH57:(tax.TI48-2000000)*0.3"
    },
    TJ57: {
      label: "เงินได้สุทธิ 2,000,001-5,000,000 (หลังวางแผน)",
      value: "",
      type: "number",
      formula:
        "(tax.TJ48<2000001)?0:(tax.TJ48>5000000)?tax.TH57:(tax.TJ48-2000000)*0.3"
    },
    TI59: {
      label: "ภาษีที่ต้องจ่าย (หลังวางแผน)",
      value: "",
      type: "number",
      formula:"tax.TI52+tax.TI53+tax.TI54+tax.TI55+tax.TI56+tax.TI57"
    },
    TJ59: {
      label: "ภาษีที่ต้องจ่าย (หลังวางแผน)",
      value: "",
      type: "number",
      formula:"tax.TJ52+tax.TJ53+tax.TJ54+tax.TJ55+tax.TJ56+tax.TJ57"
    },
    TI60: {
      label: "หัก ณ ที่จ่ายไว้แล้ว (หลังวางแผน)",
      value: "",
      type: "number",
      formula:"dataInput.TI60"
    },
    TJ60: {
      label: "หัก ณ ที่จ่ายไว้แล้ว (หลังวางแผน)",
      value: "",
      type: "number",
      formula:"dataInput.TJ60"
    },
    TI61: {
      label: "ภาษีที่ต้องชำระ/ขอคืน (หลังวางแผน)",
      value: "",
      type: "number",
      formula:"Math.abs(tax.TI60-tax.TI59)"
    },
    TJ61: {
      label: "ภาษีที่ต้องชำระ/ขอคืน (หลังวางแผน)",
      value: "",
      type: "number",
      formula:"Math.abs(tax.TJ60-tax.TJ59)"
    },
    TJ63: {
      label: "ประหยัดภาษี",
      value: "",
      type: "number",
      formula:"tax.TI59-tax.TJ59"
    },
    TF67:{
      label: "คำนวณภาษีแบบอัตราคงที่",
      value: "",
      type: "number",
      formula:"(tax.TH6+tax.TH7+tax.TH8+tax.TH9+tax.TH10+tax.TH11+tax.TH12>0)?tax.TH6+tax.TH7+tax.TH8+tax.TH9+tax.TH10+tax.TH11+tax.TH12:0"
    },
    TI67:{
      label: "คิดเป็นภาษี",
      value: "",
      type: "number",
      formula:"(tax.TF67*0.005>5000)?tax.TF67*0.005:0"
    }
  }
};
