
<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import firebase from 'firebase/compat/app';

// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld
  },
  created(){
      firebase.database().ref('/user').on('value', (snapshot) => {
      // this.contacts = snapshot.val()
      // console.log(snapshot,snapshot.val());
    })
  },
  mounted () {
     this.$router.push("/dashboard");
  }
};
</script>
