/**
 * All Plan Module
 */

import dataSheetCalculate from "./data.sheets.calculate.js";
import plan from "./plan.js";

const state = {};

const getters = {};

const actions = {};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    plan,
    dataSheetCalculate
  }
};
