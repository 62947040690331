export const demo_sheets = {
  dataInput: {
    cell: {
      // 1. ชื่อสมาชิกในครอบครัว
      YR1: {
        label: "ปีที่วางแผน",
        value: 2563
      },
      C4: {
        label: "ชื่อ-สกุล",
        value: "เกริกฤทธิ์ รอบคอบ demo"
      },
      E4: {
        label: "อายุ",
        value: "36"
      },
      // CA4: {
      //   label: "ปีเกิด",
      //   value: 2526
      // },
      C5: {
        label: "คู่สมรส",
        value: ""
      },
      E5: {
        label: "อายุ (คู่สมรส)",
        value: ""
      },
      // CA5: {
      //   label: "ปีเกิด (คู่สมรส)",
      //   value: ""
      // },
      C6: {
        label: "บิดา",
        value: ""
      },
      E6: {
        label: "อายุ (บิดา)",
        value: ""
      },
      C7: {
        label: "มารดา",
        value: ""
      },
      E7: {
        label: "อายุ (มารดา)",
        value: ""
      },
      C8: {
        label: "บุตรคนที่ 1",
        value: "น้องเอ"
      },
      E8: {
        label: "อายุ (บุตรคนที่ 1)",
        value: 1
      },
      // CA8: {
      //   label: "ปีเกิด (บุตรคนที่ 1)",
      //   value: 2563
      // },
      C9: {
        label: "บุตรคนที่ 2",
        value: "น้องบี"
      },
      E9: {
        label: "อายุ (บุตรคนที่ 2)",
        value: 2
      },
      // CA9: {
      //   label: "ปีเกิด (บุตรคนที่ 2)",
      //   value: 2562
      // },
      // 2. ข้อมูลทรัพย์สิน หนี้สิน
      K3: {
        label: "เงินสด",
        value: 1000000
      },
      P3: {
        label: "กองทุนรวมตราสารหนี้",
        value: 120000
      },
      K4: {
        label: "บ/ช ออมทรัพย์",
        value: 7200000
      },
      P4: {
        label: "บ/ช ประจำ",
        value: ""
      },
      K5: {
        label: "มูลค่าเวนคืนประกันชีวิต",
        value: ""
      },
      P5: {
        label: "สหกรณ์",
        value: ""
      },
      K6: {
        label: "พันธบัตร/หุ้นกู้/ตราสารหนี้",
        value: ""
      },
      P6: {
        label: "หุ้นบุริมสิทธิ์",
        value: ""
      },
      K7: {
        label: "หุ้นสามัญ",
        value: 515000
      },
      P7: {
        label: "warant",
        value: ""
      },
      K8: {
        label: "กองทุนรวม หุ้นMF equity",
        value: 1300000
      },
      P8: {
        label: "LTF",
        value: 1500000
      },
      K9: {
        label: "RMF equity",
        value: ""
      },
      P9: {
        label: "privatefund",
        value: ""
      },
      K10: {
        label: "forex",
        value: ""
      },
      P10: {
        label: "RMF Fix",
        value: ""
      },
      K11: {
        label: "กองทุนสำรองเลี้ยงชีพ PVD",
        value: ""
      },
      P11: {
        label: "Future",
        value: ""
      },
      K12: {
        label: "เงินออมเพื่อเกษียณ",
        value: ""
      },
      P12: {
        label: "กบข",
        value: ""
      },
      K13: {
        label: "บ้าน",
        value: 12000000
      },
      P13: {
        label: "รถยนต์",
        value: 1900000
      },
      K14: {
        label: "หอพัก.บ้านเช่า",
        value: ""
      },
      P14: {
        label: "เครื่องประดับ",
        value: ""
      },
      K15: {
        label: "เครื่องเพชร",
        value: ""
      },
      P15: {
        label: "ที่ดิน",
        value: 20000000
      },
      K16: {
        label: "คอนโด",
        value: ""
      },
      P16: {
        label: "ของสะสม",
        value: ""
      },
      K17: {
        label: "หนี้บัตรเครดิต",
        value: ""
      },
      P17: {
        label: "อสังหาริมทรัพย์อื่น",
        value: ""
      },
      K18: {
        label: "หนี้สาธารณูปโภค",
        value: ""
      },
      P18: {
        label: "หนี้บ้านคงเหลือ",
        value: 6800000
      },
      K19: {
        label: "หนี้รถยนต์คงเหลือ",
        value: 290000
      },
      P19: {
        label: "หนี้สินอื่นๆไม่มีคุ้มครอง",
        value: ""
      },
      K20: {
        label: "ทรัพย์สินอื่น",
        value: ""
      },
      P20: {
        label: "หนี้ระยะยาวอื่นๆ",
        value: ""
      },
      // 3.ข้อมูลรายรับ รายจ่าย
      K22: {
        label: "เงินเดือน /ค่าจ้าง/รายได้ ต่อเดือน",
        value: 300000
      },
      K23: {
        label: "ดอกเบี้ยรับ ต่อปี",
        value: ""
      },
      K24: {
        label: "เงินปันผลรับ(หุ้นหน่วยลงทุน) ต่อปี",
        value: ""
      },
      K25: {
        label: "รายได้จากค่าเช่า ต่อปี",
        value: 200000
      },
      K26: {
        label: "รายได้จากการขายสินทรัพย์ ต่อปี",
        value: ""
      },
      K27: {
        label: "รายได้อื่นๆ(มรดก เงินคืนภาษี) ต่อปี",
        value: ""
      },
      K28: {
        label: "ค่าคอมมิชชัน/ปี",
        value: 740000
      },
      K29: {
        label: "โบนัส/ปี",
        value: 1200000
      },
      K30: {
        label: "ค่าอาหารครอบครัวต่อเดือน",
        value: 20000
      },
      K31: {
        label: "ค่าโทรศัพท์ ครอบครัวต่อเดือน",
        value: 2500
      },
      K32: {
        label: "ค่าสาธารณูปโภค(ค่าน้ำค่าไฟ ต่อเดือน",
        value: 700
      },
      K33: {
        label: "ค่าใช้จ่ายนันทนาการท่องเที่ยวครอบครัว/ปี",
        value: 1000000
      },
      K34: {
        label: "ค่ารักษาพยาบาลครอบครัว ต่อปี",
        value: ""
      },
      K35: {
        label: "จ่ายภาษี /ปี",
        value: ""
      },
      K36: {
        label: "ค่าใช้จ่ายในการเดินทาง/ปี",
        value: ""
      },
      K37: {
        label: "เงินจ่ายให้กับบิดาและมารดา/ปี",
        value: ""
      },
      K38: {
        label: "ค่าใช้จ่ายส่วนตัว/ปี",
        value: ""
      },
      K39: {
        label: "ค่าใช้จ่ายบุตร /ปี",
        value: ""
      },
      K40: {
        label: "ค่าใช้จ่ายอื่นๆ",
        value: ""
      },
      K41: {
        label: "เงินเดือน พนักงาน / เดือน",
        value: ""
      },
      K42: {
        label: "ค่าใช้จ่าย....ปี",
        value: ""
      },
      K43: {
        label: "ค่าใช้จ่าย.....ปี",
        value: ""
      },
      P22: {
        label: "จ่ายบัตรเครดิตต่อเดือน",
        value: 60000
      },
      P23: {
        label: "จ่ายงวดรถต่อเดือน",
        value: ""
      },
      P24: {
        label: "จ่ายกู้ซื้อบ้านต่อเดือน",
        value: ""
      },
      P25: {
        label: "เบี้ยประกันชีวิตตนเอง/ปี",
        value: 748000
      },
      P26: {
        label: "เบี้ยประกันชีวิตคู่สมรส/ปี",
        value: ""
      },
      P27: {
        label: "เบี้ยประกันชีวิตบิดา/ปี",
        value: ""
      },
      P28: {
        label: "เบี้ยประกันชีวิตมารดา/ปี",
        value: ""
      },
      P29: {
        label: "เบี้ยประกันชีวิตบุตร/ปี",
        value: ""
      },
      P30: {
        label: "เบี้ยประกันภัย รถ/ปี",
        value: ""
      },
      P31: {
        label: "เบี้ยประกันภัย บ้าน/ปี",
        value: ""
      },
      P32: {
        label: "ประกันสังคม/ปี",
        value: 9000
      },
      P33: {
        label: "จ่ายสำรองเลี้ยงชีพ PVD/ปี",
        value: ""
      },
      P34: {
        label: "จ่ายเงินออมธนาคาร/ปี",
        value: ""
      },
      P35: {
        label: "จ่ายเงินลงทุนพันธบัตร/ปี",
        value: 600000
      },
      P36: {
        label: "MF/ปี",
        value: ""
      },
      P37: {
        label: "LTF/ปี",
        value: 250000
      },
      P38: {
        label: "RMF equity/ปี",
        value: ""
      },
      P39: {
        label: "RMF Fix/ปี",
        value: ""
      },
      P40: {
        label: "STOCK/ปี",
        value: ""
      },
      P41: {
        label: "ต้นทุน /ปี",
        value: ""
      },
      P42: {
        label: "จ่าย กบช. ต่อปี",
        value: ""
      },
      P43: {
        label: "จ่าย.....ปี",
        value: ""
      },
      // 4. ข้อมูลวางแผนการศึกษา
      M50: {
        label: "บุตรคนที่1 ป.ตรี ค่าใช้จ่าย/เดือน",
        value: 50000
      },
      M51: {
        label: "บุตรคนที่1 ป.โท ค่าใช้จ่าย/เดือน",
        value: 100000
      },
      M52: {
        label: "บุตรคนที่1 ป.เอก ค่าใช้จ่าย/เดือน",
        value: ""
      },
      K53: {
        label: "บุตรคนที่1 เตรียมไว้แล้ว ผลตอบแทนกี่%",
        value: 2.0,
      },
      M53: {
        label: "บุตรคนที่1 เตรียมไว้แล้ว",
        value: 500000
      },
      M54: {
        label: "บุตรคนที่2 ป.ตรี ค่าใช้จ่าย/เดือน",
        value: 50000
      },
      M55: {
        label: "บุตรคนที่2 ป.โท ค่าใช้จ่าย/เดือน",
        value: 100000
      },
      M56: {
        label: "บุตรคนที่2 ป.เอก ค่าใช้จ่าย/เดือน",
        value: ""
      },
      K57: {
        label: "บุตรคนที่2 เตรียมไว้แล้ว ผลตอบแทนกี่%",
        value: 2,        
      },
      M57: {
        label: "บุตรคนที่2 เตรียมไว้แล้ว",
        value: 500000
      },
      // 5. ข้อมูลแผนเกษียณ
      RTY33: {
        label: "1. ฝากธนาคาร (A/C) ... %",
        value: 0.25,
      },
      RTY35: {
        label: "2. ซื้อพันธบัตร/ตราสารหนี้ ... %",
        value: 3,
      },
      RTY37: {
        label: "3.  กองทุนรวมชั้นดี ... %",
        value: 6,
      },
      RTY39: {
        label: "4. ประกันชีวิตแบบบำนาญ ... %",
        value: 2,
      },
      RTY41: {
        label: "5. Investment Link ... %",
        value: 6,
      },
      RTY43: {
        label: "6. แบบชำระเบี้ยครั้งเดียว  ... %",
        value: 6,
      },
      RT1AB18: {
        label: "สินทรัพย์สภาพคล่อง",
        value: 0,
      },
      RT1AB20: {
        label: "สินทรัพย์เพื่อการลงทุน",
        value: 7000000,
      },
      RT1AB23: {
        label: "ทรัพย์สินอื่นที่เตรียมไว้แล้ว",
        value: 0
      },
      RT1AB25: {
        label: "เงินบำนาญและเงินอื่นๆ",
        value: 3644860	,
      },
      // 6. ข้อมูลภาษีรายได้
      I44: {
        label: "ต้องการเกษียณอายุ",
        value: "50"
      },
      I45: {
        label: "คู่สมรสเกษียณอายุ",
        value: ""
      },
      N44: {
        label: "เงินที่คาดว่าต้องใช้/เดือน ปัจจุบัน",
        value: ""
      },
      N45: {
        label: "เงินที่คาดว่าต้องใช้/เดือน ปัจจุบัน",
        value: ""
      },
      N46: {
        label: "เงินคืนจากกองทุนชราภาพ ปกส.",
        value: ""
      },
      N47: {
        label: "ถ้าเกษียณเงินบำนาญต่อ/เดือน",
        value: ""
      },
      N48: {
        label: "เงินประกันชีวิตแบบบำนาญ  annuity ต่อ/ปี",
        value: ""
      },
      RT1:{
        label: "เงิน/เดือน",
        value: 50000,
      },
      RT2:{
        label: "เงิน/เดือน",
        value: 50000,
      },
      // 6. ข้อมูลการคุ้มครองสุขภาพและรายได้
      TH5: {
        label: "(1) เงินเดือน โบนัส ค่าจ้าง บำนาญ",
        value: 142800
      },
      TH6: {
        label: "(2) ค่านายหน้า ค่ารับจ้างทำ",
        value: 267230
      },
      TI16: {
        label: "ส่วนตัว",
        value: 60000
      },
      TI27: {
        label: "เงินสมทบกองทุนสำรองเลี้ยงชีพ/กบข/ครูเอกชน (ก่อนวางแผน)",
        value: 80000,        
      },
      TJ27: {
        label: "เงินสมทบกองทุนสำรองเลี้ยงชีพ/กบข/ครูเอกชน (หลังวางแผน)",
        value: 80000,        
      },
      TJ30: {
        label: "ดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย (หลังวางแผน)",
        value: 5000
      },
      TJ31: {
        label: "เงินสมทบกองทุนประกันสังคม (หลังวางแผน)",
        value: 9000
      },
      // 7. health
      // คุ้มครองสุขภาพ อุบัติเหตุ
      HC3: {
        label: "โรงพยาบาลที่ใช้บริการ",
        value: "บำรุงราษฏร์"
      },
      HC4: {
        label: "ค่าห้อง ค่าบริการ /วัน",
        value: 12000
      },
      HC5: {
        label: "งบประมาณในการดูแลสุขภาพ / ปี",
        value: 600000
      },
      HC7: {
        label: "ค่าห้อง ค่าบริการ /วัน",
        value: 7000
      },
      HC8: {
        label: "วงเงินค่ารักษาพยาบาลเหมาจ่าย",
        value: 1000000
      },
      // คุ้มครองโรคร้ายแรง
      HC13: {
        label: "รายได้ /ปี ที่อาจสูเสียไป",
        value: 1000000
      },
      HC14: {
        label: "จำนวนปีที่ต้องรักษา",
        value: 5
      },
      HC16: {
        label: "เงินก้อนที่เตรียมไว้แล้ว",
        value: 2000000
      },
      //การปกป้องรายได้ของหัวหน้าครอบครัว
      HC19: {
        label: "ค่าใช้จ่ายที่รับผิดชอบครอบครัว/ปี",
        value: 1200000
      },
      HC20: {
        label: "จำนวนปีที่ต้องการคุ้มครอง",
        value: 10
      },
      HC21: {
        label: "เงินเฟ้อ",
        value: 3
      },
      HC22: {
        label: "อัตราผลตอบแทนกองทุน",
        value: 2
      },
      // หนี้สินคงค้าง
      HC26: {
        label: "หนี้สินคงค้างระยะสั้น",
        value: 250000
      },
      HC27: {
        label: "หนี้สินคงค้างระยะยาว",
        value: 7000000
      },
      HC33: {
        label: "ทุนประกัน",
        value: 10000000
      }
    }
  }
};
