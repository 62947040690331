export const   cashFlow = {
    required: {
      dataInput: {
        cell: [
          // === no required ===          
          "YR1",
          "C4",
          "E4",
          // "K22",
          // "K30",
          // "K31",
          // "K32",
          // "K33"
          // === no required ===
          // "C4",
          // "K22",
          // "K23",
          // "K24",
          // "K25",
          // "K26",
          // "K27",
          // "K28",
          // "K29",
          // "K30",
          // "K31",
          // "K32",
          // "K33",
          // "K34",
          // "K35",
          // "K36",
          // "K37",
          // "K38",
          // "K39",
          // "K41",
          // "K42",
          // "K43",
          // "P22",
          // "P23",
          // "P24",
          // "P25",
          // "P26",
          // "P27",
          // "P28",
          // "P29",
          // "P30",
          // "P31",
          // "P32",
          // "P33",
          // "P34",
          // "P35",
          // "P36",
          // "P37",
          // "P38",
          // "P39",
          // "P40",
          // "P41",
          // "P42"
        ]
      }
    },
    cell: {
      A1:{
        label:"ชื่อ-สกุล",
        value:"",
        type:"text",
        formula: "dataInput.C4"
      },
      //==== [1. กระแสเงินสดจ่ายรวม] ====
      //  กระแสเงินสดรับ (เดือน)
      H5: {
        label:
          "เงินเดือน (รวมค่าล่วงเวลา,ค่าตอบแทน,ค่าจ้าง,โบนัส,ค่าคอมฯ)",
        value: 0,
        type: "number",
        formula: "dataInput.K22"
      },
      H6: {
        label: "ดอกเบี้ยรับ(เงินฝากออมทรัพย์,ประจำ,พันธบัตร)",
        value: 0,
        type: "number"
      },
      H7: {
        label: "เงินปันผลรับ(หุ้น,หน่วยลงทุน)",
        value: 0,
        type: "number"
      },
      H8: { label: "รายได้จากค่าเช่า", value: 0, type: "number" },
      H9: {
        label: "รายได้จากการขายสินทรัพย์",
        value: 0,
        type: "number"
      },
      H10: {
        label: "รายได้อื่นๆ(มรดก,เงินคืนภาษี,รางวัลสลากกินแบ่ง)",
        value: 0,
        type: "number"
      },
      H11: {
        label: "กระแสเงินสดรับรวม",
        value: 0,
        type: "number",
        formula:
          "cashFlow.H5+cashFlow.H6+cashFlow.H7+cashFlow.H8+cashFlow.H9+cashFlow.H10"
      },
      //  กระแสเงินสดรับ (ปี)
      I5: {
        label: "เงินเดือน (รวมค่าล่วงเวลา,ค่าตอบแทน,ค่าจ้าง,โบนัส,ค่าคอม) ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K29+dataInput.K28"
      },
      I6: {
        label: "ดอกเบี้ยรับ(เงินฝากออมทรัพย์ ประจำ พันธบัตร) ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K23"
      },
      I7: {
        label: "เงินปันผลรับ(หุ้น หน่วยลงทุน) ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K24"
      },
      I8: {
        label: "รายได้จากค่าเช่า ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K25"
      },
      I9: {
        label: "รายได้จากการขายสินทรัพย์ ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K26"
      },
      I10: {
        label: "รายได้อื่นๆ(มรดก เงินคืนภาษี รางวัลสลากกินแบ่ง)สอน ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K27"
      },
      I11: {
        label: "กระแสเงินสดรับรวม ปี",
        value: 0,
        type: "number",
        formula:
          "cashFlow.I5+cashFlow.I6+cashFlow.I7+cashFlow.I8+cashFlow.I9+cashFlow.I10"
      },
      //  กระแสเงินสดรับ (รวมทั้งสิ้น)
      J5: {
        label:
          "เงินเดือน (รวมค่าล่วงเวลา,ค่าตอบแทน,ค่าจ้าง,โบนัส,ค่าคอม) รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H5*12)+cashFlow.I5"
      },
      J6: {
        label: "ดอกเบี้ยรับ(เงินฝากออมทรัพย์ ประจำ พันธบัตร) รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H6*12)+cashFlow.I6"
      },
      J7: {
        label: "เงินปันผลรับ(หุ้น หน่วยลงทุน) รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H7*12)+cashFlow.I7"
      },
      J8: {
        label: "รายได้จากค่าเช่า รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H8*12)+cashFlow.I8"
      },
      J9: {
        label: "รายได้จากการขายสินทรัพย์ รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H9*12)+cashFlow.I9"
      },
      J10: {
        label: "รายได้อื่นๆ(มรดก เงินคืนภาษี รางวัลสลากกินแบ่ง)สอน รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H10*12)+cashFlow.I10"
      },
      J11: {
        label: "กระแสเงินสดรับรวม รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula:
          "cashFlow.J5+cashFlow.J6+cashFlow.J7+cashFlow.J8+cashFlow.J9+cashFlow.J10"
      },
      //  กระแสเงินสดรับ (%)
      K5: {
        label: "เงินเดือน (รวมค่าล่วงเวลา,ค่าตอบแทน,ค่าจ้าง,โบนัส,ค่าคอม) %",
        value: 0,
        type: "number",
        formula: "cashFlow.J5*100/cashFlow.J11"
      },
      K6: {
        label: "ดอกเบี้ยรับ(เงินฝากออมทรัพย์ ประจำ พันธบัตร) %",
        value: 0,
        type: "number",
        formula: "cashFlow.J6*100/cashFlow.J11"
      },
      K7: {
        label: "เงินปันผลรับ(หุ้น หน่วยลงทุน) %",
        value: 0,
        type: "number",
        formula: "cashFlow.J7*100/cashFlow.J11"
      },
      K8: {
        label: "รายได้จากค่าเช่า %",
        value: 0,
        type: "number",
        formula: "cashFlow.J8*100/cashFlow.J11"
      },
      K9: {
        label: "รายได้จากการขายสินทรัพย์ %",
        value: 0,
        type: "number",
        formula: "cashFlow.J9*100/cashFlow.J11"
      },
      K10: {
        label: "รายได้อื่นๆ(มรดก เงินคืนภาษี รางวัลสลากกินแบ่ง)สอน %",
        value: 0,
        type: "number",
        formula: "cashFlow.J10*100/cashFlow.J11"
      },
      K11: {
        label: "กระแสเงินสดรับรวม %",
        value: 0,
        type: "number",
        formula:
          "cashFlow.K5+cashFlow.K6+cashFlow.K7+cashFlow.K8+cashFlow.K9+cashFlow.K10"
      },

      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายคงที่ (เดือน)
      H15: {
        label: "เงินชำระคืนบัตรเครดิต.สินเชื่อบุคคล",
        value: 0,
        type: "number",
        formula: "dataInput.P22"
      },
      H16: {
        label: "เงินผ่อนชำระซื้อรถยนต์",
        value: 0,
        type: "number",
        formula: "dataInput.P23"
      },
      H17: {
        label: "เงินผ่อนชำระซื้อบ้าน",
        value: 0,
        type: "number",
        formula: "dataInput.P24"
      },
      H18: { label: "เบี้ยประกันชีวิต", value: 0, type: "number" },
      H19: { label: "เบี้ยประกันภัย รถ", value: 0, type: "number" },
      H20: { label: "เบี้ยประกันภัย บ้าน", value: 0, type: "number" },
      H21: { label: "ประกันสังคม", value: 0, type: "number" },
      H22: { label: "เงินต้นทุน", value: 0, type: "number" },
      H24: {
        label: "อื่นๆ",
        value: 0,
        type: "number",
        formula: "dataInput.K41"
      },
      H25: {
        label: "กระแสเงินสดจ่ายคงที่รวม",
        value: 0,
        type: "number",
        formula:
          "cashFlow.H15+cashFlow.H16+cashFlow.H17+cashFlow.H18+cashFlow.H19+cashFlow.H20+cashFlow.H21+cashFlow.H22+cashFlow.H24"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายคงที่ (ปี)
      I15: {
        label: "เงินงวดผ่อนชำระคืนหนี้บัตรเครดิต.สินเชื่อบุคคล ปี",
        value: 0,
        type: "number"
      },
      I16: {
        label: "เงินงวดผ่อนชำระการเช่าซื้อรถ ปี",
        value: 0,
        type: "number"
      },
      I17: {
        label: "เงินงวดผ่อนชำระการกู้ซื้อบ้าน ปี",
        value: 0,
        type: "number"
      },
      I18: {
        label: "เบี้ยประกันชีวิต ปี",
        value: 0,
        type: "number",
        formula:
          "dataInput.P25+dataInput.P26+dataInput.P27+dataInput.P28+dataInput.P29"
      },
      I19: {
        label: "เบี้ยประกันภัย รถ ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P30"
      },
      I20: {
        label: "เบี้ยประกันภัย บ้าน ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P31"
      },
      I21: {
        label: "ประกันสังคม ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P32"
      },
      I22: {
        label: "เงินต้นทุน ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P41"
      },
      I24: { label: "อื่นๆ เงินเดือนพนักงาน ปี", value: 0, type: "number" },
      I25: {
        label: "กระแสเงินสดจ่ายคงที่รวม ปี",
        value: 0,
        type: "number",
        formula:
          "cashFlow.I15+cashFlow.I16+cashFlow.I17+cashFlow.I18+cashFlow.I19+cashFlow.I20+cashFlow.I21+cashFlow.I22+cashFlow.I24"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายคงที่ (รวมทั้งสิ้น)
      J15: {
        label: "เงินงวดผ่อนชำระคืนหนี้บัตรเครดิต.สินเชื่อบุคคล รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H15*12)+cashFlow.I15"
      },
      J16: {
        label: "เงินงวดผ่อนชำระการเช่าซื้อรถ รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H16*12)+cashFlow.I16"
      },
      J17: {
        label: "เงินงวดผ่อนชำระการกู้ซื้อบ้าน รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H17*12)+cashFlow.I17"
      },
      J18: {
        label: "เบี้ยประกันชีวิต รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H18*12)+cashFlow.I18"
      },
      J19: {
        label: "เบี้ยประกันภัย รถ รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H19*12)+cashFlow.I19"
      },
      J20: {
        label: "เบี้ยประกันภัย บ้าน รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H20*12)+cashFlow.I20"
      },
      J21: {
        label: "ประกันสังคม รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H21*12)+cashFlow.I21"
      },
      J22: {
        label: "เงินต้นทุน รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H22*12)+cashFlow.I22"
      },
      J24: {
        label: "อื่นๆ เงินเดือนพนักงาน รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H24*12)+cashFlow.I24"
      },
      J25: {
        label: "กระแสเงินสดจ่ายคงที่รวม รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula:
          "cashFlow.J15+cashFlow.J16+cashFlow.J17+cashFlow.J18+cashFlow.J19+cashFlow.J20+cashFlow.J21+cashFlow.J22+cashFlow.J24"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายคงที่ (%)
      K15: {
        label: "เงินงวดผ่อนชำระคืนหนี้บัตรเครดิต.สินเชื่อบุคคล %",
        value: 0,
        type: "number",
        formula: "cashFlow.J15*100/cashFlow.J11"
      },
      K16: {
        label: "เงินงวดผ่อนชำระการเช่าซื้อรถ %",
        value: 0,
        type: "number",
        formula: "cashFlow.J16*100/cashFlow.J11"
      },
      K17: {
        label: "เงินงวดผ่อนชำระการกู้ซื้อบ้าน %",
        value: 0,
        type: "number",
        formula: "cashFlow.J17*100/cashFlow.J11"
      },
      K18: {
        label: "เบี้ยประกันชีวิต %",
        value: 0,
        type: "number",
        formula: "cashFlow.J18*100/cashFlow.J11"
      },
      K19: {
        label: "เบี้ยประกันภัย รถ %",
        value: 0,
        type: "number",
        formula: "cashFlow.J19*100/cashFlow.J11"
      },
      K20: {
        label: "เบี้ยประกันภัย บ้าน %",
        value: 0,
        type: "number",
        formula: "cashFlow.J20*100/cashFlow.J11"
      },
      K21: {
        label: "ประกันสังคม %",
        value: 0,
        type: "number",
        formula: "cashFlow.J21*100/cashFlow.J11"
      },
      K22: {
        label: "เงินต้นทุน %",
        value: 0,
        type: "number",
        formula: "cashFlow.J22*100/cashFlow.J11"
      },
      K24: {
        label: "อื่นๆ เงินเดือนพนักงาน %",
        value: 0,
        type: "number",
        formula: "cashFlow.J24*100/cashFlow.J11"
      },
      K25: {
        label: "กระแสเงินสดจ่ายคงที่รวม %",
        value: 0,
        type: "number",
        formula: "cashFlow.J25*100/cashFlow.J11"
      },

      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายผันแปร (เดือน)
      H27: {
        label: "ค่าอาหาร",
        value: 0,
        type: "number",
        formula: "dataInput.K30"
      },
      H28: {
        label: "ค่าโทรศัพท์",
        value: 0,
        type: "number",
        // formula: "dataInput.K31"
      },
      H29: {
        label: "ค่าสาธารณูปโภค(ค่าน้ำ,ค่าไฟ,ค่าโทรศัพท์)",
        value: 0,
        type: "number",
        formula: "dataInput.K32"
      },
      H30: {
        label: "ค่าใช้จ่ายนันทนาการ",
        value: 0,
        type: "number"
      },
      H31: { label: "ค่ารักษาพยาบาล", value: 0, type: "number" },
      H32: {
        label: "ภาษี",
        value: 0,
        type: "number"
      },
      H33: {
        label: "ค่าใช้จ่ายในการเดินทาง (น้ำมัน,ค่าบำรุงรักษา,ค่าโดยสาร)",
        value: 0,
        type: "number"
      },
      H34: {
        label: "เงินให้บิดาและมารดา",
        value: 0,
        type: "number"
      },
      H35: {
        label:
          "ค่าใช้จ่ายส่วนตัว",
        value: 0,
        type: "number"
      },
      H36: {
        label: "ค่าใช้จ่ายบุตร",
        value: 0,
        type: "number"
      },
      H37: { label: "ค่าใช้จ่ายอื่นๆ", value: 0, type: "number" },
      H38: {
        label: "กระแสเงินสดจ่ายผันแปรรวม",
        value: 0,
        type: "number",
        formula:
          "cashFlow.H27+cashFlow.H28+cashFlow.H29+cashFlow.H30+cashFlow.H31+cashFlow.H32+cashFlow.H33+cashFlow.H34+cashFlow.H35+cashFlow.H36+cashFlow.H37"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายผันแปร (ปี)
      I27: { label: "ค่าอาหารครอบครัว ปี", value: 0, type: "number" },
      I28: { label: "ค่าโทรศัพท์ ปี", value: 0, type: "number" },
      I29: {
        label: "ค่าสาธารณูปโภค(ค่าน้ำ ค่าไฟ อื่นๆ) ปี",
        value: 0,
        type: "number"
      },
      I30: {
        label: "ค่าใช้จ่ายนันทนาการ / ปี ปี",
        value: 0,
        type: "number",
        // formula: "dataInput.K33"
      },
      I31: {
        label: "ค่ารักษาพยาบาล ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K34"
      },
      I32: {
        label: "ภาษี(บุคคลธรรมดา.ด.บเงินฝาก พันธบัตร ปันผล รถยนตื) ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K35"
      },
      I33: {
        label: "ค่าใช้จ่ายในการเดินทาง (น้ำมัน.ค่าบำรุงรักษา.ค่าโดยสาร) ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K36"
      },
      I34: {
        label: "เงินจ่ายให้กับบิดาและมารดา ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K37"
      },
      I35: {
        label:
          "ค่าใช้จ่ายส่วนตัว ค่าเสื้อผ้าและค่าใช้จ่ายในการบำรุงรักษาตนเอง ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K38"
      },
      I36: {
        label: "ค่าใช้จ่ายบุตร (ค่าเทอม) ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K39"
      },
      I37: {
        label: "ค่าใช้จ่ายอื่นๆ ปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.K42+dataInput.K43+dataInput.P43"
      },
      I38: {
        label: "กระแสเงินสดจ่ายผันแปรรวม ปี",
        value: 0,
        type: "number",
        formula:
          "cashFlow.I27+cashFlow.I28+cashFlow.I29+cashFlow.I30+cashFlow.I31+cashFlow.I32+cashFlow.I33+cashFlow.I34+cashFlow.I35+cashFlow.I36+cashFlow.I37"
      },

      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายผันแปร (รวมทั้งสิ้น)
      J27: {
        label: "ค่าอาหารครอบครัว รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H27*12)+cashFlow.I27"
      },
      J28: {
        label: "ค่าโทรศัพท์ รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H28*12)+cashFlow.I28"
      },
      J29: {
        label: "ค่าสาธารณูปโภค(ค่าน้ำ ค่าไฟ อื่นๆ) รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H29*12)+cashFlow.I29"
      },
      J30: {
        label: "ค่าใช้จ่ายนันทนาการ / ปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H30*12)+cashFlow.I30"
      },
      J31: {
        label: "ค่ารักษาพยาบาล รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H31*12)+cashFlow.I31"
      },
      J32: {
        label: "ภาษี(บุคคลธรรมดา.ด.บเงินฝาก พันธบัตร ปันผล รถยนตื) รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H32*12)+cashFlow.I32"
      },
      J33: {
        label:
          "ค่าใช้จ่ายในการเดินทาง (น้ำมัน.ค่าบำรุงรักษา.ค่าโดยสาร) รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H33*12)+cashFlow.I33"
      },
      J34: {
        label: "เงินจ่ายให้กับบิดาและมารดา รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H34*12)+cashFlow.I34"
      },
      J35: {
        label:
          "ค่าใช้จ่ายส่วนตัว ค่าเสื้อผ้าและค่าใช้จ่ายในการบำรุงรักษาตนเอง รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H35*12)+cashFlow.I35"
      },
      J36: {
        label: "ค่าใช้จ่ายบุตร (ค่าเทอม) รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "(cashFlow.H36*12)+cashFlow.I36"
      },
      J37: {
        label: "ค่าใช้จ่ายอื่นๆ ปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I37"
      },
      J38: {
        label: "กระแสเงินสดจ่ายผันแปรรวม รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula:
          "cashFlow.J27+cashFlow.J28+cashFlow.J29+cashFlow.J30+cashFlow.J31+cashFlow.J32+cashFlow.J33+cashFlow.J34+cashFlow.J35+cashFlow.J36+cashFlow.J37"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายผันแปร (%)
      K27: {
        label: "ค่าอาหารครอบครัว %",
        value: 0,
        type: "number",
        formula: "cashFlow.J27*100/cashFlow.J11"
      },
      K28: {
        label: "ค่าโทรศัพท์ %",
        value: 0,
        type: "number",
        formula: "cashFlow.J28*100/cashFlow.J11"
      },
      K29: {
        label: "ค่าสาธารณูปโภค(ค่าน้ำ ค่าไฟ อื่นๆ) %",
        value: 0,
        type: "number",
        formula: "cashFlow.J29*100/cashFlow.J11"
      },
      K30: {
        label: "ค่าใช้จ่ายนันทนาการ / ปี %",
        value: 0,
        type: "number",
        formula: "cashFlow.J30*100/cashFlow.J11"
      },
      K31: {
        label: "ค่ารักษาพยาบาล %",
        value: 0,
        type: "number",
        formula: "cashFlow.J31*100/cashFlow.J11"
      },
      K32: {
        label: "ภาษี(บุคคลธรรมดา.ด.บเงินฝาก พันธบัตร ปันผล รถยนตื) %",
        value: 0,
        type: "number",
        formula: "cashFlow.J32*100/cashFlow.J11"
      },
      K33: {
        label: "ค่าใช้จ่ายในการเดินทาง (น้ำมัน.ค่าบำรุงรักษา.ค่าโดยสาร) %",
        value: 0,
        type: "number",
        formula: "cashFlow.J33*100/cashFlow.J11"
      },
      K34: {
        label: "เงินจ่ายให้กับบิดาและมารดา %",
        value: 0,
        type: "number",
        formula: "cashFlow.J34*100/cashFlow.J11"
      },
      K35: {
        label:
          "ค่าใช้จ่ายส่วนตัว ค่าเสื้อผ้าและค่าใช้จ่ายในการบำรุงรักษาตนเอง %",
        value: 0,
        type: "number",
        formula: "cashFlow.J35*100/cashFlow.J11"
      },
      K36: {
        label: "ค่าใช้จ่ายบุตร (ค่าเทอม) %",
        value: 0,
        type: "number",
        formula: "cashFlow.J36*100/cashFlow.J11"
      },
      K37: {
        label: "ค่าใช้จ่ายอื่นๆ ปี %",
        value: 0,
        type: "number",
        formula: "cashFlow.J37*100/cashFlow.J11"
      },
      K38: {
        label: "กระแสเงินสดจ่ายผันแปรรวม %",
        value: 0,
        type: "number",
        formula: "cashFlow.J38*100/cashFlow.J11"
      },

      ////
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายเพื่อการออม/การลงทุน (เดือน)
      H40: {
        label: "พันธบัตร/หุ้นกู้",
        value: 0,
        type: "number"
      },
      H41: {
        label:
          "หุ้นบุริมสิทธิ หุ้นสามัญ",
        value: 0,
        type: "number"
      },
      H42: {
        label: "กองทุนรวมหุ้น",
        value: 0,
        type: "number"
      },
      H43: {
        label: "กองทุนรวมหุ้นระยะยาว",
        value: 0,
        type: "number"
      },
      H44: {
        label: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)",
        value: 0,
        type: "number"
      },
      H45: {
        label: "กองทุนสำรองเลี้ยงชีพPVD",
        value: 0,
        type: "number"
      },
      H46: {
        label: "เงินออม",
        value: 0,
        type: "number"
      },
      H47: { label: "อื่นๆ", value: 0, type: "number" },
      H48: {
        label: "กระแสเงินสดจ่ายเพื่อการออม/การลงทุน",
        value: 0,
        type: "number",
        formula: "cashFlow.H46+cashFlow.H47"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายเพื่อการออม/การลงทุน (ปี)
      I40: {
        label: "พันธบัตร/หุ้นกู้ (Bond) ฝ่อปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P35"
      },
      I41: {
        label: "หุ้นบุริมสิทธิ หุ้นสามัญ stock warant option future ต่อปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P40"
      },
      I42: {
        label: "กองทุนรวมหุ้น (Mutual Fund equity)ต่อปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P36"
      },
      I43: {
        label: "กองทุนรวมหุ้นระยะยาว(LTF)ต่อปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P37"
      },
      I44: {
        label: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)equity&Fix ต่อปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P38+dataInput.P39"
      },
      I45: {
        label: "กองทุนสำรองเลี้ยงชีพPVD ต่อปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P33"
      },
      I46: {
        label: "เงินออม (ฝากธนาคารทุกชนิด) ต่อปี ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P34"
      },
      I47: {
        label: "เงินออมอื่นๆ กบข ปี",
        value: 0,
        type: "number",
        formula: "dataInput.P42"
      },
      I48: {
        label: "กระแสเงินสดจ่ายเพื่อการออม/การลงทุน ปี",
        value: 0,
        type: "number",
        formula:
          "cashFlow.I40+cashFlow.I41+cashFlow.I42+cashFlow.I43+cashFlow.I44+cashFlow.I45+cashFlow.I46+cashFlow.I47"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายเพื่อการออม/การลงทุน (รวมทั้งสิ้น)
      J40: {
        label: "พันธบัตร/หุ้นกู้ (Bond) ฝ่อปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I40"
      },
      J41: {
        label:
          "หุ้นบุริมสิทธิ หุ้นสามัญ stock warant option future ต่อปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I41"
      },
      J42: {
        label: "กองทุนรวมหุ้น (Mutual Fund equity)ต่อปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I42"
      },
      J43: {
        label: "กองทุนรวมหุ้นระยะยาว(LTF)ต่อปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I43"
      },
      J44: {
        label: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)equity&Fix ต่อปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I44"
      },
      J45: {
        label: "กองทุนสำรองเลี้ยงชีพPVD ต่อปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I45"
      },
      J46: {
        label: "เงินออม (ฝากธนาคารทุกชนิด) ต่อปี รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I46"
      },
      J47: {
        label: "เงินออมอื่นๆ กบข รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I47"
      },
      J48: {
        label: "กระแสเงินสดจ่ายเพื่อการออม/การลงทุน รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.I48"
      },
      // กระแสเงินสดจ่ายรวม & กระแสเงินสดสุทธิ
      H50: {
        label: "กระแสเงินสดจ่ายรวม",
        value: 0,
        type: "number",
        formula: "cashFlow.H25+cashFlow.H38+cashFlow.H48"
      },
      I50: {
        label: "กระแสเงินสดจ่ายรวม ปี",
        value: 0,
        type: "number",
        formula: "cashFlow.I25+cashFlow.I38+cashFlow.I48"
      },
      J50: {
        label: "กระแสเงินสดจ่ายรวม รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.J25+cashFlow.J38+cashFlow.J48"
      },
      J52: {
        label: "กระแสเงินสดสุทธิ รวมทั้งสิ้น",
        value: 0,
        type: "number",
        formula: "cashFlow.J11-cashFlow.J50"
      },
      // กระแสเงินสดจ่าย - กระแสเงินสดจ่ายเพื่อการออม/การลงทุน (%)
      K40: {
        label: "พันธบัตร/หุ้นกู้ (Bond) ฝ่อปี %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J40*100)/cashFlow.J11"
      },
      K41: {
        label: "หุ้นบุริมสิทธิ หุ้นสามัญ stock warant option future ต่อปี %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J41*100)/cashFlow.J11"
      },
      K42: {
        label: "กองทุนรวมหุ้น (Mutual Fund equity)ต่อปี %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J42*100)/cashFlow.J11"
      },
      K43: {
        label: "กองทุนรวมหุ้นระยะยาว(LTF)ต่อปี %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J43*100)/cashFlow.J11"
      },
      K44: {
        label: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)equity&Fix ต่อปี %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J44*100)/cashFlow.J11"
      },
      K45: {
        label: "กองทุนสำรองเลี้ยงชีพPVD ต่อปี %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J45*100)/cashFlow.J11"
      },
      K46: {
        label: "เงินออม (ฝากธนาคารทุกชนิด) ต่อปี %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J46*100)/cashFlow.J11"
      },
      K47: {
        label: "เงินออมอื่นๆ กบข %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J47*100)/cashFlow.J11"
      },
      K48: {
        label: "กระแสเงินสดจ่ายเพื่อการออม/การลงทุน %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J48*100)/cashFlow.J11"
      },
      // กระแสเงินสดจ่ายรวม & กระแสเงินสดสุทธิ %
      K50: {
        label: "กระแสเงินสดจ่ายรวม %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J50*100)/cashFlow.J11"
      },
      K52: {
        label: "กระแสเงินสดสุทธิ %",
        value: 0,
        type: "number",
        formula: "(cashFlow.J52*100)/cashFlow.J11"
      }
    }
  };