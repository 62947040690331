<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="pa-2">
        <v-card outlined tile>
          <v-card-title>ประวัติการเข้าใช้งานย้อนหลัง 30 วัน</v-card-title>
          <v-simple-table fixed-header height="535px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">วันเวลาเข้าใช้งาน</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in login" :key="item.loginDate">
                  <td>{{ item.loginDate }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="pa-2">
        <v-row>
          <v-col cols="12" sm="6">
            <v-card outlined tile>
              <v-card-title>จำนวนรายงานทั้งหมด</v-card-title>
              <div style="padding-bottom: 20px" class="text-center text-h2">
                {{ stats.total_plan }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card outlined tile>
              <v-card-title>จำนวนรายงาน 30 วันล่าสุด</v-card-title>
              <div style="padding-bottom: 20px" class="text-center text-h2">
                {{ stats.total_plan_30days }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card outlined tile>
              <v-card-title>ประเภทรายของคุณ</v-card-title>
              <BarChart
                :width="365"
                :height="365"
                :chartDataSet="getChartDataSet()"
                :chartOptions="getChartOptions()"
              ></BarChart>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6" class="pa-2"> </v-col>
    </v-row>

    <!-- {{message}} -->
    <!-- <input v-for="(item,index) in 1000" v-bind:key="index" v-model="message" placeholder="edit me"> -->
    <!-- <v-text-field label="Hello" v-for="(item,index) in 20" v-bind:key="index" v-model="message" placeholder="edit me"></v-text-field> -->
    <!-- <v-text-field v-for="(item,index) in 1000" v-bind:key="index" :name="`val[${index}]`" v-on:change="changeItem($event.target.value)"  placeholder="edit me"></v-text-field> -->
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import BarChart from "../../components/ChartJs/BarChart.js";
import firebase from 'firebase/compat/app';
import _ from "lodash";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    source: String,
  },
  components: {
    BarChart,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  async mounted() {
    this.currentUser = await firebase.auth().currentUser;
    await firebase
      .database()
      .ref("/stats/" + this.currentUser.uid)
      .once("value", (snapshot) => {
        let time = moment().format();

        if (snapshot.val() !== null) {
          this.stats = snapshot.val();
          this.login = _.chain(this.stats.login)
            .map(function (item, index) {
              return {
                _id: index,
                createDate: item,
                loginDate: moment(item).format("YYYY-DD-MM H:mm:ss"),
              };
            })
            .orderBy(["createDate"], ["desc"])
            .value();

          // update login stats
          if (this.stats.login === undefined) {
            this.stats.login = [time];
          }
          let last = _.last(this.stats.login);
          let diff = moment().diff(moment(last), "minutes");          
          if (diff >= 60) {
            this.stats.login.push(time);
          }
        } else {
          this.stats.login.push(time);
        }

        let last = _.last(this.stats.login);
        this.stats.login = _.filter(this.stats.login, function (o) {
          let diff = moment(last).diff(moment(o), "days");          
          return diff <= 30;
        });

        // console.log(this.stats.login);

        firebase
          .database()
          .ref("/stats/" + this.currentUser.uid)
          .set(this.stats);
      });
      this.hideDrawer();
  },

  methods: {
    ...mapActions(["hideDrawer"]),
    changeItem: function (value) {
      // console.log(value);
    },
    getChartOptions(section) {
      return {};
    },
    getChartDataSet(section) {
      let dataset = {};
      dataset.labels = [
        "Balance Sheet",
        "Cash Flow",
        "Financial Ratio",
        "Education",
        "Retiremnt",
        "Tax",
        "Health",
      ];
      dataset.data = [
        this.stats.total_plan_detail.balance_sheet,
        this.stats.total_plan_detail.cash_flow,
        this.stats.total_plan_detail.financial_ratio,
        this.stats.total_plan_detail.education,
        this.stats.total_plan_detail.retirement,
        this.stats.total_plan_detail.tax,
        this.stats.total_plan_detail.health,
      ];
      dataset.backgroundColor = [
        "#1976d2",
        "#4caf50",
        "#f44237",
        "#ffc105",
        "#069688",
        "#e91e63",
        "#9c27b0",
      ];
      return dataset;
    },
  },
  data: () => ({
    user: {},
    dialog: false,
    message: "",
    login: [],
    stats: {
      login: [],
      total_plan: 0,
      total_plan_30days: 0,
      total_plan_detail: {
        balance_sheet: 0,
        cash_flow: 0,
        financial_ratio: 0,
        education: 0,
        retirement: 0,
        tax: 0,
        health: 0,
      },
    },
  }),
};
</script>
