/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/**
 * Data configuration
 */

import { balanceSheet } from "./sheets/balancesheet.js";
import { cashFlow } from "./sheets/cashflow.js";
import { financialRatio } from "./sheets/financialratio.js";
import { education, education1 } from "./sheets/education.js";
import { retirement, retirement1 } from "./sheets/retirement.js";
import { tax } from "./sheets/tax.js";
import { health } from "./sheets/health.js";

const notRequired = [(v) => !v];
const banlanceSheetRequired = [
  (v) => !!v || "The field is required for Balance Sheet",
];
const cashFlowRequired = [(v) => !!v || "The field is required for Cash Flow"];
const financialRatioRequired = [
  (v) => !!v || "The field is required for Financial Ratio",
];
const educationRequired = [(v) => !!v || "The field is required for Education"];
const retirementRequired = [
  (v) => !!v || "The field is required for Retirement",
];
const taxRequired = [(v) => !!v || "The field is required for Tax"];
const healthRequired = [(v) => !!v || "The field is required for Health"];

export const sheets = {
  dataInput: {
    form: {
      // ชื่อสมาชิกในครอบครัว
      family: {
        heading: "ข้อมูลสมาชิกในครอบครัว",
        cell: {
          YR1: {
            label: "ปีที่วางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุปีพ.ศ.",
            suffix: "ปี",
            rules: retirementRequired,
          },
          C4: {
            label: "ชื่อ-สกุล",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            suffix: "",
            rules: banlanceSheetRequired,
          },
          E4: {
            label: "อายุ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
            rules: retirementRequired,
          },
          // CA4: {
          //   label: "ปีเกิด",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุปีพ.ศ.",
          //   suffix: "ปี",
          //   rules: retirementRequired
          // },
          C5: {
            label: "คู่สมรส",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            suffix: "",
            // rules: retirementRequired
          },
          E5: {
            label: "อายุ (คู่สมรส)",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
            // rules: retirementRequired
          },
          // CA5: {
          //   label: "ปีเกิด (คู่สมรส)",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุปีพ.ศ.",
          //   suffix: "ปี",
          //   // rules: retirementRequired
          // },
          // C6: {
          //   label: "บิดา",
          //   value: "",
          //   type: "text",
          //   placeholder: "ระบุข้อมูล",
          //   suffix: ""
          // },
          // E6: {
          //   label: "อายุ (บิดา)",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุจำนวน",
          //   suffix: "ปี"
          // },
          // C7: {
          //   label: "มารดา",
          //   value: "",
          //   type: "text",
          //   placeholder: "ระบุข้อมูล",
          //   suffix: ""
          // },
          // E7: {
          //   label: "อายุ (มารดา)",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุจำนวน",
          //   suffix: "ปี"
          // },
          C8: {
            label: "บุตรคนที่ 1",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            suffix: "",
            // rules: educationRequired
          },
          E8: {
            label: "อายุ (บุตรคนที่ 1)",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
            // rules: educationRequired
          },
          // CA8: {
          //   label: "ปีเกิด (บุตรคนที่ 1)",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุปีพ.ศ.",
          //   suffix: "ปี",
          //   rules: educationRequired
          // },
          C9: {
            label: "บุตรคนที่ 2",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            suffix: "",
          },
          E9: {
            label: "อายุ (บุตรคนที่ 2)",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
          },
          // CA9: {
          //   label: "ปีเกิด (บุตรคนที่ 2)",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุปีพ.ศ.",
          //   suffix: "ปี",
          //   // rules: educationRequired
          // },
          // C10: {
          //   label: "บุตร3",
          //   value: "",
          //   type: "text",
          //   placeholder: "ระบุข้อมูล",
          //   suffix: ""
          // },
          // E10: {
          //   label: "บุตร3 อายุ",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุข้อมูล",
          //   suffix: "ปี"
          // }
        },
      },
      // ข้อมูลทรัพย์สิน หนี้สิน
      assets_and_liabilities: {
        heading: "ข้อมูลทรัพย์สิน หนี้สิน",
        cell: {
          K3: {
            label: "เงินสด",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
            // == no required == rules: banlanceSheetRequired,
          },
          P3: {
            label: "กองทุนรวมตราสารหนี้",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K4: {
            label: "บ/ช ออมทรัพย์",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
            // == no required == rules: banlanceSheetRequired,
          },
          P4: {
            label: "บ/ช ประจำ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K5: {
            label: "มูลค่าเวนคืนประกันชีวิต",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P5: {
            label: "สหกรณ์",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K6: {
            label: "พันธบัตร/หุ้นกู้/ตราสารหนี้",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P6: {
            label: "หุ้นบุริมสิทธิ์",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K7: {
            label: "หุ้นสามัญ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P7: {
            label: "warant",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K8: {
            label: "กองทุนรวม หุ้นMF equity",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P8: {
            label: "LTF",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K9: {
            label: "RMF equity",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P9: {
            label: "privatefund",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K10: {
            label: "forex",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P10: {
            label: "RMF Fix",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K11: {
            label: "กองทุนสำรองเลี้ยงชีพ PVD",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P11: {
            label: "Future",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K12: {
            label: "เงินออมเพื่อเกษียณ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P12: {
            label: "กบข",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K13: {
            label: "บ้าน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            // == no required == rules: banlanceSheetRequired,
          },
          P13: {
            label: "รถยนต์",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K14: {
            label: "หอพัก.บ้านเช่า",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P14: {
            label: "เครื่องประดับ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K15: {
            label: "เครื่องเพชร",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P15: {
            label: "ที่ดิน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K16: {
            label: "คอนโด",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P16: {
            label: "ของสะสม",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K17: {
            label: "หนี้บัตรเครดิต",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P17: {
            label: "อสังหาริมทรัพย์อื่น",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K18: {
            label: "หนี้สาธารณูปโภค",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P18: {
            label: "หนี้บ้านคงเหลือ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            // == no required == rules: banlanceSheetRequired,
          },
          K19: {
            label: "หนี้รถยนต์คงเหลือ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P19: {
            label: "หนี้สินอื่นๆไม่มีคุ้มครอง",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K20: {
            label: "ทรัพย์สินอื่น",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P20: {
            label: "หนี้ระยะยาวอื่นๆ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
        },
      },
      // ข้อมูลรายรับ รายจ่าย
      income_and_expense: {
        heading: "ข้อมูลรายรับ รายจ่าย",
        cell: {
          K22: {
            label: "เงินเดือน /ค่าจ้าง/รายได้ ต่อเดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
            // == no required == rules: cashFlowRequired,
          },
          K23: {
            label: "ดอกเบี้ยรับ ต่อปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K24: {
            // label: "เงินปันผลรับ(หุ้นหน่วยลงทุน) ต่อปี",
            label: "เงินปันผล",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K25: {
            label: "รายได้จากค่าเช่า ต่อปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K26: {
            label: "รายได้จากการขายสินทรัพย์ ต่อปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K27: {
            // label: "รายได้อื่นๆ(มรดก เงินคืนภาษี) ต่อปี",
            label: "รายได้อื่นๆ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K28: {
            label: "ค่าคอมมิชชัน/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K29: {
            label: "โบนัส/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K30: {
            label: "ค่าอาหารครอบครัวต่อเดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
            // == no required == rules: cashFlowRequired,
          },
          K31: {
            label: "ค่าโทรศัพท์ ครอบครัวต่อเดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            // == no required == rules: cashFlowRequired,
          },
          K32: {
            label: "ค่าสาธารณูปโภค(ค่าน้ำค่าไฟ ต่อเดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
            // == no required == rules: cashFlowRequired,
          },
          K33: {
            label: "ค่าใช้จ่ายนันทนาการท่องเที่ยวครอบครัว/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            // == no required == rules: cashFlowRequired,
          },
          K34: {
            label: "ค่ารักษาพยาบาลครอบครัว ต่อปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K35: {
            label: "จ่ายภาษี /ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K36: {
            label: "ค่าใช้จ่ายในการเดินทาง/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K37: {
            label: "เงินจ่ายให้กับบิดาและมารดา/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K38: {
            label: "ค่าใช้จ่ายส่วนตัว/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K39: {
            label: "ค่าใช้จ่ายบุตร /ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K40: {
            label: "ค่าใช้จ่ายอื่นๆ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K41: {
            label: "เงินเดือน พนักงาน / เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          K42: {
            label: "ค่าใช้จ่าย....ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K43: {
            label: "ค่าใช้จ่าย.....ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P22: {
            label: "จ่ายบัตรเครดิตต่อเดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P23: {
            label: "จ่ายงวดรถต่อเดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P24: {
            label: "จ่ายกู้ซื้อบ้านต่อเดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P25: {
            label: "เบี้ยประกันชีวิตตนเอง/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P26: {
            label: "เบี้ยประกันชีวิตคู่สมรส/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P27: {
            label: "เบี้ยประกันชีวิตบิดา/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P28: {
            label: "เบี้ยประกันชีวิตมารดา/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P29: {
            label: "เบี้ยประกันชีวิตบุตร/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P30: {
            label: "เบี้ยประกันภัย รถ/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P31: {
            label: "เบี้ยประกันภัย บ้าน/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P32: {
            label: "ประกันสังคม/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P33: {
            label: "จ่ายสำรองเลี้ยงชีพ PVD/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P34: {
            label: "จ่ายเงินออมธนาคาร/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P35: {
            label: "จ่ายเงินลงทุนพันธบัตร/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P36: {
            label: "MF/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P37: {
            label: "LTF/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P38: {
            label: "RMF equity/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P39: {
            label: "RMF Fix/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P40: {
            label: "STOCK/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P41: {
            label: "ต้นทุน /ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          P42: {
            label: "จ่าย กบช. ต่อปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          P43: {
            label: "จ่าย.....ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
        },
      },
      // ข้อมูลวางแผนการศึกษา
      education: {
        heading: "ข้อมูลวางแผนการศึกษา ",
        cell: {
          M50: {
            label: "บุตรคนที่1 ป.ตรี ค่าใช้จ่าย/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
            // rules: educationRequired
          },
          M51: {
            label: "บุตรคนที่1 ป.โท ค่าใช้จ่าย/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
            // rules: educationRequired
          },
          M52: {
            label: "บุตรคนที่1 ป.เอก ค่าใช้จ่าย/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K53: {
            label: "บุตรคนที่1 เตรียมไว้แล้ว ผลตอบแทนกี่%",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            // rules: educationRequired
          },
          M53: {
            label: "บุตรคนที่1 เตรียมไว้แล้ว",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            // rules: educationRequired
          },
          M54: {
            label: "บุตรคนที่2 ป.ตรี ค่าใช้จ่าย/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          M55: {
            label: "บุตรคนที่2 ป.โท ค่าใช้จ่าย/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          M56: {
            label: "บุตรคนที่2 ป.เอก ค่าใช้จ่าย/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          K57: {
            label: "บุตรคนที่2 เตรียมไว้แล้ว ผลตอบแทนกี่%",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
          },
          M57: {
            label: "บุตรคนที่2 เตรียมไว้แล้ว",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          A80: {
            label: "อัตราเงินเฟ้อการศึกษา(%)",
            value: 5.03,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
            // rules: educationRequired
          },
          A81: {
            label: "A. เตรียมเงินก้อน ..(%)",
            value: 6.0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
            // rules: educationRequired
          },
          A82: {
            label: "B. ฝากรายงวดธนาคาร ..%",
            value: 1.0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
            // rules: educationRequired
          },
          A83: {
            label: "C. ซื้อพันธบัตร ..%",
            value: 3.0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
            // rules: educationRequired
          },
          A84: {
            label: "D. ลงทุนในหุ้น ..%",
            value: 6.0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
            // rules: educationRequired
          },
          A85: {
            label: "E. Investment Link ..%",
            value: 6.0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
            // rules: educationRequired
          },
          A810: {
            label: "อื่นๆ",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          A820: {
            label: "อื่นๆ",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          A830: {
            label: "อื่นๆ",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          A840: {
            label: "อื่นๆ",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          A850: {
            label: "อื่นๆ",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          // M60: {
          //   label: "บุตรคนที่3 ป.ตรี ค่าใช้จ่าย/เดือน",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุจำนวน",
          //   suffix: "บาท"
          // },
          // M61: {
          //   label: "บุตรคนที่3 ป.โท ค่าใช้จ่าย/เดือน",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุจำนวน",
          //   suffix: "บาท"
          // },
          // M62: {
          //   label: "บุตรคนที่3 ป.เอก ค่าใช้จ่าย/เดือน",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุจำนวน",
          //   suffix: "บาท"
          // },
          // K63: {
          //   label: "บุตรคนที่3 เตรียมไว้แล้ว ผลตอบแทนกี่%",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุจำนวน",
          //   suffix: "%"
          // },
          // M63: {
          //   label: "บุตรคนที่3 เตรียมไว้แล้ว",
          //   value: "",
          //   type: "number",
          //   placeholder: "ระบุจำนวน",
          //   suffix: "บาท"
          // }
        },
      },
      // ข้อมูลวางแผนการเกษียณ
      retirement: {
        heading: "ข้อมูลวางแผนการเกษียณ",
        cell: {
          I44: {
            label: "ต้องการเกษียณอายุ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
            show:false,
            // rules: retirementRequired
          },
          I441: {
            label: "สิ้นสุดกองทุนปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
            show:false,
          },
          I45: {
            label: "คู่สมรสเกษียณอายุ",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
            show:false,
          },
          I451: {
            label: "คู่สมรสสิ้นสุดกองทุนปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "ปี",
            show:false,
          },
          N44: {
            label: "เงินที่คาดว่าต้องใช้/เดือน ปัจจุบัน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          N45: {
            label: "เงินที่คาดว่าต้องใช้/เดือน ปัจจุบัน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          N46: {
            label: "เงินคืนจากกองทุนชราภาพ ปกส.",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          N47: {
            label: "ถ้าเกษียณเงินบำนาญต่อ/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          N48: {
            label: "เงินประกันชีวิตแบบบำนาญ  annuity ต่อ/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
          },
          RTY33: {
            label: "1. ฝากธนาคาร (A/C) ... %",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
          },
          RTY330: {
            label: "อื่นๆ",
            value: "ฝากธนาคาร (A/C) ฝากประจำ",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          RTY35: {
            label: "2. ซื้อพันธบัตร/ตราสารหนี้ ... %",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
          },
          RTY350: {
            label: "อื่นๆ",
            value: "พันธบัตร/ตราสารหนี้/สหกรณ์/ประกันชีวิตสะสมทรัพย์(Bond/MMF/Endownment)",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          RTY37: {
            label: "3.  กองทุนรวมชั้นดี ... %",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
          },
          RTY370: {
            label: "อื่นๆ",
            value: "กองทุนรวมชั้นดี",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          RTY39: {
            label: "4. ประกันชีวิตแบบบำนาญ ... %",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
          },
          RTY390: {
            label: "อื่นๆ",
            value: "ประกันชีวิตแบบบำนาญ Annuity (แบบลดหย่อนภาษีได้)",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          RTY41: {
            label: "5. Investment Link ... %",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
          },
          RTY410: {
            label: "อื่นๆ",
            value: "Investment Link",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          RTY43: {
            label: "6. แบบชำระเบี้ยครั้งเดียว  ... %",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
          },
          RTY430: {
            label: "อื่นๆ",
            value: "แบบชำระเบี้ยครั้งเดียว ( investment Link Single Premium)",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          RTY44: {
            label: "7. อื่นๆ  ... %",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "%",
            show:false,
          },
          RTY440: {
            label: "อื่นๆ",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          RTY8: {
            label: "อัตราเงินเฟ้อหลังเกษียณ (%)",
            value: 4.0,
            suffix: "%",
            type: "number",
            show:false,
          },
          RTY6: {
            label: "ผลตอบแทนหลังเกษียณ(%)",
            value: 4.0,
            suffix: "%",
            type: "number",
            show:false,
          },
          RT1: {
            label: "เงิน/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT1AB18: {
            label: "สินทรัพย์สภาพคล่อง",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT1AB20: {
            label: "สินทรัพย์เพื่อการลงทุน",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT1AB23: {
            label: "ทรัพย์สินอื่นที่เตรียมไว้แล้ว",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT1AB25: {
            label: "เงินบำนาญและเงินอื่นๆ",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT2: {
            label: "เงิน/เดือน",
            value: "",
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT2AB18: {
            label: "สินทรัพย์สภาพคล่อง",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT2AB20: {
            label: "สินทรัพย์เพื่อการลงทุน",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT2AB23: {
            label: "ทรัพย์สินอื่นที่เตรียมไว้แล้ว",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
          RT2AB25: {
            label: "เงินบำนาญและเงินอื่นๆ",
            value: 0,
            type: "number",
            placeholder: "ระบุจำนวน",
            suffix: "บาท",
            show:false,
          },
        },
      },
      // ข้อมูลภาษีรายได้
      tax: {
        heading: "ข้อมูลภาษีรายได้",
        cell: {
          // เงินได้
          TH5: {
            label: "(1) เงินเดือน โบนัส ค่าจ้าง บำนาญ",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: taxRequired
          },
          TH6: {
            label: "(2) ค่านายหน้า ค่ารับจ้างทำ",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TH7: {
            label: "(3) ลิขสิทธิ์",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "ตามจริง หรือเหมา 50% ไม่เกิน 100,000",
            show:false,
          },
          TH8: {
            label: "(4) ดอกเบี้ย เงินปันผล",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "หักค่าใช้จ่ายไม่ได้",
            show:false,
          },
          TH9: {
            label: "(5) ค่าเช่า",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "บ้าน,รถ 30% ที่ดินเกษตร 20% ที่ดินเปล่า 15% อื่นๆ 10%",
            show:false,
          },
          TI9: {
            label: "(5) ค่าเช่า - หักค่าใช้จ่าย",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TH10: {
            label: "(6) วิชาชีพอิสระ",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "แพทย์ 60% อื่นๆ 30%",
            show:false,
          },
          TI10: {
            label: "(6) วิชาชีพอิสระ - หักค่าใช้จ่าย",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TH11: {
            label: "(7) รับเหมาโดยการจัดหาของให้",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "ตามจริง หรือเหมา 60%",
            show:false,
          },
          TI11: {
            label: "(7) รับเหมาโดยการจัดหาของให้ - หักค่าใช้จ่าย",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TH12: {
            label: "(8) อื่นๆ",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TI12: {
            label: "(8) อื่นๆ - หักค่าใช้จ่าย",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          // ค่าลดหย่อน
          TI16: {
            disabled: true,
            label: "ส่วนตัว (ก่อนวางแผน)",
            value: 60000,
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "60,000",
          },
          TJ16: {
            disabled: true,
            label: "ส่วนตัว (หลังวางแผน)",
            value: 60000,
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "60,000",
          },
          TI17: {
            label: "คู่สมรส (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "60,000",
            show:false,
          },
          TJ17: {
            label: "คู่สมรส (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "60,000",
            show:false,
          },
          TI18: {
            label: "บุตร (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คนละ 30,000",
            show:false,
          },
          TJ18: {
            label: "บุตร (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คนละ 30,000",
            show:false,
          },
          TI19: {
            label:
              "บุตรคนที่ 2 เป็นต้นไป ที่คลอดในปี 2561 และหลังจากนั้น (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คนละ 60,000",
            show:false,
          },
          TJ19: {
            label:
              "บุตรคนที่ 2 เป็นต้นไป ที่คลอดในปี 2561 และหลังจากนั้น (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คนละ 60,000",
            show:false,
          },
          TI20: {
            label: "ค่าฝากครรภ์-คลอดบุตร (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "ไม่เกิน 60,000/ครรภ์",
            show:false,
          },
          TJ20: {
            label: "ค่าฝากครรภ์-คลอดบุตร (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "ไม่เกิน 60,000/ครรภ์",
            show:false,
          },
          TI21: {
            // label: "บิดา/มารดา ไม่เกิน 4 คน (อายุเกิน 60 ปีและรายได้น้อยกว่า 30,000) (ก่อนวางแผน)",
            label: "ค่าเลี้ยงดูบิดา/มารดา (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คนละ 30,000",
            show:false,
          },
          TJ21: {
            // label: "บิดา/มารดา ไม่เกิน 4 คน (อายุเกิน 60 ปีและรายได้น้อยกว่า 30,000) (หลังวางแผน)",
            label: "ค่าเลี้ยงดูบิดา/มารดา (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คนละ 30,000",
            show:false,
          },
          TI22: {
            label: "ค่าเลี้ยงดูบุคคลทุพพลภาพหรือพิการ (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "เหมา 60,000",
            show:false,
          },
          TJ22: {
            label: "ค่าเลี้ยงดูบุคคลทุพพลภาพหรือพิการ (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "เหมา 60,000",
            show:false,
          },
          TI23: {
            // label: "เบี้ยประกันสุขภาพ บิดา/มารดา (รายได้น้อยกว่า 30,000 บาท) (ก่อนวางแผน)",
            label: "เบี้ยประกันสุขภาพบิดา/มารดา (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show:false,
          },
          TJ23: {
            // label: "เบี้ยประกันสุขภาพ บิดา/มารดา (รายได้น้อยกว่า 30,000 บาท) (หลังวางแผน)",
            label: "เบี้ยประกันสุขภาพบิดา/มารดา (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show:false,
          },
          TI24: {
            label: "เบี้ยประกันชีวิต - ผู้มีเงินได้ (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 100,000",
            show:false,
          },
          TJ24: {
            label: "เบี้ยประกันชีวิต - ผู้มีเงินได้ (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 100,000",
            show:false,
          },
          TI25: {
            label: "เบี้ยประกันชีวิต - คู่สมรส (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คู่สมรสไม่มีเงินได้ สูงสุด 10,000",
            show:false,
          },
          TJ25: {
            label: "เบี้ยประกันชีวิต - คู่สมรส (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "คู่สมรสไม่มีเงินได้ สูงสุด 10,000",
            show:false,
          },
          TI26: {
            label: "เบี้ยประกันบำนาญ - ผู้มีเงินได้ (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "15% ของเงินได้พึงประเมิน ไม่เกิน 200,000",
            show:false,
          },
          TJ26: {
            label: "เบี้ยประกันบำนาญ - ผู้มีเงินได้ (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "15% ของเงินได้พึงประเมิน ไม่เกิน 200,000",
            show:false,
          },
          TI27: {
            label: "เงินสมทบกองทุนสำรองเลี้ยงชีพ/กบข/ครูเอกชน (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TJ27: {
            label: "เงินสมทบกองทุนสำรองเลี้ยงชีพ/กบข/ครูเอกชน (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TI28: {
            label: "ค่าซื้อหน่วยลงทุน RMF (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TJ28: {
            label: "ค่าซื้อหน่วยลงทุน RMF (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TI29: {
            label: "ค่าซื้อหน่วยลงทุน LTF (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TJ29: {
            label: "ค่าซื้อหน่วยลงทุน LTF (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TI30: {
            label: "ดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 100,000",
            show:false,
          },
          TJ30: {
            label: "ดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 100,000",
            show:false,
          },
          TI31: {
            label: "เงินสมทบกองทุนประกันสังคม (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "ตามที่จ่ายจริง",
            show:false,
          },
          TJ31: {
            label: "เงินสมทบกองทุนประกันสังคม (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "ตามที่จ่ายจริง",
            show:false,
          },
          TI32: {
            label: "บ้านหลังแรก",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "20% ของราคาไม่เกิน 3 ล้าน แยก 5 ปี (ก่อนวางแผน)",
            show:false,
          },
          TJ32: {
            label: "บ้านหลังแรก",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "20% ของราคาไม่เกิน 3 ล้าน แยก 5 ปี (หลังวางแผน)",
            show:false,
          },
          TI33: {
            // label: "ค่าธรรมเนียมจากการรับชำระเงินด้วยบัตรเดบิต สำหรับ 40(5)-(8) ที่มีเงินได้ไม่เกิน 30 ล้าน (ก่อนวางแผน)",
            label: "ค่าธรรมเนียมจากการรับชำระเงินด้วยบัตรเดบิต (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TJ33: {
            // label: "ค่าธรรมเนียมจากการรับชำระเงินด้วยบัตรเดบิต สำหรับ 40(5)-(8) ที่มีเงินได้ไม่เกิน 30 ล้าน (หลังวางแผน)",
            label: "ค่าธรรมเนียมจากการรับชำระเงินด้วยบัตรเดบิต (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TI34: {
            label: "ลงทุนในธุรกิจ Startup (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 100,000",
            show:false,
          },
          TJ34: {
            label: "ลงทุนในธุรกิจ Startup (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 100,000",
            show:false,
          },
          TI35: {
            // label: "ช้อปช่วยชาติ (ยางรถยนต์, หนังสือ-ebook, OTOP) ช่วง 15/12/61-16/01/62 (ก่อนวางแผน)",
            label: "ช้อปช่วยชาติ ช่วง 15/12/61-16/01/62 (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TJ35: {
            // label: "ช้อปช่วยชาติ (ยางรถยนต์, หนังสือ-ebook, OTOP) ช่วง 15/12/61-16/01/62 (หลังวางแผน)",
            label: "ช้อปช่วยชาติ ช่วง 15/12/61-16/01/62 (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TI36: {
            // label: "ท่องเที่ยวในประเทศ (เมืองหลัก 15,000/เมืองรอง 20,000) ช่วง 30/04/62-30/06/62 (ก่อนวางแผน)",
            label: "ท่องเที่ยวในประเทศ ช่วง 30/04/62-30/06/62 (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 20,000",
            show: false,
          },
          TJ36: {
            // label: "ท่องเที่ยวในประเทศ (เมืองหลัก 15,000/เมืองรอง 20,000) ช่วง 30/04/62-30/06/62 (หลังวางแผน)",
            label: "ท่องเที่ยวในประเทศ ช่วง 30/04/62-30/06/62 (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 20,000",
            show: false,
          },
          TI37: {
            label:
              "สินค้าการศึกษาและอุปกรณ์กีฬา ช่วง 01/05/62 - 30/06/62 (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TJ37: {
            label:
              "สินค้าการศึกษาและอุปกรณ์กีฬา ช่วง 01/05/62 - 30/06/62 (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TI38: {
            label: "สินค้า OTOP ช่วง 30/04/62 - 30/06/62 (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TJ38: {
            label: "สินค้า OTOP ช่วง 30/04/62 - 30/06/62 (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TI39: {
            label: "หนังสือและ e-boook ช่วง 01/01/62 - 31/12/62 (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TJ39: {
            label: "หนังสือและ e-boook ช่วง 01/01/62 - 31/12/62 (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 15,000",
            show: false,
          },
          TI40: {
            label:
              "บ้านหลังแรก มูลค่าไม่เกิน 5 ล้าน ช่วง 01/05/62 - 31/12/62 (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 200,000",
            show: false,
          },
          TJ40: {
            label:
              "บ้านหลังแรก มูลค่าไม่เกิน 5 ล้าน ช่วง 01/05/62 - 31/12/62 (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "สูงสุด 200,000",
            show: false,
          },
          TI41: {
            label: "อื่นๆ",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (ก่อนวางแผน)",
            suffix: "บาท",
            show: false,
          },
          TJ41: {
            label: "อื่นๆ",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (หลังวางแผน)",
            suffix: "บาท",
            show: false,
          },
          TH501: {
            label: "อื่นๆ(1) - รายการลดหย่อน",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          TI501: {
            label: "อื่นๆ(1) - ก่อนวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (ก่อนวางแผน)",
            suffix: "บาท",
            show:false,
          },
          TJ501: {
            label: "อื่นๆ(1) - หลังวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (หลังวางแผน)",
            suffix: "บาท",
            show:false,
          },

          TH502: {
            label: "อื่นๆ(2) - รายการลดหย่อน",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",  
            show:false,          
          },
          TI502: {
            label: "อื่นๆ(2) - ก่อนวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (ก่อนวางแผน)",
            suffix: "บาท",
            show:false,
          },
          TJ502: {
            label: "อื่นๆ(2) - หลังวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (หลังวางแผน)",
            suffix: "บาท",
            show:false,
          },

          TH503: {
            label: "อื่นๆ(3) - รายการลดหย่อน",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",  
            show:false,          
          },
          TI503: {
            label: "อื่นๆ(3) - ก่อนวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (ก่อนวางแผน)",
            suffix: "บาท",
            show:false,
          },
          TJ503: {
            label: "อื่นๆ(3) - หลังวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (หลังวางแผน)",
            suffix: "บาท",
            show:false,
          },

          TH504: {
            label: "อื่นๆ(4) - รายการลดหย่อน",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            show:false,            
          },
          TI504: {
            label: "อื่นๆ(4) - ก่อนวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (ก่อนวางแผน)",
            suffix: "บาท",
            show:false,
          },
          TJ504: {
            label: "อื่นๆ(4) - หลังวางแผน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล (หลังวางแผน)",
            suffix: "บาท",
            show:false,
          },

          TI45: {
            label:
              "หัก เงินบริจาคเพื่อสนับสนุนการศึกษา, กีฬา, โรงพยาบาล (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "หักได้ 2 เท่าของยอดบริจาค ",
            show:false,
          },
          TJ45: {
            label:
              "หัก เงินบริจาคเพื่อสนับสนุนการศึกษา, กีฬา, โรงพยาบาล (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            hint: "หักได้ 2 เท่าของยอดบริจาค ",
            show:false,
          },
          TI47: {
            label: "หัก เงินบริจาคเพื่อการสาธารณกุศลอื่นๆ (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TJ47: {
            label: "หัก เงินบริจาคเพื่อการสาธารณกุศลอื่นๆ (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TI60: {
            label: "หัก ณ ที่จ่ายไว้แล้ว (ก่อนวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
          TJ60: {
            label: "หัก ณ ที่จ่ายไว้แล้ว (หลังวางแผน)",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
          },
        },
      },
      // ข้อมูลการคุ้มครองสุขภาพและรายได้
      health: {
        heading: "ข้อมูลการคุ้มครองสุขภาพและรายได้",
        cell: {
          // คุ้มครองสุขภาพ อุบัติเหตุ
          HC3: {
            label: "โรงพยาบาลที่ใช้บริการ",
            value: "",
            type: "text",
            placeholder: "ระบุข้อมูล",
            suffix: "",
            show:false,
            // rules: healthRequired
          },
          HC4: {
            label: "ค่าห้อง ค่าบริการ /วัน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC5: {
            label: "งบประมาณในการดูแลสุขภาพ / ปี",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC7: {
            label: "ค่าห้อง ค่าบริการ /วัน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC8: {
            label: "วงเงินค่ารักษาพยาบาลเหมาจ่าย",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          // คุ้มครองโรคร้ายแรง
          HC13: {
            label: "รายได้ /ปี ที่อาจสูเสียไป",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC14: {
            label: "จำนวนปีที่ต้องรักษา",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC16: {
            label: "เงินก้อนที่เตรียมไว้แล้ว",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          //การปกป้องรายได้ของหัวหน้าครอบครัว
          HC19: {
            label: "ค่าใช้จ่ายที่รับผิดชอบครอบครัว/ปี",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC20: {
            label: "จำนวนปีที่ต้องการคุ้มครอง",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC21: {
            label: "เงินเฟ้อ",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "%",
            show:false,
            // rules: healthRequired
          },
          HC22: {
            label: "อัตราผลตอบแทนกองทุน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "%",
            show:false,
            // rules: healthRequired
          },
          // หนี้สินคงค้าง
          HC26: {
            label: "หนี้สินคงค้างระยะสั้น",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC27: {
            label: "หนี้สินคงค้างระยะยาว",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
          HC32: {
            label: "สินทรัพย์เงินสดที่เตรียมไว้",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
          },
          HC33: {
            label: "ทุนประกัน",
            value: "",
            type: "number",
            placeholder: "ระบุข้อมูล",
            suffix: "บาท",
            show:false,
            // rules: healthRequired
          },
        },
      },
    },
    cell: {
      // จะถูกเพิ่มจาก actions.loadDataInput()
    },
  },
  balanceSheet: balanceSheet,
  cashFlow: cashFlow,
  financialRatio: financialRatio,
  education: education,
  education1: education1,
  retirement: retirement,
  retirement1: retirement1,
  tax: tax,
  health: health,
};
