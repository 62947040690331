export const retirement = {
  required: {
    dataInput: {
      cell: [
        // === no required ===
        "YR1",
        "C4",
        "E4",
        //  "E4", "I44", "RT1"
        // === no required ===
      ],
    },
  },
  cell: {
    A1: {
      label: "ชื่อ-สกุล",
      value: "",
      type: "text",
      formula: "dataInput.C4",
    },
    A2: {
      label: "อายุ",
      value: "",
      type: "number",
      formula: "dataInput.E4",
    },
    D12: {
      label: "ปีปัจจุบัน",
      // value: new Date().getFullYear() + 543,
      // type: "number"
      value: 0,
      type: "number",
      formula: "dataInput.YR1",
    },
    D13: {
      label: "อายุปัจจุบัน",
      value: 0,
      type: "number",
      formula: "dataInput.E4",
    },
    J14: {
      label: "จำนวนปีสะสมเงิน",
      value: 0,
      type: "number",
      formula: "dataInput.I44-dataInput.E4",
    },
    O14: {
      label: "ปีเริ่มหยุดทำงาน",
      value: 0,
      type: "number",
      formula: "retirement.D12+retirement.J14",
    },
    O15: {
      label: "อายุเริ่มหยุดทำงาน",
      value: 0,
      type: "number",
      formula:"dataInput.I44",
    },
    Q14: {
      label: "ปีหยุดทำงาน ปีที่1",
      value: 0,
      type: "number",
      formula: "retirement.O14+1",
    },    
    Q15:{
      label: "อายุหยุดทำงาน ปีที่1",
      value: 0,
      type: "number",
      formula: "dataInput.I44-0+1"
    },
    U14: {
      label: "จำนวนปีใช้เงิน",
      value: 0,
      type: "number",
      formula: "retirement.Y13-dataInput.I44"
    },
    Y13: {
      label: "อายุกองทุนสิ้นสุด",
      value: 85,
      type: "number",
      formula: "dataInput.I441"
    },
    Y12: {
      label: "ปีอายุกองทุนสิ้นสุด",
      value: 0,
      type: "number",
      formula: "retirement.O14+retirement.U14",
    },
    C16: {
      label: "เงิน/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.RT1",
    },
    B17: {
      label: "เงิน/ปี",
      value: 0,
      type: "number",
      formula: "retirement.C16*12",
    },
    I17: {
      label: "อัตราเงินเฟ้อ (%)",
      value: 3,
      type: "number",
    },
    M17: {
      label: "เงิน/ปี ในอนาคต",
      value: 0,
      type: "number",
      // =FV(I17,J14,,-B17)
      formula: "FV(retirement.I17,retirement.J14,0,-retirement.B17,0)",
    },
    M16: {
      label: "เงิน/เดือน ในอนาคต",
      value: 0,
      type: "number",
      formula: "retirement.M17/12",
    },
    Y6: {
      label: "ผลตอบแทนหลังเกษียณ(%)",
      value: 4.0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY6",
    },
    Y8: {
      label: "อัตราเงินเฟ้อหลังเกษียณ (%)",
      value: 4.0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY8",
    },
    Y10: {
      label: "หลังปรับปรุง (%)",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "(retirement.Y6-retirement.Y8)/(1+retirement.Y8/100)",
    },
    O21: {
      label: "เงินที่ต้องมี ณ ปีที่เริ่มเกษียณ",
      value: 0,
      type: "number",
      // =PV(Y10,U14,-M17,1,1)
      formula: "PV(retirement.Y10,retirement.U14,-retirement.M17,1,1)",
    },
    O5: {
      label: "สรุป",
      value: 0,
      type: "number",
      // =PV(Y10,U14,-M17,0,1)
      formula: "PV(retirement.Y10,retirement.U14,-retirement.M17,0,1)",
    },
    AB18: {
      label: "สินทรัพย์สภาพคล่อง",
      value: 0,
      type: "number",
      formula: "dataInput.RT1AB18",
    },
    AB20: {
      label: "สินทรัพย์เพื่อการลงทุน",
      value: 0,
      type: "number",
      formula: "dataInput.RT1AB20",
    },
    AB23: {
      label: "ทรัพย์สินอื่นที่เตรียมไว้แล้ว",
      value: 0,
      type: "number",
      formula: "dataInput.RT1AB23",
    },
    AB25: {
      label: "เงินบำนาญและเงินอื่นๆ",
      value: 0,
      type: "number",
      formula: "dataInput.RT1AB25",
    },
    AB27: {
      label: "จำนวนเงินที่เตรียมไว้แล้ว",
      value: 0,
      type: "number",
      formula:
        "retirement.AB18+retirement.AB20+retirement.AB23+retirement.AB25",
    },
    AB29: {
      label: "เงินที่ต้องการเตรียมเพิ่ม",
      value: 0,
      type: "number",
      formula: "retirement.O21-retirement.AB27",
    },

    Y33: {
      label: "1. ฝากธนาคาร ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY33",
    },
    AB33: {
      label: "1. ฝากธนาคาร ...",
      value: 0,
      type: "number",
      // =PMT(Y33,R31,,-AB29,1)
      formula: "PMT(retirement.Y33,retirement.J14,0,-retirement.AB29,1)",
    },
    RTY330:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY330"      
    }, 
    Y35: {
      label: "2. ซื้อพันธบัตร ...%",
      value: 0.25,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY35",
    },
    AB35: {
      label: "2. ซื้อพันธบัตร ...",
      value: 0,
      type: "number",
      // =PMT(Y35,R31,,-AB29,1)
      formula: "PMT(retirement.Y35,retirement.J14,0,-retirement.AB29,1)",
    },
    RTY350:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY350"      
    }, 
    Y37: {
      label: "3. กองทุนรวมชั้นดี ...%",
      value: 6.0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY37",
    },
    AB37: {
      label: "3. กองทุนรวมชั้นดี ...",
      value: 0,
      type: "number",
      // =PMT(Y37,R31,,-AB29,1)
      formula: "PMT(retirement.Y37,retirement.J14,0,-retirement.AB29,1)",
    },
    RTY370:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY370"      
    }, 
    Y39: {
      label: "4. ประกันชีวิตแบบบำนาญ ...%",
      value: 2.0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY39",
    },
    AB39: {
      label: "4. ประกันชีวิตแบบบำนาญ ...",
      value: 0,
      type: "number",
      // =PMT(Y39,R31,,-AB29,1)
      formula: "PMT(retirement.Y39,retirement.J14,0,-retirement.AB29,1)",
    },
    RTY390:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY390"      
    }, 
    Y41: {
      label: "5. Investment Link ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY41",
    },
    AB41: {
      label: "5. Investment Link ...",
      value: 0,
      type: "number",
      // =PMT(Y41,R31,,-AB29,1)
      formula: "PMT(retirement.Y41,retirement.J14,0,-retirement.AB29,1)",
    },
    RTY410:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY410"      
    }, 
    Y43: {
      label: "6. แบบชำระเบี้ยครั้งเดียว  ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY43",
    },
    AB43: {
      label: "6. แบบชำระเบี้ยครั้งเดียว ...",
      value: 0,
      type: "number",
      // =PV(Y43,J14,,-AB29,1)
      formula: "PV(retirement.Y43,retirement.J14,0,-retirement.AB29,1)",
    }, 
    RTY430:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY430"      
    },   
    Y44: {
      label: "7. อื่นๆ  ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY44",
    },
    AB44: {
      label: "7. อื่นๆ ...",
      value: 0,
      type: "number",
      // =PV(Y43,J14,,-AB29,1)
      formula: "PV(retirement.Y44,retirement.J14,0,-retirement.AB29,1)",
    },
    RTY440:{      
      label: "อื่นๆ ข้อ(1)",
      value: "",
      type: "text",
      formula: "dataInput.RTY440"      
    },
  },
};

export const retirement1 = {
  required: {
    dataInput: {      
      cell: [
        "YR1",
        "C4",
        "E4",
        "C5", 
        // "E5", "I45", "RT2"
      ],
    },
  },
  cell: {
    A1: {
      label: "ชื่อ-สกุล",
      value: "",
      type: "text",
      formula: "dataInput.C5",
    },
    A2: {
      label: "อายุ",
      value: "",
      type: "number",
      formula: "dataInput.E5",
    },
    D12: {
      label: "ปีปัจจุบัน",
      // value: new Date().getFullYear() + 543,
      // type: "number"
      value: 0,
      type: "number",
      formula: "dataInput.YR1",
    },
    D13: {
      label: "อายุปัจจุบัน",
      value: 0,
      type: "number",
      formula: "dataInput.E5",
    },
    J14: {
      label: "จำนวนปีสะสมเงิน",
      value: 0,
      type: "number",      
      formula: "dataInput.I45-dataInput.E5",
    },
    O14: {
      label: "ปีเริ่มหยุดทำงาน",
      value: 0,
      type: "number",
      formula: "retirement1.D12+retirement1.J14",
    },
    O15: {
      label: "อายุเริ่มหยุดทำงาน",
      formula:"dataInput.I45",
      type: "number",
    },
    Q14: {
      label: "ปีหยุดทำงาน ปีที่1",
      value: 0,
      type: "number",
      formula: "retirement1.O14+1",
    },
    Q15: {
      label: "อายุหยุดทำงาน ปีที่ 1",
      value: 61,
      type: "number",
      formula: "dataInput.I45-0+1"
    },
    U14: {
      label: "จำนวนปีใช้เงิน",
      value: 25,
      type: "number",
      formula: "retirement1.Y13-dataInput.I45"
    },
    Y13: {
      label: "อายุกองทุนสิ้นสุด",
      value: 85,
      type: "number",
      formula: "dataInput.I451"
    },
    Y12: {
      label: "ปีอายุกองทุนสิ้นสุด",
      value: 0,
      type: "number",
      formula: "retirement1.O14+retirement1.U14",
    },
    C16: {
      label: "เงิน/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.RT2",
    },
    B17: {
      label: "เงิน/ปี",
      value: 0,
      type: "number",
      formula: "retirement1.C16*12",
    },
    I17: {
      label: "อัตราเงินเฟ้อ (%)",
      value: 3,
      type: "number",
    },
    M17: {
      label: "เงิน/ปี ในอนาคต",
      value: 0,
      type: "number",
      // =FV(I17,J14,,-B17)
      formula: "FV(retirement1.I17,retirement1.J14,0,-retirement1.B17,0)",
    },
    M16: {
      label: "เงิน/เดือน ในอนาคต",
      value: 0,
      type: "number",
      formula: "retirement1.M17/12",
    },
    Y6: {
      label: "ผลตอบแทนหลังเกษียณ(%)",
      value: 4.0,
      suffix: "%",
      type: "number",
    },
    Y8: {
      label: "อัตราเงินเฟ้อหลังเกษียณ (%)",
      value: 4.0,
      suffix: "%",
      type: "number",
    },
    Y10: {
      label: "หลังปรับปรุง (%)",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "(retirement1.Y6-retirement1.Y8)/(1+retirement1.Y8/100)",
    },
    O21: {
      label: "เงินที่ต้องมี ณ ปีที่เริ่มเกษียณ",
      value: 0,
      type: "number",
      // =PV(Y10,U14,-M17,1,1)
      formula: "PV(retirement1.Y10,retirement1.U14,-retirement1.M17,1,1)",
    },
    O5: {
      label: "สรุป",
      value: 0,
      type: "number",
      // =PV(Y10,U14,-M17,0,1)
      formula: "PV(retirement1.Y10,retirement1.U14,-retirement1.M17,0,1)",
    },
    AB18: {
      label: "สินทรัพย์สภาพคล่อง",
      value: 0,
      type: "number",
      formula: "dataInput.RT2AB18",
    },
    AB20: {
      label: "สินทรัพย์เพื่อการลงทุน",
      value: 0,
      type: "number",
      formula: "dataInput.RT2AB20",
    },
    AB23: {
      label: "ทรัพย์สินอื่นที่เตรียมไว้แล้ว",
      value: 0,
      type: "number",
      formula: "dataInput.RT2AB23",
    },
    AB25: {
      label: "เงินบำนาญและเงินอื่นๆ",
      value: 0,
      type: "number",
      formula: "dataInput.RT2AB25",
    },
    AB27: {
      label: "จำนวนเงินที่เตรียมไว้แล้ว",
      value: 0,
      type: "number",
      formula:
        "retirement1.AB18+retirement1.AB20+retirement1.AB23+retirement1.AB25",
    },
    AB29: {
      label: "เงินที่ต้องการเตรียมเพิ่ม",
      value: 0,
      type: "number",
      formula: "retirement1.O21-retirement1.AB27",
    },

    Y33: {
      label: "1. ฝากธนาคาร ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY33",
    },
    AB33: {
      label: "1. ฝากธนาคาร ...",
      value: 0,
      type: "number",
      // =PMT(Y33,R31,,-AB29,1)
      formula: "PMT(retirement1.Y33,retirement1.J14,0,-retirement1.AB29,1)",
    },
    RTY330:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY330"      
    }, 
    Y35: {
      label: "2. ซื้อพันธบัตร ...%",
      value: 0.25,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY35",
    },
    AB35: {
      label: "2. ซื้อพันธบัตร ...",
      value: 0,
      type: "number",
      // =PMT(Y35,R31,,-AB29,1)
      formula: "PMT(retirement1.Y35,retirement1.J14,0,-retirement1.AB29,1)",
    },
    RTY350:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY350"      
    }, 
    Y37: {
      label: "3. กองทุนรวมชั้นดี ...%",
      value: 6.0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY37",
    },
    AB37: {
      label: "3. กองทุนรวมชั้นดี ...",
      value: 0,
      type: "number",
      // =PMT(Y37,R31,,-AB29,1)
      formula: "PMT(retirement1.Y37,retirement1.J14,0,-retirement1.AB29,1)",
    },
    RTY370:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY370"      
    }, 
    Y39: {
      label: "4. ประกันชีวิตแบบบำนาญ ...%",
      value: 2.0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY39",
    },
    AB39: {
      label: "4. ประกันชีวิตแบบบำนาญ ...",
      value: 0,
      type: "number",
      // =PMT(Y39,R31,,-AB29,1)
      formula: "PMT(retirement1.Y39,retirement1.J14,0,-retirement1.AB29,1)",
    },
    RTY390:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY390"      
    }, 
    Y41: {
      label: "5. Investment Link ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY41",
    },
    AB41: {
      label: "5. Investment Link ...",
      value: 0,
      type: "number",
      // =PMT(Y41,R31,,-AB29,1)
      formula: "PMT(retirement1.Y41,retirement1.J14,0,-retirement1.AB29,1)",
    },
    RTY410:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY410"      
    }, 
    Y43: {
      label: "6. แบบชำระเบี้ยครั้งเดียว  ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY43",
    },
    RTY430:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.RTY430"      
    },
    AB43: {
      label: "6. แบบชำระเบี้ยครั้งเดียว ...",
      value: 0,
      type: "number",
      // =PV(Y43,J14,,-AB29,1)
      formula: "PV(retirement1.Y43,retirement1.J14,0,-retirement1.AB29,1)",
    },
    Y44: {
      label: "7. อื่นๆ  ...%",
      value: 0,
      suffix: "%",
      type: "number",
      formula: "dataInput.RTY44",
    },
    RTY440:{      
      label: "อื่นๆ ข้อ(1)",
      value: "",
      type: "text",
      formula: "dataInput.RTY440"      
    },
    AB44: {
      label: "7. อื่นๆ ...",
      value: 0,
      type: "number",
      // =PV(Y43,J14,,-AB29,1)
      formula: "PV(retirement1.Y44,retirement1.J14,0,-retirement1.AB29,1)",
    },
  },
};
