<template>
  <v-container id="planNavigation" class="fluid pa-0">
    <!-- https://cdn.materialdesignicons.com/4.9.95/  -->
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <ul v-if="isNavPositionLeft()">
          <li v-for="(item, index) in navigationItems" v-bind:key="index">
            <v-btn
              small
              :color="item.color"
              :disabled="item.disabled"
              :dark="item.disabled ? false : true"
              @click="goToSheet(index)"
            >
              <v-icon small>{{ item.icon }}</v-icon>
              {{ item.label }}
            </v-btn>
            <v-progress-linear
              striped
              :value="item.progress"
              :color="item.color"
            ></v-progress-linear>
          </li>
        </ul>

        <ul v-if="!isNavPositionLeft()">
          <li v-show="!printAble">
            <v-btn small @click="enableExportPDF()">
              <v-icon small>mdi-file-pdf</v-icon>
              Export PDF
            </v-btn>
            <v-progress-linear
              striped
              :value="100"
              color="#000000"
            ></v-progress-linear>
          </li>
          <li v-show="printAble">
            <v-btn small @click="print()" color="black" :dark="true">
              <v-icon small>mdi-printer</v-icon>
              Print
            </v-btn>
            <v-progress-linear
              striped
              :value="100"
              color="#000000"
            ></v-progress-linear>
          </li>
          <!-- <li>
            <v-btn small @click="toggleChartJS()">
              <v-icon small>mdi-chart-arc</v-icon>
              ChartJS
            </v-btn>
            <v-progress-linear
              striped
              :value="100"
              color="#000000"
            ></v-progress-linear>
          </li>           -->
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan-navigation",
  props: {
    printAble: Boolean,
    navPosition: String,
  },
  computed: {
    ...mapGetters(["navigationItems"]),
  },
  data: function () {
    return {
      colors: {
        disabled: "blue-grey",
        active: {
          dataInput: "blue-grey",
          balanceSheet: "primary",
          cashFlow: "green",
          financialRatio: "red",
          education: "amber",
          retirement: "teal",
        },
      },
    };
  },
  mounted() {
    // set default tab
    if (this.$route.name == "plan-edit" || this.$route.name == "plan-detail") {
      this.goToSheet("balanceSheet");
      if (this.$route.name == "plan-detail") {
        this.navigationItems["dataInput"].active = false;
        this.navigationItems["dataInput"].disabled = true;
      }
    } else {
      this.navigationItems["dataInput"].active = true;
      this.navigationItems["dataInput"].disabled = false;
      this.goToSheet("dataInput");
    }
  },
  methods: {
    ...mapActions(["hideDrawer"]),
    goToSheet: function (sheet_index) {
      var planAddComponent =
        this.$root.$children[0].$children[0].$children[2].$children[2]
          .$children[0];
      // var planAddComponent = this.$parent;
      if (!this.navigationItems[sheet_index].disabled) {
        planAddComponent.isLoading = true;
        _.each(this.navigationItems, (sheet, index) => {
          this.navigationItems[index].active = false;
        });
        planAddComponent.isLoading = true;
        this.addClass(document.getElementById("CoverPage"), "content-hide");
        this.addClass(document.getElementById("SummaryPage"), "content-hide");
        planAddComponent.printAble = false;

        planAddComponent.enableChartJs = false;
        setTimeout(() => {
          planAddComponent.isLoading = false;
          planAddComponent.enableChartJs = true;
          this.navigationItems[sheet_index].active = true;
        }, 100);
      }
    },
    enableExportPDF: function () {
      this.hideDrawer();
      this.$parent.isLoading = true;
      _.each(this.navigationItems, (sheet, index) => {
        this.navigationItems[index].active = true;
        if (index == "education1" && this.navigationItems[index] == true) {
          this.navigationItems[index].active = false;
        }
      });
      this.navigationItems["dataInput"].active = false;
      this.removeClass(document.getElementById("CoverPage"), "content-hide");
      this.removeClass(document.getElementById("SummaryPage"), "content-hide");
      this.$parent.printAble = true;
      this.$parent.enableChartJs = false;
      setTimeout(() => {
        this.$parent.enableChartJs = true;
        this.$parent.isLoading = false;
      }, 100);
    },
    alert: function () {
      alert();
    },
    hasClass: function (el, className) {
      if (el !== undefined) {
        return !!el.className.match(
          new RegExp("(\\s|^)" + className + "(\\s|$)")
        );
      }
      return false;
    },
    addClass: function (el, className) {
      if (el !== undefined) {
        if (!this.hasClass(el, className)) {
          el.className += " " + className;
        }
      }
    },
    removeClass: function (el, className) {
      if (el !== undefined) {
        if (this.hasClass(el, className)) {
          var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
          el.className = el.className.replace(reg, " ");
        }
      }
    },
    active: function (section) {
      // console.log(section);
    },
    print() {
      this.$parent.print();
    },
    toggleChartJS: function () {
      console.log("toggleChartJS");
      this.$parent.enableChartJs = !this.$parent.enableChartJs;
    },
    showExportAndPrint: function () {
      if (this.navPosition == "left") {
        return false;
      }
      return true;
    },
    isNavPositionLeft: function () {
      if (this.navPosition == "left") {
        return true;
      }
      return false;
    },
  },
};
</script>