/* eslint-disable no-unused-vars */
import ManageUser from "@/views/user/ManageUser.vue";
import Profile from "@/views/user/Profile.vue";

export default [
  {
    path: "/manage/user",
    component: ManageUser,
    meta:{
      requiresAuth:true,      
      requiresManager:true,
    },
  },  
  {
    path: "/profile",
    component: Profile,
    meta:{
      requiresAuth:true,
    },
  },  
];
