<template>
  <v-container class="fluid pa-0" id="user">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <v-card>
      <v-card-title>
        Plan Management
        <v-btn class="ma-4" color="primary" tile small to="/plan-add">
          <v-icon small left>mdi-plus</v-icon>Add New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="plan_dttb.search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- Plan lists data table -->
      <v-data-table
        :headers="plan_dttb.headers"
        :items="plan_dttb.items"
        :search="plan_dttb.search"
        sort-by="createDate"
        :sort-desc=[true]
      >
        <!-- <template v-slot:item.clientInfo="{ item }">
          <span :data="item">
            {{ item.clientInfo.name }}<br />
            อายุ {{ item.clientInfo.age }} ปี
          </span>
        </template> -->
        <template v-slot:item.balanceSheet="{ item }">
          <span :data="item">
            สินทรัพย์ {{ item.balanceSheet.total_asset | numeralFormat }}<br />
            ความมั่งคั่งสุทธิ {{ item.balanceSheet.net_worth | numeralFormat }}
          </span>
        </template>
        <template v-slot:item.cashFlow="{ item }">
          <span :data="item">
            กระแสเงินสดรับ {{ item.cashFlow.total_income | numeralFormat
            }}<br />
            กระแสเงินสดจ่าย {{ item.cashFlow.total_expends | numeralFormat }}
          </span>
        </template>
        <template v-slot:item.action="{ item }">
          <!-- <span
            v-if="currentUser.role == 'admin' || currentUser.role == 'manager'"
          > -->
          <!-- <v-btn class="ma-1" color="primary" tile small>
              <v-icon small left>mdi-download</v-icon>PDF
            </v-btn> -->
          <v-btn class="ma-1" color="success" tile small :to="item.link">
            <v-icon small left>mdi-pencil</v-icon>Edit
          </v-btn>
          <v-btn
            class="ma-1"
            color="error"
            tile
            small
            @click="open_plan_delete_dialog(item)"
          >
            <v-icon small left>mdi-delete</v-icon>Delete
          </v-btn>
          <!-- </span> -->
        </template>
      </v-data-table>
    </v-card>

    <!-- User delete confirm dialog -->
    <v-dialog v-model="plan_delete_dialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">Delete this Plan?</v-card-title>
        <v-card-text>
          Are you sure to DELETE
          <b>"{{ plan.firstname }} {{ plan.lastname }}"</b> this all user data
          will be losts. Let Google help apps determine location. This means
          sending anonymous location data to Google, even when no apps are
          running.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template>
            <v-btn
              color="primary darken-1"
              text
              small
              @click="close_plan_delete_dialog()"
              >Close</v-btn
            >
            <v-btn color="error darken-1" dark small @click="plan_delete(plan)"
              >Delete</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-unreachable */
import Vue from "vue";
import firebase from 'firebase/compat/app';
import _ from "lodash";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "plan",
  components: {
    Loading,
  },
  creaetd() {},
  async mounted() {
    // const plans = await db
    //       .ref("/plans/")
    //       .orderByChild("uid")
    //       .equalTo(context.auth.uid)
    //       .on("value", snapshot => {
    //         return snapshot.val();
    //       });

    this.isLoading = true;

    // RTDB for plans
    this.currentUsers = await firebase.auth().currentUser;
    firebase
      .database()
      .ref("/plans")
      .orderByChild("uid")
      .equalTo(this.currentUsers.uid)
      .on("value", (snapshot) => {
        // console.log("Plan.mounted - firebase /plans");
        let plans = snapshot.val();

        this.plan_dttb.items = _.chain(plans)
          .map(function (item, index) {
            return {
              _id: index,
              link: "/plan-edit/" + index,
              createDate: item.createAt, //moment(item.createAt).format("YYYY-DD-MM H:mm:ss"),
              clientInfo: "คุณ" + item.plan.family.customer.name,
              balanceSheet: item.plan.balanceSheet,
              cashFlow: item.plan.cashFlow,
            };
          })
          .value();
        this.isLoading = false;
      });

    // this.getPlanList();
  },
  data() {
    return {
      isLoading: false,
      plan: {},
      plan_delete_dialog: false,
      plan_dttb: {
        search: "",
        headers: [
          // { text: "ID", value: "_id", sortable: false },
          { text: "Create Date", value: "createDate", sortable: true },
          {
            text: "Client Information",
            value: "clientInfo",
            sortable: false,
          },
          { text: "Balance Sheet", value: "balanceSheet", sortable: false },
          { text: "Cashflow", value: "cashFlow", sortable: false },
          { text: "Actions", value: "action", sortable: false },
        ],
        items: [],
      },
    };
  },
  computed: {
    ...mapGetters({ currentUser: "user" }),
  },
  methods: {
    ...mapActions(["showDrawer"]),
    async getPlanList() {
      const firebaseGetPlanList = firebase
        .functions()
        .httpsCallable("firebaseGetPlanList");
      const plansList = await firebaseGetPlanList();
      console.log("firebaseGetPlanList", plansList);

      this.plan_dttb.items = _.chain(plansList.data.plans)
        .map(function (item, index) {
          return {
            _id: index,
            createDate: item.createAt,
            clientInfo: item.plan.family.customer,
            balanceSheet: item.plan.balanceSheet,
            cashFlow: item.plan.cashFlow,
          };
        })
        .value();
    },

    // CONFIRM_DELETE
    open_plan_delete_dialog(item) {
      this.plan = item;
      this.plan_delete_dialog = true;
    },
    close_plan_delete_dialog() {
      this.plan = {};
      this.plan_delete_dialog = false;
    },
    async plan_delete(plan) {
      this.isLoading = true;
      let _id = plan._id;
      try {
        await firebase.database().ref("/plans/").child(_id).remove();

        let uid = this.currentUser.uid;
        firebase
          .database()
          .ref("/stats/" + uid)
          .once("value", (snapshot) => {
            let user_stats = snapshot.val();
            let time = moment().format();
            let stats = {
              login: [],
              total_plan: 0,
              total_plan_30days: 0,
              total_plan_detail: {
                balance_sheet: 0,
                cash_flow: 0,
                financial_ratio: 0,
                education: 0,
                retirement: 0,
                tax: 0,
                health: 0,
              },
            };
            if (user_stats !== null) {
              stats.login = user_stats.login;
            }
            stats.login.push(time);

            // plans
            firebase
              .database()
              .ref("/plans")
              .orderByChild("uid")
              .equalTo(uid)
              .once("value", (snapshot) => {
                snapshot.forEach(function (childSnapshot) {
                  let plan = childSnapshot.val();
                  let createAt = plan.createAt;
                  let diff = moment().diff(moment(createAt), "days");
                  stats.total_plan += 1;
                  if (diff <= 30) {
                    stats.total_plan_30days += 1;
                  }
                  stats.total_plan_detail.balance_sheet += 1;
                  stats.total_plan_detail.cash_flow += 1;
                  stats.total_plan_detail.financial_ratio += 1;

                  if (plan.progress === undefined) {
                    stats.total_plan_detail.education += 1;
                    stats.total_plan_detail.retirement += 1;
                    stats.total_plan_detail.tax += 1;
                    stats.total_plan_detail.health += 1;
                  } else {
                    if (plan.progress.education == 100) {
                      stats.total_plan_detail.education += 1;
                    }
                    if (plan.progress.retirement == 100) {
                      stats.total_plan_detail.retirement += 1;
                    }
                    if (plan.progress.tax == 100) {
                      stats.total_plan_detail.tax += 1;
                    }
                    if (plan.progress.health == 100) {
                      stats.total_plan_detail.health += 1;
                    }
                  }
                });
                firebase
                  .database()
                  .ref("/stats/" + uid)
                  .set(stats);
              });
          });

        Vue.notify({
          group: "plan",
          type: "success",
          title: "Important message",
          text: JSON.stringify("Plan.plan_delete - Deleted plan successfully"),
        });
      } catch (error) {}
      this.close_plan_delete_dialog();
      this.isLoading = false;
    },
  },
};
</script>