/* eslint-disable no-constant-condition */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */

/**
 * --------------------------------------------------------------
 * Sheet and Calucation Module
 * --------------------------------------------------------------
 */
/* eslint-disable no-unused-vars */
import { sheets } from "./data.sheets.js";
import { demo_sheets } from "./data.demo_sheet.js";
import { navigation } from "./data.navigation.js";

import _ from "lodash";
import numeral from "numeral";
var Finance = require("financejs"); // or ES6 import
var finance = new Finance();
import { PMT, PV, FV, pmt } from "@/assets/js/excelFormula/excelFormula.js";
import firebase from 'firebase/compat/app';

// console.log(finance.FV(5.03, 600000, 8));
// console.log(finance.FV(5.03, 600000, 9));
// console.log(finance.FV(5.03, 600000, 10));
// console.log(finance.FV(5.03, 600000, 11));
// console.log( finance.PV(6.00,12154438.7,17))

// console.log(PV(0.0, 25, -806350, 1, 1));
// console.log(PV(6.0, 17, 0, -12154439, 1));
// console.log(FV(5.03, 17, 0, -600000, 0));
// console.log(PMT(1, 17, 0, 12154439, 1));
// console.log(pmt(1, 17, 0, 12154439, 1));

const state = {
  /**
   * --------------------------------------------------------------
   * sheets : stored form/fields, cell, required sheet cell
   * navigation : control access result page
   * dataInput...health : stored sheet key/value for calculate
   * --------------------------------------------------------------
   */
  planItem: {},
  sheets: sheets,
  navigation: navigation,
  dataInput: {},
  balanceSheet: {},
  cashFlow: {},
  financialRatio: {},
  education: {},
  education1: {},
  retirement: {},
  retirement1: {},
  tax: {},
  health: {},
};

const getters = {
  planItem: function() {
    return state.planItem;
  },
  navigationItems: function() {
    return state.navigation;
  },
  dataInputSheet: function() {
    return state.sheets.dataInput;
  },
  balanceSheet: function() {
    return state.sheets.balanceSheet;
  },
  cashFlow: function() {
    return state.sheets.cashFlow;
  },
  financialRatio: function() {
    return state.sheets.financialRatio;
  },
  education: function() {
    return state.sheets.education;
  },
  education1: function() {
    return state.sheets.education1;
  },
  retirement: function() {
    return state.sheets.retirement;
  },
  retirement1: function() {
    return state.sheets.retirement1;
  },
  tax: function() {
    return state.sheets.tax;
  },
  health: function() {
    return state.sheets.health;
  },
};

const actions = {
  // getFirebasePlan(context, payload) {
  //   firebase
  //     .database()
  //     .ref("/plans/-MYnLh0Tg57p4UQbDdEp")
  //     .on("value", (snapshot) => {
  //       let data = snapshot.val();
  //       context.commit("saveDataInput", { cell: data.plan.cell });
  //     });
  // },
  loadDataInput(context, payload) {
    console.log("=====> called by loadDataInput");
    context.commit("LOAD_DATA_INPUT", payload);
    context.commit("CALCULATE_DATA_INPUT");
    if (payload.id != 0 && payload.id !== undefined) {
      firebase
        .database()
        .ref("/plans/" + payload.id)
        .on("value", (snapshot) => {
          const data = snapshot.val();
          payload.dataInput = { cell: [] };
          if(data.plan!==undefined){
            payload.dataInput = { cell: data.plan.cell };
          }
          payload.data = data;
          context.commit("SAVE_DATA_INPUT",payload);
          context.commit("LOAD_DATA_INPUT", payload);
          context.commit("CALCULATE_DATA_INPUT");
        });
    }
  },
  updateDataInput(context, payload) {
    if (
      payload.sheet !== undefined &&
      payload.cell !== undefined &&
      payload.value !== undefined &&
      payload.type !== undefined
    ) {
      context.commit("UPDATE_DATA_INPUT", payload);
      console.log("=====> called by updateDataInput");
      context.commit("CALCULATE_DATA_INPUT");
    }
  },
  saveDataInput(context, payload) {   
    context.commit("SAVE_DATA_INPUT",payload);
  },
};

const mutations = {
  SAVE_DATA_INPUT(state, payload) {
    state.planItem = payload.data;    
  },
  /**
   * --------------------------------------------------------------
   * Mutation : LOAD_DATA_INPUT
   * --------------------------------------------------------------
   * 1. set defualt object for sheetValue.cell dataInput
   * 2. load source dataInput from db or demo
   * 3. set sheet required_total/required_valid
   * --------------------------------------------------------------
   * @param {*} state
   * @param {*} payload
   */
  LOAD_DATA_INPUT(state, payload) {
    // console.log("LOAD_DATA_INPUT", payload);
    // [1] set defalut object for dataInput.cell
    _.each(state.sheets.dataInput.form, function(section) {
      _.each(section.cell, function(cell, index) {
        state.sheets.dataInput.cell[index] = {
          type: cell.type,
          value: cell.value,
        };
      });
    });
    console.log("[1] set default object for dataInput.cell");
    // [2] load dataInput source to dataInput.cell
    if (payload.id !== undefined) {
      // ==> from db
      if (payload.id != 0 && payload.dataInput != undefined) {
        // console.log(state.sheets.dataInput.cell);
        // console.log(payload.dataInput.cell);
        _.each(state.sheets.dataInput.cell, function(cell, index) {
          if (payload.dataInput.cell[index] !== undefined) {
            state.sheets.dataInput.cell[index].value =
              payload.dataInput.cell[index].value;
            // console.log(index,payload.dataInput.cell[index]);
          }
        });
      } else if (payload.id == 0) {
        // ==> from demo_sheet
        _.each(state.sheets.dataInput.cell, function(cell, index) {
          if (demo_sheets.dataInput.cell[index] !== undefined) {
            state.sheets.dataInput.cell[index].value =
              demo_sheets.dataInput.cell[index].value;
          }
        });
      }
    }
    console.log("[2] load dataInput source to dataInput.cell");
    // [3] set sheet required_total and required_valid
    _.each(state.sheets, function(sheet, index) {
      if (state.sheets[index].required !== undefined) {
        state.sheets[index].required_total = _.chain(
          state.sheets[index].required
        )
          .map((o) => {
            return o.cell.length;
          })
          .sum()
          .value();
      } else {
        state.sheets[index].required_total = 0;
      }
      state.sheets[index].required_valid = 0;
    });
    console.log("[3] set sheet required_total and required_valid");
    // console.log(state.sheets.dataInput);
  },

  /**
   * --------------------------------------------------------------
   * Mutation : UPDATE_DATA_INPUT
   * --------------------------------------------------------------
   * 1. Update sheet.cell.value on form input change
   * --------------------------------------------------------------
   * @param {*} state
   * @param {*} payload
   */
  UPDATE_DATA_INPUT(state, payload) {
    // [1] update sheet.cell.value with passing payload
    if (payload.type == "number") {
      state.sheets[payload.sheet].cell[payload.cell].value = Number(
        payload.value
      );
    } else {
      state.sheets[payload.sheet].cell[payload.cell].value = payload.value;
    }
  },

  /**
   * --------------------------------------------------------------
   * Mutation : CALCULATE_DATA_INPUT
   * --------------------------------------------------------------
   * 1. Preparation sheet.cell with convert string to number value
   * 2. Update sheetValue.cell value
   * 3. Verify required sheet.cell before process formula
   * 4. Update navigation with verify required sheet.cell
   * 5. Calculate sheet.cell.formula
   * --------------------------------------------------------------
   * @param {*} state
   */
  CALCULATE_DATA_INPUT(state) {
    console.log("CALCULATE_DATA_INPUT");
    /**
     * variable scope
     * state.sheets.dataInput.cell.C4 <-- state variable
     * state.dataInput.C4 <-- state variable
     *
     * _sheets.dataInput.C4 <-- local variable
     * dataInput.C4 <-- local variable
     *
     */
    // [1] must convert data string to number before process FORMULA
    var _sheets = {};
    _.each(state.sheets, function(sheet, sheet_index) {
      // console.log(sheet_index);
      _sheets[sheet_index] = {};
      _.each(sheet.cell, function(cell, index) {
        if (cell.type == "number") {
          _sheets[sheet_index][index] = {
            type: cell.type,
            value: cell.value != "" ? cell.value : 0,
          };
        } else {
          _sheets[sheet_index][index] = { type: cell.type, value: cell.value };
        }
      });
      // [2] assign value to state.sheet.cell=value
      _.each(_sheets[sheet_index], function(cell, index) {
        state[sheet_index][index] = cell.value;
        // console.log(sheet_index,index,cell.value);
      });
    });

    // [3,4] verify required cell & update navigation value
    _.each(state.sheets, function(sheet, sheet_index) {
      mutations.VALIDATE_REQUIRED_AND_UPDATE_NAVIGATION();
      if (state.sheets[sheet_index] !== undefined) {
        // state.sheets[sheet_index].required_total > 0 &&
        // state.sheets[sheet_index].required_total ==
        //   state.sheets[sheet_index].required_valid
        if (true) {
          // console.log(
          //   sheet_index,
          //   state.sheets[sheet_index].required_valid,
          //   "of",
          //   state.sheets[sheet_index].required_total
          // );
          // [5] calculate sheet.cell.formula
          var dataInput = state.dataInput;
          var balanceSheet = state.balanceSheet;
          var cashFlow = state.cashFlow;
          var financialRatio = state.financialRatio;
          var education = state.education;
          var education1 = state.education1;
          var retirement = state.retirement;
          var retirement1 = state.retirement1;
          var tax = state.tax;
          var health = state.health;
          _.each(state.sheets[sheet_index].cell, function(cell, index) {
            if (cell.formula) {
              if (sheet_index == "health") {
                // console.log(
                //   sheet_index,
                //   index,
                //   cell.formula,
                //   mutations.PARSE_FORMULA(cell.formula)
                // );
              }
              state[sheet_index][index] = state.sheets[sheet_index].cell[
                index
              ].value = mutations.PARSE_FORMULA(cell.formula);
            }
          });
        }
      }
    });
  },

  /**
   * --------------------------------------------------------------
   * Mutation : VALIDATE_REQUIRED_AND_UPDATE_NAVIGATION
   * --------------------------------------------------------------
   * 1. Verify required cell and
   * 2. Update navigation value
   *
   */
  VALIDATE_REQUIRED_AND_UPDATE_NAVIGATION() {
    // 1 verify required cell
    _.each(state.sheets, function(sheet, sheet_index) {
      state.sheets[sheet_index].required_valid = 0;
      _.each(sheet.required, function(required, required_sheet) {
        _.each(required.cell, function(cell) {
          if (sheets[required_sheet].cell[cell].value != "") {
            if (sheets[required_sheet].cell[cell].type == "number") {
              if (sheets[required_sheet].cell[cell].value > 0) {
                state.sheets[sheet_index].required_valid++;
              }
            } else {
              state.sheets[sheet_index].required_valid++;
            }
          }
        });
      });      
      // [2] update navigation value
      if (state.navigation[sheet_index] !== undefined) {
        // console.log(sheet_index,state.sheets[sheet_index].required_valid,state.sheets[sheet_index].required_total);
        if (state.sheets[sheet_index].required_total > 0) {
          state.navigation[sheet_index].disabled = true;
          state.navigation[sheet_index].progress =
            (state.sheets[sheet_index].required_valid /
              state.sheets[sheet_index].required_total) *
            100;
          if (state.navigation[sheet_index].progress == 100) {
            state.navigation[sheet_index].disabled = false;
          }
        }
      }
    });
  },

  /**
   * --------------------------------------------------------------
   * Mutation : PARSE_FORMULA
   * --------------------------------------------------------------
   * Calculate sheet formula
   * --------------------------------------------------------------
   * @param {*} formula
   */
  PARSE_FORMULA(formula) {
    var dataInput = state.dataInput;
    var balanceSheet = state.balanceSheet;
    var cashFlow = state.cashFlow;
    var financialRatio = state.financialRatio;
    var education = state.education;
    var education1 = state.education1;
    var retirement = state.retirement;
    var retirement1 = state.retirement1;
    var tax = state.tax;
    var health = state.health;

    var args, cashflow, period, principal, rate, nper, pmt, fv, type;

    if (formula.indexOf("PV") != -1) {
      /**
       * --------------------------------------------------------------
       * Present Value
       *
       * javascript.PV(rate, periods, payment, [future], [type])
       * excel.PV(rate, nper, pmt, [fv], [type])
       */
      args = formula
        .replace("PV(", "")
        .replace(")", "")
        .split(",");
      rate = eval(args[0]);
      nper = eval(args[1]);
      pmt = eval(args[2]);
      fv = args[3] ? eval(args[3]) : 0;
      type = args[4] ? eval(args[4]) : 0;
      return PV(rate, nper, pmt, fv, type);
    } else if (formula.indexOf("PMT") != -1) {
      /**
       * --------------------------------------------------------------
       * Payment
       *
       * javascript.PMT(rate, nper, pv, [fv], [type]);
       * excel.PMT(rate, nper, pv, [fv], [type])
       */
      args = formula
        .replace("PMT(", "")
        .replace(")", "")
        .split(",");
      rate = eval(args[0]);
      nper = eval(args[1]);
      pmt = eval(args[2]);
      fv = args[3] ? eval(args[3]) : 0;
      type = args[4] ? eval(args[4]) : 0;
      return PMT(rate, nper, pmt, fv, type);
    } else if (formula.indexOf("FV") != -1) {
      /**
       * --------------------------------------------------------------
       * Future Value
       *
       * javascript.FV(rate,nper,pmt,[pv],[type]);
       * excel.FV(rate,nper,pmt,[pv],[type])
       */
      args = formula
        .replace("FV(", "")
        .replace(")", "")
        .split(",");
      rate = eval(args[0]);
      nper = eval(args[1]);
      pmt = eval(args[2]);
      fv = args[3] ? eval(args[3]) : 0;
      type = args[4] ? eval(args[4]) : 0;
      return FV(rate, nper, pmt, fv, type);
    } else {
      return eval(formula);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
