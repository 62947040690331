export const financialRatio = {
  required: {
    dataInput: {
      // cell: ["C4", "K22", "K30", "K31", "K32", "K33", "K3", "K4", "K13", "P18"],
      cell: [
        // === no required ===        
        "YR1",
        "C4",
        "E4",
        //  "K22", "K30", "K32", "K3", "K4", "K13", "P18"
        // === no required ===
      ]
    },
    // balanceSheet: {
    //   cell: ["E13", "I30", "E47", "I32"],
    //   // I30 = "balanceSheet.I13+balanceSheet.I28"
    //   // E13 = "balanceSheet.E6+balanceSheet.E7+balanceSheet.E8+balanceSheet.E9+balanceSheet.E10+balanceSheet.E11"
    //   // E47 = "balanceSheet.E13+balanceSheet.E31+balanceSheet.E41+balanceSheet.E46"
    //   // I32 = "balanceSheet.E47-balanceSheet.I30"
    // },
    // cashFlow: {
    //   cell: ["H25"],
    //   // cell: ["H25", "I11", "I48"],
    //   // H25 = "cashFlow.H25+cashFlow.H38+cashFlow.H48"
    //   // I11 = "cashFlow.I5+cashFlow.I6+cashFlow.I7+cashFlow.I8+cashFlow.I9+cashFlow.I10"
    //   // I48 = "cashFlow.I40+cashFlow.I41+cashFlow.I42+cashFlow.I43+cashFlow.I44+cashFlow.I45+cashFlow.I46+cashFlow.I47"
    // },
  },
  cell: {
    A1: {
      label: "ชื่อ-สกุล",
      value: "",
      type: "text",
      formula: "dataInput.C4",
    },
    // 1.1 การวิเคราะห์สภาพคล่อง - สภาพคล่อง (เท่า)
    D7: {
      label: "สภาพคล่อง/สินทรัพย์ที่มีสภาพคล่อง",
      value: 0,
      type: "number",
      formula: "balanceSheet.E13",
    },
    D8: {
      label: "สภาพคล่อง/หนี้สินระยะสั้น",
      value: 0,
      type: "number",
      formula: "balanceSheet.I13",
    },
    E7: {
      label: "สภาพคล่อง/ผลลัพธ์",
      value: 0,
      type: "number",
      formula: "(financialRatio.D8>0)?financialRatio.D7/financialRatio.D8:0",
    },
    F7: {
      label: "สภาพคล่อง/ผลการวิเคราะห์",
      value: "",
      type: "text",
      formula:
        "(financialRatio.E7>1)?'สภาพคล่องอยู่ในเกณฑ์มาตรฐาน':'สภาพคล่องต่ำกว่าเกณฑ์ ควรเพิ่มสินทรัพย์สภาพคล่อง'",
    },
    G7: {
      label: "result - สภาพคล่อง/ผลการวิเคราะห์",
      value: 0,
      type: "number",
      formula: "(financialRatio.E7>1)?1:0",
    },

    // 1.2 การวิเคราะห์สภาพคล่อง - สภาพคล่องพื้นฐาน (เดือน)
    D10: {
      label: "สภาพคล่องพื้นฐาน/สินทรัพย์ที่มีสภาพคล่อง",
      value: 0,
      type: "number",
      formula: "balanceSheet.E13",
    },
    D11: {
      label: "สภาพคล่องพื้นฐาน/กระแสเงินสดจ่ายต่อเดือน",
      value: 0,
      type: "number",
      formula: "cashFlow.H25+cashFlow.H25",
    },
    E10: {
      label: "สภาพคล่องพื้นฐาน/ผลลัพธ์",
      value: 0,
      type: "number",
      formula: "(financialRatio.D11>0)?financialRatio.D10/financialRatio.D11:0",
    },
    F10: {
      label: "สภาพคล่องพื้นฐาน/ผลการวิเคราะห์",
      value: "",
      type: "text",
      formula:
        "(financialRatio.E10>3)?'อัตราส่วนสภาพคล่องพื้นฐาน':'ต่ำกว่าเกณฑ์มาตรฐาน ควรปรับปรุง'",
    },
    G10: {
      label: "result - สภาพคล่องพื้นฐาน/ผลการวิเคราะห์",
      value: 0,
      type: "number",
      formula: "(financialRatio.E10>3)?1:0",
    },

    // 2. การวิเคราะห์ภาระหนี้สิน - หนี้สินต่อสินทรัพย์ (%)
    D14: {
      label: "หนี้สินต่อสินทรัพย์/หนี้สินรวม",
      value: 0,
      type: "number",
      formula: "balanceSheet.I30",
    },
    D15: {
      label: "หนี้สินต่อสินทรัพย์/สินทรัพย์รวม",
      value: 0,
      type: "number",
      formula: "balanceSheet.E47",
    },
    E14: {
      label: "หนี้สินต่อสินทรัพย์/ผลลัพธ์",
      value: 0,
      type: "number",
      formula: "(financialRatio.D14/financialRatio.D15)*100",
    },
    F14: {
      label: "หนี้สินต่อสินทรัพย์/ผลการวิเคราะห์",
      value: "",
      type: "text",
      formula:
        "(financialRatio.E14<50)?'อัตราส่วนหนี้สินต่อสินทรัพย์ อยู่ในเกณฑ์มาตรฐาน':'อัตราส่วนหนี้สินต่อสินทรัพย์ ไม่อยู่ในเกณฑ์'",
    },
    G14: {
      label: "result - หนี้สินต่อสินทรัพย์/ผลการวิเคราะห์",
      value: 0,
      type: "number",
      formula: "(financialRatio.E14<50)?1:0",
    },
    // 3.1 การวิเคราะห์การออมการลงทุน การออม (%)
    D18: {
      label: "การออม/เงินออม",
      value: 0,
      type: "number",
      formula: "cashFlow.I48",
    },
    D19: {
      label: "การออม/รายได้รวม",
      value: 0,
      type: "number",
      formula: "cashFlow.I11",
    },
    E18: {
      label: "การออม/ผลลัพธ์",
      value: 0,
      type: "number",
      formula: "financialRatio.D18/financialRatio.D19*100",
    },
    F18: {
      label: "การออม/ผลการวิเคราะห์",
      value: "",
      type: "text",
      formula:
        "(financialRatio.E18>10)?'อัตราส่วนการออม อยู่ในเกณฑ์ มาตรฐาน':'อัตราส่วนการออม ต่ำกว่าเกณฑ์ ควรเพิ่ม'",
    },
    G18: {
      label: "result - การออม/ผลการวิเคราะห์",
      value: 0,
      type: "number",
      formula: "(financialRatio.E18>10)?1:0",
    },
    // 3.2 การวิเคราะห์การออมการลงทุน การลงทุน (%)
    D21: {
      label: "การลงทุน/สินทรัพย์ลงทุน",
      value: 0,
      type: "number",
      formula: "cashFlow.I48",
    },
    D22: {
      label: "การลงทุน/ความมั่งคั่งสุทธิ",
      value: 0,
      type: "number",
      formula: "balanceSheet.I32",
    },
    E21: {
      label: "การลงทุน/ผลลัพธ์",
      value: 0,
      type: "number",
      formula: "financialRatio.D21/financialRatio.D22*100",
    },
    F21: {
      label: "การลงทุน/ผลการวิเคราะห์",
      value: "",
      type: "text",
      formula:
        "(financialRatio.E21>50)?'อัตราส่วนการลงทุน อยู่ในเกณฑ์ดี':'อัตราส่วนการลงทุน น้อยเกินไป ควรเพิ่ม'",
    },
    G21: {
      label: "การลงทุน/ผลการวิเคราะห์",
      value: 0,
      type: "number",
      formula: "(financialRatio.E21>50)?1:0",
    },
  },
};
