/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import _ from "lodash";
import Vue from "vue";

const state = {
  userLists: [],
  userItem: {},
  isLoading: false,
};
const getters = {
  userLists: (state) => {
    return state.userLists;
  },
  userItem: (state) => {
    return state.userItem;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
};
const actions = {
  getUserLists(context, payload) {
    context.commit("USER_LISTS");
  },
  userAdd(context, payload) {
    context.commit("USER_ADD", payload);
  },
};

const mutations = {
  USER_LISTS(state) {
    console.log("store.user USER_LISTS");
    state.isLoading = true;
    firebase
      .database()
      .ref("/users")
      .on("value", (snapshot) => {
        state.userLists = _.chain(snapshot.val())
          .map(function(item, index) {
            item._id = index;
            return item;
          })
          .value();
        state.isLoading = false;
      });
  },
  USER_ADD(state, user) {
    console.log("store.user USER_ADD");
    const firebaseAuthcreateUser = firebase
      .functions()
      .httpsCallable("firebaseAuthcreateUser");
    const newUser = {
      email: this.user.email,
      password: this.user.password,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      displayName: this.user.firstname + " " + this.user.lastname,
      phoneNumber: this.user.phoneNumber,
      photoURL: this.user.photoURL,
      role: this.user.role,
    };
    try {
      const result = firebaseAuthcreateUser(newUser);
      if (result.data.status == 1) {
        this.close_user_form_dialog();
      } else if (result.data.status == 0) {
        if (result.data.error.errorInfo.message) {
          Vue.notify({
            group: "auth",
            type: "error",
            title: "Important message",
            text: result.data.error.errorInfo.message,
          });
        }
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  },
  USER_UPDATE(state, user) {
    console.log("store.user USER_UPDATE");
  },
  USER_DELETE(state, user) {
    console.log("store.user USER_DELETE");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
