/* eslint-disable no-redeclare */

/**
 * pies/ExcelFormulas.js
 * https://gist.github.com/pies/4166888
 *
 * @param {*} rate
 * @param {*} nper
 * @param {*} pv
 * @param {*} fv
 * @param {*} type
 */
export function PMT(rate, nper, pv, fv, type) {
  rate = rate / 100;
  if (!fv) fv = 0;
  if (!type) type = 0;
  if (rate == 0) return -(pv + fv) / nper;
  var pvif = Math.pow(1 + rate, nper);
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);
  if (type == 1) {
    pmt /= 1 + rate;
  }
  return pmt;
}

/**
 * ghalimi/PV.js
 * https://gist.github.com/ghalimi/4638848
 *
 * @param {*} rate
 * @param {*} periods
 * @param {*} payment
 * @param {*} future
 * @param {*} type
 */
export function PV(rate, periods, payment, future, type) {
  rate = rate / 100;
  // Initialize type
  var type = typeof type === "undefined" ? 0 : type;

  // Evaluate rate and periods (TODO: replace with secure expression evaluator)
  rate = eval(rate);
  periods = eval(periods);

  // Return present value
  if (rate === 0) {
    return -payment * periods - future;
  } else {
    return (
      (((1 - Math.pow(1 + rate, periods)) / rate) *
        payment *
        (1 + rate * type) -
        future) /
      Math.pow(1 + rate, periods)
    );
  }
}

/**
 * lancevo/FV.js
 * https://gist.github.com/lancevo/6010111
 *
 * @param {*} rate
 * @param {*} nper
 * @param {*} pmt
 * @param {*} pv
 * @param {*} type
 */
export function FV(rate, nper, pmt, pv, type) {
  rate = rate / 100;
  var pow = Math.pow(1 + rate, nper),
    fv;

  pv = pv || 0;
  type = type || 0;

  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv;
}

/**
 * maarten00/pmt.js
 * https://gist.github.com/maarten00/23400873d51bf2ec4eeb
 *
 * @param {*} rate_per_period
 * @param {*} number_of_payments
 * @param {*} present_value
 * @param {*} future_value
 * @param {*} type
 */
export function pmt(
  rate_per_period,
  number_of_payments,
  present_value,
  future_value,
  type
) {
  rate_per_period = rate_per_period / 100;
  future_value = typeof future_value !== "undefined" ? future_value : 0;
  type = typeof type !== "undefined" ? type : 0;
  if (rate_per_period != 0.0) {
    // Interest rate exists
    var q = Math.pow(1 + rate_per_period, number_of_payments);
    return (
      -(rate_per_period * (future_value + q * present_value)) /
      ((-1 + q) * (1 + rate_per_period * type))
    );
  } else if (number_of_payments != 0.0) {
    // No interest rate, but number of payments exists
    return -(future_value + present_value) / number_of_payments;
  }
  return 0;
}
