// Doughnut Chart
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */

import { Doughnut } from "vue-chartjs";
import { ChartConfig } from "@/constants/chart-config.js";
import { template } from "lodash";
import numeral from "numeral";

export default {
  name: "doughnut-chart",
  extends: Doughnut,
  props: {
    chartOptions: Object,
    chartDataSet: Object,
    width: {
      default: 365,
      type: Number
    },
    height: {
      default: 365,
      type: Number
    },
  },
  data() {
    return {
      ChartConfig,
      options: {
        legend: {
          display: false,
        },        
        cutoutPercentage: 68,
        padding: 0,
        maintainAspectRatio: false,
        tooltips: {
         backgroundColor: "#000000",        
          callbacks: {
            label: function(tooltipItem, data) {
              var _index = tooltipItem.index;
              var _value = data.datasets[0].data[_index];
              var _label = data.labels[_index];
              var _total = data.datasets[0].data.reduce((a, b) => a + b, 0);
              var _percentage = numeral((_value / _total) * 100).format(
                "0,0.00"
              );
              return (
                _label +
                " " +
                numeral(_value).format("0,0") +
                " | " +
                _percentage +
                "%"
              );
            },
          },
        },
      },
    };
  },
  methods: {
    getDataset() {
      // merge options
      this.options = { ...this.options, ...this.chartOptions };
      const dataset = this.chartDataSet;      
      this.renderChart(
        {
          labels: dataset.labels,
          datasets: [
            {
              data: dataset.data,
              backgroundColor: dataset.backgroundColor,              
            },
          ],          
        },
        this.options
      );
    },
  },
  mounted() {
    this.getDataset();
  },
  watch: {
    chartDataSet: function() {
      this.getDataset();
    },
  },
};
