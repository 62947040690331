import Demo from "@/views/layouts/Demo.vue";
import YouTube from "@/views/layouts/YouTube.vue";
import BaseLine from "@/views/layouts/BaseLine.vue";
import GoogleContact from "@/views/layouts/GoogleContact.vue";
import GoogleKeep from "@/views/layouts/GoogleKeep.vue";
import Dark from "@/views/layouts/Dark.vue";
import Centered from "@/views/layouts/Centered";

export default {
  path: "/demo",
  component: Demo,

  children: [
    {
      path: "/demo/youtube",
      component: YouTube
    },
    {
      path: "/demo/baseline",
      component: BaseLine
    },
    {
      path: "/demo/googlecontact",
      component: GoogleContact
    },
    {
      path: "/demo/googlekeep",
      component: GoogleKeep
    },
    {
      path: "/demo/dark",
      component: Dark
    },
    {
      path: "/demo/centered",
      component: Centered
    }
  ]
};
