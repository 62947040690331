export const education = {
  required: {
    dataInput: {
      cell: [
        // === no required ===
        // "C4",
        // "A80",
        // "A81",
        // "A82",
        // "A83",
        // "A84",
        // "A85",
        "YR1",
        "C4",
        "E4",
        "C8",
        // "E8",
        // "M50",
        // "M51",
        // "K53",
        // "M53"
        // === no required ===
      ]
    }
  },
  cell: {
    M2:{
      label:"ชื่อ",
      value:"",
      type:"text",
      formula:"dataInput.C8"
    },
    C6: {
      label: "ปีปัจจุบัน ปี",
      // value: new Date().getFullYear() + 543,
      value: 0,
      type: "number",
      formula: "dataInput.YR1"
    },
    C7: {
      label: "อายุปัจจุบัน",
      value: 0,
      type: "number",
      formula: "dataInput.E8"
    },
    M7: {
      label: "อายุเริ่ม ป.ตรี",
      value: 18,
      type: "number"
    },
    G8: {
      label: "จำนวนปีสะสมเงิน",
      value: 0,
      type: "number",
      formula: "education.M7-education.C7"
    },
    // ปีเริ่ม ป.ตรี -  ป.โท
    M6: {
      label: "ปีเริ่ม ป.ตรี",
      value: 0,
      type: "number",
      formula: "education.C6+education.G8"
    },
    O6: {
      label: "ปี ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      formula: "education.M6+1"
    },
    O7: {
      label: "อายุ ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      formula: "education.M7+1"
    },
    S6: {
      label: "ปี ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education.M6+2"
    },
    S7: {
      label: "อายุ ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education.M7+2"
    },
    V6: {
      label: "ปี ป.ตรี ปีที่4",
      value: 0,
      type: "number",
      formula: "education.M6+3"
    },
    V7: {
      label: "อายุ ป.ตรี ปีที่4",
      value: 0,
      type: "number",
      formula: "education.M7+3"
    },
    Z6: {
      label: "ปีเริ่ม ป.โท",
      value: 0,
      type: "number",
      formula: "education.V6+1"
    },
    Z7: {
      label: "อายุเริ่ม ป.โท",
      value: 0,
      type: "number",
      formula: "education.V7+1"
    },
    AB6: {
      label: "ปี ป.โท ปีที่2",
      value: 0,
      type: "number",
      formula: "education.V6+2"
    },
    AB7: {
      label: "อายุ ป.โท ปีที่2",
      value: 0,
      type: "number",
      formula: "education.V7+2"
    },
    G13: {
      label: "คชจ. ปี.ตรี/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.M50"
    },
    G15: {
      label: "คชจ. ปี.โท/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.M51"
    },
    G17: {
      label: "คชจ. ปี.เอก/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.M52"
    },

    L13: {
      label: "คชจ. ปี.ตรี/ปี",
      value: 0,
      type: "number",
      formula: "dataInput.M50*12"
    },
    L15: {
      label: "คชจ. ปี.โท/ปี",
      value: 0,
      type: "number",
      formula: "dataInput.M51*12"
    },
    L17: {
      label: "คชจ. ปี.เอก/ปี",
      value: 0,
      type: "number",
      formula: "dataInput.M52*12"
    },
    M19: {
      label: "อัตราเงินเฟ้อการศึกษา (%)",
      value: 0,
      type: "number",
      suffix: "%",
      formula: "dataInput.A80"
    },
    X12: {
      label: "ปีสะสม ป.ตรี ปีที่1",
      value: 0,
      type: "number",
      formula: "education.G8"
    },
    X13: {
      label: "ปีสะสม ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      formula: "education.G8+1"
    },
    X14: {
      label: "ปีสะสม ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education.G8+2"
    },
    X15: {
      label: "ปีสะสม ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education.G8+3"
    },
    AB12: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่1",
      value: 0,
      type: "number",
      // FV(M19,X12,,-L13)
      formula:"FV(education.M19,education.X12,0,-education.L13,0)"      
    },
    AB13: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      // =FV(M19,X13,,-L13)	
      formula:"FV(education.M19,education.X13,0,-education.L13,0)"   
    },
    AB14: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      // =FV(M19,X14,,-L13)
      formula:"FV(education.M19,education.X14,0,-education.L13,0)"   
    },
    AB15: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่4",
      value: 0,
      type: "number",
      // =FV(M19,X15,,-L13)
      formula:"FV(education.M19,education.X15,0,-education.L13,0)"   
    },
    AB17: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.ตรี",
      value: 0,
      type: "number",
      formula: "education.AB12+education.AB13+education.AB14+education.AB15"
    },
    X22: {
      label: "ปีสะสม ป.โท ปีที่1",
      value: 0,
      type: "number",
      formula: "education.X15+1"
    },
    X23: {
      label: "ปีสะสม ป.โท ปีที่2",
      value: 0,
      type: "number",
      formula: "education.X15+2"
    },
    AB22: {
      label: "ค่าใช้จ่ายในอนาคต ป.โท ปีที่1",
      value: 0,
      type: "number",
      // =FV(M19,X22,,-L15)	
      formula: "FV(education.M19,education.X22,0,-education.L15,0)"
    },
    AB23: {
      label: "ค่าใช้จ่ายในอนาคต ป.โท ปีที่2",
      value: 0,
      type: "number",
      // =FV(M19,X23,,-L15)	
      formula: "FV(education.M19,education.X23,0,-education.L15,0)"
    },
    AB25: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.โท",
      value: 0,
      type: "number",
      formula: "education.AB22+education.AB23"
    },
    X29: {
      label: "ปีสะสม ป.เอก ปีที่1",
      value: 0,
      type: "number",
      formula: "education.X23+1"
    },
    X30: {
      label: "ปีสะสม ป.เอก ปีที่2",
      value: 0,
      type: "number",
      formula: "education.X23+2"
    },
    X31: {
      label: "ปีสะสม ป.เอก ปีที่3",
      value: 0,
      type: "number",
      formula: "education.X23+3"
    },
    AB29: {
      label: "ค่าใช้จ่ายในอนาคต ป.เอก ปีที่1",
      value: 0,
      type: "number",
      // =FV(M19,X29,,-L17)	
      formula: "FV(education.M19,education.X29,0,-education.L17,0)"
    },
    AB30: {
      label: "ค่าใช้จ่ายในอนาคต ป.เอก ปีที่2",
      value: 0,
      type: "number",
      // =FV(M19,X30,,-L17)	
      formula: "FV(education.M19,education.X30,0,-education.L17,0)"
    },
    AB31: {
      label: "ค่าใช้จ่ายในอนาคต ป.เอก ปีที่3",
      value: 0,
      type: "number",
      // =FV(M19,X31,,-L17)	
      formula: "FV(education.M19,education.X31,0,-education.L17,0)"
    },
    AB33: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.เอก",
      value: 0,
      type: "number",
      formula: "education.AB29+education.AB30+education.AB31"
    },
    AB35: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.ตรี ป.โท ป.เอก",
      value: 0,
      type: "number",
      formula: "education.AB17+education.AB25+education.AB33"
    },
    AB37: {
      label: "ส่วนที่เตรียมไว้แล้ว",
      value: 0,
      type: "number",
      // =FV('DATA - Table 1'!K53,C22,0,-'DATA - Table 1'!M53,1)      
      formula:"FV(dataInput.K53,education.G8,0,-dataInput.M53,1)"
    },
    AB40: {
      label: "ส่วนที่ต้องเตรียมเพิ่มทั้งหมด",
      value: 0,
      type: "number",
      formula: "Math.abs(education.AB37-education.AB35)"
    },

    M25: {
      label: "A.เตรียมเงินก้อน ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A81"
    },
    O25: {
      label: "A.เตรียมเงินก้อน ...",
      value: 0,
      type: "number",
      // =PV(M25,G8,,M22,1)		
      formula: "PV(education.M25,education.G8,0,-education.AB40,1)"
    },
    M27: {
      label: "B. ฝากรายงวดธนาคาร ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A82"
    },
    O27: {
      label: "B. ฝากรายงวดธนาคาร ...",
      value: 0,
      type: "number",
      // =PMT(M27,G8,0,M22,1)
      formula: "PMT(education.M27,education.G8,0,-education.AB40,1)"
    },
    M30: {
      label: "C. ซื้อพันธบัตร ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A83"
    },
    O30: {
      label: "C. ซื้อพันธบัตร ...",
      value: 0,
      type: "number",
      // =PMT(M30,G8,0,M22,1)
      formula: "PMT(education.M30,education.G8,0,-education.AB40,1)"
    },
    M32: {
      label: "D. ลงทุนในหุ้น ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A84"
    },
    O32: {
      label: "D. ลงทุนในหุ้น ...",
      value: 0,
      type: "number",
      // =PMT(M32,G8,0,M22,1)
      formula: "PMT(education.M32,education.G8,0,-education.AB40,1)"
    },
    M35: {
      label: "E. Investment Link... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A85"
    },
    O35: {
      label: "E. Investment Link ...",
      value: 0,
      type: "number",
      // =PMT(M35,G8,0,M22,1)
      formula: "PMT(education.M35,education.G8,0,-education.AB40,1)"
    },
    A810:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A810"      
    },
    A820:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A820"      
    },
    A830:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A830"      
    },
    A840:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A840"      
    },
    A850:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A850"      
    },
  }
};

export const education1 = {
  required: {
    dataInput: {
      cell: [
        // "C4",
        "YR1",
        "C4",
        "E4",
        "C9",
        // === no required ===
        // "E9",
        // "M54",
        // "M55",        
        // "K57",
        // "M57"
        // === no required ===
      ]
    }
  },
  cell: {
    M2:{
      label:"ชื่อ",
      value:"",
      type:"text",
      formula:"dataInput.C9"
    },
    C6: {
      label: "ปีปัจจุบัน ปี",
      // value: new Date().getFullYear() + 543,
      value: 0,
      type: "number",
      formula: "dataInput.YR1"
    },
    C7: {
      label: "อายุปัจจุบัน",
      value: 0,
      type: "number",
      formula: "dataInput.E9"
    },
    M7: {
      label: "อายุเริ่ม ป.ตรี",
      value: 18,
      type: "number"
    },
    G8: {
      label: "จำนวนปีสะสมเงิน",
      value: 0,
      type: "number",
      formula: "education1.M7-education1.C7"
    },
    // ปีเริ่ม ป.ตรี -  ป.โท
    M6: {
      label: "ปีเริ่ม ป.ตรี",
      value: 0,
      type: "number",
      formula: "education1.C6+education1.G8"
    },
    O6: {
      label: "ปี ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      formula: "education1.M6+1"
    },
    O7: {
      label: "อายุ ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      formula: "education1.M7+1"
    },
    S6: {
      label: "ปี ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education1.M6+2"
    },
    S7: {
      label: "อายุ ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education1.M7+2"
    },
    V6: {
      label: "ปี ป.ตรี ปีที่4",
      value: 0,
      type: "number",
      formula: "education1.M6+3"
    },
    V7: {
      label: "อายุ ป.ตรี ปีที่4",
      value: 0,
      type: "number",
      formula: "education1.M7+3"
    },
    Z6: {
      label: "ปีเริ่ม ป.โท",
      value: 0,
      type: "number",
      formula: "education1.V6+1"
    },
    Z7: {
      label: "อายุเริ่ม ป.โท",
      value: 0,
      type: "number",
      formula: "education1.V7+1"
    },
    AB6: {
      label: "ปี ป.โท ปีที่2",
      value: 0,
      type: "number",
      formula: "education1.V6+2"
    },
    AB7: {
      label: "อายุ ป.โท ปีที่2",
      value: 0,
      type: "number",
      formula: "education1.V7+2"
    },
    G13: {
      label: "คชจ. ปี.ตรี/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.M54"
    },
    G15: {
      label: "คชจ. ปี.โท/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.M55"
    },
    G17: {
      label: "คชจ. ปี.เอก/เดือน",
      value: 0,
      type: "number",
      formula: "dataInput.M56"
    },

    L13: {
      label: "คชจ. ปี.ตรี/ปี",
      value: 0,
      type: "number",
      formula: "dataInput.M54*12"
    },
    L15: {
      label: "คชจ. ปี.โท/ปี",
      value: 0,
      type: "number",
      formula: "dataInput.M55*12"
    },
    L17: {
      label: "คชจ. ปี.เอก/ปี",
      value: 0,
      type: "number",
      formula: "dataInput.M56*12"
    },
    M19: {
      label: "อัตราเงินเฟ้อการศึกษา (%)",
      value: 0,
      type: "number",
      suffix: "%",
      formula: "dataInput.A80"
    },
    X12: {
      label: "ปีสะสม ป.ตรี ปีที่1",
      value: 0,
      type: "number",
      formula: "education1.G8"
    },
    X13: {
      label: "ปีสะสม ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      formula: "education1.G8+1"
    },
    X14: {
      label: "ปีสะสม ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education1.G8+2"
    },
    X15: {
      label: "ปีสะสม ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      formula: "education1.G8+3"
    },
    AB12: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่1",
      value: 0,
      type: "number",
      // FV(M19,X12,,-L13)
      formula:"FV(education1.M19,education1.X12,0,-education1.L13,0)"      
    },
    AB13: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่2",
      value: 0,
      type: "number",
      // =FV(M19,X13,,-L13)	
      formula:"FV(education1.M19,education1.X13,0,-education1.L13,0)"   
    },
    AB14: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่3",
      value: 0,
      type: "number",
      // =FV(M19,X14,,-L13)
      formula:"FV(education1.M19,education1.X14,0,-education1.L13,0)"   
    },
    AB15: {
      label: "ค่าใช้จ่ายในอนาคต ป.ตรี ปีที่4",
      value: 0,
      type: "number",
      // =FV(M19,X15,,-L13)
      formula:"FV(education1.M19,education1.X15,0,-education1.L13,0)"   
    },
    AB17: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.ตรี",
      value: 0,
      type: "number",
      formula: "education1.AB12+education1.AB13+education1.AB14+education1.AB15"
    },
    X22: {
      label: "ปีสะสม ป.โท ปีที่1",
      value: 0,
      type: "number",
      formula: "education1.X15+1"
    },
    X23: {
      label: "ปีสะสม ป.โท ปีที่2",
      value: 0,
      type: "number",
      formula: "education1.X15+2"
    },
    AB22: {
      label: "ค่าใช้จ่ายในอนาคต ป.โท ปีที่1",
      value: 0,
      type: "number",
      // =FV(M19,X22,,-L15)	
      formula: "FV(education1.M19,education1.X22,0,-education1.L15,0)"
    },
    AB23: {
      label: "ค่าใช้จ่ายในอนาคต ป.โท ปีที่2",
      value: 0,
      type: "number",
      // =FV(M19,X23,,-L15)	
      formula: "FV(education1.M19,education1.X23,0,-education1.L15,0)"
    },
    AB25: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.โท",
      value: 0,
      type: "number",
      formula: "education1.AB22+education1.AB23"
    },
    X29: {
      label: "ปีสะสม ป.เอก ปีที่1",
      value: 0,
      type: "number",
      formula: "education1.X23+1"
    },
    X30: {
      label: "ปีสะสม ป.เอก ปีที่2",
      value: 0,
      type: "number",
      formula: "education1.X23+2"
    },
    X31: {
      label: "ปีสะสม ป.เอก ปีที่3",
      value: 0,
      type: "number",
      formula: "education1.X23+3"
    },
    AB29: {
      label: "ค่าใช้จ่ายในอนาคต ป.เอก ปีที่1",
      value: 0,
      type: "number",
      // =FV(M19,X29,,-L17)	
      formula: "FV(education1.M19,education1.X29,0,-education1.L17,0)"
    },
    AB30: {
      label: "ค่าใช้จ่ายในอนาคต ป.เอก ปีที่2",
      value: 0,
      type: "number",
      // =FV(M19,X30,,-L17)	
      formula: "FV(education1.M19,education1.X30,0,-education1.L17,0)"
    },
    AB31: {
      label: "ค่าใช้จ่ายในอนาคต ป.เอก ปีที่3",
      value: 0,
      type: "number",
      // =FV(M19,X31,,-L17)	
      formula: "FV(education1.M19,education1.X31,0,-education1.L17,0)"
    },
    AB33: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.เอก",
      value: 0,
      type: "number",
      formula: "education1.AB29+education1.AB30+education1.AB31"
    },
    AB35: {
      label: "รวมค่าใช้จ่ายในอนาคต ป.ตรี ป.โท ป.เอก",
      value: 0,
      type: "number",
      formula: "education1.AB17+education1.AB25+education1.AB33"
    },
    AB37: {
      label: "ส่วนที่เตรียมไว้แล้ว",
      value: 0,
      type: "number",
      // =FV('DATA - Table 1'!K53,C22,0,-'DATA - Table 1'!M53,1)      
      formula:"FV(dataInput.K53,education1.G8,0,-dataInput.M53,1)"
    },
    AB40: {
      label: "ส่วนที่ต้องเตรียมเพิ่มทั้งหมด",
      value: 0,
      type: "number",
      formula: "Math.abs(education1.AB37-education1.AB35)"
    },

    M25: {
      label: "A.เตรียมเงินก้อน ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A81"
    },
    O25: {
      label: "A.เตรียมเงินก้อน ...",
      value: 0,
      type: "number",
      // =PV(M25,G8,,M22,1)		
      formula: "PV(education1.M25,education1.G8,0,-education1.AB40,1)"
    },
    M27: {
      label: "B. ฝากรายงวดธนาคาร ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A82"
    },
    O27: {
      label: "B. ฝากรายงวดธนาคาร ...",
      value: 0,
      type: "number",
      // =PMT(M27,G8,0,M22,1)
      formula: "PMT(education1.M27,education1.G8,0,-education1.AB40,1)"
    },
    M30: {
      label: "C. ซื้อพันธบัตร ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A83"
    },
    O30: {
      label: "C. ซื้อพันธบัตร ...",
      value: 0,
      type: "number",
      // =PMT(M30,G8,0,M22,1)
      formula: "PMT(education1.M30,education1.G8,0,-education1.AB40,1)"
    },
    M32: {
      label: "D. ลงทุนในหุ้น ... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A84"
    },
    O32: {
      label: "D. ลงทุนในหุ้น ...",
      value: 0,
      type: "number",
      // =PMT(M32,G8,0,M22,1)
      formula: "PMT(education1.M32,education1.G8,0,-education1.AB40,1)"
    },
    M35: {
      label: "E. Investment Link... (%)",
      value: 0,
      type: "number",
      formula: "dataInput.A85"
    },
    O35: {
      label: "E. Investment Link ...",
      value: 0,
      type: "number",
      // =PMT(M35,G8,0,M22,1)
      formula: "PMT(education1.M35,education1.G8,0,-education1.AB40,1)"
    },
    A810:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A810"      
    },
    A820:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A820"      
    },
    A830:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A830"      
    },
    A840:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A840"      
    },
    A850:{      
      label: "อื่นๆ",
      value: "",
      type: "text",
      formula: "dataInput.A850"      
    },
  }
};