<template>
  <v-container class="fluid pa-0 wealthplus-container" id="dataInput" style="width:96%!important;">
    <!--
    <v-row>
      <v-card>
        <v-tabs center-active>
          <v-tab>ข้อมูลสมาชิกในครอบครัว</v-tab>
          <v-tab>ข้อมูลทรัพย์สิน หนี้สิน</v-tab>
          <v-tab>ข้อมูลรายรับ รายจ่าย</v-tab>
          <v-tab>ข้อมูลวางแผนการศึกษา</v-tab>
          <v-tab>ข้อมูลวางแผนการเกษียณ</v-tab>
          <v-tab>ข้อมูลภาษีรายได้</v-tab>
          <v-tab>ข้อมูลการคุ้มครองสุขภาพและรายได้</v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-for="(item,index) in dataInputSheet.form.family.cell"
                      v-bind:key="index"
                    >
                      <v-text-field
                        :dense="form_prop.dense"
                        :type="item.type"
                        :label="`${index} ${item.label}`"
                        :placeholder="item.placeholder?item.placeholder:''"
                        :hint="item.hint?item.hint:''"
                        :suffix="item.suffix?item.suffix:''"
                        :value="dataInputSheet.cell[index].value"
                        :validate-on-blur="true"
                        :name="`dataInput.cell.${index}`"
                        :rules="item.rules?item.rules:[]"
                        @change="onDataInputChange(`dataInput.cell.${index}`,$event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-for="(item,index) in dataInputSheet.form.assets_and_liabilities.cell"
                      v-bind:key="index"
                    >
                      <v-text-field
                        :dense="form_prop.dense"
                        :type="item.type"
                        :label="`${index} ${item.label}`"
                        :placeholder="item.placeholder?item.placeholder:''"
                        :hint="item.hint?item.hint:''"
                        :suffix="item.suffix?item.suffix:''"
                        :value="dataInputSheet.cell[index].value"
                        :validate-on-blur="true"
                        :name="`dataInput.cell.${index}`"
                        :rules="item.rules?item.rules:[]"
                        @change="onDataInputChange(`dataInput.cell.${index}`,$event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-for="(item,index) in dataInputSheet.form.income_and_expense.cell"
                      v-bind:key="index"
                    >
                      <v-text-field
                        :dense="form_prop.dense"
                        :type="item.type"
                        :label="`${index} ${item.label}`"
                        :placeholder="item.placeholder?item.placeholder:''"
                        :hint="item.hint?item.hint:''"
                        :suffix="item.suffix?item.suffix:''"
                        :value="dataInputSheet.cell[index].value"
                        :validate-on-blur="true"
                        :name="`dataInput.cell.${index}`"
                        :rules="item.rules?item.rules:[]"
                        @change="onDataInputChange(`dataInput.cell.${index}`,$event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-for="(item,index) in dataInputSheet.form.education.cell"
                      v-bind:key="index"
                    >
                      <v-text-field
                        :dense="form_prop.dense"
                        :type="item.type"
                        :label="`${index} ${item.label}`"
                        :placeholder="item.placeholder?item.placeholder:''"
                        :hint="item.hint?item.hint:''"
                        :suffix="item.suffix?item.suffix:''"
                        :value="dataInputSheet.cell[index].value"
                        :validate-on-blur="true"
                        :name="`dataInput.cell.${index}`"
                        :rules="item.rules?item.rules:[]"
                        @change="onDataInputChange(`dataInput.cell.${index}`,$event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-for="(item,index) in dataInputSheet.form.retirement.cell"
                      v-bind:key="index"
                    >
                      <v-text-field
                        :dense="form_prop.dense"
                        :type="item.type"
                        :label="`${index} ${item.label}`"
                        :placeholder="item.placeholder?item.placeholder:''"
                        :hint="item.hint?item.hint:''"
                        :suffix="item.suffix?item.suffix:''"
                        :value="dataInputSheet.cell[index].value"
                        :validate-on-blur="true"
                        :name="`dataInput.cell.${index}`"
                        :rules="item.rules?item.rules:[]"
                        @change="onDataInputChange(`dataInput.cell.${index}`,$event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-for="(item,index) in dataInputSheet.form.tax.cell"
                      v-bind:key="index"
                    >
                      <v-text-field
                        :dense="form_prop.dense"
                        :type="item.type"
                        :label="`${index} ${item.label}`"
                        :placeholder="item.placeholder?item.placeholder:''"
                        :hint="item.hint?item.hint:''"
                        :suffix="item.suffix?item.suffix:''"
                        :value="dataInputSheet.cell[index].value"
                        :validate-on-blur="true"
                        :name="`dataInput.cell.${index}`"
                        :rules="item.rules?item.rules:[]"
                        @change="onDataInputChange(`dataInput.cell.${index}`,$event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      v-for="(item,index) in dataInputSheet.form.health.cell"
                      v-bind:key="index"
                    >
                      <v-text-field
                        :dense="form_prop.dense"
                        :type="item.type"
                        :label="`${index} ${item.label}`"
                        :placeholder="item.placeholder?item.placeholder:''"
                        :hint="item.hint?item.hint:''"
                        :suffix="item.suffix?item.suffix:''"
                        :value="dataInputSheet.cell[index].value"
                        :validate-on-blur="true"
                        :name="`dataInput.cell.${index}`"
                        :rules="item.rules?item.rules:[]"
                        @change="onDataInputChange(`dataInput.cell.${index}`,$event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-row>
    -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- เตรียมไว้สำหรับ แสดงผลข้อมูลตัวเลขที่เป็นทศนิยม และขั่นเครื่องหมายหลักพัน
      <v-row>
        <v-col cols="12">
          <p>{{birthdate}}</p>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            :dense="form_prop.dense"
            type="text"
            label="วันเกิด"
            placeholder="วว/ดด/ปปป"
            v-mask="'##/##/####'"
            v-model="birthdate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <the-mask
            mask="##/##/####"
            value="21/03/1983"
            type="text"
            masked="true"
            placeholder="test your mask here"
          ></the-mask>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <vuetify-money
            v-model="value"
            label="เงินสด"
            v-bind:dense="form_prop.dense"
            v-bind:outlined="false"
            v-bind:options="options"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <VuetifyMoneyTH
            v-model="value"            
            v-bind:options="options"
            label="เงินสด"
            suffix="บาท"
            disabled="true"
          ></VuetifyMoneyTH>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <vuetify-money-th
            v-model="value"            
            v-bind:options="options"
            label="เงินสด"
            suffix="บาท"
          ></vuetify-money-th>
        </v-col>
      </v-row>
      -->
      <v-row class="editable">
        <v-col cols="12">
          <ul>
            <li>
              <v-btn small color="primary" @click="saveChanges()">
                <v-icon small>mdi-content-save</v-icon>Save Changes
              </v-btn>
            </li>
          </ul>
          <!-- <v-btn small dark  :disabled="!valid" color="success" @click="validate">Validate</v-btn>
          <v-btn small dark color="error" @click="reset">Reset Form</v-btn>
          <v-btn small dark color="warning" @click="resetValidation">Reset Validation</v-btn>-->
        </v-col>
      </v-row>
      <!-- <div style="height: 30px"></div> -->
      <v-row
        v-for="(sheet, sheet_index) in dataInputSheet.form"
        v-bind:key="sheet_index"
        v-bind:class="'sheet-' + sheet_index"
      >
        <v-col cols="12" class="sheet-heading">
          <h3>{{ sheet.heading }}</h3>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          :md="sheet_index == 'tax' ? 6 : 6"
          v-for="(item, index) in sheet.cell"
          v-bind:key="index"
          v-show="item.show!==undefined ? item.show : true"  
        >
        <!-- :label="`${index} - ${item.label}`" -->
          <v-text-field          
            :ref="`dataInput.cell.${index}`"
            :dense="form_prop.dense"
            :type="item.type"
            :label="`${item.label}`"
            :placeholder="item.placeholder ? item.placeholder : ''"
            :hint="item.hint ? item.hint : ''"
            :suffix="item.suffix ? item.suffix : ''"
            :value="
              dataInputSheet.cell[index] ? dataInputSheet.cell[index].value : ''
            "
            :validate-on-blur="true"
            :name="`dataInput.cell.${index}`"
            :rules="item.rules ? item.rules : []"            
            @change="
              onDataInputChange(`dataInput.cell.${index}`, $event, item.type)
            "
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
// import VuetifyMoneyTH from "@/components/Form/VuetifyMoneyTH.vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import moment from "moment";
import firebase from 'firebase/compat/app';

export default {
  name: "plan-data-input",
  components: {},
  props: {},
  async created() {
    if (this.$route.name == "plan-edit" || this.$route.name == "plan-detail") {
      this.plan_id = this.$route.params.id;
    }

    // if(this.$route.name == "plan-add"){
    //   this.loadDataInput({});
    // }else if(this.$route.name == "plan-edit"){
    //   this.loadDataInput({ id: this.$route.params.id });
    // }
  },
  async mounted() {
    // const firebaseGetPlanList = firebase.functions().httpsCallable("firebaseGetPlanList");
    // const plansList = await firebaseGetPlanList();
    // console.log("firebaseGetPlanList",plansList);
    // console.log(moment().format());
    // await firebase.auth().onAuthStateChanged(function(user) {
    //   if (user) {
    //     firebase
    //       .database()
    //       .ref("/plans")
    //       .orderByChild("uid")
    //       .equalTo(user.uid)
    //       .on("value", snapshot => {
    //         console.log(snapshot.val());
    //       });
    //   }
    // });
  },
  computed: {
    ...mapGetters([
      "dataInputSheet",
      "education",
      "balanceSheet",
      "cashFlow",
      "planItem",
      "navigationItems",
    ]),
    ...mapGetters({
      currentUser: "user",
    }),
  },
  methods: {
    ...mapActions(["loadDataInput", "updateDataInput", "saveDataInput"]),
    onDataInputChange: function (name, value, type) {
      var keys = name.split(".");
      var sheet = keys[0];
      var cell = keys[2];
      this.updateDataInput({
        sheet: sheet,
        cell: cell,
        value: value,
        type: type,
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveChanges() {
      let form_valid = this.$refs.form.validate();
      if (form_valid) {
        this.$parent.isLoading = true;
        let plan = {
          family: {
            customer: {
              name: this.dataInputSheet.cell.C4.value,
              age: this.dataInputSheet.cell.E4.value,
            },
            spouse: {
              name: this.dataInputSheet.cell.C5.value,
              age: this.dataInputSheet.cell.E5.value,
            },
            children: [
              {
                name: this.dataInputSheet.cell.C8.value,
                age: this.dataInputSheet.cell.E8.value,
              },
              {
                name: this.dataInputSheet.cell.C9.value,
                age: this.dataInputSheet.cell.E9.value,
              },
            ],
          },
          balanceSheet: {
            total_asset: this.balanceSheet.cell.E47.value,
            net_worth: this.balanceSheet.cell.I32.value,
          },
          cashFlow: {
            total_income: this.cashFlow.cell.J11.value,
            total_expends: this.cashFlow.cell.J50.value,
          },
          cell: this.dataInputSheet.cell,
        };
        // console.log(this.dataInputSheet.cell.TH501,this.dataInputSheet.cell.TI501,this.dataInputSheet.cell.TJ501);
        // console.log(this.dataInputSheet.cell.TH502,this.dataInputSheet.cell.TI502,this.dataInputSheet.cell.TJ502);
        // console.log(this.dataInputSheet.cell.TH503,this.dataInputSheet.cell.TI503,this.dataInputSheet.cell.TJ503);
        // console.log(this.dataInputSheet.cell.TH504,this.dataInputSheet.cell.TI504,this.dataInputSheet.cell.TJ504);
        

        let _planItem = JSON.parse(JSON.stringify(this.planItem));
        let createAt = moment().format();
        // plan add        
        if (this.plan_id === null || this.plan_id == 0) {
          this.plan_id = firebase.database().ref().child("plans").push().key;
        } else if (this.plan_id == 0) {
          // this.plan_id = "-MYnLh0Tg57p4UQbDdEp";
        } else{
          createAt = _planItem.createAt;
        }

        // fixed for {__ob__: Observer}                
        let single_plan = {
          plan: plan,
          uid: this.currentUser.uid,
          createAt: createAt,
          updateAt: moment().format(),
          progress: {
            balanceSheet: this.navigationItems.balanceSheet.progress,
            cashFlow: this.navigationItems.cashFlow.progress,
            financialRatio: this.navigationItems.financialRatio.progress,
            education: this.navigationItems.education.progress,
            retirement: this.navigationItems.retirement.progress,
            tax: this.navigationItems.tax.progress,
            health: this.navigationItems.health.progress,
          },
        };
        console.log(single_plan);

        // save firebase
        firebase
          .database()
          .ref("/plans/" + this.plan_id)
          .update(single_plan)
          .then(() => {
            this.$parent.isLoading = false;
            console.log("firebase update plan/" + this.plan_id);
            this.saveDataInput({
              data: single_plan,
            });

            let uid = this.currentUser.uid;
            firebase
              .database()
              .ref("/stats/" + uid)
              .once("value", (snapshot) => {
                let user_stats = snapshot.val();
                let time = moment().format();
                let stats = {
                  login: [],
                  total_plan: 0,
                  total_plan_30days: 0,
                  total_plan_detail: {
                    balance_sheet: 0,
                    cash_flow: 0,
                    financial_ratio: 0,
                    education: 0,
                    retirement: 0,
                    tax: 0,
                    health: 0,
                  },
                };
                if (user_stats !== null) {
                  stats.login = user_stats.login;
                } else {
                  stats.login.push(time);
                }

                // plans update stats
                firebase
                  .database()
                  .ref("/plans")
                  .orderByChild("uid")
                  .equalTo(uid)
                  .once("value", (snapshot) => {
                    snapshot.forEach(function (childSnapshot) {
                      let plan = childSnapshot.val();
                      let createAt = plan.createAt;
                      let diff = moment().diff(moment(createAt), "days");
                      stats.total_plan += 1;
                      if (diff <= 30) {
                        stats.total_plan_30days += 1;
                      }
                      stats.total_plan_detail.balance_sheet += 1;
                      stats.total_plan_detail.cash_flow += 1;
                      stats.total_plan_detail.financial_ratio += 1;

                      if (plan.progress === undefined) {
                        stats.total_plan_detail.education += 1;
                        stats.total_plan_detail.retirement += 1;
                        stats.total_plan_detail.tax += 1;
                        stats.total_plan_detail.health += 1;
                      } else {
                        if (plan.progress.education == 100) {
                          stats.total_plan_detail.education += 1;
                        }
                        if (plan.progress.retirement == 100) {
                          stats.total_plan_detail.retirement += 1;
                        }
                        if (plan.progress.tax == 100) {
                          stats.total_plan_detail.tax += 1;
                        }
                        if (plan.progress.health == 100) {
                          stats.total_plan_detail.health += 1;
                        }
                      }
                    });
                    console.log(stats);
                    firebase
                      .database()
                      .ref("/stats/" + uid)
                      .set(stats)
                      .then(() => {});
                  });
              });
            if (this.$route.name == "plan-add") {
              this.$router.push("/plan-edit/" + this.plan_id);
            }
          })
          .catch((error) => {
            this.$parent.isLoading = false;
            console.log("[error] firebase update plan/" + this.plan_id);
            console.log(error);
          });
      } else {
        var elem = document.getElementsByClassName("error--text")[0];
        if (elem) {
          window.scrollTo(0, elem.offsetTop - 200);
        }
      }
    },
  },
  data: function () {
    return {
      form_prop: {
        dense: true,
      },
      valid: true,
      plan_id: null,
      current_plan_item: {},
      showFormula: true,
      // value: 1122.9, // Number or String
      // label: "Value",
      // disabled: false,
      // outlined: true,
      // options: {
      //   locale: "th-TH",
      //   prefix: "บาท",
      //   suffix: "บาท",
      //   precision: 2
      // }
    };
  },
};
</script>

<style>
.v-tab {
  letter-spacing: inherit !important;
}
</style>
