export const health = {
  required: {
    dataInput: {
      cell: [
        // === no required ===
        "YR1",
        "C4",
        "E4",
        // "HC3",
        // "HC4",
        // "HC5",
        // "HC7",
        // "HC8",
        // "HC13",
        // "HC14",
        // "HC16",
        // "HC19",
        // "HC20",
        // "HC21",
        // "HC22",
        // "HC26",
        // "HC27",
        // "HC33"
        // === no required ===
      ]
    }
  },
  cell: {
    A1:{
      label:"ชื่อ-สกุล",
      value:"",
      type:"text",
      formula: "dataInput.C4"
    },
    // คุ้มครองสุขภาพ อุบัติเหตุ
    HC3: {
      label: "โรงพยาบาลที่ใช้บริการ",
      value: "",
      type: "text",
      placeholder: "ระบุข้อมูล",
      suffix: "",
      formula: "dataInput.HC3"
    },
    HC4: {
      label: "ค่าห้อง ค่าบริการ /วัน",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC4"
    },
    HC5: {
      label: "งบประมาณในการดูแลสุขภาพ / ปี",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC5"
    },
    HC7: {
      label: "ค่าห้อง ค่าบริการ /วัน",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC7"
    },
    HC8: {
      label: "วงเงินค่ารักษาพยาบาลเหมาจ่าย",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC8"
    },
    HC10: {
      label: "สวัสดิการที่ควรเตรียมเพิ่ม - ค่าห้อง ค่าบริการ /วัน",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC4-dataInput.HC7"
    },
    HC11: {
      label: "สวัสดิการที่ควรเตรียมเพิ่ม - วงเงินค่ารักษาพยาบาลเหมาจ่าย",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC5-dataInput.HC8"
    },
    // คุ้มครองโรคร้ายแรง
    HC13: {
      label: "รายได้ /ปี ที่อาจสูเสียไป",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC13"
    },
    HC14: {
      label: "จำนวนปีที่ต้องรักษา",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC14"
    },
    HC15: {
      label: "จำนวนเงินที่ต้องเตรียม",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC13*dataInput.HC14"
    },
    HC16: {
      label: "เงินก้อนที่เตรียมไว้แล้ว",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC16"
    },
    HC17: {
      label: "เงินสำรองที่ขาดอยู่",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "health.HC15-health.HC16"
    },
    //การปกป้องรายได้ของหัวหน้าครอบครัว
    HC19: {
      label: "ค่าใช้จ่ายที่รับผิดชอบครอบครัว/ปี",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC19"
    },
    HC20: {
      label: "จำนวนปีที่ต้องการคุ้มครอง",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC20"
    },
    HC21: {
      label: "เงินเฟ้อ",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "%",
      formula: "dataInput.HC21"
    },
    HC22: {
      label: "อัตราผลตอบแทนกองทุน",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "%",
      formula: "dataInput.HC22"
    },
    HC23: {
      label: "จำนวนเงินที่ต้องเตรียม",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      //=FV(C21-C22,C20,,C19)
      //=FV(C21-C22,C20,,C19)
      formula: "FV(health.HC21-health.HC22,health.HC20,0,health.HC19,0)"
    },

    // หนี้สินคงค้าง
    HC26: {
      label: "หนี้สินคงค้างระยะสั้น",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC26"
    },
    HC27: {
      label: "หนี้สินคงค้างระยะยาว",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC27"
    },
    HC28: {
      label: "รวมหนี้สินคงค้าง",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC26+dataInput.HC27"
    },
    HC30: {
      label: "ทุนคุ้มครองรายได้ครอบครัวที่ต้องเตรียมเพิ่ม",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "health.HC28-health.HC23"
    },
    HC33: {
      label: "ทุนประกัน",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC33"
    },
    HC34: {
      label: "รวมสินทรัพย์ที่สามารถแปลงเป็นเงินสดเตรียมไว้",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "dataInput.HC32+health.HC33"
    },
    HC35: {
      label: "ความคุ้มครองรายได้ที่ขาดอยู่",
      value: "",
      type: "number",
      placeholder: "ระบุข้อมูล",
      suffix: "บาท",
      formula: "health.HC30-health.HC33"
    }
  }
};
