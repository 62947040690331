<template>
  <v-container
    class="fluid pa-0 wealthplus-container export-pdf"
    id="retirement"
  >
    <v-row class="editable">
      <v-col cols="12">
        <ul>
          <li>
            <v-btn small color="primary" @click="saveChanges()">
              <v-icon small>mdi-content-save</v-icon>Save Changes
            </v-btn>
          </li>
          <li>
            <v-btn small :color="btnEditColor" @click="toggleShowEdit()">
              <v-icon small>mdi-lead-pencil</v-icon>Edit
            </v-btn>
          </li>
          <li v-if="enableFormula">
            <v-btn small :color="btnFormulaColor" @click="toggleShowFormula()">
              <v-icon small>mdi-calculator</v-icon>Formula
            </v-btn>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <!-- <v-img src="../../assets/img/005-RTM.jpg" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>-->

      <div
        class="wrapper-content"
        id="retirement1Page"
        v-bind:class="{ 'print-able': printAble, '': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          <div class="primary-content -retirement">
            <div class="title-page">
              <div class="detail">
                <h2>Retirement Plan</h2>
                <h3>
                  วางแผนการเกษียณสำหรับ คุณ {{ dataInputSheet.cell.C5.value }}
                </h3>
                <p>
                  เงินเพื่อเกษียณนั้นเป็นเงินจำนวนมาก เป็นเงินก้อนใหญ่ ต้องใช้
                  ระยะเวลาในการเตรียมตัวนานมาก ดังนั้น การเก็บเงินเพื่อเกษียณ
                  จึงไม่ใช่เรื่องไกลตัวต้องเริ่มต้นเก็บออมเงินตั้งแต่วันนี้
                  เพราะคุณ
                  จะมีเวลาเก็บออมเงินนานขึ้นช่วยให้ภาระการเก็บเงินของคุณน้อย ลง
                  เก็บเงินได้สบายขึ้นนั่นเอง
                </p>
              </div>

              <img src="../../assets/img/retirement/title-page-img.png" alt />
            </div>

            <div class="box-content _pd-0">
              <div class="timeline">
                <div class="title-timeline">
                  <p>จำนวนปีสะสมเงิน {{ retirement1.cell.J14.value }} ปี</p>
                  <p>จำนวนปีที่ใช้เงิน {{ retirement1.cell.U14.value }} ปี</p>
                </div>

                <div class="detail">
                  <div class="column">
                    <div class="box">
                      <span class="backup" v-if="showFormula"
                        >&nbsp;D13 = {{ retirement1.cell["D13"].formula }}</span
                      >
                      <p v-show="!showEdit">
                        <strong
                          >อายุ {{ retirement1.cell.D13.value }} ปี</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          E5: dataInputSheet.form.family.cell.E5,
                        }"
                        v-bind:key="index"
                      >
                        <v-text-field
                          style="width: 100px; display: inline-block"
                          :ref="`dataInput.cell.${index}`"
                          :type="item.type"
                          :value="
                            dataInputSheet.cell[index]
                              ? dataInputSheet.cell[index].value
                              : ''
                          "
                          @change="
                            onDataInputChange(
                              `dataInput.cell.${index}`,
                              $event,
                              item.type
                            )
                          "
                          prefix="อายุ"
                          suffix=" ปี"
                        ></v-text-field>
                      </p>
                      <p>เริ่มสะสมเงิน {{ retirement1.cell.D12.value }}</p>
                    </div>
                    <div class="box">
                      <span class="backup" v-if="showFormula"
                        >&nbsp;O15 = {{ retirement1.cell["O15"].formula }}</span
                      >
                      <p v-show="!showEdit">
                        <strong
                          >อายุ {{ retirement1.cell.O15.value }} ปี</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          I45: dataInputSheet.form.retirement.cell.I45,
                        }"
                        v-bind:key="index"
                      >
                        <v-text-field
                          style="width: 100px; display: inline-block"
                          :ref="`dataInput.cell.${index}`"
                          :type="item.type"
                          :value="
                            dataInputSheet.cell[index]
                              ? dataInputSheet.cell[index].value
                              : ''
                          "
                          @change="
                            onDataInputChange(
                              `dataInput.cell.${index}`,
                              $event,
                              item.type
                            )
                          "
                          prefix="อายุ"
                          suffix=" ปี"
                        ></v-text-field>
                      </p>
                      <p>หยุดทำงานปี {{ retirement1.cell.O14.value }}</p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="box">
                      <p>
                        <strong
                          >อายุ {{ retirement1.cell.Q15.value }} ปี</strong
                        >
                      </p>
                      <p>เริ่มใช้กองทุนปี {{ retirement1.cell.Q14.value }}</p>
                    </div>
                    <div class="box">
                      <span class="backup" v-if="showFormula">&nbsp;Y13 = {{ retirement1.cell["Y13"].formula }}</span>                      
                      <p v-show="!showEdit">
                        <strong>อายุ {{ retirement1.cell.Y13.value }} ปี</strong>
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          I451: dataInputSheet.form.retirement.cell.I451,
                        }"
                        v-bind:key="index"
                      >
                        <v-text-field
                          style="width: 100px; display: inline-block"
                          :ref="`dataInput.cell.${index}`"
                          :type="item.type"
                          :value="
                            dataInputSheet.cell[index]
                              ? dataInputSheet.cell[index].value
                              : ''
                          "
                          @change="
                            onDataInputChange(
                              `dataInput.cell.${index}`,
                              $event,
                              item.type
                            )
                          "
                          prefix="อายุ"
                          suffix=" ปี"
                        ></v-text-field>
                      </p>
                      <p>สิ้นสุดกองทุนปี {{ retirement1.cell.Y12.value }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">                    
                    <h3>ประมาณค่าใช้จ่ายสำหรับเกษียณ</h3>
                  </div>
                  <div class="column">
                    <span class="backup" v-if="showFormula"
                      >&nbsp;Y8 = {{ retirement.cell["Y8"].formula }}</span
                    >
                    <h3 v-show="!showEdit">
                      <small>
                        <i
                          >* อัตราเงินเฟ้อหลังเกษียณ
                          {{
                            retirement1.cell.Y8.value | numeralFormat("0,0.00")
                          }}% ต่อปี</i
                        >
                      </small>
                    </h3>
                    <h3
                      v-show="showEdit"
                      v-for="(item, index) in {
                        RTY8: dataInputSheet.form.retirement.cell.RTY8,
                      }"
                      v-bind:key="index"
                    >
                      <small>
                        * อัตราเงินเฟ้อหลังเกษียณ
                        <v-text-field
                          style="width: 100px; display: inline-block"
                          :ref="`dataInput.cell.${index}`"
                          :type="item.type"
                          :value="
                            dataInputSheet.cell[index]
                              ? dataInputSheet.cell[index].value
                              : ''
                          "
                          @change="
                            onDataInputChange(
                              `dataInput.cell.${index}`,
                              $event,
                              item.type
                            )
                          "
                          suffix="% ต่อปี"
                        ></v-text-field>
                      </small>
                    </h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <div class="row">
                        <p>
                          <strong
                            >อายุ {{ retirement1.cell.D13.value }} ปี</strong
                          >
                          เริ่มสะสมเงิน {{ retirement1.cell.D12.value }}
                        </p>
                        <p>
                          <strong>{{ retirement1.cell.J14.value }} ปี</strong
                          >สะสมเงิน
                        </p>
                        <p>
                          <strong>{{ retirement1.cell.U14.value }} ปี</strong
                          >ใช้เงิน
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p>
                          <strong
                            >อายุ {{ retirement1.cell.Q15.value }} ปี</strong
                          >
                          เริ่มใช้กองทุนปี {{ retirement1.cell.Q14.value }}
                        </p>
                        <p>
                          <strong
                            >อายุ {{ retirement1.cell.Y13.value }} ปี</strong
                          >
                          สิ้นสุดกองทุนปี {{ retirement1.cell.Y12.value }}
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <span class="backup" v-if="showFormula">&nbsp;Y6 = {{ retirement.cell["Y6"].formula }}</span>
                      <div class="row">
                        <p v-show="!showEdit">
                          <strong
                            >{{
                              retirement1.cell.Y6.value
                                | numeralFormat("0,0.00")
                            }}%</strong
                          >ผลตอบแทนหลังเกษียณ
                        </p>
                        <p
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY6: dataInputSheet.form.retirement.cell.RTY6,
                          }"
                          v-bind:key="index"
                        >
                          <strong>
                            <v-text-field
                              style="width: 100px; display: inline-block"
                              :ref="`dataInput.cell.${index}`"
                              :type="item.type"
                              :value="
                                dataInputSheet.cell[index]
                                  ? dataInputSheet.cell[index].value
                                  : ''
                              "
                              @change="
                                onDataInputChange(
                                  `dataInput.cell.${index}`,
                                  $event,
                                  item.type
                                )
                              "
                              suffix="% ต่อปี"
                            ></v-text-field>
                          </strong>
                          ผลตอบแทนหลังเกษียณ
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="column">
                      <span class="backup" v-if="showFormula">&nbsp;C16 = {{ retirement1.cell["C16"].formula }}</span>
                      <div class="row">
                        <p>ค่าใช้จ่ายในปัจจุบัน</p>
                      </div>
                      <div class="row">
                        <p v-show="!showEdit">
                          <strong>{{
                            retirement1.cell.C16.value | numeralFormat
                          }}</strong
                          >ต่อเดือน
                        </p>
                        <p
                          style="
                            max-width: 100px;
                            padding: 0px !important;
                            margin: 0px !important;
                          "
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RT2: dataInputSheet.form.retirement.cell.RT2,
                          }"
                          v-bind:key="index"
                        >
                          <strong>
                            <v-text-field
                              style="width: 100px; display: inline-block"
                              :ref="`dataInput.cell.${index}`"
                              :type="item.type"
                              :value="
                                dataInputSheet.cell[index]
                                  ? dataInputSheet.cell[index].value
                                  : ''
                              "
                              @change="
                                onDataInputChange(
                                  `dataInput.cell.${index}`,
                                  $event,
                                  item.type
                                )
                              "
                            ></v-text-field>
                          </strong>
                          ต่อเดือน
                        </p>
                        <p>
                          <strong>{{
                            retirement1.cell.B17.value | numeralFormat
                          }}</strong
                          >ต่อปี
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p>ผลคำนวณค่าใช้จ่ายในอนาคต*</p>
                      </div>
                      <div class="row">
                        <p>
                          <strong>{{
                            retirement1.cell.M16.value | numeralFormat
                          }}</strong
                          >ต่อเดือน
                        </p>
                        <p>
                          <strong>{{
                            retirement1.cell.M17.value | numeralFormat
                          }}</strong
                          >ต่อปี
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <div class="row">
                        <p>&nbsp;</p>
                      </div>
                      <div class="row">
                        <p>
                          <strong v-if="retirement1.cell.O21.value!=-1">{{retirement1.cell.O21.value | numeralFormat}}</strong>
                          <strong v-if="retirement1.cell.O21.value==-1">0</strong>
                          ค่าใช้จ่ายทั้งหมดสำหรับเกษียณ*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green _bor-b-0">
                <header class="heading">
                  <div class="column">
                    <h3>จำนวนเงินขั้นต่ำที่ต้องมี</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong v-if="retirement1.cell.O21.value!=-1">{{retirement1.cell.O21.value | numeralFormat}}</strong>
                      <strong v-if="retirement1.cell.O21.value==-1">0</strong>
                    </h3>
                  </div>
                </header>

                <div class="group-chart">
                  <div class="column-25">
                    <div
                      class="box-chart"
                      style="position: relative; width: 136px; height: 136px"
                    >
                      <img
                        v-show="!enableChartJs"
                        src="../../assets/img/retirement/img-chart-01.png"
                        alt
                      />
                      <div
                        v-show="enableChartJs"
                        class="absolute-center-parent mimi"
                      >
                        <div
                          v-bind:class="
                            retirement1.cell.AB18.value > 0 ? '_c-green' : ''
                          "
                        >
                          {{
                            (100 *
                              (retirement1.cell.AB18.value /
                                retirement1.cell.O21.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </div>
                      </div>
                      <div
                        style="
                          width: 136px;
                          height: 136px;
                          position: absolute;
                          z-index: 1;
                          top: 0;
                        "
                        v-show="enableChartJs"
                      >
                        <DoughnutChart
                          :width="136"
                          :height="136"
                          :chartDataSet="getChartDataSet('AB18')"
                          :chartOptions="getChartOptions('AB18')"
                        ></DoughnutChart>
                      </div>
                      <!-- {{
                        (100 *
                          (retirement.cell.AB18.value /
                            retirement.cell.O21.value))
                          | numeralFormat("0,0.0")
                      }} -->
                    </div>
                    <div class="detail">
                      <span class="backup" v-if="showFormula">&nbsp;AB18 = {{ retirement1.cell["AB18"].formula }}</span>
                      <p v-show="!showEdit">
                        สินทรัพย์สภาพคล่อง
                        <strong
                          v-bind:class="
                            retirement1.cell.AB18.value > 0 ? '_c-green' : ''
                          "
                          >{{
                            retirement1.cell.AB18.value | numeralFormat
                          }}</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          RT2AB18: dataInputSheet.form.retirement.cell.RT2AB18,
                        }"
                        v-bind:key="index"
                      >
                        สินทรัพย์สภาพคล่อง
                        <strong
                          v-bind:class="
                            retirement1.cell.AB18.value > 0 ? '_c-green' : ''
                          "
                        >
                          <v-text-field
                            style="display: inline-block"
                            class="fz24px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class="column-25">
                    <div
                      class="box-chart"
                      style="position: relative; width: 136px; height: 136px"
                    >
                      <img
                        v-show="!enableChartJs"
                        src="../../assets/img/retirement/img-chart-02.png"
                        alt
                      />
                      <div
                        v-show="enableChartJs"
                        class="absolute-center-parent mimi"
                      >
                        <div
                          v-bind:class="
                            retirement1.cell.AB20.value > 0 ? '_c-green' : ''
                          "
                        >
                          {{
                            (100 *
                              (retirement1.cell.AB20.value /
                                retirement1.cell.O21.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </div>
                      </div>
                      <div
                        style="
                          width: 136px;
                          height: 136px;
                          position: absolute;
                          z-index: 1;
                          top: 0;
                        "
                        v-show="enableChartJs"
                      >
                        <DoughnutChart
                          :width="136"
                          :height="136"
                          :chartDataSet="getChartDataSet('AB20')"
                          :chartOptions="getChartOptions('AB20')"
                        ></DoughnutChart>
                      </div>
                      <!-- {{
                        (100 *
                          (retirement.cell.AB20.value /
                            retirement.cell.O21.value))
                          | numeralFormat("0,0.0")
                      }} -->
                    </div>
                    <div class="detail">
                      <span class="backup" v-if="showFormula">&nbsp;AB20 = {{ retirement1.cell["AB20"].formula }}</span>
                      <p v-show="!showEdit">
                        สินทรัพย์เพื่อการลงทุน
                        <strong
                          v-bind:class="
                            retirement1.cell.AB20.value > 0 ? '_c-green' : ''
                          "
                          >{{
                            retirement1.cell.AB20.value | numeralFormat
                          }}</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          RT2AB20: dataInputSheet.form.retirement.cell.RT2AB20,
                        }"
                        v-bind:key="index"
                      >
                        สินทรัพย์เพื่อการลงทุน
                        <strong
                          v-bind:class="
                            retirement1.cell.AB20.value > 0 ? '_c-green' : ''
                          "
                        >
                          <v-text-field
                            style="display: inline-block"
                            class="fz24px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class="column-25">
                    <div
                      class="box-chart"
                      style="position: relative; width: 136px; height: 136px"
                    >
                      <img
                        v-show="!enableChartJs"
                        src="../../assets/img/retirement/img-chart-03.png"
                        alt
                      />
                      <div
                        v-show="enableChartJs"
                        class="absolute-center-parent mimi"
                      >
                        <div
                          v-bind:class="
                            retirement1.cell.AB23.value > 0 ? '_c-green' : ''
                          "
                        >
                          {{
                            (100 *
                              (retirement1.cell.AB23.value /
                                retirement1.cell.O21.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </div>
                      </div>
                      <div
                        style="
                          width: 136px;
                          height: 136px;
                          position: absolute;
                          z-index: 1;
                          top: 0;
                        "
                        v-show="enableChartJs"
                      >
                        <DoughnutChart
                          :width="136"
                          :height="136"
                          :chartDataSet="getChartDataSet('AB23')"
                          :chartOptions="getChartOptions('AB23')"
                        ></DoughnutChart>
                      </div>
                      <!-- {{
                        (100 *
                          (retirement.cell.AB23.value /
                            retirement.cell.O21.value))
                          | numeralFormat("0,0.0")
                      }} -->
                    </div>
                    <div class="detail">
                      <span class="backup" v-if="showFormula">&nbsp;AB23 = {{ retirement1.cell["AB23"].formula }}</span>
                      <p v-show="!showEdit">
                        ทรัพย์สินอื่นที่เตรียมไว้แล้ว
                        <strong
                          v-bind:class="
                            retirement1.cell.AB23.value > 0 ? '_c-green' : ''
                          "
                          >{{
                            retirement1.cell.AB23.value | numeralFormat
                          }}</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          RT2AB23: dataInputSheet.form.retirement.cell.RT2AB23,
                        }"
                        v-bind:key="index"
                      >
                        ทรัพย์สินอื่นที่เตรียมไว้แล้ว
                        <strong
                          v-bind:class="
                            retirement1.cell.AB23.value > 0 ? '_c-green' : ''
                          "
                        >
                          <v-text-field
                            style="display: inline-block"
                            class="fz24px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class="column-25">
                    <div
                      class="box-chart"
                      style="position: relative; width: 136px; height: 136px"
                    >
                      <img
                        v-show="!enableChartJs"
                        src="../../assets/img/retirement/img-chart-04.png"
                        alt
                      />
                      <div
                        v-show="enableChartJs"
                        class="absolute-center-parent mimi"
                      >
                        <div
                          v-bind:class="
                            retirement1.cell.AB25.value > 0 ? '_c-green' : ''
                          "
                        >
                          {{
                            (100 *
                              (retirement1.cell.AB25.value /
                                retirement1.cell.O21.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </div>
                      </div>
                      <div
                        style="
                          width: 136px;
                          height: 136px;
                          position: absolute;
                          z-index: 1;
                          top: 0;
                        "
                        v-show="enableChartJs"
                      >
                        <DoughnutChart
                          :width="136"
                          :height="136"
                          :chartDataSet="getChartDataSet('AB25')"
                          :chartOptions="getChartOptions('AB25')"
                        ></DoughnutChart>
                      </div>
                      <!-- {{
                        (100 *
                          (retirement.cell.AB25.value /
                            retirement.cell.O21.value))
                          | numeralFormat("0,0.0")
                      }} -->
                    </div>
                    <div class="detail">
                      <span class="backup" v-if="showFormula">&nbsp;AB25 = {{ retirement1.cell["AB25"].formula }}</span>
                      <p v-show="!showEdit">
                        เงินบำนาญและเงินอื่นๆ
                        <strong
                          v-bind:class="
                            retirement1.cell.AB25.value > 0 ? '_c-green' : ''
                          "
                          >{{
                            retirement1.cell.AB25.value | numeralFormat
                          }}</strong
                        >
                      </p>
                      <p
                        v-show="showEdit"
                        v-for="(item, index) in {
                          RT2AB25: dataInputSheet.form.retirement.cell.RT2AB25,
                        }"
                        v-bind:key="index"
                      >
                        เงินบำนาญและเงินอื่นๆ
                        <strong
                          v-bind:class="
                            retirement1.cell.AB25.value > 0 ? '_c-green' : ''
                          "
                        >
                          <v-text-field
                            style="display: inline-block"
                            class="fz24px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </strong>
                      </p>
                    </div>
                  </div>

                  <div class="column-100">
                    <div style="position: absolute; width: 450px">
                      <div
                        class="box-chart"
                        style="
                          position: relative;
                          width: 136px;
                          height: 136px;
                          margin-left: 156px;
                        "
                      >
                        <img
                          v-show="!enableChartJs"
                          src="../../assets/img/retirement/img-chart-05.png"
                          alt
                        />
                        <div
                          style="
                            width: 136px;
                            height: 136px;
                            position: absolute;
                            z-index: 1;
                            top: 0;
                          "
                          v-show="enableChartJs"
                        >
                          <DoughnutChart
                            :width="136"
                            :height="136"
                            :chartDataSet="getChartDataSet('AB27AB29')"
                            :chartOptions="getChartOptions('AB27AB29')"
                          ></DoughnutChart>
                        </div>
                      </div>
                    </div>
                    <div class="column-25">
                      <div
                        class="box-chart"
                        style="
                          position: relative;
                          width: 136px;
                          height: 136px;
                          padding-top: 56px;
                        "
                      >
                        <p
                          class="-fs-26"
                          v-bind:class="
                            retirement1.cell.AB27.value > 0 ? '_c-green' : ''
                          "
                        >
                          {{
                            (100 *
                              (retirement1.cell.AB27.value /
                                retirement1.cell.O21.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </p>
                      </div>
                      <div class="detail">
                        <p>
                          เงินส่วนที่เตรียมไว้แล้ว
                          <strong
                            v-bind:class="
                              retirement1.cell.AB27.value > 0 ? '_c-green' : ''
                            "
                            >{{
                              retirement1.cell.AB27.value | numeralFormat
                            }}</strong
                          >
                        </p>
                      </div>
                    </div>
                    <div class="column-25">
                      <div
                        class="box-chart"
                        style="
                          position: relative;
                          width: 136px;
                          height: 136px;
                          padding-top: 56px;
                        "
                      >
                        <p
                          class="-fs-26"
                          v-bind:class="
                            retirement1.cell.AB29.value > 0 ? '_c-orange' : ''
                          "
                        >
                          {{
                            (100 *
                              (retirement1.cell.AB29.value /
                                retirement1.cell.O21.value))
                              | numeralFormat("0,0.0")
                          }}%
                        </p>
                      </div>
                      <div class="detail">
                        <p>
                          เงินส่วนที่เตรียมเพิ่ม
                          <strong v-if="retirement1.cell.AB29.value!=-1" v-bind:class="retirement1.cell.AB29.value > 0 ? '_c-orange' : ''">
                            {{ retirement1.cell.AB29.value | numeralFormat }}</strong>
                          <strong v-if="retirement1.cell.AB29.value==-1">0</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="heading -orange">
                  <div class="column">
                    <h3>
                      การลงทุนเพื่อการเกษียณในเวลา
                      {{ retirement1.cell.J14.value }} ปี
                    </h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong v-if="retirement1.cell.AB29.value!=-1">{{retirement1.cell.AB29.value | numeralFormat}}</strong>
                      <strong v-if="retirement1.cell.AB29.value==-1">0</strong>
                    </h3>
                  </div>
                </div>

                <table class="already-prepared">
                  <tbody>
                    <tr>
                      <td>-</td>
                      <td>
                       <span v-show="!showEdit">{{ retirement.cell["RTY330"].value }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY330: dataInputSheet.form.retirement.cell.RTY330,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            retirement1.cell.Y33.value
                              | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY33: dataInputSheet.form.retirement.cell.RTY33,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="display: inline-block; width: 100px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ retirement1.cell.AB33.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                         <span v-show="!showEdit">{{ retirement.cell["RTY350"].value }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY350: dataInputSheet.form.retirement.cell.RTY350,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            retirement1.cell.Y35.value
                              | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY35: dataInputSheet.form.retirement.cell.RTY35,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="display: inline-block; width: 100px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ retirement1.cell.AB35.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                        <span v-show="!showEdit">{{ retirement.cell["RTY370"].value }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY370: dataInputSheet.form.retirement.cell.RTY370,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            retirement1.cell.Y37.value
                              | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY37: dataInputSheet.form.retirement.cell.RTY37,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="display: inline-block; width: 100px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ retirement1.cell.AB37.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                        <span v-show="!showEdit">{{ retirement.cell["RTY390"].value }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY390: dataInputSheet.form.retirement.cell.RTY390,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            retirement1.cell.Y39.value
                              | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY39: dataInputSheet.form.retirement.cell.RTY39,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="display: inline-block; width: 100px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ retirement1.cell.AB39.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                        <span v-show="!showEdit">{{ retirement.cell["RTY410"].value }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY410: dataInputSheet.form.retirement.cell.RTY410,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            retirement1.cell.Y41.value
                              | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY41: dataInputSheet.form.retirement.cell.RTY41,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="display: inline-block; width: 100px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td>{{ retirement1.cell.AB41.value | numeralFormat }}</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                       <span v-show="!showEdit">{{ retirement.cell["RTY430"].value }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY430: dataInputSheet.form.retirement.cell.RTY430,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            retirement1.cell.Y43.value
                              | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY43: dataInputSheet.form.retirement.cell.RTY43,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="display: inline-block; width: 100px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td v-if="retirement1.cell.AB43.value!=-1">{{ retirement1.cell.AB43.value | numeralFormat }}</td>
                      <td v-if="retirement1.cell.AB43.value==-1">0</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>
                         <span v-show="!showEdit">อื่นๆ {{ retirement.cell["RTY440"].value }}</span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY440: dataInputSheet.form.retirement.cell.RTY440,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="width: 100%; display: inline-block"
                            :placeholder="item.placeholder ? item.placeholder : ''"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                        
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{
                            retirement.cell.Y44.value | numeralFormat("0,0.00")
                          }}%
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            RTY44: dataInputSheet.form.retirement.cell.RTY44,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            style="display: inline-block; width: 100px"
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                            suffix="%"
                          ></v-text-field>
                        </span>
                      </td>
                      <td v-if="retirement1.cell.AB44.value!=-1">{{ retirement1.cell.AB44.value | numeralFormat }}</td>
                      <td v-if="retirement1.cell.AB44.value==-1">0</td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <small>
                          * การคำนวณผลตอบแทน เป็นผลตอบแทนสุทธิ
                          ที่พิจารณาจากผลตอบแทนย้อนหลัง ยังไม่รวมค่าธรรมเนียม
                          ดังนั้นจึงไม่การันตีอนาคต
                          ผู้ลงทุนต้องศึกษาข้อมูลให้ดีก่อนตัดสินใจลงทุน
                          ละต้องดูค่าธรรมเนียม ของแต่ละบริษัท
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- DATA -->
    <v-row v-if="showBackup">
      <v-col cols="12">
        <h2>Retirement1</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(item, index) in retirement1.cell"
        v-bind:key="index"
      >
        <v-text-field
          :disabled="true"
          :dense="true"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
          :placeholder="item.placeholder ? item.placeholder : 'ระบุจำนวน'"
        ></v-text-field>
      </v-col>
    </v-row>
    <hr v-if="showBackup" style="border: 1px solid red" />
    <v-row v-if="showBackup" :set="(sheet = dataInputSheet.form.retirement)">
      <v-col cols="12" class="sheet-heading">
        <h3>{{ sheet.heading }}</h3>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :md="3"
        v-for="(item, index) in sheet.cell"
        v-bind:key="index"
      >
        <v-text-field
          :ref="`dataInput.cell.${index}`"
          :type="item.type"
          :label="`${index} ${item.label}`"
          :suffix="item.suffix ? item.suffix : ''"
          :value="
            dataInputSheet.cell[index] ? dataInputSheet.cell[index].value : ''
          "
          @change="
            onDataInputChange(`dataInput.cell.${index}`, $event, item.type)
          "
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { mapGetters, mapActions } from "vuex";
import DoughnutChart from "../ChartJs/DoughnutChart.js";
export default {
  name: "retirement-component",
  components: {
    DoughnutChart,
  },
  props: {
    printAble: Boolean,
    enableChartJs: Boolean,
    enableFormula: Boolean,
  },
  created() {
    console.log("retirement1 - created");
  },
  mounted() {
    console.log("retirement1 - mounted");
  },
  computed: {
    ...mapGetters(["dataInputSheet", "retirement", "retirement1", "user"]),
  },
  methods: {
    ...mapActions(["updateDataInput"]),
    getChartOptions(section) {
      return {
        tooltips: {
          enabled: false,
        },
      };
    },
    getChartDataSet(section) {
      let dataset = {};

      if (section == "AB18") {
        dataset.labels = ["สินทรัพย์สภาพคล่อง", ""];
        if (this.retirement1.cell.AB18.value != undefined) {
          dataset.data = [
            this.retirement1.cell.AB18.value,
            this.retirement1.cell.O21.value - this.retirement1.cell.AB18.value,
          ];
        }
        dataset.backgroundColor = ["#1aa8b8", "#e1e1e1"];
        return dataset;
      } else if (section == "AB20") {
        dataset.labels = ["สินทรัพย์เพื่อการลงทุน", ""];
        if (this.retirement1.cell.AB20.value != undefined) {
          dataset.data = [
            this.retirement1.cell.AB20.value,
            this.retirement1.cell.O21.value - this.retirement1.cell.AB20.value,
          ];
        }
        dataset.backgroundColor = ["#1aa8b8", "#e1e1e1"];
        return dataset;
      } else if (section == "AB23") {
        dataset.labels = ["ทรัพย์สินอื่นที่เตรียมไว้แล้ว", ""];
        if (this.retirement1.cell.AB23.value != undefined) {
          dataset.data = [
            this.retirement1.cell.AB23.value,
            this.retirement1.cell.O21.value - this.retirement1.cell.AB23.value,
          ];
        }
        dataset.backgroundColor = ["#1aa8b8", "#e1e1e1"];
        return dataset;
      } else if (section == "AB25") {
        dataset.labels = ["เงินบำนาญและเงินอื่นๆ", ""];
        if (this.retirement1.cell.AB25.value != undefined) {
          dataset.data = [
            this.retirement1.cell.AB25.value,
            this.retirement1.cell.O21.value - this.retirement1.cell.AB25.value,
          ];
        }
        dataset.backgroundColor = ["#1aa8b8", "#e1e1e1"];
        return dataset;
      } else if (section == "AB27") {
        dataset.labels = ["เงินส่วนที่เตรียมไว้แล้ว", ""];
        if (this.retirement1.cell.AB27.value != undefined) {
          dataset.data = [
            this.retirement1.cell.AB27.value,
            this.retirement1.cell.O21.value - this.retirement1.cell.AB27.value,
          ];
        }
        dataset.backgroundColor = ["#1aa8b8", "#e1e1e1"];
        return dataset;
      } else if (section == "AB29") {
        dataset.labels = ["เงินส่วนที่เตรียมเพิ่ม", ""];
        if (this.retirement1.cell.AB29.value != undefined) {
          dataset.data = [
            this.retirement1.cell.AB29.value,
            this.retirement1.cell.O21.value - this.retirement1.cell.AB29.value,
          ];
        }
        dataset.backgroundColor = ["#fe9156", "#e1e1e1"];
        return dataset;
      } else if (section == "AB27AB29") {
        dataset.labels = ["เงินส่วนที่เตรียมเพิ่ม", "เงินส่วนที่เตรียมไว้แล้ว"];
        if (this.retirement1.cell.AB27.value != undefined) {
          dataset.data = [
            this.retirement1.cell.AB29.value,
            this.retirement1.cell.AB27.value,
          ];
        }
        dataset.backgroundColor = ["#fe9156", "#1aa8b8"];
        return dataset;
      } else {
        return {};
      }
    },
    onDataInputChange: function (name, value, type) {
      var keys = name.split(".");
      var sheet = keys[0];
      var cell = keys[2];

      this.updateDataInput({
        sheet: sheet,
        cell: cell,
        value: value,
        type: type,
      });
    },
    toggleShowEdit() {
      this.showEdit = !this.showEdit;
      if (this.showEdit) {
        this.btnEditColor = "warning";
      } else {
        this.btnEditColor = "default";
      }
    },
    toggleShowFormula() {
      this.showFormula = !this.showFormula;
      if (this.showFormula) {
        this.btnFormulaColor = "warning";
      } else {
        this.btnFormulaColor = "default";
      }
    },
    saveChanges() {
      this.showEdit = false;
      this.btnEditColor = "default";
      this.$parent.$refs.dataInputComponent.saveChanges();
    },
  },
  data: function () {
    return {
      btnEditColor: "default",
      btnFormulaColor: "default",
      showBackup: false,
      showFormula: false,
      showEdit: false,
    };
  },
};
</script>
