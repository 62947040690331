/* eslint-disable no-unused-vars */

import firebase from 'firebase/compat/app';
import _ from "lodash";

/**
 * Plan Module
 */
const state = {
  plans: [],
  plan: {},
};

const getters = {
  plans: (state) => {
    return state.plans;
  },
  plan: (state) => {
    return state.plan;
  },
};

const actions = {
  getAllPlan(context, payload) {
    firebase.database
      .ref("plans")
      .on("value")
      .then((snapshot) => {
        state.plans = _.chain(snapshot.val())
          .map(function(item, index) {
            item._id = index;
            return item;
          })
          .value();
      })
      .catch((error) => {
        console.log(error);
      });
    context.commit("PLAN_LISTS", payload);
  },
  getPlanDetail(context, payload) {
    context.commit("PLAN_DETAIL", payload);
  },
  createPlan(context, payload) {
    context.commit("PLAN_CREATE", payload);
  },
  updatePlan(context, payload) {
    context.commit("PLAN_UPDATE", payload);
  },
};

const mutations = {
  PLAN_LISTS(state, owner) {
    state.plans = [];
    // console.log(owner);
  },
  PLAN_DETAIL(state, plan) {
    state.plan = {};
    // console.log(plan);
  },
  PLAN_CREATE(state, plan) {
    state.plan = {};
    // console.log(plan);
  },
  PLAN_UPDATE(state, plan) {
    state.plan = {};
    // console.log(plan);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
