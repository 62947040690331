<template>
  <v-container class="fluid pa-0 wealthplus-container export-pdf" id="cashFlow">
    <v-row class="editable">
      <v-col cols="12">
        <ul>
          <li>
            <v-btn small color="primary" @click="saveChanges()">
              <v-icon small>mdi-content-save</v-icon>Save Changes
            </v-btn>
          </li>
          <li>
            <v-btn small :color="btnEditColor" @click="toggleShowEdit()">
              <v-icon small>mdi-lead-pencil</v-icon>Edit
            </v-btn>
          </li>
          <li v-if="enableFormula">
            <v-btn small :color="btnFormulaColor" @click="toggleShowFormula()">
              <v-icon small>mdi-calculator</v-icon>Formula
            </v-btn>
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <!-- <v-img src="../../assets/img/002-CSF.jpg" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>-->

      <div
        class="wrapper-content"
        id="cashFlowPage"
        v-bind:class="{ 'print-able': printAble, '': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          

          <div class="primary-content -cash-flow">
            <div class="title-page">
              <div class="detail">
                <h2>Cash Flows</h2>
                <h3>
                  รายการกระแสเงินสดสำหรับ คุณ {{ cashFlow.cell.A1.value }}
                </h3>
              </div>

              <img src="../../assets/img/cash-flow/title-page-img.png" alt />
            </div>

            <div class="box-content -content-percent">
              <div class="box-heading">
                <div class="column">
                  <h3>
                    กระแสเงินสดรับ
                    <strong class="_c-green">
                      {{ cashFlow.cell.J11.value | numeralFormat }}
                      <small
                        >{{
                          cashFlow.cell.K11.value | numeralFormat("0,0[.]00")
                        }}%</small
                      >
                    </strong>
                  </h3>
                </div>
                <div class="column">
                  <h3>
                    กระแสเงินสดจ่ายรวม
                    <strong class="_c-orange">
                      {{ cashFlow.cell.J50.value | numeralFormat }}
                      <small
                        >{{
                          cashFlow.cell.K50.value | numeralFormat("0,0[.]00")
                        }}%</small
                      >
                    </strong>
                  </h3>
                </div>
              </div>

              <div
                id="cashFlowChart1"
                class="box-chart"
                style="position: relative; width: 365px; height: 365px"
              >
                <img
                  v-show="!enableChartJs"
                  src="../../assets/img/cash-flow/img-chart-01.png"
                  alt
                />
                <div v-show="enableChartJs" class="absolute-center-parent">
                  <div>กระแสเงินสดรับ-จ่าย</div>
                </div>
                <div
                  style="
                    width: 365px;
                    height: 365px;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                  "
                  v-show="enableChartJs"
                >
                  <DoughnutChart
                    :width="365"
                    :height="365"
                    :chartDataSet="getChartDataSet('cashflow')"
                    :chartOptions="getChartOptions('cashflow')"
                  ></DoughnutChart>
                </div>
              </div>

              <div class="detail">
                <div class="section -orange">
                  <div class="column-6">
                    <p>รายจ่ายคงที่</p>
                  </div>
                  <div class="column-6">
                    <p>
                      <span>{{ cashFlow.cell.J25.value | numeralFormat }}</span>
                      <span
                        >{{
                          cashFlow.cell.K25.value | numeralFormat("0,0[.]00")
                        }}%</span
                      >
                    </p>
                  </div>
                  <div class="column-12">
                    <div class="percent-bar">
                      <div
                        class="bar"
                        v-bind:style="{ width: cashFlow.cell.K25.value + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="section -orange">
                  <div class="column-6">
                    <p>รายจ่ายผันแปร</p>
                  </div>
                  <div class="column-6">
                    <p>
                      <span>{{ cashFlow.cell.J38.value | numeralFormat }}</span>
                      <span
                        >{{
                          cashFlow.cell.K38.value | numeralFormat("0,0[.]00")
                        }}%</span
                      >
                    </p>
                  </div>
                  <div class="column-12">
                    <div class="percent-bar">
                      <div
                        class="bar"
                        v-bind:style="{ width: cashFlow.cell.K38.value + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="section -orange">
                  <div class="column-6">
                    <p>รายจ่ายเพื่อออม/ลงทุน</p>
                  </div>
                  <div class="column-6">
                    <p>
                      <span>{{ cashFlow.cell.J48.value | numeralFormat }}</span>
                      <span
                        >{{
                          cashFlow.cell.K48.value | numeralFormat("0,0[.]00")
                        }}%</span
                      >
                    </p>
                  </div>
                  <div class="column-12">
                    <div class="percent-bar">
                      <div
                        class="bar"
                        v-bind:style="{ width: cashFlow.cell.K48.value + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="section -net-cash-flow -green">
                  <div class="column-6">
                    <p>กระแสเงินสดสุทธิ</p>
                  </div>
                  <div class="column-6">
                    <p>
                      <span>{{ cashFlow.cell.J52.value | numeralFormat }}</span>
                      <span
                        >{{
                          cashFlow.cell.K52.value | numeralFormat("0,0[.]00")
                        }}%</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>กระแสเงินสดรับทั้งหมด</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong>{{
                        cashFlow.cell.J11.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </header>

                <!-- table กระแสเงินสดรับทั้งหมด (backup)-->
                <table>
                  <thead>
                    <tr>
                      <th>รายการเงินสดรับ</th>
                      <th>เดือน</th>
                      <th>ปี</th>
                      <th>รวม</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody v-if="showBackup" class="backup">
                    <tr
                      v-for="(item, index) in [5, 6, 7, 8, 9, 10]"
                      v-bind:key="index"
                    >
                      <td>H{{ item }} {{ cashFlow.cell["H" + item].label }}</td>
                      <td>
                        {{ cashFlow.cell["H" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["I" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K" + item].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>

                  <tbody>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H5"].label }}
                        <p class="backup" v-if="showFormula">
                          H5 = {{ cashFlow.cell["H5"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H5"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K22: dataInputSheet.form.income_and_expense.cell
                              .K22,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I5"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J5"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K5"].value | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H6"].label }}
                        <p class="backup" v-if="showFormula">
                          I6 = {{ cashFlow.cell["I6"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H6"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I6"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K23: dataInputSheet.form.income_and_expense.cell
                              .K23,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J6"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K6"].value | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H7"].label }}
                        <p class="backup" v-if="showFormula">
                          I7 = {{ cashFlow.cell["I7"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H7"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I7"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K24: dataInputSheet.form.income_and_expense.cell
                              .K24,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J7"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K7"].value | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H8"].label }}
                        <p class="backup" v-if="showFormula">
                          I8 = {{ cashFlow.cell["I8"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H8"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I8"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K25: dataInputSheet.form.income_and_expense.cell
                              .K25,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J8"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K8"].value | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H9"].label }}
                        <p class="backup" v-if="showFormula">
                          I9 = {{ cashFlow.cell["I9"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H9"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I9"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K26: dataInputSheet.form.income_and_expense.cell
                              .K26,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J9"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K9"].value | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H10"].label }}
                        <p class="backup" v-if="showFormula">
                          I10 = {{ cashFlow.cell["I10"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H10"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I10"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K27: dataInputSheet.form.income_and_expense.cell
                              .K27,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J10"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K10"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>รวม</td>
                      <td>{{ cashFlow.cell.H11.value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell.I11.value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell.J11.value | numeralFormat }}</td>
                      <td>
                        {{
                          cashFlow.cell.K11.value | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -orange">
                <header class="heading">
                  <div class="column">
                    <h3>กระแสเงินสดจ่ายทั้งหมด</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong>{{
                        cashFlow.cell.J50.value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </header>

                <!-- table A กระแสเงินสดจ่ายคงที่ (backup) -->
                <table class="_td-pl-44">
                  <thead>
                    <tr>
                      <th>A กระแสเงินสดจ่ายคงที่</th>
                      <th>เดือน</th>
                      <th>ปี</th>
                      <th>รวม</th>
                      <th>%</th>
                    </tr>
                  </thead>

                  <tbody v-if="showBackup" class="backup">
                    <tr
                      v-for="(item, index) in [
                        15, 16, 17, 18, 19, 20, 21, 22, 24,
                      ]"
                      v-bind:key="index"
                    >
                      <td>H{{ item }} {{ cashFlow.cell["H" + item].label }}</td>
                      <td>
                        {{ cashFlow.cell["H" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["I" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K" + item].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>

                  <tbody>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H15"].label }}
                        <p class="backup" v-if="showFormula">
                          H15 = {{ cashFlow.cell["H15"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H15"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P22: dataInputSheet.form.income_and_expense.cell
                              .P22,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I15"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J15"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K15"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H16"].label }}
                        <p class="backup" v-if="showFormula">
                          H16 = {{ cashFlow.cell["H16"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H16"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P23: dataInputSheet.form.income_and_expense.cell
                              .P23,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I16"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J16"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K16"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H17"].label }}
                        <p class="backup" v-if="showFormula">
                          H17 = {{ cashFlow.cell["H17"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H17"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P24: dataInputSheet.form.income_and_expense.cell
                              .P24,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I17"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J17"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K17"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H18"].label }}
                        <p class="backup" v-if="showFormula">
                          I18 = {{ cashFlow.cell["I18"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H18"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["I18"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J18"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K18"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H19"].label }}
                        <p class="backup" v-if="showFormula">
                          I19 = {{ cashFlow.cell["I19"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H19"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I19"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P30: dataInputSheet.form.income_and_expense.cell
                              .P30,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J19"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K19"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H20"].label }}
                        <p class="backup" v-if="showFormula">
                          I20 = {{ cashFlow.cell["I20"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H20"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I20"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P31: dataInputSheet.form.income_and_expense.cell
                              .P31,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J20"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K20"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H21"].label }}
                        <p class="backup" v-if="showFormula">
                          I21 = {{ cashFlow.cell["I21"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H21"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I21"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P32: dataInputSheet.form.income_and_expense.cell
                              .P32,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J21"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K21"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        {{ cashFlow.cell["H22"].label }}
                        <p class="backup" v-if="showFormula">
                          I22 = {{ cashFlow.cell["I22"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H22"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I22"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P41: dataInputSheet.form.income_and_expense.cell
                              .P41,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J22"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K22"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H24"].label }}
                        <p class="backup" v-if="showFormula">
                          H24 = {{ cashFlow.cell["H24"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H24"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K41: dataInputSheet.form.income_and_expense.cell
                              .K41,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I24"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J24"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K24"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr class="tr-total">
                      <td></td>
                      <td>{{ cashFlow.cell["H25"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["I25"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["J25"].value | numeralFormat }}</td>
                      <td>
                        {{
                          cashFlow.cell["K25"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- table B กระแสเงินสดจ่ายผันแปร (backup) -->
                <table class="_td-pl-44">
                  <thead>
                    <tr>
                      <th>B กระแสเงินสดจ่ายผันแปร</th>
                      <th>เดือน</th>
                      <th>ปี</th>
                      <th>รวม</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody v-if="showBackup" class="backup">
                    <tr
                      v-for="(item, index) in [
                        27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
                      ]"
                      v-bind:key="index"
                    >
                      <td>H{{ item }} {{ cashFlow.cell["H" + item].label }}</td>
                      <td>
                        {{ cashFlow.cell["H" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["I" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K" + item].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H27"].label }}
                        <p class="backup" v-if="showFormula">
                          H27 = {{ cashFlow.cell["H27"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H27"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K30: dataInputSheet.form.income_and_expense.cell
                              .K30,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I27"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J27"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K27"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        {{ cashFlow.cell["H28"].label }}
                        <p class="backup" v-if="showFormula">
                          H28 = {{ cashFlow.cell["H28"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H28"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K31: dataInputSheet.form.income_and_expense.cell
                              .K31,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I28"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J28"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K28"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H29"].label }}
                        <p class="backup" v-if="showFormula">
                          H29 = {{ cashFlow.cell["H29"].formula }}
                        </p>
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["H29"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K32: dataInputSheet.form.income_and_expense.cell
                              .K32,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["I29"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J29"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K29"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr v-if="false">
                      <td>
                        {{ cashFlow.cell["H30"].label }}
                        <p class="backup" v-if="showFormula">
                          I30 = {{ cashFlow.cell["I30"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H30"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I30"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K33: dataInputSheet.form.income_and_expense.cell
                              .K33,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J30"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K30"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H31"].label }}
                        <p class="backup" v-if="showFormula">
                          I31 = {{ cashFlow.cell["I31"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H31"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I31"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K34: dataInputSheet.form.income_and_expense.cell
                              .K34,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J31"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K31"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H32"].label }}
                        <p class="backup" v-if="showFormula">
                          I32 = {{ cashFlow.cell["I32"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H32"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I32"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K35: dataInputSheet.form.income_and_expense.cell
                              .K35,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J32"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K32"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H33"].label }}
                        <p class="backup" v-if="showFormula">
                          I33 = {{ cashFlow.cell["I33"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H33"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I33"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K36: dataInputSheet.form.income_and_expense.cell
                              .K36,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J33"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K33"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H34"].label }}
                        <p class="backup" v-if="showFormula">
                          I34 = {{ cashFlow.cell["I34"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H34"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I34"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K37: dataInputSheet.form.income_and_expense.cell
                              .K37,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J34"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K34"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H35"].label }}
                        <p class="backup" v-if="showFormula">
                          I35 = {{ cashFlow.cell["I35"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H35"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I35"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K38: dataInputSheet.form.income_and_expense.cell
                              .K38,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J35"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K35"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H36"].label }}
                        <p class="backup" v-if="showFormula">
                          I36 = {{ cashFlow.cell["I36"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H36"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I36"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            K39: dataInputSheet.form.income_and_expense.cell
                              .K39,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J36"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K36"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H37"].label }}
                        <p class="backup" v-if="showFormula">
                          I37 = {{ cashFlow.cell["I37"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H37"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["I37"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J37"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K37"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr class="tr-total">
                      <td></td>
                      <td>{{ cashFlow.cell["H38"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["I38"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["J38"].value | numeralFormat }}</td>
                      <td>
                        {{
                          cashFlow.cell["K38"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- table C กระแสเงินสดจ่ายเพื่อการออม/การลงทุน (backup) -->
                <table class="_td-pl-44">
                  <thead>
                    <tr>
                      <th>C กระแสเงินสดจ่ายเพื่อการออม/การลงทุน</th>
                      <th>เดือน</th>
                      <th>ปี</th>
                      <th>รวม</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody v-if="showBackup" class="backup">
                    <tr
                      v-for="(item, index) in [40, 41, 42, 43, 44, 45, 46, 47]"
                      v-bind:key="index"
                    >
                      <td>H{{ item }} {{ cashFlow.cell["H" + item].label }}</td>
                      <td>
                        {{ cashFlow.cell["H" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["I" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J" + item].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K" + item].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H40"].label }}
                        <p class="backup" v-if="showFormula">
                          I40 = {{ cashFlow.cell["I40"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H40"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I40"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P35: dataInputSheet.form.income_and_expense.cell
                              .P35,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J40"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K40"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H41"].label }}
                        <p class="backup" v-if="showFormula">
                          I41 = {{ cashFlow.cell["I41"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H41"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I41"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P40: dataInputSheet.form.income_and_expense.cell
                              .P40,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J41"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K41"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H42"].label }}
                        <p class="backup" v-if="showFormula">
                          I42 = {{ cashFlow.cell["I42"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H42"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I42"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P36: dataInputSheet.form.income_and_expense.cell
                              .P36,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J42"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K42"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H43"].label }}
                        <p class="backup" v-if="showFormula">
                          I43 = {{ cashFlow.cell["I43"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H43"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I43"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P37: dataInputSheet.form.income_and_expense.cell
                              .P37,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J43"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K43"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H44"].label }}
                        <p class="backup" v-if="showFormula">
                          I44 = {{ cashFlow.cell["I44"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H44"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["I44"].value | numeralFormat }}
                      </td>
                      <td>
                        {{ cashFlow.cell["J44"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K44"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H45"].label }}
                        <p class="backup" v-if="showFormula">
                          I45 = {{ cashFlow.cell["I45"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H45"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I45"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P33: dataInputSheet.form.income_and_expense.cell
                              .P33,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J45"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K45"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H46"].label }}
                        <p class="backup" v-if="showFormula">
                          I46 = {{ cashFlow.cell["I46"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H46"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I46"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P34: dataInputSheet.form.income_and_expense.cell
                              .P34,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J46"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K46"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ cashFlow.cell["H47"].label }}
                        <p class="backup" v-if="showFormula">
                          I47 = {{ cashFlow.cell["I47"].formula }}
                        </p>
                      </td>
                      <td>
                        {{ cashFlow.cell["H47"].value | numeralFormat }}
                      </td>
                      <td>
                        <span v-show="!showEdit">
                          {{ cashFlow.cell["I47"].value | numeralFormat }}
                        </span>
                        <span
                          v-show="showEdit"
                          v-for="(item, index) in {
                            P42: dataInputSheet.form.income_and_expense.cell
                              .P42,
                          }"
                          v-bind:key="index"
                        >
                          <v-text-field
                            :ref="`dataInput.cell.${index}`"
                            :type="item.type"
                            :value="
                              dataInputSheet.cell[index]
                                ? dataInputSheet.cell[index].value
                                : ''
                            "
                            @change="
                              onDataInputChange(
                                `dataInput.cell.${index}`,
                                $event,
                                item.type
                              )
                            "
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        {{ cashFlow.cell["J47"].value | numeralFormat }}
                      </td>
                      <td>
                        {{
                          cashFlow.cell["K47"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>

                    <tr class="tr-total">
                      <td></td>
                      <td>{{ cashFlow.cell["H48"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["I48"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["J48"].value | numeralFormat }}</td>
                      <td>
                        {{
                          cashFlow.cell["K48"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>กระแสเงินสดจ่ายรวม</td>
                      <td>{{ cashFlow.cell["H50"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["I50"].value | numeralFormat }}</td>
                      <td>{{ cashFlow.cell["J50"].value | numeralFormat }}</td>
                      <td>
                        {{
                          cashFlow.cell["K50"].value
                            | numeralFormat("0,0[.]00")
                        }}%
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>กระแสเงินสดสุทธิ</h3>
                  </div>
                  <div class="column">
                    <h3>
                      <strong>{{
                        cashFlow.cell["J52"].value | numeralFormat
                      }}</strong>
                    </h3>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- DATA -->
    <v-row class="content-hide">
      <v-col cols="12">
        <h2>CashFlow</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(item, index) in cashFlow.cell"
        v-bind:key="index"
      >
        <v-text-field
          :disabled="true"
          :dense="true"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
          :placeholder="item.placeholder ? item.placeholder : 'ระบุจำนวน'"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import DoughnutChart from "../ChartJs/DoughnutChart.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "cash-flow-component",
  components: {
    DoughnutChart,
  },
  props: {
    printAble: Boolean,
    enableChartJs: Boolean,
    enableFormula: Boolean,
  },
  computed: {
    ...mapGetters(["dataInputSheet", "balanceSheet", "cashFlow", "user"]),
  },
  data: function () {
    return {
      btnEditColor: "default",
      btnFormulaColor: "default",
      showBackup: false,
      showFormula: false,
      showEdit: false,
    };
  },
  created() {
    console.log("cashFlow - created");
  },
  mounted() {
    console.log("cashFlow - mounted");
  },
  methods: {
    ...mapActions(["updateDataInput", "saveDataInput"]),
    getChartOptions(section) {
      return {};
    },
    getChartDataSet(section) {
      let dataset = {};
      if (section == "cashflow") {
        dataset.labels = ["กระแสเงินสดจ่ายรวม", "กระแสเงินสดสุทธิ"];
        // dataset.data = [3605400, 2134600];
        if (this.cashFlow.cell.J50.value != undefined) {
          dataset.data = [
            this.cashFlow.cell.J50.value,
            this.cashFlow.cell.J52.value,
          ];
        }
        dataset.backgroundColor = ["#fd8d50", "#71cbb7"];
        return dataset;
      } else {
        return {};
      }
    },
    onDataInputChange: function (name, value, type) {
      var keys = name.split(".");
      var sheet = keys[0];
      var cell = keys[2];
      this.updateDataInput({
        sheet: sheet,
        cell: cell,
        value: value,
        type: type,
      });
    },
    toggleShowFormula() {
      this.showFormula = !this.showFormula;
      if (this.showFormula) {
        this.btnFormulaColor = "warning";
      } else {
        this.btnFormulaColor = "default";
      }
    },
    toggleShowEdit() {
      this.showEdit = !this.showEdit;
      if (this.showEdit) {
        this.btnEditColor = "warning";
      } else {
        this.btnEditColor = "default";
      }
    },
    saveChanges() {
      this.showEdit = false;
      this.btnEditColor = "default";
      this.$parent.$refs.dataInputComponent.saveChanges();
    },
  },
};
</script>