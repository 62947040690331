<template>
<v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
    <v-app-bar-nav-icon @click="toggleDrawer()" />
    <v-toolbar-title style="width: 300px;padding-left:0;">
        <span class="hidden-sm-and-down"><img src="../../assets/img/logo/Estest_Horizontal_white_resize.png"></span>
    </v-toolbar-title>
    <v-spacer />

    <v-toolbar-items>
        <v-btn text v-for="(item, i) in userMenuItems"
          :key="i"
          :to="item.link" @click="hideDrawer">
            <v-icon v-text="item.icon"></v-icon>{{item.text}}
        </v-btn>
    </v-toolbar-items>
</v-app-bar>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/valid-v-on */
import _ from "lodash";
import {
    mapGetters,
    mapActions
} from "vuex";

export default {
    // getters from store
    computed: {
        ...mapGetters(["user", "isAuthenticated", "drawer"]),
        userMenuItems() {
            if (this.user.role !== undefined) {
                if (this.user.role != "admin") {
                    _.remove(this.items, function (item) {
                        return (item.link == "/manage/user")
                    });
                }
            }
            return this.items;
        },
    },
    // actions from store
    methods: {
        ...mapActions([
            "toggleDrawer" // map `this.toggleDrawer()` to `this.$store.dispatch('toggleDrawer')`
        ]),
        ...mapActions(["hideDrawer"]),
    },
    data: () => ({
        dialog: false,
        item: 0,
        items: [{
                text: "Dashboard",
                icon: "mdi-buffer",
                link: "/dashboard"
            },
            {
                text: "Plans",
                icon: "mdi-clipboard-text",
                link: "/plan"
            },
            {
                text: "User Management",
                icon: "mdi-account-multiple",
                link: "/manage/user",
            },
            {
                text: "Account",
                icon: "mdi-account",
                link: "/profile"
            },
            
            {
                text: "Logout",
                icon: "mdi-logout-variant",
                link: "/auth/logout"
            },
        ]
    })
};
</script>
