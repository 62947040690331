/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';

const state = {
  isAuthenticated: false,
  user: {},
};

const getters = {
  isAuthenticated: (state) => {
    return state.isAuthenticated;
  },
  role: (state) => {
    return state.user.role;
  },
  user: (state) => {
    return state.user;
  },
};

const actions = {
  login(context, payload) {
    context.commit("USER_LOGIN", payload);
  },
  logout(context, payload) {
    context.commit("USER_LOGOUT");
  },
};

const mutations = {
  USER_LOGIN(state, user) {
    // console.log("store.auth USER_LOGIN");
    state.isAuthenticated = true;
    // get some extended user data from firebase RTDB
    firebase
      .database()
      .ref("/users")
      .child(user.uid)
      .on("value", (snapshot) => {
        const dbUser = snapshot.val();
        state.role = dbUser.role;
        state.user = dbUser;
        console.log("store.auth USER_LOGIN", state.user);
      });
  },
  USER_LOGOUT(state) {
    // console.log("store.auth USER_LOGOUT");
    state.isAuthenticated = false;
    state.role = "";
    state.user = {};
    console.log("store.auth USER_LOGOUT", state.user);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
