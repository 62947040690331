<template>
  <v-container
    class="fluid pa-0 wealthplus-container export-pdf"
    id="financialRatio"
  >
    <v-row align="center" justify="center" style="margin-top: 12px;">
      <!-- <v-img src="../../assets/img/003-FNR.jpg" class="grey lighten-2">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>-->

      <div
        class="wrapper-content"
        id="financialRatioPage"
        v-bind:class="{ 'print-able': printAble, '': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          <div class="primary-content -financial-ratio">
            <div class="title-page">
              <div class="detail">
                <h2>Financial Ratio</h2>
                <h3>
                  การคำนวณอัตราส่วนทางการเงินสำหรับ คุณ {{
                    dataInputSheet.cell.C4.value
                  }}
                </h3>
              </div>

              <img
                src="../../assets/img/financial-ratio/title-page-img.png"
                alt
              />
            </div>

            <div class="box-content _pd-0">
              <div class="group-chart">
                <div class="column-22"></div>                
                <div class="column-33">
                  <div
                    class="box-chart"
                    style="position: relative; width: 136px; height: 136px"
                  >
                    <img
                      v-show="!enableChartJs"
                      src="../../assets/img/financial-ratio/img-chart-02.png"
                      alt
                    />
                    <div
                      v-show="enableChartJs"
                      class="absolute-center-parent mimi"
                    >
                      <div
                        v-bind:class="
                          financialRatio.cell.G10.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        {{ financialRatio.cell.E10.value | numeralFormat
                        }}<br />เดือน
                      </div>
                    </div>
                    <div
                      style="
                        width: 136px;
                        height: 136px;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                      "
                      v-show="enableChartJs"
                    >
                      <DoughnutChart
                        :width="136"
                        :height="136"
                        :chartDataSet="getChartDataSet('E10')"
                        :chartOptions="getChartOptions('E10')"
                      ></DoughnutChart>
                    </div>
                    <!-- {{ financialRatio.cell.E10.value | numeralFormat }}
                    {{ financialRatio.cell.G10.value }} -->
                  </div>
                  <div class="detail">
                    <p>อัตราสภาพคล่องพื้นฐาน</p>
                    <p
                      v-if="!financialRatio.cell.G10.value"
                      class="below-threshold"
                    >
                      <img src="../../assets/img/icons/angle-down-orange.svg" class="icon" alt="">
                      ต่ำกว่าเกณฑ์
                    </p>
                    <p
                      v-if="financialRatio.cell.G10.value"
                      class="in-line-with"
                    >
                      <img src="../../assets/img/icons/angle-top-green.svg" class="icon" alt="">
                      อยู่ในเกณฑ์
                    </p>
                    <p class="-fs-15">เกณฑ์มาตรฐาน ควรอยู่ในระหว่าง 3-12 เดือน</p>
                  </div>
                </div>                               
                <div class="column-33">
                  <div
                    class="box-chart"
                    style="position: relative; width: 136px; height: 136px"
                  >
                    <img
                      v-show="!enableChartJs"
                      src="../../assets/img/financial-ratio/img-chart-01.png"
                      alt
                    />
                    <div
                      v-show="enableChartJs"
                      class="absolute-center-parent mimi"
                    >
                      <div
                        v-bind:class="
                          financialRatio.cell.G7.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        {{ financialRatio.cell.E7.value | numeralFormat }} เท่า
                      </div>
                    </div>
                    <div
                      style="
                        width: 136px;
                        height: 136px;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                      "
                      v-show="enableChartJs"
                    >
                      <DoughnutChart
                        :width="136"
                        :height="136"
                        :chartDataSet="getChartDataSet('E7')"
                        :chartOptions="getChartOptions('E7')"
                      ></DoughnutChart>
                    </div>
                    <!-- {{ financialRatio.cell.E7.value | numeralFormat }}
                    {{ financialRatio.cell.G7.value }} -->
                  </div>
                  <div class="detail">
                    <p>อัตราสภาพคล่อง</p>
                    <p
                      v-if="!financialRatio.cell.G7.value"
                      class="below-threshold"
                    >
                    <img src="../../assets/img/icons/angle-down-orange.svg" class="icon" alt="">
                      ต่ำกว่าเกณฑ์
                    </p>
                    <p v-if="financialRatio.cell.G7.value" class="in-line-with">
                      <img src="../../assets/img/icons/angle-top-green.svg" class="icon" alt="">
                      อยู่ในเกณฑ์
                    </p>
                    <p class="-fs-15">เกณฑ์มาตรฐาน ควรมากกว่า 1 เท่า</p>
                  </div>
                </div>
                <div class="column-22"></div> 
                <div class="column-33">
                  <div
                    class="box-chart"
                    style="position: relative; width: 136px; height: 136px"
                  >
                    <img
                      v-show="!enableChartJs"
                      src="../../assets/img/financial-ratio/img-chart-03.png"
                      alt
                    />
                    <div
                      v-show="enableChartJs"
                      class="absolute-center-parent mimi"
                    >
                      <div
                        v-bind:class="
                          financialRatio.cell.G14.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        {{ financialRatio.cell.E14.value | numeralFormat }}%
                      </div>
                    </div>
                    <div
                      style="
                        width: 136px;
                        height: 136px;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                      "
                      v-show="enableChartJs"
                    >
                      <DoughnutChart
                        :width="136"
                        :height="136"
                        :chartDataSet="getChartDataSet('E14')"
                        :chartOptions="getChartOptions('E14')"
                      ></DoughnutChart>
                    </div>
                    <!-- {{ financialRatio.cell.E14.value | numeralFormat }}
                    {{ financialRatio.cell.G14.value }} -->
                  </div>
                  <div class="detail">
                    <p>อัตราส่วนหนี้สินต่อทรัพสิน</p>
                    <p
                      v-if="!financialRatio.cell.G14.value"
                      class="below-threshold"
                    >
                    <img src="../../assets/img/icons/angle-down-orange.svg" class="icon" alt="">
                      ต่ำกว่าเกณฑ์
                    </p>
                    <p
                      v-if="financialRatio.cell.G14.value"
                      class="in-line-with"
                    >
                    <img src="../../assets/img/icons/angle-top-green.svg" class="icon" alt="">
                      อยู่ในเกณฑ์
                    </p>
                    <p class="-fs-15">เกณฑ์มาตรฐาน ไม่ควรเกิน 50%</p>
                  </div>
                </div>
                <div class="column-33">
                  <div
                    class="box-chart"
                    style="position: relative; width: 136px; height: 136px"
                  >
                    <img
                      v-show="!enableChartJs"
                      src="../../assets/img/financial-ratio/img-chart-04.png"
                      alt
                    />
                    <div
                      v-show="enableChartJs"
                      class="absolute-center-parent mimi"
                    >
                      <div
                        v-bind:class="
                          financialRatio.cell.G18.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        {{ financialRatio.cell.E18.value | numeralFormat }}%
                      </div>
                    </div>
                    <div
                      style="
                        width: 136px;
                        height: 136px;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                      "
                      v-show="enableChartJs"
                    >
                      <DoughnutChart
                        :width="136"
                        :height="136"
                        :chartDataSet="getChartDataSet('E18')"
                        :chartOptions="getChartOptions('E18')"
                      ></DoughnutChart>
                    </div>
                    <!-- {{ financialRatio.cell.E18.value | numeralFormat }}
                    {{ financialRatio.cell.G18.value }} -->
                  </div>
                  <div class="detail">
                    <p>สัดส่วนเงินออม</p>
                    <p
                      v-if="!financialRatio.cell.G18.value"
                      class="below-threshold"
                    >
                    <img src="../../assets/img/icons/angle-down-orange.svg" class="icon" alt="">
                      ต่ำกว่าเกณฑ์
                    </p>
                    <p
                      v-if="financialRatio.cell.G18.value"
                      class="in-line-with"
                    >
                    <img src="../../assets/img/icons/angle-top-green.svg" class="icon" alt="">
                      อยู่ในเกณฑ์
                    </p>
                    <p class="-fs-15">เกณฑ์มาตรฐาน ควรมากกว่า 10%</p>
                  </div>
                </div>
                <div class="column-33">
                  <div
                    class="box-chart"
                    style="position: relative; width: 136px; height: 136px"
                  >
                    <img
                      v-show="!enableChartJs"
                      src="../../assets/img/financial-ratio/img-chart-05.png"
                      alt
                    />
                    <div
                      v-show="enableChartJs"
                      class="absolute-center-parent mimi"
                    >
                      <div
                        v-bind:class="
                          financialRatio.cell.G21.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        {{ financialRatio.cell.E21.value | numeralFormat }}%
                      </div>
                    </div>
                    <div
                      style="
                        width: 136px;
                        height: 136px;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                      "
                      v-show="enableChartJs"
                    >
                      <DoughnutChart
                        :width="136"
                        :height="136"
                        :chartDataSet="getChartDataSet('E21')"
                        :chartOptions="getChartOptions('E21')"
                      ></DoughnutChart>
                    </div>
                    <!-- {{ financialRatio.cell.E21.value | numeralFormat }}
                    {{ financialRatio.cell.G21.value }} -->
                  </div>
                  <div class="detail">
                    <p>อัตราส่วนการลงทุน</p>
                    <p
                      v-if="!financialRatio.cell.G21.value"
                      class="below-threshold"
                    >
                    <img src="../../assets/img/icons/angle-down-orange.svg" class="icon" alt="">
                      ต่ำกว่าเกณฑ์
                    </p>
                    <p
                      v-if="financialRatio.cell.G21.value"
                      class="in-line-with"
                    >
                    <img src="../../assets/img/icons/angle-top-green.svg" class="icon" alt="">
                      อยู่ในเกณฑ์
                    </p>
                    <p class="-fs-15">เกณฑ์มาตรฐาน ควรมากกว่า 50% ขึ้นไป</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>1. การวิเคราะห์สภาพคล่อง</h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <p>
                        สินทรัพย์ที่มีสภาพคล่อง
                        <strong>{{
                          financialRatio.cell.D10.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        กระแสเงินสดจ่ายต่อเดือน
                        <strong>{{
                          financialRatio.cell.D11.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        อัตราสภาพคล่องพื้นฐาน
                        <strong
                          v-bind:class="
                            financialRatio.cell.G10.value
                              ? '_c-green'
                              : '_c-orange'
                          "
                          >{{
                            financialRatio.cell.E10.value | numeralFormat
                          }}
                          เดือน</strong
                        >
                      </p>
                    </div>
                    <div class="column">
                      <p
                        v-bind:class="
                          financialRatio.cell.G10.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        <strong>{{ financialRatio.cell.F10.value }}</strong
                        >ผลลัพธ์ที่ได้ควรมากกว่า 3-6 เดือน
                        ถือว่าดีไม่ต่ำกว่าเกณฑ์มาตรฐาน
                      </p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="column">
                      <p>
                        สินทรัพย์ที่มีสภาพคล่อง
                        <strong>{{
                          financialRatio.cell.D7.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        หนี้สินระยะสั้น
                        <strong>{{
                          financialRatio.cell.D8.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        อัตราสภาพคล่อง
                        <strong
                          v-bind:class="
                            financialRatio.cell.G7.value
                              ? '_c-green'
                              : '_c-orange'
                          "
                          >{{
                            financialRatio.cell.E7.value
                              | numeralFormat("0,0[.]00")
                          }}
                          เท่า</strong
                        >
                      </p>
                    </div>
                    <div class="column">
                      <p
                        v-bind:class="
                          financialRatio.cell.G7.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        <strong>{{ financialRatio.cell.F7.value }}</strong
                        >ผลลัพธ์ที่ได้ควรมากกว่า 1ถือว่าดีไม่ต่ำกว่าเกณฑ์มาตรฐาน
                      </p>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -orange">
                <header class="heading">
                  <div class="column">
                    <h3>2. การวิเคราะห์ภาระหนี้สิน</h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <p>
                        หนี้สินรวม
                        <strong>{{
                          financialRatio.cell.D14.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        สินทรัพย์รวม
                        <strong>{{
                          financialRatio.cell.D15.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        อัตราส่วนหนี้สินต่อทรัพย์สิน
                        <strong
                          v-bind:class="
                            financialRatio.cell.G14.value
                              ? '_c-green'
                              : '_c-orange'
                          "
                          >{{
                            financialRatio.cell.E14.value
                              | numeralFormat("0,0[.]00")
                          }}%</strong
                        >
                      </p>
                    </div>
                    <div class="column">
                      <p
                        v-bind:class="
                          financialRatio.cell.G14.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        <strong>{{ financialRatio.cell.F14.value }}</strong
                        >ผลลัพธ์ที่ได้ควรน้อยกว่า 50%
                        ถือว่าดีไม่สูงกว่าเกณฑ์มาตรฐาน
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-content _pd-0">
              <div class="table-style -green">
                <header class="heading">
                  <div class="column">
                    <h3>3. การวิเคราะห์การออมการลงทุน</h3>
                  </div>
                </header>

                <div class="flex-content">
                  <div class="item">
                    <div class="column">
                      <p>
                        เงินออม
                        <strong>{{
                          financialRatio.cell.D18.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        รายได้รวม
                        <strong>{{
                          financialRatio.cell.D19.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        อัตราส่วนเงินออม
                        <strong
                          v-bind:class="
                            financialRatio.cell.G18.value
                              ? '_c-green'
                              : '_c-orange'
                          "
                          >{{
                            financialRatio.cell.E18.value
                              | numeralFormat("0,0[.]00")
                          }}%</strong
                        >
                      </p>
                    </div>
                    <div class="column">
                      <p
                        v-bind:class="
                          financialRatio.cell.G18.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        <strong>{{ financialRatio.cell.F18.value }}</strong
                        >ผลลัพธ์ที่ได้ควรมากกว่า 10%
                        ถือว่าดีไม่ต่ำกว่าเกณฑ์มาตรฐาน
                      </p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="column">
                      <p>
                        สินทรัพย์ลงทุน
                        <strong>{{
                          financialRatio.cell.D21.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        ความมั่งคั่งสุทธิ
                        <strong>{{
                          financialRatio.cell.D22.value | numeralFormat
                        }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p>
                        อัตราส่วนการลงทุน
                        <strong
                          v-bind:class="
                            financialRatio.cell.G21.value
                              ? '_c-green'
                              : '_c-orange'
                          "
                          >{{
                            financialRatio.cell.E21.value
                              | numeralFormat("0,0[.]00")
                          }}%</strong
                        >
                      </p>
                    </div>
                    <div class="column">
                      <p
                        v-bind:class="
                          financialRatio.cell.G21.value
                            ? '_c-green'
                            : '_c-orange'
                        "
                      >
                        <strong>{{ financialRatio.cell.F21.value }}</strong
                        >ผลลัพธ์ที่ได้ควรมากกว่าหรือเท่ากับ 50%
                        ถือว่าดีไม่ต่ำกว่าเกณฑ์มาตรฐาน
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- DATA -->
    <v-row class="content-hide">
      <v-col cols="12">
        <h2>Financial Ratio</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(item, index) in financialRatio.cell"
        v-bind:key="index"
      >
        <v-text-field
          :disabled="true"
          :dense="true"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
          :placeholder="item.placeholder ? item.placeholder : 'ระบุจำนวน'"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import DoughnutChart from "../ChartJs/DoughnutChart.js";
export default {
  name: "financial-ratio-component",
  components: {
    DoughnutChart,
  },
  props: {
    printAble: Boolean,
    enableChartJs: Boolean,
  },
  computed: {
    ...mapGetters(["dataInputSheet", "financialRatio", "user"]),
  },
  data: function () {
    return {};
  },
  created() {
    console.log("financialRatio - created");
  },
  mounted() {
    console.log("financialRatio - mounted");
  },
  methods: {
    getChartOptions(section) {
      return {
        tooltips: {
          enabled: false,
        },
      };
    },
    getChartDataSet(section) {
      let dataset = {};
      if (section == "E7") {
        dataset.labels = ["อัตราสภาพคล่อง", ""];
        if (this.financialRatio.cell.E7.value != undefined) {
          dataset.data = [
            this.financialRatio.cell.E7.value,
            100 - this.financialRatio.cell.E7.value,
          ];
        }
        dataset.backgroundColor = ["#fd8d50", "#e1e1e1"];
        if (this.financialRatio.cell.G7.value) {
          dataset.backgroundColor = ["#71cbb7", "#e1e1e1"];
        }
        return dataset;
      } else if (section == "E10") {
        dataset.labels = ["อัตราสภาพคล่องพื้นฐาน", ""];
        if (this.financialRatio.cell.E10.value != undefined) {
          if (this.financialRatio.cell.E10.value >= 3) {
            dataset.data = [100, 0];
          } else {
            dataset.data = [
              this.financialRatio.cell.E10.value,
              3 - this.financialRatio.cell.E10.value,
            ];
          }
        }
        dataset.backgroundColor = ["#fd8d50", "#e1e1e1"];
        if (this.financialRatio.cell.G10.value) {
          dataset.backgroundColor = ["#71cbb7", "#e1e1e1"];
        }
        return dataset;
      } else if (section == "E14") {
        dataset.labels = ["อัตราส่วนหนี้สินต่อทรัพสิน", ""];
        if (this.financialRatio.cell.E14.value != undefined) {
          dataset.data = [
            this.financialRatio.cell.E14.value,
            100 - this.financialRatio.cell.E14.value,
          ];
        }
        dataset.backgroundColor = ["#fd8d50", "#e1e1e1"];
        if (this.financialRatio.cell.G14.value) {
          dataset.backgroundColor = ["#71cbb7", "#e1e1e1"];
        }
        return dataset;
      } else if (section == "E18") {
        dataset.labels = ["สัดส่วนเงินออม", ""];
        if (this.financialRatio.cell.E18.value != undefined) {
          dataset.data = [
            this.financialRatio.cell.E18.value,
            100 - this.financialRatio.cell.E18.value,
          ];
        }
        dataset.backgroundColor = ["#fd8d50", "#e1e1e1"];
        if (this.financialRatio.cell.G18.value) {
          dataset.backgroundColor = ["#71cbb7", "#e1e1e1"];
        }
        return dataset;
      } else if (section == "E21") {
        dataset.labels = ["อัตราส่วนการลงทุน", ""];
        if (this.financialRatio.cell.E21.value != undefined) {
          dataset.data = [
            this.financialRatio.cell.E21.value,
            100 - this.financialRatio.cell.E21.value,
          ];
        }
        dataset.backgroundColor = ["#fd8d50", "#e1e1e1"];
        if (this.financialRatio.cell.G21.value) {
          dataset.backgroundColor = ["#71cbb7", "#e1e1e1"];
        }
        return dataset;
      } else {
        return {};
      }
    },
  },
};
</script>