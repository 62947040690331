<template>
  <v-container
    class="fluid pa-0 wealthplus-container export-pdf"
    id="balanceSheet"
  >
    <v-row class="editable">
      <v-col cols="12">
        <ul>
          <li>
            <v-btn small color="primary" @click="saveChanges()">
              <v-icon small>mdi-content-save</v-icon>Save Changes
            </v-btn>
          </li>
          <li>
            <v-btn small :color="btnEditColor" @click="toggleShowEdit()">
              <v-icon small>mdi-lead-pencil</v-icon>Edit
            </v-btn>
          </li>
          <li v-if="enableFormula">
            <v-btn small :color="btnFormulaColor" @click="toggleShowFormula()">
              <v-icon small>mdi-calculator</v-icon>Formula
            </v-btn>
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <!-- <v-img src="../../assets/img/001-BLS.jpg" class="grey lighten-2">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>-->

      <!-- Cover -->
      <div
        id="CoverPage"
        class="wrapper-content"
        v-bind:class="{ 'print-able': printAble, 'content-hide': !printAble }"
      >
        <div class="main-content -cover">
          <header class="heading">
            <h2>Financial Report</h2>
            <p>คุณ {{ dataInputSheet.cell.C4.value }}</p>
          </header>

          <div class="list-analysis">
            <div class="items">
              <div class="item">
                <p class="investment">
                  <img
                    src="../../assets/img/cover/investment.svg"
                    alt=""
                    class="icon"
                  />
                  Investment
                </p>
              </div>
              <div class="item">
                <p class="tax">
                  <img
                    src="../../assets/img/cover/tax.svg"
                    alt=""
                    class="icon"
                  />Tax
                </p>
              </div>
              <div class="item">
                <p class="retirement">
                  <img
                    src="../../assets/img/cover/retirement.svg"
                    alt=""
                    class="icon"
                  />Retirement
                </p>
              </div>
              <div class="item">
                <p class="education">
                  <img
                    src="../../assets/img/cover/education.svg"
                    alt=""
                    class="icon"
                  />Education
                </p>
              </div>
              <div class="item">
                <p class="protection">
                  <img
                    src="../../assets/img/cover/protection.svg"
                    alt=""
                    class="icon"
                  />Protection
                </p>
              </div>
              <div class="item">
                <p class="cash-flow">
                  <img
                    src="../../assets/img/cover/cash-flow.svg"
                    alt=""
                    class="icon"
                  />Cash Flow
                </p>
              </div>
              <div class="item">
                <p class="financial-ratio">
                  <img
                    src="../../assets/img/cover/financial-ratio.svg"
                    alt=""
                    class="icon"
                  />Financial Ratio
                </p>
              </div>
              <div class="item">
                <p class="balance-sheet">
                  <img
                    src="../../assets/img/cover/balance-sheet.svg"
                    alt=""
                    class="icon"
                  />Balance Sheet
                </p>
              </div>
            </div>
          </div>

          <footer class="footer">
            <p class="date">{{ planItem.createAt | momentTH }}</p>

            <div class="group-text">
              <div class="flex-content">
                <p>{{ user.displayName }}</p>
                <p>Financial Planner</p>
              </div>
              <p>{{ user.phoneNumber | phone }}</p>
            </div>
          </footer>
        </div>
      </div>

      <!-- Summary -->
      <div
        id="SummaryPage"
        class="wrapper-content"
        v-bind:class="{ 'print-able': printAble, 'content-hide': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          <div class="primary-content -home">
            <div class="title-page">
              <div class="detail">
                <h2>financial plan</h2>
                <h3>
                  แผนการเงินสำหรับ <br />คุณ {{ dataInputSheet.cell.C4.value }}
                </h3>
              </div>

              <img src="../../assets/img/home/title-page-img.png" alt />
            </div>

            <div class="section-content">
              <div class="box-cash-flow">
                <h2>Cash Flow</h2>

                <div class="box-content">
                  <div class="flex-content">
                    <div class="item">
                      <div class="column">
                        <p>กระแสเงินสดรับ</p>
                        <p>
                          {{ cashFlow.cell.J11.value | numeralFormat }}
                          <small
                            >{{
                              cashFlow.cell.K11.value
                                | numeralFormat("0,0[.]00")
                            }}%</small
                          >
                        </p>
                      </div>
                      <div class="column">
                        <p>กระแสเงินสดจ่ายรวม</p>
                        <p>
                          {{ cashFlow.cell.J50.value | numeralFormat }}
                          <small
                            >-{{
                              cashFlow.cell.K50.value
                                | numeralFormat("0,0[.]00")
                            }}%</small
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="section-content _flex">
              <div class="box-balance-sheet">
                <h2>Balance Sheet</h2>

                <div class="box-content _pd-0">
                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.I13.value > 0 ? '-orange2' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>หนี้สินระยะสั้น</h3>
                        <h2>
                          {{ balanceSheet.cell.I13.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.J13.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                  </div>
                </div>

                <div class="box-content _pd-0">
                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.I32.value > 0 ? '-blue' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>ความมั่งคั่งสุทธิ (Net Worth)</h3>
                        <h2>
                          {{ balanceSheet.cell.I32.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.J32.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                  </div>
                </div>

                <div class="box-content _pd-0">
                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.I28.value > 0 ? '-orange' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>หนี้สินระยะยาว</h3>
                        <h2>
                          {{ balanceSheet.cell.I28.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.J28.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                  </div>
                </div>
              </div>

              <div class="box-financial-ratio">
                <h2>Financial Ratio</h2>

                <div class="box-content _pd-0">
                  <div class="table-style -green">
                    <header class="heading">
                      <div class="column">
                        <h3>1. การวิเคราะห์สภาพคล่อง</h3>
                        <h2>อยู่ในเกณฑ์มาตรฐาน</h2>
                      </div>
                      <div class="column">
                        <img
                          src="../../assets/img/icons/inline-with-white.svg"
                          alt
                        />
                      </div>
                    </header>
                  </div>
                </div>

                <div class="box-content _pd-0">
                  <div class="table-style -orange">
                    <header class="heading">
                      <div class="column">
                        <h3>2. การวิเคราะห์ภาระหนี้สิน</h3>
                        <h2>อยู่ในเกณฑ์มาตรฐาน</h2>
                      </div>
                      <div class="column">
                        <img
                          src="../../assets/img/icons/inline-with-white.svg"
                          alt
                        />
                      </div>
                    </header>
                  </div>
                </div>

                <div class="box-content _pd-0">
                  <div class="table-style -green">
                    <header class="heading">
                      <div class="column">
                        <h3>3. การวิเคราะห์การออมการลงทุน</h3>
                        <h2>ต่ำกว่าเกณฑ์มาตรฐาน</h2>
                      </div>
                      <div class="column">
                        <img src="../../assets/img/icons/below-white.svg" alt />
                      </div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- balancesheet -->
      <div
        class="wrapper-content"
        id="balanceSheetPage"
        v-bind:class="{ 'print-able': printAble, '': !printAble }"
      >
        <div class="main-content">
          <header class="header-content">
            <h1 class="logo">ESTESTWEALTH</h1>
            <p class="username">ผู้ให้คำปรึกษา {{ user.displayName }}</p>
          </header>

          <div class="primary-content -balance-sheet">
            <div class="title-page">
              <div class="detail">
                <h2>Balance Sheet</h2>
                <h3>รายการงบดุลสำหรับ คุณ {{ balanceSheet.cell.A1.value }}</h3>
              </div>

              <img
                src="../../assets/img/balance-sheet/title-page-img.png"
                alt
              />
            </div>

            <div class="content-total-assets">
              <div class="box-content -content-percent">
                <div
                  id="balanceSheetChart1"
                  class="box-chart"
                  style="position: relative; width: 365px; height: 365px"
                >
                  <img
                    v-show="!enableChartJs"
                    src="../../assets/img/balance-sheet/img-chart-01.png"
                    alt
                  />
                  <div v-show="enableChartJs" class="absolute-center-parent">
                    <div>สินทรัพย์รวม</div>
                  </div>
                  <div
                    style="
                      width: 365px;
                      height: 365px;
                      position: absolute;
                      z-index: 1;
                      top: 0;
                    "
                    v-show="enableChartJs"
                  >
                    <DoughnutChart
                      :width="365"
                      :height="365"
                      :chartDataSet="getChartDataSet('assets')"
                      :chartOptions="getChartOptions('assets')"
                    ></DoughnutChart>
                  </div>
                </div>

                <div class="detail">
                  <div class="section head">
                    <h3>สินทรัพย์รวม</h3>
                    <p>{{ balanceSheet.cell.E47.value | numeralFormat }}</p>
                  </div>
                  <div
                    class="section"
                    v-bind:class="
                      balanceSheet.cell.E47.value > 0 ? '-ocean' : ''
                    "
                  >
                    <div class="column-6">
                      <p>สินทรัพย์สภาพคล่อง</p>
                    </div>
                    <div class="column-6">
                      <p>
                        <span>{{
                          balanceSheet.cell.E13.value | numeralFormat
                        }}</span>
                        <span
                          >{{
                            balanceSheet.cell.F13.value
                              | numeralFormat("0,0[.]00")
                          }}%</span
                        >
                      </p>
                    </div>
                    <div class="column-12">
                      <div class="percent-bar">
                        <div
                          class="bar"
                          v-bind:style="{
                            width: balanceSheet.cell.F13.value + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="section"
                    v-bind:class="
                      balanceSheet.cell.E31.value > 0 ? '-blue' : ''
                    "
                  >
                    <div class="column-6">
                      <p>สินทรัพย์เพื่อการลงทุน</p>
                    </div>
                    <div class="column-6">
                      <p>
                        <span>{{
                          balanceSheet.cell.E31.value | numeralFormat
                        }}</span>
                        <span
                          >{{
                            balanceSheet.cell.F31.value
                              | numeralFormat("0,0[.]00")
                          }}%</span
                        >
                      </p>
                    </div>
                    <div class="column-12">
                      <div class="percent-bar">
                        <div
                          class="bar"
                          v-bind:style="{
                            width: balanceSheet.cell.F31.value + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="section"
                    v-bind:class="
                      balanceSheet.cell.E41.value > 0 ? '-green' : ''
                    "
                  >
                    <div class="column-6">
                      <p>สินทรัพย์ส่วนตัว/มีค่า</p>
                    </div>
                    <div class="column-6">
                      <p>
                        <span>{{
                          balanceSheet.cell.E41.value | numeralFormat
                        }}</span>
                        <span
                          >{{
                            balanceSheet.cell.F41.value
                              | numeralFormat("0,0[.]00")
                          }}%</span
                        >
                      </p>
                    </div>
                    <div class="column-12">
                      <div class="percent-bar">
                        <div
                          class="bar"
                          v-bind:style="{
                            width: balanceSheet.cell.F41.value + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="section"
                    v-bind:class="
                      balanceSheet.cell.E46.value > 0 ? '-green2' : ''
                    "
                  >
                    <div class="column-6">
                      <p>สินทรัพย์ที่ไม่มีตัวตน</p>
                    </div>
                    <div class="column-6">
                      <p>
                        <span>{{
                          balanceSheet.cell.E46.value | numeralFormat
                        }}</span>
                        <span
                          >{{
                            balanceSheet.cell.F46.value
                              | numeralFormat("0,0[.]00")
                          }}%</span
                        >
                      </p>
                    </div>
                    <div class="column-12">
                      <div class="percent-bar">
                        <div
                          class="bar"
                          v-bind:style="{
                            width: balanceSheet.cell.F46.value + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input type="checkbox" id="expand-assets" />
              <label class="btn-expand" for="expand-assets"></label>

              <div class="group-table-data">
                <div class="left">
                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.E13.value > 0 ? '-ocean' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>สินทรัพย์สภาพคล่อง</h3>
                        <h2>
                          {{ balanceSheet.cell.E13.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.F13.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                    <!-- table - สินทรัพย์สภาพคล่อง (backup) -->
                    <table v-if="showBackup" class="backup">
                      <tbody>
                        <tr
                          v-for="(item, index) in [6, 7, 8, 9, 10, 11]"
                          v-bind:key="index"
                        >
                          <td>
                            <span
                              >E{{ item }}
                              {{ balanceSheet.cell["E" + item].formula }}</span
                            >
                            {{ balanceSheet.cell["E" + item].label }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["E" + item].value
                                | numeralFormat
                            }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F" + item].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E6"].label }}
                            <p class="backup" v-if="showFormula">
                              E6 = {{ balanceSheet.cell["E6"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E6"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K3: dataInputSheet.form.assets_and_liabilities
                                  .cell.K3,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F6"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E7"].label }}
                            <p class="backup" v-if="showFormula">
                              E7 = {{ balanceSheet.cell["E7"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E7"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K4: dataInputSheet.form.assets_and_liabilities
                                  .cell.K4,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F7"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E8"].label }}
                            <p class="backup" v-if="showFormula">
                              E8 = {{ balanceSheet.cell["E8"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E8"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P4: dataInputSheet.form.assets_and_liabilities
                                  .cell.P4,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F8"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E11"].label }}
                            <p class="backup" v-if="showFormula">
                              E11 = {{ balanceSheet.cell["E11"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E11"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P5: dataInputSheet.form.assets_and_liabilities
                                  .cell.P5,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F11"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E10"].label }}
                            <p class="backup" v-if="showFormula">
                              E10 = {{ balanceSheet.cell["E10"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E10"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K5: dataInputSheet.form.assets_and_liabilities
                                  .cell.K5,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F10"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E9"].label }}
                            <p class="backup" v-if="showFormula">
                              E9 = {{ balanceSheet.cell["E9"].formula }}
                            </p>
                          </td>
                          <td>
                            <span>
                              {{
                                balanceSheet.cell["E9"].value | numeralFormat
                              }}
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F9"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.E41.value > 0 ? '-green' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>สินทรัพย์ส่วนตัว/มีค่า</h3>
                        <h2>
                          {{ balanceSheet.cell.E41.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.F41.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>

                    <!-- table สินทรัพย์ส่วนตัว/มีค่า (backup) -->
                    <table v-if="showBackup" class="backup">
                      <tbody>
                        <tr
                          v-for="(item, index) in [
                            33, 34, 35, 36, 37, 38, 39, 40,
                          ]"
                          v-bind:key="index"
                        >
                          <td>
                            <span
                              >E{{ item }}
                              {{ balanceSheet.cell["E" + item].formula }}</span
                            >
                            {{ balanceSheet.cell["E" + item].label }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["E" + item].value
                                | numeralFormat
                            }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F" + item].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E33"].label }}
                            <p class="backup" v-if="showFormula">
                              E33 = {{ balanceSheet.cell["E33"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E33"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F33"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E34"].label }}
                            <p class="backup" v-if="showFormula">
                              E34 = {{ balanceSheet.cell["E34"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E34"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P13: dataInputSheet.form.assets_and_liabilities
                                  .cell.P13,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F34"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E35"].label }}
                            <p class="backup" v-if="showFormula">
                              E35 = {{ balanceSheet.cell["E35"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E35"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K13: dataInputSheet.form.assets_and_liabilities
                                  .cell.K13,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F35"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["E36"].label }}
                            <p class="backup" v-if="showFormula">
                              E36 = {{ balanceSheet.cell["E36"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E36"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K16: dataInputSheet.form.assets_and_liabilities
                                  .cell.K16,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F36"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["E37"].label }}
                            <p class="backup" v-if="showFormula">
                              E37 = {{ balanceSheet.cell["E37"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E37"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K14: dataInputSheet.form.assets_and_liabilities
                                  .cell.K14,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F37"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E38"].label }}
                            <p class="backup" v-if="showFormula">
                              E38 = {{ balanceSheet.cell["E38"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E38"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P15: dataInputSheet.form.assets_and_liabilities
                                  .cell.P15,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F38"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E39"].label }}
                            <p class="backup" v-if="showFormula">
                              E39 = {{ balanceSheet.cell["E39"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E39"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P17: dataInputSheet.form.assets_and_liabilities
                                  .cell.P17,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F39"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E40"].label }}
                            <p class="backup" v-if="showFormula">
                              E40 = {{ balanceSheet.cell["E40"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E40"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F40"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.E46.value > 0 ? '-green2' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>สินทรัพย์ที่ไม่มีตัวตน</h3>
                        <h2>
                          {{ balanceSheet.cell.E46.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.F46.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                    <table>
                      <tbody>
                        <tr
                          v-for="(item, index) in [43, 44]"
                          v-bind:key="index"
                        >
                          <td>
                            {{ balanceSheet.cell["E" + item].label }}
                            <p class="backup" v-if="showFormula">
                              E{{ item }} =
                              {{ balanceSheet.cell["E" + item].formula }}
                            </p>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["E" + item].value
                                | numeralFormat
                            }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F" + item].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="right">
                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.E31.value > 0 ? '-blue' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>สินทรัพย์เพื่อการลงทุน</h3>
                        <h2>
                          {{ balanceSheet.cell.E31.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.F31.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                    <!-- table สินทรัพย์เพื่อการลงทุน (backup)-->
                    <table v-if="showBackup" class="backup">
                      <tbody>
                        <tr
                          v-for="(item, index) in [
                            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                            28, 29, 30,
                          ]"
                          v-bind:key="index"
                        >
                          <td>
                            <span
                              >E{{ item }}
                              {{ balanceSheet.cell["E" + item].formula }}</span
                            >
                            {{ balanceSheet.cell["E" + item].label }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["E" + item].value
                                | numeralFormat
                            }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F" + item].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E15"].label }}
                            <p class="backup" v-if="showFormula">
                              E15 = {{ balanceSheet.cell["E15"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E15"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F15"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E16"].label }}
                            <p class="backup" v-if="showFormula">
                              E16 = {{ balanceSheet.cell["E16"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E16"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F16"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E17"].label }}
                            <p class="backup" v-if="showFormula">
                              E17 = {{ balanceSheet.cell["E17"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E17"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K7: dataInputSheet.form.assets_and_liabilities
                                  .cell.K7,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F17"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E18"].label }}
                            <p class="backup" v-if="showFormula">
                              E18 = {{ balanceSheet.cell["E18"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E18"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F18"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E19"].label }}
                            <p class="backup" v-if="showFormula">
                              E19 = {{ balanceSheet.cell["E19"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E19"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F19"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E20"].label }}
                            <p class="backup" v-if="showFormula">
                              E20 = {{ balanceSheet.cell["E20"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E20"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F20"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["E21"].label }}
                            <p class="backup" v-if="showFormula">
                              E21 = {{ balanceSheet.cell["E21"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E21"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P7: dataInputSheet.form.assets_and_liabilities
                                  .cell.P7,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F21"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["E22"].label }}
                            <p class="backup" v-if="showFormula">
                              E22 = {{ balanceSheet.cell["E22"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E22"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P11: dataInputSheet.form.assets_and_liabilities
                                  .cell.P11,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F22"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["E23"].label }}
                            <p class="backup" v-if="showFormula">
                              E23 = {{ balanceSheet.cell["E23"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E23"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K10: dataInputSheet.form.assets_and_liabilities
                                  .cell.K10,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F23"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E24"].label }}
                            <p class="backup" v-if="showFormula">
                              E24 = {{ balanceSheet.cell["E24"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E24"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K11: dataInputSheet.form.assets_and_liabilities
                                  .cell.K11,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F24"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E25"].label }}
                            <p class="backup" v-if="showFormula">
                              E25 = {{ balanceSheet.cell["E25"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E25"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P12: dataInputSheet.form.assets_and_liabilities
                                  .cell.P12,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F25"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E26"].label }}
                            <p class="backup" v-if="showFormula">
                              E26 = {{ balanceSheet.cell["E26"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E26"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P3: dataInputSheet.form.assets_and_liabilities
                                  .cell.P3,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F26"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["E27"].label }}
                            <p class="backup" v-if="showFormula">
                              E27 = {{ balanceSheet.cell["E27"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E27"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P33: dataInputSheet.form.income_and_expense.cell
                                  .P33,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F27"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E28"].label }}
                            <p class="backup" v-if="showFormula">
                              E28 = {{ balanceSheet.cell["E28"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["E28"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P9: dataInputSheet.form.assets_and_liabilities
                                  .cell.P9,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F28"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E29"].label }}
                            <p class="backup" v-if="showFormula">
                              E29 = {{ balanceSheet.cell["E29"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E29"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F29"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["E30"].label }}
                            <p class="backup" v-if="showFormula">
                              E30 = {{ balanceSheet.cell["E30"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["E30"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["F30"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="content-total-liabilities">
              <div class="box-content -content-percent">
                <div
                  id="balanceSheetChart2"
                  class="box-chart"
                  style="position: relative; width: 365px; height: 365px"
                >
                  <img
                    v-show="!enableChartJs"
                    src="../../assets/img/balance-sheet/img-chart-02.png"
                    alt
                  />
                  <div v-show="enableChartJs" class="absolute-center-parent">
                    <div>หนี้สินรวมและ<br />ความมั่งคั่งสุทธิ</div>
                  </div>
                  <div
                    style="
                      width: 365px;
                      height: 365px;
                      position: absolute;
                      z-index: 1;
                      top: 0;
                    "
                    v-show="enableChartJs"
                  >
                    <DoughnutChart
                      :width="365"
                      :height="365"
                      :chartDataSet="getChartDataSet('liabilities')"
                      :chartOptions="getChartOptions('liabilities')"
                    ></DoughnutChart>
                  </div>
                </div>

                <div class="detail">
                  <div class="section head">
                    <h3>
                      หนี้สินรวมและ
                      <br />ความมั่งคั่งสุทธิ
                    </h3>
                    <p>{{ balanceSheet.cell.I47.value | numeralFormat }}</p>
                  </div>
                  <div
                    class="section"
                    v-bind:class="
                      balanceSheet.cell.I13.value > 0 ? '-orange2' : ''
                    "
                  >
                    <div class="column-6">
                      <p>หนี้สินระยะสั้น</p>
                    </div>
                    <div class="column-6">
                      <p>
                        <span>{{
                          balanceSheet.cell.I13.value | numeralFormat
                        }}</span>
                        <span
                          >{{
                            balanceSheet.cell.J13.value
                              | numeralFormat("0,0[.]00")
                          }}%</span
                        >
                      </p>
                    </div>
                    <div class="column-12">
                      <div class="percent-bar">
                        <div
                          v-if="!balanceSheet.cell.J13.value"
                          class="bar"
                          v-bind:style="{
                            width: '0%',
                          }"
                        ></div>
                        <div
                          v-if="balanceSheet.cell.J13.value > 0"
                          class="bar"
                          v-bind:style="{
                            width: balanceSheet.cell.J13.value + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="section"
                    v-bind:class="
                      balanceSheet.cell.I28.value > 0 ? '-orange' : ''
                    "
                  >
                    <div class="column-6">
                      <p>หนี้สินระยะยาว</p>
                    </div>
                    <div class="column-6">
                      <p>
                        <span>{{
                          balanceSheet.cell.I28.value | numeralFormat
                        }}</span>
                        <span
                          >{{
                            balanceSheet.cell.J28.value
                              | numeralFormat("0,0[.]00")
                          }}%</span
                        >
                      </p>
                    </div>
                    <div class="column-12">
                      <div class="percent-bar">
                        <div
                          class="bar"
                          v-bind:style="{
                            width: balanceSheet.cell.J28.value + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="section"
                    v-bind:class="
                      balanceSheet.cell.I32.value > 0 ? '-blue' : ''
                    "
                  >
                    <div class="column-6">
                      <p>ความมั่งคั่งสุทธิ</p>
                    </div>
                    <div class="column-6">
                      <p>
                        <span>{{
                          balanceSheet.cell.I32.value | numeralFormat
                        }}</span>
                        <span
                          >{{
                            balanceSheet.cell.J32.value
                              | numeralFormat("0,0[.]00")
                          }}%</span
                        >
                      </p>
                    </div>
                    <div class="column-12">
                      <div class="percent-bar">
                        <div
                          class="bar"
                          v-bind:style="{
                            width: balanceSheet.cell.J32.value + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input type="checkbox" id="expand-liabilities" />
              <label class="btn-expand" for="expand-liabilities"></label>

              <div class="group-table-data">
                <div class="left">
                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.I13.value > 0 ? '-orange2' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>หนี้สินระยะสั้น</h3>
                        <h2>
                          {{ balanceSheet.cell.I13.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.J13.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>

                    <!-- table หนี้สินระยะสั้น (backup) -->
                    <table v-if="showBackup" class="backup">
                      <tbody>
                        <tr
                          v-for="(item, index) in [6, 7, 8, 9, 10]"
                          v-bind:key="index"
                        >
                          <td>
                            <span
                              >I{{ item }}
                              {{ balanceSheet.cell["I" + item].formula }}</span
                            >
                            {{ balanceSheet.cell["I" + item].label }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["I" + item].value
                                | numeralFormat
                            }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J" + item].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["I6"].label }}
                            <p class="backup" v-if="showFormula">
                              I6 = {{ balanceSheet.cell["I6"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["I6"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K18: dataInputSheet.form.assets_and_liabilities
                                  .cell.K18,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J6"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["I7"].label }}
                            <p class="backup" v-if="showFormula">
                              I7 = {{ balanceSheet.cell["I7"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["I7"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K17: dataInputSheet.form.assets_and_liabilities
                                  .cell.K17,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J7"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["I8"].label }}
                            <p class="backup" v-if="showFormula">
                              I8 = {{ balanceSheet.cell["I8"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["I8"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J8"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["I9"].label }}
                            <p class="backup" v-if="showFormula">
                              I9 = {{ balanceSheet.cell["I9"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["I9"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J9"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["I10"].label }}
                            <p class="backup" v-if="showFormula">
                              I10 = {{ balanceSheet.cell["I10"].formula }}
                            </p>
                          </td>
                          <td>
                            {{ balanceSheet.cell["I10"].value | numeralFormat }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J10"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    class="table-style"
                    v-bind:class="
                      balanceSheet.cell.I32.value > 0 ? '-blue' : ''
                    "
                  >
                    <header class="heading">
                      <div class="column">
                        <h3>ความมั่งคั่งสุทธิ (Net Worth)</h3>
                        <h2>
                          {{ balanceSheet.cell.I32.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.J32.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                  </div>
                </div>

                <div class="right">
                  <div class="table-style -orange">
                    <header class="heading">
                      <div class="column">
                        <h3>หนี้สินระยะยาว</h3>
                        <h2>
                          {{ balanceSheet.cell.I28.value | numeralFormat }}
                        </h2>
                      </div>
                      <div class="column">
                        <p>
                          {{
                            balanceSheet.cell.J28.value
                              | numeralFormat("0,0[.]00")
                          }}%
                        </p>
                      </div>
                    </header>
                    <!-- table หนี้สินระยะยาว (backup) -->
                    <table v-if="showBackup" class="backup">
                      <tbody>
                        <tr
                          v-for="(item, index) in [15, 16, 17, 18]"
                          v-bind:key="index"
                        >
                          <td>
                            <span
                              >I{{ item }}
                              {{ balanceSheet.cell["I" + item].formula }}</span
                            >
                            {{ balanceSheet.cell["I" + item].label }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["I" + item].value
                                | numeralFormat
                            }}
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J" + item].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["I15"].label }}
                            <p class="backup" v-if="showFormula">
                              I15 = {{ balanceSheet.cell["I15"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["I15"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                K19: dataInputSheet.form.assets_and_liabilities
                                  .cell.K19,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J15"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["I16"].label }}
                            <p class="backup" v-if="showFormula">
                              I16 = {{ balanceSheet.cell["I16"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["I16"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P18: dataInputSheet.form.assets_and_liabilities
                                  .cell.P18,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J16"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td>
                            {{ balanceSheet.cell["I17"].label }}
                            <p class="backup" v-if="showFormula">
                              I17 = {{ balanceSheet.cell["I17"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["I17"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P19: dataInputSheet.form.assets_and_liabilities
                                  .cell.P19,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J17"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ balanceSheet.cell["I18"].label }}
                            <p class="backup" v-if="showFormula">
                              I18 = {{ balanceSheet.cell["I18"].formula }}
                            </p>
                          </td>
                          <td>
                            <span v-show="!showEdit">
                              {{
                                balanceSheet.cell["I18"].value | numeralFormat
                              }}
                            </span>
                            <span
                              v-show="showEdit"
                              v-for="(item, index) in {
                                P20: dataInputSheet.form.assets_and_liabilities
                                  .cell.P20,
                              }"
                              v-bind:key="index"
                            >
                              <v-text-field
                                :ref="`dataInput.cell.${index}`"
                                :type="item.type"
                                :value="
                                  dataInputSheet.cell[index]
                                    ? dataInputSheet.cell[index].value
                                    : ''
                                "
                                @change="
                                  onDataInputChange(
                                    `dataInput.cell.${index}`,
                                    $event,
                                    item.type
                                  )
                                "
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            {{
                              balanceSheet.cell["J18"].value
                                | numeralFormat("0,0[.]00")
                            }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- Data -->
    <v-row class="" v-if="showBackup">
      <v-col cols="12">
        <h2>Balance Sheet</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(item, index) in balanceSheet.cell"
        v-bind:key="index"
      >
        <!-- <v-text-field
          :disabled="true"
          :dense="true"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
          :placeholder="item.placeholder ? item.placeholder : 'ระบุจำนวน'"
        ></v-text-field> -->

        <v-text-field
          :type="item.type"
          :label="`${index} ${item.label}`"
          :value="item.value != '' ? item.value : 0"
        ></v-text-field>
      </v-col>
    </v-row>

    <hr v-if="showBackup" style="border: 1px solid red" />
    <v-row
      v-if="showBackup"
      :set="(sheet = dataInputSheet.form.assets_and_liabilities)"
    >
      <v-col cols="12" class="sheet-heading">
        <h3>{{ sheet.heading }}</h3>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :md="3"
        v-for="(item, index) in sheet.cell"
        v-bind:key="index"
      >
        <v-text-field
          :ref="`dataInput.cell.${index}`"
          :type="item.type"
          :label="`${index} ${item.label}`"
          :suffix="item.suffix ? item.suffix : ''"
          :value="
            dataInputSheet.cell[index] ? dataInputSheet.cell[index].value : ''
          "
          @change="
            onDataInputChange(`dataInput.cell.${index}`, $event, item.type)
          "
        ></v-text-field>
      </v-col>
    </v-row>

    <div>
      <!-- <v-btn @click="print()">print</v-btn> -->
      <!-- SOURCE -->
      <!-- <div ref="printMe">
        <h1>Print me! สวัสดี</h1>
        <p>ทดสอบ แบบกำหนดรูปแบบ ทำ ที นี่ นู่น นั่น นี้ มี หรือ ไม่</p>
        <p>ภาษาไทย เป็นภาษาราชการของประเทศไทย และภาษาแม่ของชาวไทย และชนเชื้อสายอื่นในประเทศไทย ภาษาไทยเป็นภาษาในกลุ่มภาษาไท ซึ่งเป็นกลุ่มย่อยของตระกูลภาษาไท-กะได สันนิษฐานว่า ภาษาในตระกูลนี้มีถิ่นกำเนิดจากทางตอนใต้ของประเทศจีน และนักภาษาศาสตร์บางส่วนเสนอว่า ภาษาไทยน่าจะมีความเชื่อมโยงกับตระกูลภาษาออสโตร-เอเชียติก ตระกูลภาษาออสโตรนีเซียน และตระกูลภาษาจีน-ทิเบต</p>
        <p>ภาษาไทยเป็นภาษาที่มีระดับเสียงของคำแน่นอนหรือวรรณยุกต์เช่นเดียวกับภาษาจีน และออกเสียงแยกคำต่อคำ ทำให้เป็นที่ลำบากของชาวต่างชาติเนื่องจากการออกเสียงวรรณยุกต์ที่เป็นเอกลักษณ์ของแต่ละคำ และการสะกดคำที่ซับซ้อน</p>
        <p>คำว่า ไทย หมายความว่า อิสรภาพ เสรีภาพ หรืออีกความหมายหนึ่งคือ ใหญ่ ยิ่งใหญ่ เพราะการจะเป็นอิสระได้จะต้องมีกำลังที่มากกว่า แข็งแกร่งกว่า เพื่อป้องกันการรุกรานจากข้าศึก คำนี้เป็นคำไทยแท้ที่เกิดจากการสร้างคำที่เรียก "การลากคำเข้าวัด" ซึ่งเป็นการลากความวิธีหนึ่ง ตามหลักคติชนวิทยา คนไทยเป็นชนชาติที่นับถือกันว่า ภาษาบาลี ซึ่งเป็นภาษาที่บันทึกพระธรรมคำสอนของพระพุทธเจ้าเป็นภาษาอันศักดิ์สิทธิ์และเป็นมงคล เมื่อคนไทยต้องการตั้งชื่อประเทศว่า ไท ซึ่งเป็นคำไทยแท้ จึงเติมตัว ย เข้าไปข้างท้าย เพื่อให้มีลักษณะคล้ายคำในภาษาบาลี - สันสกฤตเพื่อความเป็นมงคลตามความเชื่อของตน ภาษาไทยจึงหมายถึงภาษาของชนชาติไทยผู้เป็นไทนั่นเอง</p>
        <template>
          <v-row>
            <v-col cols="12" md="4">
              <LineChartDemo></LineChartDemo>
            </v-col>
            <v-col cols="12" md="6">
              <LineChart></LineChart>
            </v-col>
            <v-col cols="12" md="6">
              <DoughnutChart></DoughnutChart>
            </v-col>
          </v-row>
          <div style="width:400px;"></div>
        </template>

        <template>
          <div style="width:400px;"></div>
        </template>
      </div> -->
      <!-- OUTPUT -->
      <!-- <img :src="output" /> -->
    </div>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import domtoimage from "dom-to-image";
import moment from "moment";
import jsPDF from "jspdf";

import LineChart from "../../components/ChartJs/LineChart.js";
import DoughnutChart from "../ChartJs/DoughnutChart.js";

Vue.filter("phone", function (phone) {
  if (!phone) {
    return "";
  }
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
});

export default {
  name: "balance-sheet-component",
  components: {
    LineChart,
    DoughnutChart,
  },
  props: {
    printAble: Boolean,
    enableChartJs: Boolean,
    enableFormula: Boolean,
  },
  computed: {
    ...mapGetters([
      "dataInputSheet",
      "balanceSheet",
      "cashFlow",
      "financialRatio",
      "planItem",
      "user",
    ]),
  },
  data: function () {
    return {
      output: null,
      btnEditColor: "default",
      btnFormulaColor: "default",
      showBackup: false,
      showFormula: false,
      showEdit: false,
    };
  },
  created() {
    console.log("balanceSheet - created");
  },
  mounted() {
    console.log("balanceSheet - mounted");
  },
  methods: {
    ...mapActions(["updateDataInput", "saveDataInput"]),
    onDataInputChange: function (name, value, type) {
      var keys = name.split(".");
      var sheet = keys[0];
      var cell = keys[2];
      // console.log({
      //   sheet: sheet,
      //   cell: cell,
      //   value: value,
      //   type: type,
      // });

      // this.updateDataInput({
      //   sheet: "dataInput",
      //   cell: "K3",
      //   value: 999,
      //   type: "number",
      // });
      this.updateDataInput({
        sheet: sheet,
        cell: cell,
        value: value,
        type: type,
      });
    },
    toggleShowEdit() {
      this.showEdit = !this.showEdit;
      if (this.showEdit) {
        this.btnEditColor = "warning";
      } else {
        this.btnEditColor = "default";
      }
    },
    toggleShowFormula() {
      this.showFormula = !this.showFormula;
      if (this.showFormula) {
        this.btnFormulaColor = "warning";
      } else {
        this.btnFormulaColor = "default";
      }
    },
    saveChanges() {
      this.showEdit = false;
      this.btnEditColor = "default";
      this.$parent.$refs.dataInputComponent.saveChanges();
    },
    async print() {
      this.$parent.isLoading = true;
      // const el = this.$refs.printMe1;
      const el = document.getElementById("balanceSheetCard");
      console.log(el);
      let png = await domtoimage
        .toPng(el, { quality: 0.95 })
        .then((dataUrl) => {
          this.output = dataUrl;
          // return dataUrl;
        })
        .catch(function (error) {
          //console.error("oops, something went wrong!", error);
        });

      // let png_dimesion = await this.addImageProcess(png)
      //   .then(obj => {
      //     return obj;
      //   })
      //   .catch(function(error) {
      //     console.error("oops, something went wrong! - addImageProcess", error);
      //   });

      /**
       * A4 Paper dimesion
       * https://www.graphic-design-employment.com/a4-paper-dimensions.html
       * Standard ISO A4 paper dimensions are:
       * 210mm x 297mm
       * 8.27" x 11.69"
       *
       * Equivalent A4 paper dimensions in pixels at 300 DPI and 72 DPI respectively are:
       * 2480 pixels x 3508 pixels (print resolution)
       * 595 pixels x 842 pixels (screen resolution)
       */

      // calculate aspect ratio
      // let x = 5;
      // let y = 5;

      // var doc = new jsPDF();
      // for (var i = 0; i < 2; i++) {
      //   let w1 = png_dimesion.width;
      //   let h1 = png_dimesion.height;
      //   let w2 = 200;
      //   let h2 = (h1 / w1) * w2;
      //   let pageHeight = doc.internal.pageSize.height;

      //   if (y + h2 >= pageHeight) {
      //     doc.addPage();
      //     y = 5;
      //   }
      //   doc.addImage(png, "PNG", x, y, w2, h2);
      //   y = y + h2;
      // }
      // doc.save("a4.pdf");
      this.$parent.isLoading = false;
    },
    async addImageProcess(src) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = reject;
        img.src = src;
      });
    },
    exportPDF() {
      alert("exportPDF");
    },
    getChartOptions(section) {
      return {};
    },
    getChartDataSet(section) {
      let dataset = {};
      if (section == "assets") {
        dataset.labels = [
          "สินทรัพย์สภาพคล่อง",
          "สินทรัพย์เพื่อการลงทุน",
          "สินทรัพย์ส่วนตัว/มีค่า",
          "สินทรัพย์ไม่มีตัวตน",
        ];
        // dataset.data = [8200000, 4285000, 33900000, 0];
        // dataset.backgroundColor = ["#71cbb7", "#95d6ca", "#b3dfdb", "#e1e1e1"];
        if (this.balanceSheet.cell.E13.value != undefined) {
          dataset.data = [
            this.balanceSheet.cell.E13.value,
            this.balanceSheet.cell.E31.value,
            this.balanceSheet.cell.E41.value,
            this.balanceSheet.cell.E46.value,
          ];
        }
        dataset.backgroundColor = ["#1284aa", "#0741a1", "#71cbb7", "#55a2a1"];
        return dataset;
      } else if (section == "liabilities") {
        dataset.labels = [
          "หนี้สินระยะสั้น",
          "หนี้สินระยะยาว",
          "ความมั่งคั่งสุทธิ",
        ];
        // dataset.data = [0, 7090000, 48295000];
        if (this.balanceSheet.cell.E13.value != undefined) {
          dataset.data = [
            this.balanceSheet.cell.I13.value,
            this.balanceSheet.cell.I28.value,
            this.balanceSheet.cell.I32.value,
          ];
        }
        dataset.backgroundColor = ["#fcaa7e", "#fd8d50", "#0741a1"];
        return dataset;
      } else {
        return {};
      }
    },
  },
  filters: {
    momentTH: function (date) {
      moment.locale("th_TH");
      var year = parseInt(moment(date).format("YYYY")) + 543;
      return moment(date).format("DD MMMM") + " " + year;
    },
  },
};
// addImage(imageData, format, x, y, width, height, alias, compression, rotation)
</script>

