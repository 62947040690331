<template>
<v-row align="center" justify="center">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <v-col cols="12" sm="8" md="4">
        <div style="text-align: center;margin-bottom:20px;">
            <img src="../../assets/img/logo/Estest_vertical_crop.png" />
        </div>
        <v-card>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-card-text>
                <v-form @submit.prevent="login">
                    <v-text-field v-model="credential.email" label="Login" name="login" prepend-icon="person" type="text" />
                    <v-text-field v-model="credential.password" id="password" label="Password" name="password" prepend-icon="lock" type="password" />
                    <v-btn v-show="false" class="mr-4" type="submit" :disabled="invalid">
                        submit
                    </v-btn>
                </v-form>
                <b>ลืมรหัสผ่าน กรุณาติดต่อเจ้าหน้าที่</b>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="login()">Login</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unreachable */
import Vue from "vue";
import firebase from 'firebase/compat/app';
import _ from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "@/router";

export default {
    name: "login",
    components: {
        Loading,
    },
    data: () => ({
        drawer: null,
        isLoading: false,
        credential: {
            email: "",
            password: "",
        },
    }),
    created() {
        this.$vuetify.theme.dark = false;
    },
    mounted() {},
    computed: {},
    methods: {
        async login() {         
            this.isLoading = true;
            await firebase
                .auth()
                .signInWithEmailAndPassword(
                    this.credential.email,
                    this.credential.password
                )
                .then(
                    (user) => {
                        this.isLoading = false;
                        if (this.$route.query.redirect) {
                            router.push(this.$route.query.redirect);
                        } else {
                            router.push("/dashboard");
                        }
                    },
                    (err) => {
                        this.isLoading = false;
                        Vue.notify({
                            group: "auth",
                            type: "error",
                            title: "Important message",
                            text: err.message,
                        });
                    }
                );
                return false;
        },
    },
};
</script>
