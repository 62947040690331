import Full from "@/views/container/Full.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import DashboardCalculate from "@/views/dashboard/DashboardCalculate.vue";

import userRoutes from "./user.js";
import planRoutes from "./plan.js";

export default {
  path: "/dashboard",
  component: Full,
  // redirect: "/dashboard",
  children: [
    {
      path: "/dashboard",
      meta:{
        requiresAuth:true
      },
      component: Dashboard
    },
    {
      path: "/dashboard-calculate",
      meta:{
        requiresAuth:true
      },
      component: DashboardCalculate
    },
    ...userRoutes,
    ...planRoutes
  ]
};
