<template>
  <v-container class="fluid pa-0" id="user">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>

    <!-- {{getUserListsData}} -->
    <v-card>
      <v-card-title>
        Users Management
        <v-btn
          class="ma-4"
          color="primary"
          tile
          small
          @click="open_user_form_dialog()"
        >
          <v-icon small left>mdi-plus</v-icon>Add New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="users_dttb.search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- User lists data table -->
      <v-data-table
        :headers="users_dttb.headers"
        :items="users_dttb.items"
        :search="users_dttb.search"
        sort-by="displayName"
      >
        <!-- <template v-slot:item.photoURL="{ item }">
                <v-avatar size="36">
                    <img :src="(item.photoURL!='')?item.photoURL:`../../assets/img/avatar.png`" alt />
                </v-avatar>
            </template> -->
        <template v-slot:item.role="{ item }">
          <span>{{
            item.role.charAt(0).toUpperCase() + item.role.slice(1)
          }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <span
            v-if="
              currentUser.role == 'admin' ||
              (currentUser.role == 'manager' && item.role != 'admin')
            "
          >
            <v-btn
              class="ma-1"
              color="success"
              tile
              small
              @click="open_user_form_dialog(item)"
            >
              <v-icon small left>mdi-pencil</v-icon>Edit
            </v-btn>
            <v-btn
              class="ma-1"
              color="error"
              tile
              small
              @click="open_user_delete_dialog(item)"
            >
              <v-icon small left>mdi-delete</v-icon>Delete
            </v-btn>
          </span>
        </template>
      </v-data-table>
    </v-card>

    <!-- User add/edit form dialog -->
    <v-dialog v-model="user_form_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ user_form_dialog_heading }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="user.firstname"
                    dense
                    label="Firstname"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="user.lastname"
                    dense
                    label="Lastname"
                    required
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                                <v-text-field v-model="user.photoURL" dense label="Photo" required></v-text-field>
                            </v-col> -->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="user.phoneNumber"
                    dense
                    label="Telephone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="user.role"
                    dense
                    :items="[
                      { text: 'Admin', value: 'admin' },
                      { text: 'Manager', value: 'manager' },
                      { text: 'Staff', value: 'staff' },
                    ]"
                    label="Role"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="user.email"
                    dense
                    label="Email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="user.password"
                    dense
                    label="Password"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            small
            @click="close_user_form_dialog()"
            >Close</v-btn
          >
          <v-btn
            v-if="!user._id"
            color="blue darken-1"
            dark
            small
            @click="user_add()"
            >Add New User</v-btn
          >
          <v-btn
            v-if="user._id"
            color="blue darken-1"
            dark
            small
            @click="user_edit(user._id)"
            >Update User</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- User delete confirm dialog -->
    <v-dialog v-model="user_delete_dialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">Delete this User?</v-card-title>
        <v-card-text>
          Are you sure to DELETE
          <b>"{{ user.firstname }} {{ user.lastname }}"</b> this all user data
          will be losts. Let Google help apps determine location. This means
          sending anonymous location data to Google, even when no apps are
          running.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template>
            <v-btn
              color="primary darken-1"
              text
              small
              @click="close_user_delete_dialog()"
              >Close</v-btn
            >
            <v-btn color="error darken-1" dark small @click="user_delete(user)"
              >Delete</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-unreachable */
import Vue from "vue";
import firebase from 'firebase/compat/app';

import _ from "lodash";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "user",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["drawer", "isAuthenticated", "userLists"]),
    // https://vuex.vuejs.org/guide/getters.html
    // If you want to map a getter to a different name, use an object:
    ...mapGetters({
      currentUser: "user",
    }),
    getUserListsData() {
      return this.userLists;
    },
  },
  async created() {
    // await this.getUserLists();
    // console.log(firebase.auth().currentUser);
  },
  mounted() {
    this.isLoading = true;
    // RTDB for users
    firebase
      .database()
      .ref("/users")
      .on("value", (snapshot) => {
        console.log("ManageUser.mounted - firebase /users");
        this.users = snapshot.val();
        console.log(snapshot.val());
        this.users_dttb.items = _.chain(snapshot.val())
          .map(function (item, index) {
            item._id = index;
            return item;
          })
          .value();
        this.isLoading = false;
      });
  },
  data() {
    return {
      isLoading: true,
      users: [],
      user: {
        firstname: "",
        lastname: "",
        photoURL: "",
        phoneNumber: "",
        email: "",
        password: "",
      },
      dialog: false,
      user_form_dialog: false,
      user_form_dialog_heading: "User Add",
      user_form_dialog_submit_btn_text: "Add New User",
      user_delete_dialog: false,
      users_dttb: {
        search: "",
        headers: [
          // { text: "ID", value: "_id", sortable: false },
          // {
          //     text: "Photo",
          //     value: "photoURL",
          //     sortable: false
          // },
          {
            text: "Name",
            value: "displayName",
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Role",
            value: "role",
          },
          {
            text: "Telephone",
            value: "phoneNumber",
          },

          {
            text: "Actions",
            value: "action",
            sortable: false,
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    ...mapActions(["getUserLists"]),
    /**
     * --------------------------------------------------------------
     * Dialog control
     * --------------------------------------------------------------
     */

    // USER_FORM
    open_user_form_dialog(item) {
      this.user_form_dialog_heading = "User Add";
      this.user_form_dialog_submit_btn_text = "Add New User";
      this.user = {
        firstname: "",
        lastname: "",
        photoURL: "",
        phoneNumber: "",
        email: "",
        password: "",
        role: "staff",
      };
      if (item !== undefined) {
        this.user = item;
        this.user_form_dialog_heading = "User Edit";
        this.user_form_dialog_submit_btn_text = "Update User";
      }
      this.user_form_dialog = true;
    },
    close_user_form_dialog() {
      this.user = {};
      this.user_form_dialog = false;
      this.$refs.form.reset();
    },

    // CONFIRM_DELETE
    open_user_delete_dialog(item) {
      this.user = item;
      this.user_delete_dialog = true;
    },
    close_user_delete_dialog() {
      this.user = {};
      this.user_delete_dialog = false;
    },

    /**
     * --------------------------------------------------------------
     * Firebase realtime database ref /user manage
     * --------------------------------------------------------------
     */

    // USER_ADD
    async user_add() {
      this.isLoading = true;
      const firebaseAuthcreateUser = firebase
        .functions()
        .httpsCallable("firebaseAuthcreateUser");
      const newUser = {
        email: this.user.email,
        password: this.user.password,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        displayName: this.user.firstname + " " + this.user.lastname,
        phoneNumber: this.user.phoneNumber,
        photoURL: this.user.photoURL,
        role: this.user.role,
      };

      try {
        const result = await firebaseAuthcreateUser(newUser);
        if (result.data.status == 1) {
          this.close_user_form_dialog();
        } else if (result.data.status == 0) {
          if (result.data.error.errorInfo.message) {
            Vue.notify({
              group: "auth",
              type: "error",
              title: "Important message",
              text: result.data.error.errorInfo.message,
            });
          }
        }
        console.log(result);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    // USER_EDIT
    async user_edit() {
      this.isLoading = true;
      const firebaseAuthupdateUser = firebase
        .functions()
        .httpsCallable("firebaseAuthupdateUser");
      const updateUser = {
        email: this.user.email,
        password: this.user.password,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        displayName: this.user.firstname + " " + this.user.lastname,
        phoneNumber: this.user.phoneNumber,
        photoURL: this.user.photoURL,
        role: this.user.role,
      };
      try {
        const result = await firebaseAuthupdateUser({
          uid: this.user.uid,
          user: updateUser,
        });
        if (result.data.status == 1) {
          this.close_user_form_dialog();
        } else {
          Vue.notify({
            group: "auth",
            type: "error",
            title: "Important message",
            text: JSON.stringify(result.data.error),
          });
        }
        console.log("ManageUser.fbfn.firebaseAuthupdateUser");
        console.log(result);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    // DELETE_USER
    async user_delete(user) {
      this.isLoading = true;
      const firebaseAuthdeleteUser = firebase
        .functions()
        .httpsCallable("firebaseAuthdeleteUser");
      try {
        const result = await firebaseAuthdeleteUser(user);
        if (result.data.status == 0) {
          Vue.notify({
            group: "auth",
            type: "error",
            title: "Important message",
            text: JSON.stringify(result.data.error),
          });
        } else {
          Vue.notify({
            group: "auth",
            type: "success",
            title: "Important message",
            text: JSON.stringify("Deleted user successfully"),
          });
        }
        this.close_user_delete_dialog();
        console.log("ManageUser.fbfn.firebaseAuthdeleteUser");
        console.log(result);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
