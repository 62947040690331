// plan management
import ManagePlan from "@/views/plan/ManagePlan.vue";
import Plan from "@/views/plan/Plan.vue";
import PlanAdd from "@/views/plan/PlanAdd.vue";

// import PlanEdit from "@/views/dashboard/plan/PlanEdit.vue";
import PlanDetail from "@/views/dashboard/plan/PlanDetail.vue";

export default [
  {
    path: "/manage/plan",
    component: ManagePlan,
    meta: {
      requiresAuth: true,
      requiresManager:true,
    }
  },
  {
    path: "/plan",
    component: Plan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/plan-add",
    name: 'plan-add',
    component: PlanAdd
  },
  {
    path: "/plan-edit/:id",
    name: 'plan-edit',
    component: PlanAdd
  },
  {
    path: "/plan/detail/:id",
    name: 'plan-detail',
    component: PlanDetail
  }
];
