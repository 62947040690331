<template>
    <v-content>
      <v-container>
        Plan Detail Page
      </v-container>
    </v-content>
</template>

<script>
export default {
};
</script>