import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";

//routes
import demoRoutes from "./demo.js";
import dashboardRotes from "./dashboard.js";

// session component
import Login from "@/views/auth/Login.vue";
import Logout from "@/views/auth/Logout.vue";

// errors
import error403 from "@/views/errors/403.vue";
import error404 from "@/views/errors/404.vue";

Vue.use(VueRouter);

const routes = [
  demoRoutes,
  dashboardRotes,
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/auth/login",
    name: "Login",
    component: Login
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/403",
    name: "403",
    component: error403,
  },
  {
    path: "/404",
    name: "404",
    component: error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
